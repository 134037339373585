import React from 'react'
import Libraries from "./Helpers/Libraries";

export default function SDKs() {
	return (
		<>
			<h1>
				Dice FunX
			</h1>
			<p className="lead">The recommended way to integrate with the DICE ecosystem is by using one of our official FunX boilerplate. Today, Dice offers fine-tuned JavaScript, Ruby, PHP, Python, and Go libraries to make your integration process easier and give you the best experience when consuming the API.</p>
			<Libraries/>
		</>
	)
}
