export default {
  title: 'Hotel Apis',
  links: [
    {
      title: 'Static Hotels',
      href: '/docs/hotels/static-hotels',
    },
    {
      title: 'Search Hotels',
      href: '/docs/hotels/search-hotels',
    },
    {
      title: 'Hotel Details',
      href: '/docs/hotels/hotel-details',
    },
    {
      title: 'Hotel Rooms',
      href: '/docs/hotels/hotel-rooms',
    },
    {
      title: 'Hold Hotel Rooms',
      href: '/docs/hotels/hold-hotel',
    },
    {
      title: 'Book Hotel',
      href: '/docs/hotels/book-hotel',
    },
    {
      title: 'Get Booking',
      href: '/docs/hotels/order-details',
    },
    {
      title: 'Refund Details',
      href: '/docs/hotels/refund-details',
    },
    {
      title: 'Cancel Booking',
      href: '/docs/hotels/cancel-booking',
    },
    {
      title: 'Cancellation Charges',
      href: '/docs/hotels/cancellation-charges',
    },
  ],
};
