import { Col, Properties, Property, Row } from '../../../Common/Property';
import { CodeGroup } from '../../../Common/Code';
import Eyebrow from '../../../Common/Eyebrow';
import { Note } from '../../../Common/Note';

export default function Docs() {
  return (
    <>
      <Eyebrow label='/flight/sandbox/domestic/review' tag='POST' />
      <h1>Review Flight</h1>
      <p>
        As the name suggests, employees are a core part of Dice — the very
        reason Dice exists is so you can provide secure spends management to
        your employees. On this page, we'll dive into how to create employees
        programmatically.
      </p>
      <h2>Request Model</h2>
      <p className='lead'>
        Details of all the parameters that can be passed in the request.
      </p>
      <Row>
        <Col>
          <Properties>
            <Property name='inventoryId' type='string'>
              Value can be extracted from Seat Map api response.
              <br />
              search.results[:].key
            </Property>
            <Property name='fareId' type='string'>
              Value can be extracted from Seat Map api response.
              <br />
              search.results[:].fares[:].key
            </Property>
            <Note>
              <code>inventoryId</code> & <code>fareId</code> are valid for only
              15 mins
            </Note>
          </Properties>
        </Col>
        <Col>
          <CodeGroup
            tag='POST'
            label='/flight/sandbox/domestic/review'
            title='Request Payload'
          >
            <code title='cURL'>
              curl --request POST \ <br />
              --url /flight/sandbox/domestic/review \ <br />
              --header 'Origin: Dice' \ <br />
              --header 'accept: application/json' \ <br />
              --header 'content-type: application/json' \ <br />
              --data '
              {JSON.stringify({
                inventoryId: 'search.results[:].key',
                fareId: 'search.results[:].fares[:].key',
              })}
              '
            </code>
          </CodeGroup>

          <CodeGroup title='Response Pay Load'>
            <code title='json'>
              {JSON.stringify(
                {
                  flowId: 'TJ103754037515@TJ_FLIGHT',
                  search: {
                    origin: {
                      code: 'DEL',
                      id: 1,
                      airport: 'Indira Gandhi International Airport',
                      city: 'Delhi',
                      country: 'INDIA',
                      terminal: '-',
                    },
                    destination: {
                      code: 'BOM',
                      id: 2,
                      airport: 'Chhatrapati Shivaji International Airport',
                      city: 'Mumbai',
                      country: 'INDIA',
                      terminal: '-',
                    },
                    count: {
                      adult: 1,
                      child: 0,
                      infant: 0,
                    },
                    date: '24-01-2023',
                    cabinClass: 'ECONOMY',
                  },
                  success: true,
                  review: {
                    flowId: 'TJ103754037515',
                    code: 200,
                    alerts: [],
                    fare: {
                      offeredFare: 5497.22,
                      publishedFare: 5823,
                      baseFare: 4830,
                      taxes: {
                        total: 993,
                        breakups: {
                          'Carrier Misc': 0,
                          'Fuel Surcharge': 0,
                          'Management Fee Tax': 0,
                          'Other Charges': 733,
                          'Management Fees': 0,
                          'Gst Charges': 260,
                        },
                      },
                    },
                    segments: [
                      {
                        id: '973',
                        meals: [
                          {
                            code: 'CRPX',
                            name: 'Free Corporate Meal',
                          },
                        ],
                        logo: 'https://static.eka.io/airline/G8.png',
                        flightCode: 'G8',
                        airline: 'Go First',
                        rank: 0,
                        flightNumber: '354',
                        durationInMin: 130,
                        departure: {
                          code: 'DEL',
                          airport: 'Delhi Indira Gandhi Intl',
                          city: 'Delhi',
                          country: 'India',
                          terminal: 'Terminal 2',
                        },
                        departureTime: 1674579600000,
                        departureDate: '2023-01-24T22:30',
                        arrival: {
                          code: 'BOM',
                          airport:
                            'Chhatrapati Shivaji Maharaj International Airport',
                          city: 'Mumbai',
                          country: 'India',
                          terminal: 'Terminal 1',
                        },
                        arrivalTime: 1674587400000,
                        arrrivalDate: '2023-01-25T00:40',
                      },
                    ],
                    baggages: [],
                    condition: {
                      passportMandatory: false,
                      dateOfBirth: false,
                      infantDob: true,
                      gstRequired: false,
                      gstApplicable: true,
                    },
                    fares: {
                      fareTypeKey: 'CORPORATE',
                      fareType: 'LOADED',
                      adult: {
                        baggage: {
                          checkIn: '15 Kg',
                          cabin: '7 Kg',
                        },
                        cancellationCharges: {
                          type: 'REFUNDABLE',
                        },
                        ancillery: {
                          meal: false,
                          seat: false,
                          dateChange: false,
                        },
                        remainingSeats: 9,
                        offeredFare: 5497.22,
                        publishedFare: 5823,
                        comission: 325.78,
                        bookingClass: 'BC',
                        fareBasis: 'ZO9CBINE',
                        cabinClass: 'ECONOMY',
                        baseFare: 4830,
                        taxes: {
                          total: 993,
                          breakups: {
                            'Carrier Misc': 0,
                            'Fuel Surcharge': 0,
                            'Management Fee Tax': 0,
                            'Other Charges': 733,
                            'Management Fees': 0,
                            'Gst Charges': 260,
                          },
                        },
                      },
                    },
                  },
                },
                null,
                2
              )}
            </code>
          </CodeGroup>
        </Col>
      </Row>
    </>
  );
}
