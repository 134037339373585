import React from 'react'
import {Route, Routes} from "react-router-dom";
import CreateEmployees from './CreateEmployees'
import CreateTags from './CreateTags'

export default function index() {
	return (
		<Routes>
			<Route element={<CreateEmployees/>} index/>
			<Route element={<CreateTags/>} path="/tags"/>
		</Routes>
	)
}
