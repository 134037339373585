import React from 'react'
import { CodeGroup} from "./Common/Code";
import ParamSyntax from "./Common/ParamSyntax";
import CodeSyntax from "./Common/CodeSyntax";
import VariableSyntax from "./Common/VariableSyntax";
import TextSyntax from "./Common/TextSyntax";
import {Properties, Property} from "./Common/Property";

export default function Webhook(){
	return(
		<>
			<h1>
				Webhooks
			</h1>
			<p className="lead">
				In this guide, we will look at how to register and consume webhooks to integrate your app with Dice. With webhooks, your app can know when something happens in Dice, such as someone creating a trip or adding a expense.
			</p>
			<h2>
				Registering webhooks
			</h2>
			<div className="lead">
				To register a new webhook, you need to have a URL in your app that Dice can call. You can configure a new webhook from the Dice dashboard under  <span style={{color:"var(--tw-prose-links)",fontWeight:600}}>Control Room  » Dice Studio » Add Integration » Push Integration</span>. Give your webhook a name, pick the events you want to listen for, and add your other configuration.
				<br/><br/>
				Now, whenever something of interest happens in your app, a webhook is fired off by Dice. In the next section, we'll look at how to consume webhooks.
			</div>
			<h2>
				Consuming Webhooks
			</h2>
			<p className="lead">
				When your app receives a webhook request from Dice, check the <code>type</code> attribute to see what event caused it. The first part of the event type will tell you the payload type, e.g., a trip, expense, etc.
			</p>
			<CodeGroup title="Sample Payload">
				<code>
					{JSON.stringify({
						"event_id": "a056V7R7NmNRjl70",
						"type": "trips.create",
						"data": {
							"id": "WAz8eIbvDR60rouK"
						},
						"config":{

						}
					},null,2)
					}
				</code>
			</CodeGroup>
			<p className="lead">
				In the example above, a trip was <code>created</code>, and the payload type is a <code>trips</code>.
			</p>
			<h2>
				Security
			</h2>
			<p className="lead">
				To know for sure that a webhook was, in fact, sent by Dice instead of a malicious actor, you can verify the request signature. Each webhook request contains a header named `x-dice-signature`, and you can verify this signature by using your secret webhook key. The signature is an HMAC hash of the request payload hashed using your secret key. Here is an example of how to verify the signature in your app:
			</p>
			<CodeGroup title="Verifying a request">
				<code language="js">
					const signature = req.headers[<ParamSyntax>x-dice-signature</ParamSyntax>] <br/>
					const hash = crypto.<CodeSyntax>createHmac(<ParamSyntax>sha256</ParamSyntax>, <TextSyntax>secret</TextSyntax>).update(<TextSyntax>payload</TextSyntax>).digest(<ParamSyntax>hex</ParamSyntax>)</CodeSyntax><br/>
					<VariableSyntax>if</VariableSyntax> (hash === signature) &#123;<br/>
					// Request is verified<br/>
					&#125;<VariableSyntax>else</VariableSyntax> &#123;<br/>
						// Request could not be verified<br/>
					&#125;
					<br/>
				</code>
				<code language="python">
					<VariableSyntax>from</VariableSyntax> flask <VariableSyntax>import</VariableSyntax> request<br/>
					<VariableSyntax>import</VariableSyntax> hashlib<br/>
					<VariableSyntax>import</VariableSyntax> hmac<br/>
					signature = request.headers.get(<ParamSyntax>x-protocol-signature</ParamSyntax>)<br/>
					hash = hmac<CodeSyntax>.new(bytes(<TextSyntax>secret</TextSyntax>, <ParamSyntax>ascii</ParamSyntax>), bytes(<TextSyntax>payload</TextSyntax>, <ParamSyntax>ascii</ParamSyntax>), <TextSyntax>hashlib.sha256</TextSyntax>)</CodeSyntax><br/>
					<VariableSyntax>if</VariableSyntax> <CodeSyntax>hash.hexdigest()</CodeSyntax> <VariableSyntax>==</VariableSyntax> signature:<br/>
					# Request is verified<br/>
					<VariableSyntax>else:</VariableSyntax><br/>
					# Request could not be verified<br/>
				</code>
				<code language="php">
					$signature = $request[<ParamSyntax>headers</ParamSyntax>][<ParamSyntax>x-dice-signature</ParamSyntax>];<br/>
					$hash = <CodeSyntax>hash_hmac(<ParamSyntax>sha256</ParamSyntax>, $payload, $secret);</CodeSyntax><br/>
					<br/>
					<VariableSyntax>if</VariableSyntax> (<CodeSyntax>hash_equals($hash, $signature)</CodeSyntax>) &#123;<br/>
					// Request is verified<br/>
					} <VariableSyntax>else</VariableSyntax> &#123;<br/>
						// Request could not be verified<br/>
					}<br/><br/>
				</code>
			</CodeGroup>
			<h2>
				All Events
			</h2>
			<p className="lead">
				All of the event types are listed below.
			</p>
			<Properties>
				<Property name="trips.create">
					This event is triggered when a new trip is created
				</Property>
				<Property name="trips.approved">
					This event is triggered when a new trip is approved by his manager
				</Property>
			</Properties>

		</>
	)
}
