import { Col, Properties, Property, Row } from '../../Common/Property';
import { CodeGroup } from '../../Common/Code';
import Eyebrow from '../../Common/Eyebrow';

const ROUTE = '/hotel/search';

export default function Docs() {
  return (
    <>
      <Eyebrow label={ROUTE} tag='POST' />
      <h1>Search Hotels</h1>
      <p>
        This endpoint provide all the available hotels in a city with their min price per night.
      </p>
      <h2>Request Model</h2>
      <p className='lead'>
        Details of all the parameters that can be passed in the request.
      </p>
      <Row>
        <Col>
          <Properties>
            <Property name='city' type='string'>
              Name of city.
            </Property>
            <Property name='country' type='string'>
              Name of countries.
            </Property>
            <Property name='stay' type='object'>
              Stay dates in which you want to filter results.
            </Property>
            <Property name='rooms' type='object'>
              Number of rooms you want to search for.
            </Property>
          </Properties>
        </Col>
        <Col>
          <CodeGroup tag='POST' label={ROUTE} title='Request Payload'>
            <code title='json'>
              {JSON.stringify({rooms:[{adult: 2,child:1, childAges: [12]}],stay:{in:"27-05-2023",out:"28-05-2023"},city:"Panipat",country:"India"},null,2)}
            </code>
          </CodeGroup>

          <CodeGroup title='Response Pay Load'>
            <code title='json'>
              {JSON.stringify(
                {
                  sessionId: '63d1a2be46e0fb0008b52642',
                  success: true,
                  request: {
                    city: 'Karnal',
                    country: 'India',
                    rooms: [
                      {
                        adult: 2,
                        child: 1,
                        childAges: [3],
                      },
                    ],
                    dates: {
                      in: '15-02-2023',
                      out: '17-02-2023',
                    },
                  },
                  results: [
                    {
                      id: 'ChIJ47SE3z9xDjkRSdO1WnA3R5Y',
                      name: 'Hotel Dreamz Residency',
                      providerKey: 'CL_HOTEL',
                      type: 'HOTEL',
                      starRating: 3,
                      score: 0,
                      lat: 29.6901063,
                      lng: 76.98872879999999,
                      city: 'Karnal',
                      address:
                        ' Bus Stand, Old GT Road Near, Karnal, Haryana 132001, Karnal, Haryana, 132001, India',
                      image:
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3962/3962349/images/HO_peLCQt.jpg',
                      pricePerNight: 2515,
                      available: true,
                      amenities: {},
                    },
                    {
                      id: 'ChIJC_hSL1dlDjkR0oyqLEAK9f0',
                      name: 'Noormahal Palace Hotel',
                      providerKey: 'CL_HOTEL',
                      type: 'HOTEL',
                      starRating: 5,
                      score: 0,
                      lat: 29.698193,
                      lng: 77.0304037,
                      city: 'Karnal',
                      address:
                        'Noormahal X-ing, NH-1, Sector 32, Sector 32, Karnal, Haryana, 132001, India',
                      image:
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3938/3938864/images/Dining.jpg',
                      pricePerNight: 12830,
                      available: true,
                      amenities: {},
                    },
                    {
                      id: 'ChIJXw22sRRwDjkRad9AlWZVNzg',
                      name: 'Hotel Rest Inn',
                      providerKey: 'CL_HOTEL',
                      type: 'HOTEL',
                      starRating: 3,
                      score: 0,
                      lat: 29.6751043,
                      lng: 76.99299549999999,
                      city: 'Karnal',
                      address:
                        'Meera Ghati Road , Karnal, Haryana, 132001, India',
                      image:
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1350/1350092/images/BEDROOM1.jpg',
                      pricePerNight: 2542.5,
                      available: true,
                      amenities: {},
                    },
                    {
                      id: 'ChIJ-5wcMFn5PjkRdLCMRmIGk3I',
                      name: 'Hotel Dreamz Residency',
                      providerKey: 'CL_HOTEL',
                      type: 'HOTEL',
                      starRating: 3,
                      score: 0,
                      lat: 29.6901778,
                      lng: 76.9884539,
                      city: 'Karnal',
                      address: 'karnal bus stand old gt road',
                      image:
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7264/726469/images/46c0d5f4716511e7beda0a4cef95d023.jpg',
                      pricePerNight: 1338,
                      available: true,
                      amenities: {},
                    },
                    {
                      id: 'ChIJoTMjdBdwDjkR5gtZPGKV_wg',
                      name: 'Fidalgo hotel Pvt. Ltd.',
                      providerKey: 'CL_HOTEL',
                      type: 'HOTEL',
                      starRating: 3,
                      score: 0,
                      lat: 29.6886585,
                      lng: 76.9890126,
                      city: 'Karnal',
                      address: 'Opp. Old Tehsil, Near PNB, old GT Road,',
                      image:
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7201/720108/images/740946ee617b11e7b3270a4cef95d023.jpg',
                      pricePerNight: 2426.5,
                      available: true,
                      amenities: {},
                    },
                  ],
                },
                null,
                2
              )}
            </code>
          </CodeGroup>
        </Col>
      </Row>
    </>
  );
}
