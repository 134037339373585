import {Route, Routes} from 'react-router-dom';
import StaticHotels from './StaticHotels';
import SearchHotels from './SearchHotels';
import HotelDetails from './HotelDetails';
import HotelRooms from './HotelRooms';
import HotelHold from './HoldHotelRooms';
import BookHotel from './BookHotel';
import RefundDetails from './RefundDetails';
import OrderDetails from './OrderDetails';
import CancelBooking from './CancelBooking';
import CancellationCharges from './CancellationCharges';

export default function index() {
	return (
		<Routes>
			<Route element={<StaticHotels/>} path='/static-hotels'/>
			<Route element={<SearchHotels/>} path='/search-hotels'/>
			<Route element={<HotelDetails/>} path='/hotel-details'/>
			<Route element={<HotelRooms/>} path='/hotel-rooms'/>
			<Route element={<HotelHold/>} path='/hold-hotel'/>
			<Route element={<BookHotel/>} path='/book-hotel'/>
			<Route element={<RefundDetails/>} path='/refund-details'/>
			<Route element={<OrderDetails/>} path='/order-details'/>
			<Route element={<CancelBooking/>} path='/cancel-booking'/>
			<Route element={<CancellationCharges/>} path='/cancellation-charges'/>
		</Routes>
	);
}
