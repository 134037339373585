import React from 'react'
import {Link} from "react-router-dom";
import NavigationGroup from "./NavigationGroup";
import navigation from "../navigation";


export default function Sidebar({className}) {
	return (
        <nav className={className}>
            <ul role="list">
                <li className="md:hidden">
                    <Link to="/docs"
                          className="block py-1 text-sm text-zinc-600 transition hover:text-zinc-900 dark:text-zinc-400 dark:hover:text-white">
                        Documentation
                    </Link>
                </li>
                {navigation.map((group, groupIndex) => (
                    <NavigationGroup
                        key={group.title}
                        group={group}
                        className={groupIndex === 0 && 'md:mt-0'}
                    />
                ))}
            </ul>
        </nav>
	)
}
