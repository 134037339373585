import {Col, Properties, Property, Row} from "./Common/Property";
import {CodeGroup} from "./Common/Code";

export default function Pagination(){
	return (
		<div>
			<h1>
				Pagination
			</h1>
			<p className="lead">
				In this guide, we will look at how to work with paginated responses when querying the Dice API. By default, all responses limit results to ten.  If you are using one of the official Dice API client libraries, you don't need to worry about pagination, as it's all being taken care of behind the scenes.
			</p>
			<p className="lead">
				When an API response returns a list of objects, no matter the amount, pagination is supported. In paginated responses, objects are nested in a <code>items</code> attribute and have a <code>total</code> attribute that indicates total number of records and <code>currentPage</code> which indicates current page.
			</p>
			<h2>
				Example using cursors
			</h2>
			<Row>
				<Col>
					<div className="lead">
						In this example, we request the page that starts with pageNumber 3. As a result, we get a list of three conversations and can tell by the `total` attribute that we have reached the end of the result set.
					</div>
					<Properties>
						<Property name="offset" type="integer">
							Total number of items to be skipped. It is multiplied by limit itself.
							Like if u r offset 2 and limit is 25 then cursor will skip 50 rows and return next 25 rows.
						</Property>
						<Property name="limit" type="integer">
							Limit the number of items returned.
						</Property>
					</Properties>
				</Col>
				<Col>
					<CodeGroup title="Sample Request">
						<code title="curl">
							curl -G https://provider.dice.tech/api/v1/employees \ <br/>
							-H "Authorization: Bearer {`{token}`}" \<br/>
							-H "Origin: Bearer {`{origin}`}" \<br/>
							-H "Authorization: Api Key {`{apiKey}`}" \<br/>
							-d limit=10 \<br/>
							-d offset=3 <br/>
						</code>
					</CodeGroup>
					<CodeGroup title="Sample Json">
						<code title="curl">
							{
								JSON.stringify(
									{
										"total": 33,
										"current":4,
										"limit":10,
										"items": [
											{
												"id": "31",

											},
											{
												"id": "32"

											},
											{
												"id": "33"

											}
										]
									},null,2
								)
							}
						</code>
					</CodeGroup>
				</Col>
			</Row>
		</div>
	)
}
