import Employees from './Employees/navigation'
import Settlements from './Settlements/navigation'
import Expenses from './Expenses/navigation'

export default {
	title: 'Dice Apis',
	links: [
		Employees,
		Settlements,
		Expenses
	],
}
