import React from 'react'
import { BrowserRouter,Routes,Route } from "react-router-dom";
import Home from './Home'
import AppWrapper from "./Wrapper/AppWrapper";
import Docs from './Docs'
import NotFound from "./NotFound";

export default function index() {
	return (
		<BrowserRouter>
			<Routes>
				<Route element={<AppWrapper/>}>
					<Route element={<Home/>} index/>
				</Route>
				<Route element={<Docs/>} path="/docs/*"/>
				<Route element={<NotFound/>} path="*"/>
			</Routes>
		</BrowserRouter>
	)
}
