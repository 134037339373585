import { Col, Properties, Property, Row } from '../../Common/Property';
import { CodeGroup } from '../../Common/Code';
import Eyebrow from '../../Common/Eyebrow';

const ROUTE = '/hotel/rooms';

export default function Docs() {
  return (
    <>
      <Eyebrow label={ROUTE} tag='POST' />
      <h1>Hotel Rooms</h1>
      <p>
        This endpoint will bring all the rooms available for the particular hotel
      </p>
      <h2>Request Model</h2>
      <p className='lead'>
        Details of all the parameters that can be passed in the request.
      </p>
      <Row>
        <Col>
          <Properties>
              <Property name='hotelId' type='string'>
                  Value can be extracted from Hotel Search api response.<br/>
                  root.hotels[:].id
              </Property>
              <Property name='sessionId' type='string'>
                  Value can be extracted from Hotel Search api response. <br/>
                  root.sessionId
              </Property>
              <Property name='appId' type='string'>
                  Value can be extracted from Hotel Search api response. <br/>
                  root.hotels[:].providerKey
              </Property>
          </Properties>
        </Col>
        <Col>
          <CodeGroup tag='POST' label={ROUTE} title='Request Payload'>
              <code title='json'>
                  {
                      JSON.stringify({ sessionId: '63d1a5d5c9e77c000797e6ed',appId:"CL_HOTEL",
                          hotelId: 'ChIJdbadeOi7wjsRsVmEMhrj-9Q'},null,2)
                  }
              </code>
          </CodeGroup>

          <CodeGroup title='Response Pay Load'>
            <code title='json'>
              {JSON.stringify(
                {
                  success: true,
                  rooms: [
                    {
                      id: '736600|Room, 1 King Bed with Sofa bed (Specialty) - Room Only|274197414:218103705~16:35943:274197414:218103705#736600|si-fef9a54b-8d3e-48e7-a00a-abc4db36316e~15300.0@CL_HOTEL',
                      name: 'Room, 1 King Bed with Sofa bed (Specialty) - Room Only',
                      description:
                        'Room, 1 King Bed with Sofa bed (Specialty), <br/>Room, 1 King Bed with Sofa bed (Specialty)<br/>* Free room upgrade<br/>* Free valet parking<br/>* Free self parking<br/>* Free WiFi<br/>',
                      totalFare: 15300,
                      cancellationPolicy:
                        'Fully refundable for cancellations done before 11:59 PM, 14 February (local time at the property). Charges for cancellations done after the above-mentioned time - booking amount equivalent to 1 night and taxes. ',
                      items: [],
                    },
                    {
                      id: '736600|Room, 1 King Bed with Sofa bed (Specialty) - breakfast|380178353:218103705~16:35943:380178353:218103705#736600|si-fef9a54b-8d3e-48e7-a00a-abc4db36316e~17280.0@CL_HOTEL',
                      name: 'Room, 1 King Bed with Sofa bed (Specialty) - breakfast',
                      description:
                        'Room, 1 King Bed with Sofa bed (Specialty), <br/>Room, 1 King Bed with Sofa bed (Specialty)<br/>* Free breakfast<br/>* Free valet parking<br/>* Free self parking<br/>* Free WiFi<br/>',
                      totalFare: 17280,
                      cancellationPolicy:
                        'Fully refundable for cancellations done before 11:59 PM, 14 February (local time at the property). Charges for cancellations done after the above-mentioned time - booking amount equivalent to 1 night and taxes. ',
                      items: [],
                    },
                  ],
                  errors: [],
                },
                null,
                2
              )}
            </code>
          </CodeGroup>
        </Col>
      </Row>
    </>
  );
}
