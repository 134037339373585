import React from 'react'
import {Route, Routes} from "react-router-dom";
import Settlements from './Settlements'
import Employees from './Employees'
import Expenses from './Expenses'

export default function index() {
	return (
		<Routes>
			<Route element={<Employees/>} path="/employees/*"/>
			<Route element={<Settlements/>} path="/settlements/*"/>
			<Route element={<Expenses/>} path="/expenses/*"/>
		</Routes>
	)
}
