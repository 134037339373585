import { Col, Properties, Property, Row } from '../../Common/Property';
import { CodeGroup } from '../../Common/Code';
import Eyebrow from '../../Common/Eyebrow';

const ROUTE = '/hotel/cancel';

export default function Docs() {
  return (
    <>
      <Eyebrow label={ROUTE} tag='POST' />
      <h1>Cancel Booking</h1>
      <p>
        This api can be used to cancel the booking.
      </p>
      <h2>Request Model</h2>
      <p className='lead'>
        Details of all the parameters that can be passed in the request.
      </p>
      <Row>
        <Col>
          <Properties>
            <Property name='bookingId' type='number'>
               Booking Id generated  which needs to be cancelled.
            </Property>
            <Property name='remarks' type='string'>
               Provide a remarks why traveller is cancelling this booking
            </Property>
          </Properties>
        </Col>
        <Col>
          <CodeGroup tag='POST' label={ROUTE} title='Request Payload'>
            <code title='json'>
                {JSON.stringify(
                    {
                        bookingId: '223072400001',
                        remarks: 'no remarks',
                    },
                    null,
                    2
                )}
            </code>
          </CodeGroup>

          <CodeGroup title='Response Pay Load'>
            <code title='json'>{JSON.stringify({success:true,bookingId:"223072400001",cancellationId:"AX000123122121"}, null, 2)}</code>
          </CodeGroup>
        </Col>
      </Row>
    </>
  );
}
