import { Col, Properties, Property, Row } from '../../../Common/Property';
import { CodeGroup } from '../../../Common/Code';
import Eyebrow from '../../../Common/Eyebrow';

export default function Docs() {
  return (
    <>
      <Eyebrow label='/flight/sandbox/domestic/hold' tag='GET' />
      <h1>Booking Session</h1>
      <p>
        As the name suggests, employees are a core part of Dice — the very
        reason Dice exists is so you can provide secure spends management to
        your employees. On this page, we'll dive into how to create employees
        programmatically.
      </p>
      <h2>Request Model</h2>
      <p className='lead'>
        Details of all the parameters that can be passed in the request.
      </p>
      <Row>
        <Col>
          <Properties>
            <Property name='holdId' type='string'>
              Value can be extracted from Booking Session api response.
            </Property>
          </Properties>
        </Col>
        <Col>
          <CodeGroup
            tag='GET'
            label='/flight/sandbox/domestic/hold'
            title='Request Payload'
          >
            <code title='cURL'>
              curl --request GET \ <br />
              /flight/sandbox/domestic/hold \ <br />
              --header 'Origin: Dice' \ <br />
              --header 'accept: application/json' <br />
            </code>
          </CodeGroup>

          <CodeGroup title='Response Pay Load'>
            <code title='json'>
              {JSON.stringify(
                {
                  holdId: 'sandbox::flight::hold:631bc19bc9e77c000760a509',
                  segments: [
                    {
                      id: '365',
                      meals: [
                        {
                          code: 'POHA',
                          name: 'POHA Combo',
                          amount: 225,
                        },
                        {
                          code: 'VBIR',
                          name: 'VEG BIRYANI Combo',
                          amount: 275,
                        },
                        {
                          code: 'UPMA',
                          name: 'RAVA UPMA Combo',
                          amount: 275,
                        },
                        {
                          code: 'MUYO',
                          name: 'Muesli with yogurt',
                          amount: 275,
                        },
                        {
                          code: 'DACH',
                          name: 'DAL CHAWAL Combo',
                          amount: 275,
                        },
                        {
                          code: 'COMI',
                          name: 'Cornflakes with Milk',
                          amount: 275,
                        },
                        {
                          code: 'VGML',
                          name: 'Veg Meal',
                          amount: 300,
                        },
                        {
                          code: 'ZCHK',
                          name: 'Nissin Zesty Chicken Keema Noodle',
                          amount: 325,
                        },
                        {
                          code: 'NVML',
                          name: 'Non Veg Meal',
                          amount: 350,
                        },
                        {
                          code: 'PTSW',
                          name: 'Paneer Tikka Sandwich Combo',
                          amount: 400,
                        },
                        {
                          code: 'CTSW',
                          name: 'Chicken Tikka Sandwich Combo',
                          amount: 425,
                        },
                        {
                          code: 'CJSW',
                          name: 'Chicken Junglee Sandwich Combo',
                          amount: 425,
                        },
                        {
                          code: 'CHCT',
                          name: 'Chicken cucumber tomato sandwich',
                          amount: 425,
                        },
                        {
                          code: 'CHSS',
                          name: 'Chicken Supreme Salad',
                          amount: 450,
                        },
                        {
                          code: 'CHFR',
                          name: 'Chinese Fried Rice',
                          amount: 450,
                        },
                        {
                          code: 'PITA',
                          name: '2 Dips with Baked Pita',
                          amount: 500,
                        },
                        {
                          code: 'NVTR',
                          name: 'Non veg trio sandwich',
                          amount: 500,
                        },
                      ],
                      extra: [],
                      flightCode: '6E',
                      airline: 'IndiGo',
                      rank: 0,
                      flightNumber: '6939',
                      durationInMin: 95,
                      departure: {
                        code: 'DEL',
                        airport: 'Delhi Indira Gandhi Intl',
                        city: 'Delhi',
                        country: 'India',
                      },
                      departureTime: 1665574200000,
                      departureDate: '2022-10-12T17:00',
                      arrival: {
                        code: 'AMD',
                        airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                        city: 'Ahmedabad',
                        country: 'India',
                      },
                      arrivalTime: 1665579900000,
                      arrrivalDate: '2022-10-12T18:35',
                    },
                    {
                      id: '366',
                      meals: [
                        {
                          code: 'MUYO',
                          name: 'Muesli with yogurt',
                          amount: 275,
                        },
                        {
                          code: 'COMI',
                          name: 'Cornflakes with Milk',
                          amount: 275,
                        },
                        {
                          code: 'VGML',
                          name: 'Veg Meal',
                          amount: 300,
                        },
                        {
                          code: 'NVML',
                          name: 'Non Veg Meal',
                          amount: 350,
                        },
                        {
                          code: 'PTSW',
                          name: 'Paneer Tikka Sandwich Combo',
                          amount: 400,
                        },
                        {
                          code: 'CTSW',
                          name: 'Chicken Tikka Sandwich Combo',
                          amount: 425,
                        },
                        {
                          code: 'CJSW',
                          name: 'Chicken Junglee Sandwich Combo',
                          amount: 425,
                        },
                        {
                          code: 'CHCT',
                          name: 'Chicken cucumber tomato sandwich',
                          amount: 425,
                        },
                        {
                          code: 'CHSS',
                          name: 'Chicken Supreme Salad',
                          amount: 450,
                        },
                        {
                          code: 'PITA',
                          name: '2 Dips with Baked Pita',
                          amount: 500,
                        },
                      ],
                      extra: [],
                      flightCode: '6E',
                      airline: 'IndiGo',
                      rank: 1,
                      flightNumber: '5325',
                      durationInMin: 75,
                      departure: {
                        code: 'AMD',
                        airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                        city: 'Ahmedabad',
                        country: 'India',
                      },
                      departureTime: 1665584100000,
                      departureDate: '2022-10-12T19:45',
                      arrival: {
                        code: 'BOM',
                        airport: 'Chhatrapati Shivaji',
                        city: 'Mumbai',
                        country: 'India',
                      },
                      arrivalTime: 1665588600000,
                      arrrivalDate: '2022-10-12T21:00',
                    },
                  ],
                  startTime: 1662763419756,
                  endTime: 1662763419756,
                  flowId: 'TJS102700339567',
                  app: '63039e1f46e0fb0007c7dbe9',
                  fare: 4485.08,
                  addOns: 0,
                  totalFare: 4485.08,
                  search: {
                    origin: {
                      code: 'DEL',
                      id: 1,
                      airport: 'Indira Gandhi International Airport',
                      city: 'Delhi',
                      country: 'INDIA',
                    },
                    destination: {
                      code: 'BOM',
                      id: 2,
                      airport: 'Chhatrapati Shivaji International Airport',
                      city: 'Mumbai',
                      country: 'INDIA',
                    },
                    date: '12-10-2022',
                    count: {
                      adult: 1,
                      child: 0,
                      infant: 0,
                    },
                  },
                  status: 'PENDING',
                  travellers: [
                    {
                      type: 'ADULT',
                      primary: false,
                      id: '631bc19bc9e77c000760a50a',
                      addOnCost: 0,
                      meals: {},
                      extra: {},
                      seats: {},
                      empty: true,
                    },
                  ],
                },
                null,
                2
              )}
            </code>
          </CodeGroup>
        </Col>
      </Row>
    </>
  );
}
