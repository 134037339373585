import {Note} from "../../Common/Note";
import {Col, Properties, Property, Row} from "../../Common/Property";
import {CodeGroup} from "../../Common/Code";
import Eyebrow from "../../Common/Eyebrow";


export default function Docs(){
	return (
		<>
			<Eyebrow label="//provider.dice.tech/api/v1/employees/tags" tag="POST" />
			<h1>
				Create Tags
			</h1>
			<p>
				As the name suggests, employees are a core part of Dice — the very reason Dice exists is so you can provide secure spends management to your employees. On this page, we'll dive into how to tags/manager mapping of employees programmatically.
			</p>
			<Note>
				By default this api supports updation of employees as well. If you send an employee data which already exists then it will compare
				data with existing record and do an update operation.
			</Note>
			<h2>
				The Tags Model
			</h2>
			<p className="lead">
				The tags model contains all the information about your heirarchy of your employee.
			</p>
			<Row>
				<Col>
					<Properties>
						<Property name="code" type="string">
							Unique identifier for the employee.
						</Property>
						<Property name="manager" type="string">
							Manager of the employee
						</Property>
						<Property name="alternateManager" type="string">
							Alternate Manager of the employee
						</Property>
						<Property name="policy" type="string">
							Name of policy programme this employee will be part of
						</Property>
					</Properties>
				</Col>
				<Col>
					<CodeGroup tag="POST" label="/api/v1/employees/tags" title="Request Payload">
						<code title="json">
							{
								JSON.stringify({
									items : [
										{
											code:"E0001",
											manager:"E0004",
											policy:"Founder Policies"
										}
									]
								},null,2)
							}
						</code>
					</CodeGroup>

					<CodeGroup  title="Response Pay Load">
						<code title="json">
							{
								JSON.stringify({
									success : true,
									errors : {}
								},null,2)
							}
						</code>
					</CodeGroup>
				</Col>
			</Row>
		</>
	)
}
