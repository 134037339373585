import React from 'react'
import {HeroPattern} from "./Patterns/HeroPattern";
import Button from "./Common/Button";
import {Link} from "react-router-dom";
import {Guides} from "./Helpers/Guides";
import {Resources} from "./Helpers/Resources";

export default function Home() {
	return (
		<>
			<HeroPattern />
			<h1>
				API Documentation
			</h1>
			<p className="lead">
				Use the Dice API to access expenses, voucher, manage vendors, and more and seamlessly integrate your product into the workflows of dozens of devoted Dice users.
			</p>
			<div className="not-prose mb-16 mt-6 flex gap-3">
				<Button to="/docs/quickstart" arrow="right" children="Quickstart" />
				<Button to="/docs/funx" variant="outline" children="Explore funX" />
			</div>
			<h2 className="scroll-mt-24">
				Getting started
			</h2>
			<p className="lead">
				To get started, create a new application in your <Link to="/docs/studio">Dice Studio</Link>, then read about how to make requests for the resources you need to access using our HTTP APIs or dedicated client SDKs. When your integration is ready to go live, publish it to our  <Link to="/docs/studio">Dice Studio</Link> to start handling production events.
			</p>
			<div className="not-prose">
				<Button
					to="/docs/sdks"
					variant="text"
					arrow="right"
					children="Get your API key"
				/>
			</div>
			<Guides/>
			<Resources/>
		</>
	)
}
