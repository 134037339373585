import React from 'react';
import {Heading} from '../../Common/Heading';
import Eyebrow from '../../Common/Eyebrow';
import {Col, Properties, Property, Row} from '../../Common/Property';
import {CodeGroup} from '../../Common/Code';

export default function Vouchers() {
	return (
		<>
			<h1>All Bookings</h1>
			<p className='lead mb-10'>
				As the name suggests, this page is related to fetch all bookings done in given timeline.
			</p>
			<Heading level={2}>Fetch Bookings</Heading>
			<div className='lead mb-8'>
				This api is for fetching all bookings between a certain timeframe.
			</div>
			<Eyebrow
				label='https://provider.dice.tech/api/v1/expenses/bookings'
				tag='POST'
			/>
			<div className='mb-6'/>
			<Row>
				<Col>
					<Properties>
						<Property name='startDate' type='timestamp'>
							Start of timeframe to fetch data.
						</Property>
						<Property name='endDate' type='timestamp'>
							End of timeframe to fetch data.
						</Property>
					</Properties>
				</Col>
				<Col>
					<CodeGroup
						tag='POST'
						label='/api/v1/expenses/bookings'
						title='Request Payload'
					>
						<code title='json'>
							{JSON.stringify(
								{
									startDate: 1682983046000,
									endDate: 1685661446000
								},
								null,
								2
							)}
						</code>
					</CodeGroup>
					<CodeGroup title='Response Payload'>
						<code title='json'>
							{JSON.stringify(
								{
									"success": true,
									"transactions": [
										{
											"transId": "20032923051700001",
											"amount": {
												"currency": "INR",
												"amount": 2368.0,
												"converted": 2368.0
											},
											"reimbursable": {
												"currency": "INR",
												"amount": 0.0,
												"converted": 0.0
											},
											"converted": {
												"currency": "INR",
												"amount": 2368.0,
												"converted": 2368.0
											},
											"service": "Flight",
											"date": 1688097600000,
											"createdAt": 1684312844642,
											"expenseStatus": 2,
											"owner": {
												"id": 240376,
												"ownerType": "EMPLOYEE",
												"ownerId": "167462",
												"ownerName": "Sukesh Shetty",
												"fields": {
													"hr head": "167462",
													"manager": "167462",
													"alternate": "167462",
													"team": "CT LEADS",
													"department": "CT LEADS",
													"doj": "1683016732000"
												}
											},
											"office": {
												"id": 9,
												"name": "CT LEADS"
											},
											"department": {
												"id": 11,
												"name": "CT LEADS"
											},
											"voucherId": "-",
											"transactionType": "WALLETPAID",
											"merchant": "6E",
											"refunded": true,
											"fraud": false,
											"seekClarification": false,
											"violation": false,
											"file": "https://static.eka.io/dice/upload/client/service/e381f8eb-1860-4cf4-9949-02189d656aab.pdf",
											"details": "Airline - 6E | Pnr - WKD7SR"
										}
									]
								},
								null,
								2
							)}
						</code>
					</CodeGroup>
				</Col>
			</Row>
		</>
	);
}
