import {useRef,useEffect,Fragment,forwardRef,useId,useState} from "react";
import {useLocation} from "react-router-dom";
import { Dialog, Transition } from '@headlessui/react'
import clsx from "clsx";
import { createAutocomplete } from '@algolia/autocomplete-core'

function SearchIcon(props) {
	return (
		<svg viewBox="0 0 20 20" fill="none" aria-hidden="true" {...props}>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M12.01 12a4.25 4.25 0 1 0-6.02-6 4.25 4.25 0 0 0 6.02 6Zm0 0 3.24 3.25"
			/>
		</svg>
	)
}

function NoResultsIcon(props) {
	return (
		<svg viewBox="0 0 20 20" fill="none" aria-hidden="true" {...props}>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M12.01 12a4.237 4.237 0 0 0 1.24-3c0-.62-.132-1.207-.37-1.738M12.01 12A4.237 4.237 0 0 1 9 13.25c-.635 0-1.237-.14-1.777-.388M12.01 12l3.24 3.25m-3.715-9.661a4.25 4.25 0 0 0-5.975 5.908M4.5 15.5l11-11"
			/>
		</svg>
	)
}


function useAutocomplete() {
	let id = useId()
	let [autocompleteState, setAutocompleteState] = useState({})

	let [autocomplete] = useState(() =>
		createAutocomplete({
			id,
			placeholder: 'Find something...',
			defaultActiveItemId: 0,
			onStateChange({ state }) {
				setAutocompleteState(state)
			},
			shouldPanelOpen({ state }) {
				return state.query !== ''
			},
			getSources({ query }) {
				return []
			},
		})
	)

	return { autocomplete, autocompleteState }
}

function LoadingIcon(props) {
	let id = useId()

	return (
		<svg viewBox="0 0 20 20" fill="none" aria-hidden="true" {...props}>
			<circle cx="10" cy="10" r="5.5" strokeLinejoin="round" />
			<path
				stroke={`url(#${id})`}
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M15.5 10a5.5 5.5 0 1 0-5.5 5.5"
			/>
			<defs>
				<linearGradient
					id={id}
					x1="13"
					x2="9.5"
					y1="9"
					y2="15"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="currentColor" />
					<stop offset="1" stopColor="currentColor" stopOpacity="0" />
				</linearGradient>
			</defs>
		</svg>
	)
}

const SearchInput = forwardRef(function SearchInput(
	{ autocomplete, autocompleteState, onClose },
	inputRef
) {
	let inputProps = autocomplete.getInputProps({})

	return (
		<div className="group relative flex h-12">
			<SearchIcon className="pointer-events-none absolute left-3 top-0 h-full w-5 stroke-zinc-500" />
			<input
				ref={inputRef}
				className={clsx(
					'flex-auto appearance-none bg-transparent pl-10 text-zinc-900 outline-none placeholder:text-zinc-500 focus:w-full focus:flex-none dark:text-white sm:text-sm [&::-webkit-search-cancel-button]:hidden [&::-webkit-search-decoration]:hidden [&::-webkit-search-results-button]:hidden [&::-webkit-search-results-decoration]:hidden',
					autocompleteState.status === 'stalled' ? 'pr-11' : 'pr-4'
				)}
				{...inputProps}
				onKeyDown={(event) => {
					if (
						event.key === 'Escape' &&
						!autocompleteState.isOpen &&
						autocompleteState.query === ''
					) {
						// In Safari, closing the dialog with the escape key can sometimes cause the scroll position to jump to the
						// bottom of the page. This is a workaround for that until we can figure out a proper fix in Headless UI.
						document.activeElement?.blur()

						onClose()
					} else {
						inputProps.onKeyDown(event)
					}
				}}
			/>
			{autocompleteState.status === 'stalled' && (
				<div className="absolute inset-y-0 right-3 flex items-center">
					<LoadingIcon className="h-5 w-5 animate-spin stroke-zinc-200 text-zinc-900 dark:stroke-zinc-800 dark:text-emerald-400" />
				</div>
			)}
		</div>
	)
})


export  default function SearchDialog({ open, setOpen, className }) {
	let formRef = useRef()
	let panelRef = useRef()
	let inputRef = useRef()
	let { autocomplete, autocompleteState } = useAutocomplete()

	useEffect(() => {
		if (open) {
			return
		}

		function onKeyDown(event) {
			if (event.key === 'k' && (event.metaKey || event.ctrlKey)) {
				event.preventDefault()
				setOpen(true)
			}
		}

		window.addEventListener('keydown', onKeyDown)

		return () => {
			window.removeEventListener('keydown', onKeyDown)
		}
	}, [open, setOpen])

	return (
		<Transition.Root
			show={open}
			as={Fragment}
			afterLeave={() => {

			}}
		>
			<Dialog
				onClose={setOpen}
				className={clsx('fixed inset-0 z-50', className)}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-zinc-400/25 backdrop-blur-sm dark:bg-black/40" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto px-4 py-4 sm:px-6 sm:py-20 md:py-32 lg:px-8 lg:py-[15vh]">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<Dialog.Panel className="mx-auto overflow-hidden rounded-lg bg-zinc-50 shadow-xl ring-1 ring-zinc-900/7.5 dark:bg-zinc-900 dark:ring-zinc-800 sm:max-w-xl">
							<div >
								<form
									ref={formRef}
								>
									<SearchInput
										ref={inputRef}
										autocomplete={autocomplete}
										autocompleteState={autocompleteState}
										onClose={() => setOpen(false)}
									/>
									<div
										ref={panelRef}
										className="border-t border-zinc-200 bg-white empty:hidden dark:border-zinc-100/5 dark:bg-white/2.5"
									>

									</div>
								</form>
							</div>
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
