import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './Common/Layout';
import Home from './Home';
import SDKs from './SDKs';
import NotFound from '../NotFound';
import Quickstart from './Quickstart';
import Authentication from './Authentication';
import Pagination from './Pagination';
import Error from './Error';
import Webhook from './Webhook';
import WebHooks from './Webhooks';
import Apis from './Apis';
import FlightAPIs from './FlightAPIs';
import HotelAPIs from './HotelAPIs';

export default function index() {
  return (
    <>
      <Routes>
        <Route element={<Layout />}>
          <Route element={<Home />} index />
          <Route element={<Home />} path='/home' />
          <Route element={<Quickstart />} path='/quickstart' />
          <Route element={<SDKs />} path='/funx' />
          <Route element={<Error />} path='/errors' />
          <Route element={<Webhook />} path='/webhooks' />
          <Route element={<Pagination />} path='/pagination' />
          <Route element={<Authentication />} path='/authentication' />
          <Route element={<WebHooks />} path='/webhooks/*' />
          <Route element={<Apis />} path='/apis/*' />
          <Route element={<FlightAPIs />} path='/flights/*' />
          <Route element={<HotelAPIs />} path='/hotels/*' />
        </Route>
        <Route element={<NotFound />} path='*' />
      </Routes>
    </>
  );
}
