import { Col, Properties, Property, Row } from '../../../Common/Property';
import { CodeGroup } from '../../../Common/Code';
import Eyebrow from '../../../Common/Eyebrow';
import { Note } from '../../../Common/Note';

export default function Docs() {
  return (
    <>
      <Eyebrow label='/flight/sandbox/domestic/search' tag='POST' />
      <h1>Fare Rules</h1>
      <p>
        As the name suggests, employees are a core part of Dice — the very
        reason Dice exists is so you can provide secure spends management to
        your employees. On this page, we'll dive into how to create employees
        programmatically.
      </p>
      <h2>Request Model</h2>
      <p className='lead'>
        Details of all the parameters that can be passed in the request.
      </p>
      <Row>
        <Col>
          <Properties>
            <Property name='inventoryId' type='string'>
              Value can be extracted from Fare Rules api response.
              <br />
              search.results[:].key
            </Property>
            <Property name='fareId' type='string'>
              Value can be extracted from Fare Rules api response.
              <br />
              search.results[:].fares[:].key
            </Property>
            <Note>
              <code>inventoryId</code> & <code>fareId</code> are valid for only
              15 mins
            </Note>
          </Properties>
        </Col>
        <Col>
          <CodeGroup
            tag='POST'
            label='/flight/sandbox/domestic/search'
            title='Request Payload'
          >
            <code title='cURL'>
              curl --request POST \ <br />
              --url /flight/sandbox/domestic/search \ <br />
              --header 'Origin: Dice' \ <br />
              --header 'accept: application/json' \ <br />
              --header 'content-type: application/json' \ <br />
              --data ' <br />
              {JSON.stringify(
                {
                  inventoryId: 'search.results[:].key',
                  fareId: 'search.results[:].fares[:].key',
                },
                null,
                2
              )}
              '
            </code>
          </CodeGroup>

          <CodeGroup title='Response Pay Load'>
            <code title='json'>
              {JSON.stringify(
                {
                  success: true,
                  app: 'Trip Jack',
                  fareRules: {
                    apiId: '63039e1f46e0fb0007c7dbe9',
                    cancellation: {
                      policy:
                        '__nls__Cancellation permitted 04 Hrs before scheduled departure at a charge of INR 3920 per sector per Passenger',
                      total: 3920,
                      airlineFees: 3920,
                      aggregatorFees: 50,
                      airlineTax: 9,
                      aggregatorTax: 0,
                    },
                    dateChange: {
                      policy:
                        '+ Fare Difference __nls__Changes permitted 04 Hrs before scheduled departure',
                      total: 3920,
                      airlineFees: 3920,
                      aggregatorFees: 50,
                      airlineTax: 9,
                      aggregatorTax: 0,
                    },
                    noShow: {
                      policy: 'Only Statutory Taxes will be refunded',
                      airlineFees: 0,
                      aggregatorFees: 0,
                      airlineTax: 0,
                      aggregatorTax: 0,
                    },
                    errors: [],
                    code: 0,
                    latency: 151,
                  },
                },
                null,
                2
              )}
            </code>
          </CodeGroup>
        </Col>
      </Row>
    </>
  );
}
