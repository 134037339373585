import React from 'react'
import clsx from "clsx";
import { forwardRef } from 'react'
import MobileNavigation, {useIsInsideMobileNavigation, useMobileNavigationStore} from "./MobileNavigation";
import {Link} from "react-router-dom";
import Logo from "./logo.png";
import {motion, useScroll, useTransform} from "framer-motion";
import Button from './Button'
import Search from './Search'

function SunIcon(props) {
    return (
        <svg viewBox="0 0 20 20" fill="none" aria-hidden="true" {...props}>
            <path d="M12.5 10a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" />
            <path
                strokeLinecap="round"
                d="M10 5.5v-1M13.182 6.818l.707-.707M14.5 10h1M13.182 13.182l.707.707M10 15.5v-1M6.11 13.889l.708-.707M4.5 10h1M6.11 6.111l.708.707"
            />
        </svg>
    )
}

function MoonIcon(props) {
    return (
        <svg viewBox="0 0 20 20" fill="none" aria-hidden="true" {...props}>
            <path d="M15.224 11.724a5.5 5.5 0 0 1-6.949-6.949 5.5 5.5 0 1 0 6.949 6.949Z" />
        </svg>
    )
}


function TopLevelNavItem({ href, children }) {
    return (
        <li>
            <Link
                to={href}
                className="text-sm leading-5 text-zinc-600 transition hover:text-zinc-900 dark:text-zinc-400 dark:hover:text-white"
            >
                {children}
            </Link>
        </li>
    )
}


function ModeToggle() {
    function disableTransitionsTemporarily() {
        document.documentElement.classList.add('[&_*]:!transition-none')
        window.setTimeout(() => {
            document.documentElement.classList.remove('[&_*]:!transition-none')
        }, 0)
    }

    function toggleMode() {
        disableTransitionsTemporarily()

        let darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
        let isSystemDarkMode = darkModeMediaQuery.matches
        let isDarkMode = document.documentElement.classList.toggle('dark')

        if (isDarkMode === isSystemDarkMode) {
            delete window.localStorage.isDarkMode
        } else {
            window.localStorage.isDarkMode = isDarkMode
        }
    }

    return (
        <button
            type="button"
            className="flex h-6 w-6 items-center justify-center rounded-md transition hover:bg-zinc-900/5 dark:hover:bg-white/5"
            aria-label="Toggle dark mode"
            onClick={toggleMode}
        >
            <SunIcon className="h-5 w-5 stroke-zinc-900 dark:hidden" />
            <MoonIcon className="hidden h-5 w-5 stroke-white dark:block" />
        </button>
    )
}


const Header = forwardRef(function Header({ className }, ref) {{
    let isInsideMobileNavigation = useIsInsideMobileNavigation()
    let { scrollY } = useScroll()
    let { isOpen: mobileNavIsOpen } = useMobileNavigationStore()
    let bgOpacityLight = useTransform(scrollY, [0, 72], [0.5, 0.9])
    let bgOpacityDark = useTransform(scrollY, [0, 72], [0.2, 0.8])
    return (
        <motion.div
            ref={ref}
            className={clsx(
                'fixed inset-x-0 top-0 z-50 flex h-14 items-center justify-between gap-12 px-4 transition sm:px-6 lg:left-72 lg:z-30 lg:px-8 xl:left-80',
                !isInsideMobileNavigation &&
                'backdrop-blur-sm dark:backdrop-blur lg:left-72 xl:left-80',
                isInsideMobileNavigation
                    ? 'bg-white dark:bg-zinc-900'
                    : 'bg-white/[var(--bg-opacity-light)] dark:bg-zinc-900/[var(--bg-opacity-dark)]'
            )}
            style={{
                '--bg-opacity-light': bgOpacityLight,
                '--bg-opacity-dark': bgOpacityDark,
            }}
        >
            <div
                className={clsx(
                    'absolute inset-x-0 top-full h-px transition',
                    (isInsideMobileNavigation || !mobileNavIsOpen) &&
                    'bg-zinc-900/7.5 dark:bg-white/7.5'
                )}
            />
            <div className="flex items-center gap-5 lg:hidden">
                <MobileNavigation/>
                <Link to="/">
                    <img alt="logo" src={Logo} style={{height: 30}}/>
                </Link>
            </div>
            <Search/>
            <div className="flex items-center gap-5">
                <nav className="hidden md:block">
                    <ul role="list" className="flex items-center gap-8">
                        <TopLevelNavItem href="/docs/apis/settlements/voucher">API</TopLevelNavItem>
                        <TopLevelNavItem href="/docs">Documentation</TopLevelNavItem>
                    </ul>
                </nav>
                <div className="hidden md:block md:h-5 md:w-px md:bg-zinc-900/10 md:dark:bg-white/15" />
                <div className="hidden min-[416px]:contents">
                    <Button to="#">Contact Us</Button>
                </div>
            </div>
        </motion.div>
	)
}})

export default Header;
