import { Col, Properties, Property, Row } from '../../../Common/Property';
import { CodeGroup } from '../../../Common/Code';
import Eyebrow from '../../../Common/Eyebrow';

const ROUTE = '/flight/sandbox/domestic/round/hold';

export default function Docs() {
  return (
    <>
      <Eyebrow label={ROUTE} tag='GET' />
      <h1>Booking Session</h1>
      <p>
        As the name suggests, employees are a core part of Dice — the very
        reason Dice exists is so you can provide secure spends management to
        your employees. On this page, we'll dive into how to create employees
        programmatically.
      </p>
      <h2>Request Model</h2>
      <p className='lead'>
        Details of all the parameters that can be passed in the request.
      </p>
      <Row>
        <Col>
          <Properties>
            <Property name='holdId' type='string'>
              Value can be extracted from Booking Session api response.
            </Property>
          </Properties>
        </Col>
        <Col>
          <CodeGroup tag='GET' label={ROUTE} title='Request Payload'>
            <code title='cURL'>
              curl --request GET \ <br />
              --url {ROUTE} \ <br />
              --header 'Origin: Dice' \ <br />
              --header 'accept: application/json' \ <br />
              --header 'content-type: application/json' <br />
            </code>
          </CodeGroup>

          <CodeGroup title='Response Pay Load'>
            <code title='json'>
              {JSON.stringify(
                {
                  app: '63039e1f46e0fb0007c7dbe9',
                  fare: 10786,
                  totalFare: 10786,
                  travellers: [
                    {
                      type: 'ADULT',
                      primary: false,
                      id: '8',
                      addOnCost: 0,
                      meals: {},
                      seats: {},
                      pnrs: {},
                      tickets: {},
                      empty: true,
                      name: 'Lakshay Jain',
                      fName: 'Lakshay',
                      lName: 'Jain',
                      email: 'lakshay@antrepriz.com',
                      mobile: '+918950000756',
                      gender: 'MALE',
                      dob: 1641839400000,
                    },
                  ],
                  addOns: 0,
                  RETURN: [
                    {
                      id: '957',
                      meals: [
                        {
                          code: 'VGSW',
                          name: 'Veg Sandwich/Wrap/Sub',
                          amount: 0,
                        },
                        {
                          code: 'VGML',
                          name: 'Veg Meal',
                          amount: 0,
                        },
                        {
                          code: 'NVSW',
                          name: 'Non Veg Sandwich/Wrap/Sub',
                          amount: 0,
                        },
                        {
                          code: 'NVML',
                          name: 'Non Veg Meal',
                          amount: 0,
                        },
                        {
                          code: 'BVG',
                          name: 'Beverage',
                          amount: 0,
                        },
                        {
                          code: 'JNSW',
                          name: 'Jain Cold Sandwich (current Cucumber and Tomato sandwich)',
                          amount: 250,
                        },
                        {
                          code: 'LCVS',
                          name: 'Low cal salad Vegetarian',
                          amount: 275,
                        },
                        {
                          code: 'LCNS',
                          name: 'Low cal salad Non Vegetarian',
                          amount: 300,
                        },
                        {
                          code: 'VCC2',
                          name: 'Vegetable in Red Thai Curry with Steamed Rice',
                          amount: 395,
                        },
                        {
                          code: 'NCC6',
                          name: 'Chicken schezwan on bed of fried rice',
                          amount: 395,
                        },
                        {
                          code: 'NCC5',
                          name: 'Tawa Fish masala on bed of Steamed rice with tadka masoordal',
                          amount: 395,
                        },
                        {
                          code: 'NCC4',
                          name: 'Tandoori Chicken tangri with chicken haryali tikka & vegetable shami kebab',
                          amount: 395,
                        },
                        {
                          code: 'NCC2',
                          name: 'Chicken in Red Thai Curry with Steamed Rice',
                          amount: 395,
                        },
                        {
                          code: 'NCC1',
                          name: 'Grilled Chicken Breast with Mushroom Sauce, Yellow Rice, Saut Carrot & Beans Baton',
                          amount: 395,
                        },
                        {
                          code: 'GFCM',
                          name: 'Vegetarian Gluten-free Cold Meal',
                          amount: 395,
                        },
                        {
                          code: 'FPML',
                          name: 'Fruit Platter',
                          amount: 400,
                        },
                        {
                          code: 'JNML',
                          name: 'Jain Hot Meal',
                          amount: 425,
                        },
                        {
                          code: 'GFVG',
                          name: 'Vegetarian Gluten-free Hot Meal',
                          amount: 425,
                        },
                        {
                          code: 'GFNV',
                          name: 'Non Vegetarian Gluten-free Hot Meal',
                          amount: 425,
                        },
                        {
                          code: 'DNVL',
                          name: 'Non Vegetarian Diabetic Hot Meal',
                          amount: 425,
                        },
                        {
                          code: 'DBML',
                          name: 'Vegetarian Diabetic Hot Meal',
                          amount: 425,
                        },
                        {
                          code: 'CHML',
                          name: 'Kids meal',
                          amount: 425,
                        },
                      ],
                      logo: 'https://static.eka.io/airline/SG.png',
                      flightCode: 'SG',
                      airline: 'SpiceJet',
                      rank: 0,
                      flightNumber: '8158',
                      durationInMin: 135,
                      departure: {
                        code: 'BOM',
                        airport:
                          'Chhatrapati Shivaji Maharaj International Airport',
                        city: 'Mumbai',
                        country: 'India',
                      },
                      departureTime: 1666626900000,
                      departureDate: '2022-10-24T21:25',
                      arrival: {
                        code: 'DEL',
                        airport: 'Delhi Indira Gandhi Intl',
                        city: 'Delhi',
                        country: 'India',
                      },
                      arrivalTime: 1666635000000,
                      arrrivalDate: '2022-10-24T23:40',
                    },
                  ],
                  search: {
                    origin: {
                      code: 'DEL',
                      id: 1,
                      airport: 'Indira Gandhi International Airport',
                      city: 'Delhi',
                      country: 'INDIA',
                    },
                    destination: {
                      code: 'BOM',
                      id: 2,
                      airport: 'Chhatrapati Shivaji International Airport',
                      city: 'Mumbai',
                      country: 'INDIA',
                    },
                    date: '20-10-2022',
                    count: {
                      adult: 1,
                      child: 0,
                      infant: 0,
                    },
                  },
                  ONWARDS: [
                    {
                      id: '484',
                      meals: [],
                      logo: 'https://static.eka.io/airline/SG.png',
                      flightCode: 'SG',
                      airline: 'SpiceJet',
                      rank: 0,
                      flightNumber: '8703',
                      durationInMin: 145,
                      departure: {
                        code: 'DEL',
                        airport: 'Delhi Indira Gandhi Intl',
                        city: 'Delhi',
                        country: 'India',
                      },
                      departureTime: 1666229100000,
                      departureDate: '2022-10-20T06:55',
                      arrival: {
                        code: 'BOM',
                        airport:
                          'Chhatrapati Shivaji Maharaj International Airport',
                        city: 'Mumbai',
                        country: 'India',
                      },
                      arrivalTime: 1666237800000,
                      arrrivalDate: '2022-10-20T09:20',
                    },
                  ],
                  startTime: 1666088100913,
                  endTime: 1666088100913,
                  holdId: 'flight::hold:634e7ca44cedfd0007a95aa0',
                  flowId: 'TJ109045774741',
                  status: 'PENDING',
                },
                null,
                2
              )}
            </code>
          </CodeGroup>
        </Col>
      </Row>
    </>
  );
}
