import React from 'react'
import {Routes,Route} from "react-router-dom";
import Trips from './Trips'

export default function index() {
	return (
		<Routes>
			<Route element={<Trips/>} path="/trips"/>
		</Routes>
	)
}
