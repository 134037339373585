import React from 'react'
import clsx from 'clsx'
import {motion,AnimatePresence} from "framer-motion";
import NavLink from "./NavLink";
import {useLocation} from "react-router-dom";
import {ActivePageMarker} from "./PageMarker";

export default function NavigationGroup({className,group}){
	let location = useLocation();
	let isActiveGroup =
		group.links.findIndex((link) => location.pathname.includes(link.href)) !== -1

	return (
		<li className={clsx('relative mt-6', className)}>
			<motion.h2
				layout="position"
				className="text-xs font-semibold text-zinc-900 dark:text-white"
			>
				{group.title}
			</motion.h2>

			<div className="relative mt-3 pl-2">

				<motion.div
					layout
					className="absolute inset-y-0 left-2 w-px bg-zinc-900/10 dark:bg-white/5"
				/>
				<AnimatePresence initial={false}>
					{isActiveGroup && (
						<ActivePageMarker group={group} pathname={location.pathname} />
					)}
				</AnimatePresence>
				<ul role="list" className="border-l border-transparent">
					{group.links.map((link) => (
						<motion.li key={link.href} layout="position" className="relative">
							<NavLink href={link.href} active={location.pathname.includes(link.href)}>
								{link.title}
							</NavLink>
							<AnimatePresence mode="popLayout" initial={false}>
								{location.pathname.includes(link.href) && link.sections && link.sections.length > 0 && (
									<motion.ul
										role="list"
										initial={{ opacity: 0 }}
										animate={{
											opacity: 1,
											transition: { delay: 0.1 },
										}}
										exit={{
											opacity: 0,
											transition: { duration: 0.15 },
										}}
									>
										{link.sections.map((section) => (
											<li key={section.title}>
												<NavLink
													active={location.pathname===section.link}
													href={`${section.link}`}
													tag={section.tag}
													isAnchorLink
												>
													{section.title}
												</NavLink>
											</li>
										))}
									</motion.ul>
								)}
							</AnimatePresence>
						</motion.li>
					))}
				</ul>
			</div>
		</li>
	)
}
