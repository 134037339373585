import { Col, Properties, Property, Row } from '../../Common/Property';
import { CodeGroup } from '../../Common/Code';
import Eyebrow from '../../Common/Eyebrow';

const ROUTE = '/hotel/static';

export default function Docs() {
  return (
    <>
      <Eyebrow label={ROUTE} tag='POST' />
      <h1>Static Hotels</h1>
      <p>
        This endpoint provides all static dump of all hotels present in a city.
      </p>
      <h2>Request Model</h2>
      <p className='lead'>
        Details of all the parameters that can be passed in the request.
      </p>
      <Row>
        <Col>
          <Properties>
              <Property name='place' type='string'>
                  Name of city you want to search
              </Property>
              <Property name='country' type='string'>
                  Name of country
              </Property>
          </Properties>
        </Col>
        <Col>
          <CodeGroup tag='POST' label={ROUTE} title='Request Payload'>
            <code title='json'>
                {JSON.stringify({place:"Panipat"},null,2)}
            </code>
          </CodeGroup>

          <CodeGroup title='Response Pay Load'>
            <code title='json'>
              {JSON.stringify(
                {
                  success: true,
                  results: [
                    {
                      id: 'ChIJJ9-3s4zaDTkREzAx7i8vyHg',
                      name: 'Days Hotels',
                      type: 'Panipat',
                      starRating: 3,
                      description:
                        '<p><b>Property Location: </b> A stay at Days Hotel Panipat places you in the heart of Panipat, and within a 15-minute drive of Kabuli Bagh Mosque.   This 4-star hotel is 3.5 mi (5.6 km) from Salar Gunj Gate and 3.6 mi (5.7 km) from Devi Temple.</p><p><b>Rooms: </b> Make yourself at home in one of the 80 individually furnished guestrooms, featuring refrigerators and minibars. 32-inch flat-screen televisions with cable programming provide entertainment, while complimentary wireless Internet access keeps you connected. Private bathrooms with shower/tub combinations feature hair dryers and slippers. Conveniences include phones, as well as safes and complimentary newspapers.</p><p><b>Amenities: </b> Relax and unwind with body treatments and facials. This hotel also features complimentary wireless Internet access, concierge services, and gift shops/newsstands.</p><p><b>Dining: </b> Enjoy international cuisine at Ambrosia, a restaurant which features a bar, or stay in and take advantage of the 24-hour room service. Mingle with other guests at the complimentary reception, held on select days. A complimentary buffet breakfast is served daily from 7:30 AM to 10:30 AM.</p><p><b>Business, Other Amenities: </b> Featured amenities include a computer station, express check-in, and express check-out. A roundtrip airport shuttle is complimentary (available 24 hours).</p>',
                      score: 32,
                      lng: 76.987448,
                      city: 'Panipat',
                      address:
                        'Opp. Sector-25, Huda Part-II, By Pass Road, Panipat-132103',
                      userRating: 4.1,
                      locality: 'Huda',
                      image:
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/3860d0905c9311e78bfd025f77df004f.jpg',
                      hotelChain: '',
                      images: [
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/3860d0905c9311e78bfd025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/e73d67365c9311e79be1025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/c2c288bcbcb611e8a8e70a55aafef38a.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/fbd61a2a734111e78f1e025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/0a3a4c44734211e7ad67025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/af8d3e705c9211e7aafe0a553a66de59.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/922aa800ca4311e5baf836cfdd80c293.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/08abcf00ca4311e5811f36cfdd80c293.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/f91ec728734111e7ad67025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/56f5b570f48c11e785fe0a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/f42822f65c9311e79e40025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/2590cc9a5c9311e79e40025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/fb4aefd0734211e78b200a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/258b04905c9311e7aafe0a553a66de59.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/2cd5b09e734311e7aec50a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/c312da06bcb611e8bbe80a55aafef38a.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/d0c162e8ca4311e5b71a001ec9b85d13.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/f92a5656e51c11e5a020001ec9b85d13.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/ebd4c102734211e78b200a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/c413094ebcb611e88a780281517d0dd4.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/edb4ffb0734111e78f1e025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/c3834b92bcb611e899c90224510f5e5b.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/2cc9336ae51811e581fbdaf4768ad8d9.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/1457b6ac360e11e6929636cfdd80c293.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/a95861fa5c9311e79e40025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/0403cc46734311e7aec50a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/485eea2aca4211e59127daf4768ad8d9.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/f3fa2836734211e7aec50a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/e64a22525c9211e79e40025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/19c3644e360c11e6986f001ec9b85d13.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/109e4cf6734311e7aec50a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/ff78e7ca734111e78bc5025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/bdc3e024812511e59ccf0022195573b9.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/cf76cabe734211e78b200a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/126b6f10734211e78bc5025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/16e9fcdc734211e7ad67025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/aab6acb8e51c11e5abed001ec9b85d13.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/709133385c9311e7aafe0a553a66de59.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/2433f348734211e7ad67025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/86c8f4745c9311e79be1025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/5a5efed8f48c11e79b7c0a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/5ea55b8c360a11e6bd995ee5da2daa2a.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/feb87188e51811e5ae49001ec9b85d13.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/e7ea3154734111e7ad67025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/f097d48c734111e78bc5025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1348/1348966/images/e014dfb4734211e78b200a4cef95d023.jpg',
                      ],
                      pricePerNight: 0,
                      available: false,
                      amenities: {
                        parking: true,
                        ac: true,
                        wifi: false,
                        tv: true,
                        pool: true,
                      },
                    },
                    {
                      id: 'ChIJNQIAHDPfDTkRsBHbweP7QGQ',
                      name: 'Hotel Luxmi Residency',
                      type: 'Panipat',
                      starRating: 3,
                      description:
                        "<p><b>Property Location: </b> With a stay at HOTEL LUXMI RESIDENCY in Panipat, you'll be 8.5 mi (13.6 km) from Devi Temple and 8.5 mi (13.7 km) from Salar Gunj Gate.  This hotel is 9.7 mi (15.7 km) from Kabuli Bagh Mosque and 11.8 mi (18.9 km) from Panipat Museum.</p><p><b>Rooms: </b> Make yourself at home in one of the 50 air-conditioned rooms featuring flat-screen televisions. Rooms have private balconies or patios. Complimentary wireless Internet access keeps you connected, and cable programming is available for your entertainment. Bathrooms have showers and complimentary toiletries.</p><p><b>Amenities: </b> Take advantage of recreation opportunities such as a 24-hour health club or take in the view from a rooftop terrace and a garden. Additional features at this hotel include complimentary wireless Internet access, tour/ticket assistance, and a reception hall.</p><p><b>Dining: </b> Enjoy a meal at Alacarte, or stay in and take advantage of the hotel's room service (during limited hours). A complimentary breakfast is included.</p><p><b>Business, Other Amenities: </b> Featured amenities include complimentary newspapers in the lobby, dry cleaning/laundry services, and a 24-hour front desk. Free self parking is available onsite.</p>",
                      score: 28,
                      lng: 76.9186031,
                      city: 'Panipat',
                      address:
                        '1, REFINERY ROAD, RAJAPUR, Panipat, Haryana, 132140',
                      userRating: 3.8,
                      locality: '',
                      image:
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/3c8a622e732411e78f1e025f77df004f.jpg',
                      hotelChain: '',
                      images: [
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/3c8a622e732411e78f1e025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/1e58ec6c732411e783e6025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/018606ce732411e78f1e025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/12135c94732411e783e6025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/340a631a732411e78f1e025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/6cf99a42290811e494c35ee5da2daa2a.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/5c467210290811e49eacdaf4768ad8d9.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/33839858732411e7938c025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/fbb1a3d4732311e783e6025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/ea98c474732311e78f1e025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/9621633e732211e7898a0a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/f2c2f3f4732311e78f1e025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/151f69c8732411e78f1e025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/5e18b564732211e7b7090a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/43535b60732411e783e6025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/4a70f592732411e78f1e025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/7f231614732211e7b7090a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/cb09b536290811e4b56f5ee5da2daa2a.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/879f1b88290811e4b0ee36cfdd80c293.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/c2b11c62290811e4951632e76f7e45c9.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/7cbdeb4c732211e7b7090a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/76c9180a290211e48adcdaf4768ad8d9.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/f6761670732311e78f1e025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/720df62e732211e7b7090a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/12e3dedc732411e7938c025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/4d0c0fd224b711e79b600224510f5e5b.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/b6f5139c290811e49e325ee5da2daa2a.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/af8043c2732211e7898a0a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/9b89ccc6732211e7898a0a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/0615204e732411e783e6025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/38e3c73c732411e783e6025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/0054597c86b411e9afaa0242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/0342fef4732411e783e6025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/3c7bc502732411e7938c025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/6154de74732211e7b7090a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/e07352b6732311e78f1e025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/2b7bce28732411e783e6025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/902f057e290811e49dbbdaf4768ad8d9.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/1aad6bd8732411e7938c025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/004df5f086b411e9a6e10242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/09d702b0732411e783e6025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/1ea08b58732411e78f1e025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/1d87896a732411e7938c025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/3399f77a732311e7aec50a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/4be85924732411e783e6025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/a25bccde732211e7898a0a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7260/726057/images/9ed4000e732211e7898a0a4cef95d023.jpg',
                      ],
                      pricePerNight: 0,
                      available: false,
                      amenities: {
                        parking: true,
                        ac: true,
                        wifi: false,
                        tv: true,
                        pool: true,
                      },
                    },
                    {
                      id: 'ChIJwx2A6lfaDTkRbSiY9835q9w',
                      name: 'Hotel Gold',
                      type: 'Panipat',
                      starRating: 3,
                      description:
                        'Hotel Gold is situated at a distance of 2 km from Panipat Junction (Railway Station). This hotel offers free internet access and breakfast service to its guests.This hotel in Panipat has 3 floors and 45 rooms. All rooms are equipped with amenities like telephone, room heater, sofa bed and an attached bathroom with hot and cold water facility.Additional facilities like banquet hall, business centre, front desk and sauna are available at the hotel. Other services include medical and room service.Hotel Gold provides airport transportation. Chandigarh International Airport is 155 km away from the hotel. The famous attractions include Hazrat Bu-Ali Shah Qalandar (2 km), Salar Gunj Gate (2 km), Kabuli Bagh Masjid (3 km) and Panipat Battle Field Memorial (7 km).',
                      score: 28,
                      lng: 76.9742109,
                      city: 'Panipat',
                      address:
                        'Hotel Gold G.T. Road,  Huda Sector 11, Huda Panipat Haryana India, Main Highway, Panipat, Haryana, 132103, India',
                      userRating: 3.8,
                      locality: 'Huda',
                      image:
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3896/389687/images/SITTING_AREA_4.jpg',
                      hotelChain: '',
                      images: [
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3896/389687/images/SITTING_AREA_4.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3896/389687/images/1_uKknqh.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3896/389687/images/3_ijxVHs.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3896/389687/images/BAR.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3896/389687/images/TOP_VIEW.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3896/389687/images/6_iHt0t5.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3896/389687/images/RESTAURANT.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3896/389687/images/DELUXE_ROOM.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3896/389687/images/LOBBY_AREA.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3896/389687/images/FRONT_VIEW.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3896/389687/images/SITTING_AREA.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3896/389687/images/LOBBY.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3896/389687/images/RESTAURENT.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3896/389687/images/5_4xpMcZ.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3896/389687/images/SITTING_AREA_3.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3896/389687/images/RECEPTION.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3896/389687/images/PRIMIUM_ROOM.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3896/389687/images/1_agkDpS.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3896/389687/images/ENTRANCE.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3896/389687/images/4_qWMm5I.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3896/389687/images/ROOM_2.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3896/389687/images/SITTING_AREA_2.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3896/389687/images/2_ngVerZ.jpg',
                      ],
                      pricePerNight: 0,
                      available: false,
                      amenities: {
                        parking: true,
                        ac: true,
                        wifi: false,
                        tv: false,
                        pool: true,
                      },
                    },
                    {
                      id: 'ChIJ5e35B1faDTkREeXJiAl77LY',
                      name: 'Maharaja Hotel',
                      type: 'Panipat',
                      starRating: 3,
                      description:
                        'Presenting an exceptional blend of architecture and service, Hotel Maharaja is one of the easily approachable 3-star hotels in Panipat. The hotel lies at a distance of 2 km from Panipat Junction, Panipat main bus stand is within 5 km and Indira Gandhi International airport is 105 km away.\r\n\r\nThe property houses 27 spacious & comfortable rooms equipped with first-class amenities such as LED television with a set-top box, Wi-Fi connectivity, digital safe, and central air-conditioning/heating. The hotel caters to both business and leisure travellers with on-premise amenities like 24-hour room service, on-call doctor, same day laundry, banquet halls and event spaces with varied capacities, parking facility, travel desk, and conference facility. In-house dining facilities include a multi-cuisine restaurant Mezban and a fast-food point The Palm. Among the popular tourist spots in Panipat, Panipat museum is 4 km, Kabuli Bagh Masjid is 4.4 km away.\r\n\r\nVisitors can choose to relax at the hotel or move out to explore various popular attractions in Panipat. Known as the Textile City, the city is also popular for three significant battles, fought in Indian history. Panipat Battle Field Memorial, a monument including a museum dedicated to the 3rd Battle of Panipat, is located at a distance of 6.4 km. The landmark tomb of the Sultan of Delhi, Ibrahim Lodhi Tomb, is 3.5 km far from the hotel. Purana Qila, Panipat Museum, Kabuli Bagh Masjid, Devi Mandir, and Kala Amb Park are some other interesting places to visit in Panipat.',
                      score: 26,
                      lng: 76.9735796,
                      city: 'Panipat',
                      address: 'G.T. Road, Panipat Haryana 132103',
                      userRating: 3.6,
                      locality: 'Netaji Colony',
                      image:
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/33968a4c728611e7b345025f77df004f.jpg',
                      hotelChain: '',
                      images: [
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/33968a4c728611e7b345025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/9431bf5af6b311e782a60a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/594affd44a9611e6bdf55ee5da2daa2a.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/91ba009054b811e7b22d02755708f0b3.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/627dd70ad36d11e7a1b00a9df65c8753.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/8be8867e25e011e8aac50224510f5e5b.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/bd9beda026b611e990ea0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/55d24fb262b711e7a6420a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/845498dc37b011e6aa5e001ec9b85d13.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/d25ae5ca26b611e993f30242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/16ee88e0728611e7a88f025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/a6ee5e78c37911e6a79a0a9df65c8753.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/9399144c25e011e8b69b0a9df65c8753.png',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/8f049612680911e791630a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/5f73be2e25e011e8806702755708f0b3.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/d2f41aa626b611e990ea0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/f16d778e26b611e9b1d10242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/725399f625e011e883ec0a9df65c8753.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/b909707826b611e9bc940242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/af959a4e41d511e68a5a0015c5f4277e.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/2e98580e728611e789e90a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/f11c1b5a26b611e9b5fa0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/e823d8f826b611e9ac310242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/30a2c9b2a4c511e590b4001ec9b85d13.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/2e6352804a9611e693f636cfdd80c293.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/3afc283c728611e7a88f025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/c2db00c626b611e9ab230242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/1f687b8e728611e7a88f025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/459dc99e728611e789e90a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/591f658225e011e8aac50224510f5e5b.png',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/27225746728611e7b345025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/8648526466a911e681ba001ec9b85d13.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/4b7f0970d36d11e7b3df02755708f0b3.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/5253a2bec37a11e6857b0a209fbd0127.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/cde0b6be26b611e9a7f60242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/cb8f1c30502f11e699f80015c5f4277e.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/92e84daa41d611e6bc675ee5da2daa2a.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/953e5fdc4aa911e6851f5ee5da2daa2a.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/d812826626b611e99b670242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/a45e117c37af11e6b735001ec9b85d13.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/5faa3ffc41d611e6a0db0022195573b9.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/7a0691ee25e011e880670ad53c9aae80.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/c71cdda826b611e995170242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/9d4b0fa237af11e6aa4a0015c5f4277e.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/57823e3425e011e894080ad53c9aae80.png',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/b400a7b826b611e990ea0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/3b9f3be8a4c511e5bdd5001ec9b85d13.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/8ccc9c7425e011e8ab6c0ad53c9aae80.png',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/e185973e26b611e9a6cd0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/da7b51f426b611e9b1d10242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/2e1d30de728611e7b345025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/8f317c9c502f11e69ed836cfdd80c293.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/33e34268c37911e688ee02755708f0b3.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/55175aac41d611e6a3790015c5f4277e.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/76917f54d36c11e7ad1302b0346f88ee.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/421b2d2625e011e88a4f0a2acc9137fc.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/b6286e2867c611e7ae150a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/4898f40241d611e6a2620022195573b9.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/417a1ad025e011e89d61023897a4ac92.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/adfd448e26b611e99c2c0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/398d78ecd36c11e7bfa20a692cfeef8a.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/9346678af6b311e782a60a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/64dbe95841d611e69ae30022195573b9.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/2b45f94c66aa11e696530015c5f4277e.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/28eb0c2c67ea11e790ab0a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/b40b902e26b611e98d600242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/5721dd6437ad11e68fb65ee5da2daa2a.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/91daab3425e011e89eb60ad53c9aae80.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/04f2cc2a66aa11e6a3f00015c5f4277e.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/b8aa807c26b611e9ab230242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/2a8e1370728611e78915025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/b27b4034502f11e6be3a5ee5da2daa2a.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/0c367a9854b811e7889602755708f0b3.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/48f152eed36d11e7939102755708f0b3.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/405372b0624911e78ce90a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/34d449e641d411e68c3a5ee5da2daa2a.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/40030c8425e011e8a1cc0a9df65c8753.png',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/21696236728611e7b7fa025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/ae35b8c826b611e9904f0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/765c7ec825e011e8808702f63dadf2dc.png',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/42a17cc825e011e895680a2acc9137fc.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/5a61a81025e011e8a31b0224510f5e5b.png',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/3eac007e728611e7b7fa025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/df2daa6226b611e9aa9b0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/4a1d5e4e728611e789e90a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/be1140dc26b611e9b1000242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/9270f906f6b311e782a60a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/4af334d837ae11e695a4001ec9b85d13.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/50413b5637ae11e6a9c50022195573b9.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/73f4a07a25e011e8b2230a2acc9137fc.png',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/25e9cc9c54b811e78c610224510f5e5b.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/db579caee52d11e59e285ee5da2daa2a.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/7b38511c66a911e685c336cfdd80c293.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/37d91cd2728611e789e90a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/4d50777466aa11e6a4635ee5da2daa2a.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/40ca993e25e011e8aa66023897a4ac92.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/5a32894a25e011e8827902755708f0b3.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/183dec54728611e78915025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/91871b4cf6b311e782a60a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/73b33c8425e011e88e8302755708f0b3.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/3e5bc3ecc37811e6bb7102bf5ac07431.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/28bfb5bc728611e7a88f025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/cc939dd026b611e99b900242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/46763a364a9611e6b28b001ec9b85d13.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/2eb5d578728611e7b7fa025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/3a2aa0a0728611e7b345025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/8b88ad3025e011e8baa00224510f5e5b.png',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/ae61fe384aa911e6a9dd001ec9b85d13.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/dfb03f9241d311e69b7f0015c5f4277e.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/eb4b105a26b611e9888c0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/75036c1c25e011e89b6502f63dadf2dc.png',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/91fa3c4c25e011e89b02023897a4ac92.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1361/1361608/images/3ff7ccac25e011e89b02023897a4ac92.png',
                      ],
                      pricePerNight: 0,
                      available: false,
                      amenities: {
                        parking: true,
                        ac: true,
                        wifi: false,
                        tv: true,
                        pool: false,
                      },
                    },
                    {
                      id: 'ChIJPcr-QEraDTkR0Ju9CQXKNgk',
                      name: 'Hotel BR Inn',
                      type: 'Panipat',
                      starRating: 3,
                      description:
                        'Offering a secluded accommodation, Hotel BR Inn is one of the excellent family-run 3-star hotels in Panipat. The property is located just 7 minutes walk from Panipat Bus Stop while Panipat Junction is 1.5 km. The nearest airport is IGI Airport, New Delhi which is 105 km away.\r\n\r\nThe property offers guests with a choice of economy, executive, and suite rooms, equipped with modern day facilities and nicely furnished interiors, LCD TVs, cosy beds, and round-the-clock room service. Dining table, separate living room, and a minibar are additional facilities available in suite rooms. Other amenities available on-premise include valet parking, on-call doctor, lobby lounge, travel desk, elevator, conference facility, safe deposit lockers, and 24-hour power backup among others. The in-house restaurant serves a wide range of Indian & international cuisines. A 24-hour coffee shop is also available.\r\n\r\nPanipat, a historic city in Haryana also known as the City of Weavers and Textile City, is the abode to various monuments, museums, and memorials of historical importance. Things to do in Panipat are plenty, explore the landmark tomb of Sultan of Delhi, Ibrahim Lodhi Tomb located at a distance of 1.2 km and Kabuli Bagh Masjid is about 4 km away from the hotel. Discover Haryanas history, archaeology, arts and crafts at the Panipat Museum. Panipat Battle Field Memorial stands as a reminiscent of the 3rd battle of Panipat. Other interesting places to visit in Panipat include Purana Qila, Kabuli Bagh Masjid, Devi Mandir, Kala Amb Park, and Maulana Altaf Hussain Hali Park.',
                      score: 26,
                      lng: 76.9679999,
                      city: 'Panipat',
                      address:
                        'Bishan Sarup Colony, Opp. Bus Stand, G.T. Road, NH - 1',
                      userRating: 3.6,
                      locality: 'Bishon Sarup Colony',
                      image:
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/b699386aab6d11e8a4150a9bc2a08e76.jpg',
                      hotelChain: '',
                      images: [
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/b699386aab6d11e8a4150a9bc2a08e76.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/606a983657a611e9a3320242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/d0f1a3ccab6e11e8a2c80224510f5e5b.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/c1b4cbf6ab6d11e8984e02f9e4ca0bee.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/1d232c841e1011e9b7870242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/6a0306f857a611e9bac60242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/257a099a57a711e981530242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/c995e6b6ab6d11e8b6930224510f5e5b.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/b642f504ab6d11e88a0c0aea9f0f8f9a.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/d52e000e57a611e995000242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/5c980ba65b8011e59b475ee5da2daa2a.png',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/8901c034ab6d11e8bdcd022a5b1314fc.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/69318ad857a611e9bc910242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/755d297457a711e989150242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/d4471a8aab6d11e8bdcd022a5b1314fc.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/36aced2c57a711e9bd420242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/ab760d4aab7311e893f20a15483f7d68.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/ad4b241cab6d11e8bf300a9bc2a08e76.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/d28b1ff2ab6d11e8bab5026ce8268fba.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/2b545666ab6f11e8b19c028fa9c2d59e.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/e502086cab6d11e8b6930224510f5e5b.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/a3e38c60ab7311e8b1910a3c4ab57b6a.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/8a02ebd4ab6d11e881ce027e9e9fa588.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/939b9a60ab6d11e8a6f80ad0106b5cb8.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/612b80be57a611e9a2cc0242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/30d67a24ab7411e8906902f9e4ca0bee.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/ac0d89beab6d11e88008026ce8268fba.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/3a8eae06ab7411e8b13a026ce8268fba.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/828c62f85b8011e5a7c8001ec9b85d13.png',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/8061c640ab6d11e8afbc0ad0106b5cb8.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/3986e118ab6f11e8b5a6028fa9c2d59e.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/773b2c6eab7211e895ff0ad0106b5cb8.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/de7d05beab6d11e8815f022f693b9042.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/1dce097657a711e9ab9b0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/26c4214eab7411e88018028fa9c2d59e.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/82279d6aab6d11e8952f0a9df65c8753.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/1d522ea057a711e995000242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/2653efd457a711e9a9770242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/acf0e60eab7311e8b40b0a307c688064.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/524f150a57a711e982850242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/8add8f28ab6d11e8ace6022a5b1314fc.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/af28b4faab7411e8a8150a3c4ab57b6a.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/caea01beab6d11e8b55a0a15483f7d68.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/279916ecab7411e891680ad0106b5cb8.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/a2b6bc54ab7311e8ad6d0aea9f0f8f9a.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/31f7609eab7411e88008026ce8268fba.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/fea397eaab7211e8acdb0ad0106b5cb8.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/dd03a4e0ab6d11e889db027e9e9fa588.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/7b3b2742ab7211e8b917022a5b1314fc.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/7fd37142ab6d11e88cde022a5b1314fc.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/c17ba542ab6d11e8ae55028fa9c2d59e.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7241/724185/images/3b8c4f66ab7411e8b684022f693b9042.jpg',
                      ],
                      pricePerNight: 0,
                      available: false,
                      amenities: {
                        parking: true,
                        ac: true,
                        wifi: false,
                        tv: true,
                        pool: false,
                      },
                    },
                    {
                      id: 'ChIJWc5VXG7FDTkRgV_moeqq_CU',
                      name: 'Swarn Mahal Hotel',
                      type: 'Panipat',
                      starRating: 3,
                      description:
                        "Keeping both leisure and business travellers' requirements in mind, Hotel Swarn Mahal provides 5-star comfort with traditional hospitality and efficient service. It is conveniently located about 15km from Panipat Junction Railway Station and Bus Stand while the nearest airport is the Indira Gandhi International Airport (100km). \r\n\r\nSome places of tourist interest around the hotel are Ibrahim Lodhi Tomb (15km), Hemu's Tomb (18km), Kabuli Bagh Masjid (16km), Salar Ganj Bazar (14km), Kala Amb Park (Panipat Battle Field Memorial) (19km), Devi Temple (16km), Panipat Museum (13km) and Bu-Ali Shah Qalandar Dargah (14km). \r\n\r\nThe hotel offers 72 luxurious rooms including Executive, Luxury, Executive Suite, and Luxury Suite Rooms. These elegantly furnished rooms provide facilities like air-conditioning, Wi-Fi access, television, tea/coffee maker, mini-bar and an attached bath with premium toiletries. Satiate your cravings for world-class food and cocktails at the Swarn Restaurant or relish multiple varieties of food under one roof at the food court.",
                      score: 26,
                      lng: 76.995733,
                      city: 'Panipat',
                      address: '80th milestone, NH-1, jhattipur,',
                      userRating: 3.7,
                      locality: '',
                      image:
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/fb698992b45211e9ab7a0242ac110002.jpg',
                      hotelChain: '',
                      images: [
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/fb698992b45211e9ab7a0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/81397054725211e7a95a025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/aeaa824e725211e788a00a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/47c8c934b76d11e9a87b0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/f8efe954603a11e7a188025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/5a391c32d47111e5a8495ee5da2daa2a.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/b12a8960725211e7b2390a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/369d536aa96611e991440242ac110004.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/5c7ef42ab45711e9b2820242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/1c5c8c50a96111e9ab560242ac110006.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/c6a5df6ea96011e9b3ea0242ac110004.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/0413dd6ef4bb11e59501001ec9b85d13.png',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/ffa07ff4a96011e9aa260242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/54bdb346725211e7a95a025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/7e2d0978d47111e58f99001ec9b85d13.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/a2a013fad47011e5ad2c36cfdd80c293.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/aa9bb246ac6011e9acb10242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/88477b8214fd11e79f5102755708f0b3.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/a3474d88725211e7b2390a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/6342953a725211e788a00a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/0d9931e0b45711e9a51f0242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/9392432a725211e7b2390a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/19e1034ab5e911e98d100242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/b06e743aa96111e986310242ac110005.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/609ad03e14fd11e7b55e02755708f0b3.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/37ea17d2725211e7a95a025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/80288dee725211e7b2390a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/68608342725211e799540a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/31c48a42628111e9a18c0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/9aba620ea96411e9a1840242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/c8d837c2d47111e5b2d936cfdd80c293.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/78ca0f72d47011e584ad5ee5da2daa2a.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/df862d26b76c11e9937f0242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/71c5b51a725211e799540a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/0e2704a4608311e7a2460a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/32cddf4c628111e9822d0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/0e8e4bb6b45311e99b270242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/7ebc9434b76d11e99fa00242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/9a6700f0725211e788a00a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/8951cdd0b45511e981e00242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/6ff9c730725211e7b2390a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/7f73f3eab76d11e9a47c0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/6cac72842b0011e6a8200022195573b9.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/c084ed84ac6011e9b7830242ac110004.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/49227914b45311e9b1bc0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/86155aa2725211e799540a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/0d87afdab45511e98d3d0242ac11000b.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/102516f0b45611e9a5430242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/84a26db6d47111e5b097001ec9b85d13.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/8334db6e725211e799540a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/9c6d65d6b76c11e99b2d0242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/89b2834ea96211e991430242ac110008.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/32bc3a1c628111e9b21b0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/1edbbaac725211e78915025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/06857d18b45811e98b710242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/8abef8822b0011e6b0f7001ec9b85d13.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/309dbb66b45711e9a5f60242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/a7a4cbced47111e59c9036cfdd80c293.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/31d1dd5a628111e9886b0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/9c7a0656b76c11e993cb0242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/83bafe36b45611e995280242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/61096f94b45611e98b880242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/98f6215ad47011e5a9b8001ec9b85d13.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/30c67df4b45611e987d00242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/33f17910628111e98e0e0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/2a4f9f2a725211e78915025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/73442f8e725211e7a95a025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/7540a088725211e788a00a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/66063e0cac6111e9828f0242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/8eeab6b2d47011e5916036cfdd80c293.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/b2a6c298b45611e9940e0242ac110005.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/9a140fbaa96611e981ef0242ac110004.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/97a51f52b45711e98a550242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/6618b640ac6111e9866a0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/61aabf70d47111e5b75e36cfdd80c293.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/575edf92a96611e9b4640242ac110004.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/9a04fe1cd47111e591abdaf4768ad8d9.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/86dc166ed47011e59bd75ee5da2daa2a.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/7f2fef08d47011e5967e001ec9b85d13.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1351/1351954/images/8aa9257aac6211e982ec0242ac110005.jpg',
                      ],
                      pricePerNight: 0,
                      available: false,
                      amenities: {
                        parking: true,
                        ac: true,
                        wifi: false,
                        tv: true,
                        pool: false,
                      },
                    },
                    {
                      id: 'ChIJy0p4IrPbDTkRndxgGauo4IM',
                      name: 'Hotel Dolives',
                      type: 'Panipat',
                      starRating: 3,
                      description:
                        "<p><b>Near Salar Gunj Gate</b></p><p><b>Location: </b>Located in Panipat, Hotel Dolives is within a 15-minute drive of Salar Gunj Gate and Kabuli Bagh Mosque.  This hotel is 2.9 mi (4.6 km) from Panipat Museum and 30.4 mi (48.8 km) from National Dairy Research Institute.</p><p><b>Rooms: </b>Make yourself at home in one of the 85 guestrooms featuring minibars and LCD televisions. Complimentary wireless Internet access keeps you connected, and cable programming is available for your entertainment. Bathrooms have showers and complimentary toiletries. Conveniences include safes and coffee/tea makers, and housekeeping is provided daily.</p><p><b>Attractions: </b>Distances are displayed to the nearest 0.1 mile and kilometer. <br /> <p>Salar Gunj Gate - 1.1 km / 0.7 mi <br /> Devi Temple - 1.4 km / 0.9 mi <br /> Kabuli Bagh Mosque - 2.5 km / 1.6 mi <br /> Panipat Museum - 4.5 km / 2.8 mi <br /> Deenbandhu Chhotu Ram University Of Science & Technology - 44 km / 27.4 mi <br /> Super Mall - 44.6 km / 27.7 mi <br /> Karan Park - 45.1 km / 28 mi <br /> Atal Park - 45.4 km / 28.2 mi <br /> National Dairy Research Institute - 48.8 km / 30.4 mi <br /> Khwaja Khizar Tomb - 48.9 km / 30.4 mi <br /> </p></p><p><b>Amenities: </b>Make use of convenient amenities, which include complimentary wireless Internet access and concierge services.</p><p><b>Business Amenities: </b>Featured amenities include a business center, complimentary newspapers in the lobby, and dry cleaning/laundry services. Guests may use a roundtrip airport shuttle for a surcharge, and free self parking is available onsite.</p><p><b>Dining: </b>Enjoy a meal at Hotel Dolives, or stay in and take advantage of the hotel's 24-hour room service. Quench your thirst with your favorite drink at the bar/lounge. Continental breakfasts are available daily from 8 AM to 10 AM for a fee.</p>",
                      score: 26,
                      lng: 76.9695916,
                      city: 'Panipat',
                      address:
                        'Opposite Hdfc Bank, Pillar No.40, G.T. Road, Haryana132103, India',
                      userRating: 3.8,
                      locality: 'Old Housing Board Colony',
                      image:
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/fe418752_z.jpg',
                      hotelChain: '',
                      images: [
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/fe418752_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/8ef2b79b_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/68608505_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/f316deb3_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/869ac0c5_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/027ad472_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/4091d864_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/92d4c638_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/e5e00618_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/bd155e85_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/5fc08caf_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/0a5c7e31_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/6e84bd0b_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/28d204c9_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/13a29b08_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/6a75cec9_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/cc1d663a_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/22f31cbf_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/4abfbde7_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/c7fea9ff_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/37b89a6c_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/8ac138ce_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/83895ab8_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/8e0ec877_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/26830caa_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/b984a6cc_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/3c6ca401_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/ebc4fc83_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/3a4d1037_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/d968604e_z.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/3935/3935922/images/e3a7ff2c_z.jpg',
                      ],
                      pricePerNight: 0,
                      available: false,
                      amenities: {
                        parking: true,
                        ac: true,
                        wifi: false,
                        tv: false,
                        pool: false,
                      },
                    },
                    {
                      id: 'ChIJy0p4IrPbDTkRndxgGauo4IM',
                      name: "Hotel D'Olives",
                      type: 'Panipat',
                      starRating: 3,
                      description:
                        "Hotel D'Olives is an impressive 3-star hotel in Panipat. All its rooms are completely furnished with stylish decor. The rooms are also neatly maintained and well-lit. They come with an attached bath, a LCD television, a sitting area, and air conditioning. Banquet facilities can also be availed at this hotel. It can be used to host both private as well as business events.\r\n\r\nAn in-house multi cuisine restaurant, which is one of the best restaurants in Panipat, is another highlight of this property. The other features of this establishment include free Wifi, a 24-hours front desk, free parking space, an airport shuttle, laundry service, and room service. The nearest airport to Panipat is Indira Gandhi International Airport and Hotel DOlives is 104 km away from it.\r\n\r\nThere are quite a number of places to visit in Panipat during your stay in the city. One such place is the Kabuli Bagh Mosque, which was built by the Mughal emperor Babur. Other things to do in Panipat include visiting the Tomb of Bu-Ali-Shah Qalandar. This tomb has been deftly standing there for seven hundred years. The best time to visit Panipat is during the winters. Hence, it is better to make your travel plans between October and January.",
                      score: 24,
                      lng: 76.9695916,
                      city: 'Panipat',
                      address: 'GT Road, Pillar no.40, Opposite HDFC Bank',
                      userRating: 3.8,
                      locality: 'Old Housing Board Colony',
                      image:
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/0f2769f2199111e9843c0242ac110004.jpg',
                      hotelChain: '',
                      images: [
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/0f2769f2199111e9843c0242ac110004.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/c92c4a801a3011e9bf380242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/21669a6ec06211e686a602bf5ac07431.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/af8abf0e198f11e992310242ac110004.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/94a19ff2c05711e6ae410224510f5e5b.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/539fa8fece5e11e694b702bf5ac07431.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/fe62c8f06f7811e798fc0a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/02c0c0d62ea011e993fa0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/f412de522e9f11e9b6e50242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/115663fece6111e6b75d0224510f5e5b.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/8b814f1cc05711e6819902755708f0b3.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/ee6757981a2d11e98e050242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/eae99dea1a2f11e9adac0242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/6f1a468267ec11e9874e0242ac110002.jpeg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/2b8b75b21a3011e9a2da0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/6f28a9ca67ec11e9b44e0242ac110002.jpeg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/a15af96ec05711e695ea0a9df65c8753.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/96ee663e67ec11e9afb70242ac110007.jpeg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/d0bead801a2e11e99d540242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/a48d26b867ec11e98e1a0242ac110002.jpeg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/682ba2a41a2f11e99b2f0242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/be0a24c4cd9811e695510224510f5e5b.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/188c2f0a6f7911e7b03f0a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/b8d709b4198f11e98d4b0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/146c57a66f7911e7b03f0a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/088a69006f7911e7b03f0a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/a386d454198f11e9b9f30242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/beebc996cd9911e69b1c02755708f0b3.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/5ccd5140199011e9843c0242ac110004.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/fe6f3a4a6f7811e7b03f0a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/d75807f62e9f11e989df0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/7629a8a6ce5f11e6813d0a209fbd0127.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/0324673e2ea111e995510242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/5a48bc4e1a2f11e9aa210242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/4a04eb98c05711e6a0fd02755708f0b3.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/064474dc1a2d11e9afa40242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/0c4b09326f7911e798fc0a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/662362781a2d11e9b3f60242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/963f8090cd9a11e692bc0a9df65c8753.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/200a532e6f7911e7b03f0a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/1a3b82fec06211e6b3780a9df65c8753.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/0e509a80cd9911e68a2c02755708f0b3.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/43f55634cd9a11e6821602755708f0b3.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/d3707d020fb711e7a8bb0224510f5e5b.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/827da26cc05711e6a72b0224510f5e5b.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/b627a57a198f11e9b2b60242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/60c019c66f7911e782c6025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/d554d4de0fb611e785df0a9df65c8753.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/30e0813c6f7911e782c6025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/713a5c0467ec11e9b8400242ac110004.jpeg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/abbbabae198f11e9afc40242ac110004.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/9724fe901a2d11e9b4240242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/3be246ec6f7911e782c6025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/27d08bb46f7911e798fc0a4cef95d023.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/f95193d0ce5d11e684480224510f5e5b.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/e659f66a2e9f11e9801f0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/25c6fca46f7911e782c6025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/4466a064c05711e698930224510f5e5b.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/6bd2dae26f7911e782c6025f77df004f.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/115a69a62ea211e98be80242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/8e14b9ea1a3111e99c260242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/6a5b785ecd9911e6994602bf5ac07431.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/1509/1509782/images/65487e021a3111e9bfb80242ac110002.jpg',
                      ],
                      pricePerNight: 0,
                      available: false,
                      amenities: {
                        parking: false,
                        ac: true,
                        wifi: false,
                        tv: true,
                        pool: false,
                      },
                    },
                    {
                      id: 'ChIJv2HsYoXbDTkRR96PNnjyS7Y',
                      name: 'Hotel Green Castle',
                      type: 'Panipat',
                      starRating: 3,
                      description:
                        'Strategically Located in the heart of City on National Highway 1, Green Castle , Panipat is easily accessible by Road and by Train from Delhi or Chandigarh/Punjab.All the 17 rooms at Green Castle carry a contemporary design combined with features that give our guests the desired comfort : Individual Climate Control WI-FI connectivity LCD televisions Smart Workstations Iron and Iron Board on request Daily Newspaper in room',
                      score: 24,
                      lng: 76.968727,
                      city: 'Panipat',
                      address: 'GT Road, Pillor No 54, Near Uco Bank',
                      userRating: 3.9,
                      locality: 'Geeta Colony',
                      image:
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/2877/2877844/images/645a8cf45c2311e981fb0242ac110002.jpg',
                      hotelChain: '',
                      images: [
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/2877/2877844/images/645a8cf45c2311e981fb0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/2877/2877844/images/daf9daca64f911e99f600242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/2877/2877844/images/58e5507864f911e9bc120242ac110004.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/2877/2877844/images/638954545c2311e98e510242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/2877/2877844/images/65cee2385c2311e98cd00242ac110003.jpeg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/2877/2877844/images/f2709f3664f911e9a40c0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/2877/2877844/images/0f7a6d1464fa11e99e2e0242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/2877/2877844/images/69b9677c64f911e99f600242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/2877/2877844/images/3cf916729ef811e99b5e0242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/2877/2877844/images/0f62acd864fa11e982e60242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/2877/2877844/images/564ca8a664fa11e9a5400242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/2877/2877844/images/54f71c3eb32411e99cd60242ac110004.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/2877/2877844/images/65b5b2ca64f911e990bb0242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/2877/2877844/images/5591c9d264fa11e9937e0242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/2877/2877844/images/3f1e1e8664f911e9b1150242ac110002.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/2877/2877844/images/4aa8150464f911e9bdbe0242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/2877/2877844/images/626bc9869ef811e9879e0242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/2877/2877844/images/65d2a2245c2311e9880e0242ac110002.jpeg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/2877/2877844/images/3d4f061a64f911e98bc60242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/2877/2877844/images/60e1fcbc64fa11e99e2e0242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/2877/2877844/images/643f461264fa11e9814f0242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/2877/2877844/images/57541de264fa11e982090242ac110003.jpg',
                        'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/2877/2877844/images/2a2e8f82b32411e9b2ea0242ac110003.jpg',
                      ],
                      pricePerNight: 0,
                      available: false,
                      amenities: {
                        parking: false,
                        ac: true,
                        wifi: false,
                        tv: true,
                        pool: false,
                      },
                    },
                  ],
                },
                null,
                2
              )}
            </code>
          </CodeGroup>
        </Col>
      </Row>
    </>
  );
}
