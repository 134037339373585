import { Col, Properties, Property, Row } from '../../Common/Property';
import { CodeGroup } from '../../Common/Code';
import Eyebrow from '../../Common/Eyebrow';

const ROUTE = '/hotel/order';

export default function Docs() {
  return (
    <>
      <Eyebrow label={ROUTE} tag='POST' />
      <h1>Book Hotel</h1>
      <p>
        You can confirm booking by hitting this api.
      </p>
      <h2>Request Model</h2>
      <p className='lead'>
        Details of all the parameters that can be passed in the request.
      </p>
      <Row>
        <Col>
          <Properties>
              <Property name='referenceId' type='string'>
                  Provide unique generated at your system
              </Property>
              <Property name='holdId' type='string'>
                  Provide hold id generated by our system from hold api.
              </Property>
          </Properties>
        </Col>
        <Col>
          <CodeGroup tag='POST' label={ROUTE} title='Request Payload'>
            <code title='cURL'>
                {JSON.stringify(
                    {
                        holdId: 'order_MA3amIK2IqSXp7',
                        referenceId: 'order_MA3amIK2IqSXp7',
                    },
                    null,
                    2
                )}
            </code>
          </CodeGroup>

          <CodeGroup title='Response Pay Load'>
            <code title='json'>
                {JSON.stringify(
                    {
                        status: 'ONGOING',
                        bookingId: 'order_MA3amIK2IqSXp7',
                    },
                    null,
                    2
                )}
            </code>
          </CodeGroup>
        </Col>
      </Row>
    </>
  );
}
