import React from 'react'
import {Route, Routes} from "react-router-dom";
import OneWay from "./OneWay";
import Round from "./Round";


export default function index() {
	return (
		<Routes>
      <Route element={<OneWay/>} path="/oneway/*"/>
      <Route element={<Round/>} path="/round/*"/>
		</Routes>
	)
}
