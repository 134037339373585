import OneWay from './OneWay/navigation'
import Round from './Round/navigation'

export default {
	title: 'Flight Apis',
	links: [
		OneWay,
		Round
	]
}
