import { Col, Properties, Property, Row } from '../../Common/Property';
import { CodeGroup } from '../../Common/Code';
import Eyebrow from '../../Common/Eyebrow';

const ROUTE = '/hotel/cancel/charges';

export default function Docs() {
  return (
    <>
      <Eyebrow label={ROUTE} tag='GET' />
      <h1>Cancellation Charges</h1>
      <p>
       This api is used to fetch refundable amount for this booking.
      </p>
      <h2>Request Model</h2>
      <p className='lead'>
        Details of all the parameters that can be passed in the request.
      </p>
      <Row>
        <Col>
          <Properties>
            <Property name='bookingId' type='number'>
              Value can be extracted from Refund Details api response.
            </Property>
          </Properties>
        </Col>
        <Col>
          <CodeGroup tag='GET' label={ROUTE} title='Request Payload'>
            <code title='URL'>
                /hotel/cancel/charges?bookingId=223072400001
            </code>
          </CodeGroup>
          <CodeGroup title='Response Pay Load'>
            <code title='json'>{JSON.stringify({refundAmount:1202}, null, 2)}</code>
          </CodeGroup>
        </Col>
      </Row>
    </>
  );
}
