import Tag from './Tag'

export default function Eyebrow({ tag, label }) {
	if (!tag && !label) {
		return null
	}

	return (
		<div className="flex items-center gap-x-3 mb-2">
			{tag && <Tag>{tag}</Tag>}
			{tag && label && (
				<span className="h-0.5 w-0.5 rounded-full bg-zinc-300 dark:bg-zinc-600" />
			)}
			{label && (
				<span className="font-mono text-xs text-zinc-400">{label}</span>
			)}
		</div>
	)
}
