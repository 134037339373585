import { Col, Properties, Property, Row } from '../../../Common/Property';
import { CodeGroup } from '../../../Common/Code';
import Eyebrow from '../../../Common/Eyebrow';
import { Note } from '../../../Common/Note';

const ROUTE = '/flight/sandbox/domestic/round/search';

export default function Docs() {
  return (
    <>
      <Eyebrow label={ROUTE} tag='POST' />
      <h1>Round Search</h1>
      <p>
        As the name suggests, employees are a core part of Dice — the very
        reason Dice exists is so you can provide secure spends management to
        your employees. On this page, we'll dive into how to create employees
        programmatically.
      </p>
      <h2>Request Model</h2>
      <p className='lead'>
        Details of all the parameters that can be passed in the request.
      </p>
      <Row>
        <Col>
          <Properties>
            <Property name='origin' type='string'>
              Value can be extracted from Search api response.
            </Property>
            <Property name='destination' type='string'>
              Value can be extracted from Search api response.
            </Property>
            <Property name='date' type='string'>
              Value can be extracted from Search api response.
            </Property>
            <Property name='returnDate' type='string'>
              Value can be extracted from Search api response.
            </Property>
            <Property name='counts' type='object'>
              Value can be extracted from Search api response.
            </Property>
            <Note>Search results are valid for only 15 mins</Note>
          </Properties>
        </Col>
        <Col>
          <CodeGroup tag='POST' label={ROUTE} title='Request Payload'>
            <code title='cURL'>
              curl --request POST \ <br />
              --url {ROUTE}
              \ <br />
              --header 'Origin: Dice' \ <br />
              --header 'accept: application/json' \ <br />
              --header 'content-type: application/json' <br />
            </code>
          </CodeGroup>

          <CodeGroup title='Response Pay Load'>
            <code title='json'>
              {JSON.stringify(
                {
                  success: true,
                  ONWARDS: [
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666270200000,
                        departure: 1666230600000,
                        numberOfStops: 1,
                        duration: 660,
                      },
                      key: 'I5|548|1666230600000|1666270200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_4DELLKOI5548LKOBOMI5338_18182007832195@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 10326.83,
                            publishedFare: 10469,
                            comission: 142.17,
                            bookingClass: 'SM',
                            baseFare: 9525,
                            taxes: {
                              total: 944,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 480,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_4DELLKOI5548LKOBOMI5338_18182007832405@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 10447.1,
                            publishedFare: 10591,
                            comission: 143.9,
                            bookingClass: 'EC',
                            baseFare: 9641,
                            taxes: {
                              total: 950,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 486,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '204',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '548',
                          durationInMin: 60,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666230600000,
                          departureDate: '2022-10-20T07:20',
                          arrival: {
                            code: 'LKO',
                            airport: 'Amausi Arpt',
                            city: 'Lucknow',
                            country: 'India',
                          },
                          arrivalTime: 1666234200000,
                          arrrivalDate: '2022-10-20T08:20',
                        },
                        {
                          id: '205',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '338',
                          durationInMin: 135,
                          departure: {
                            code: 'LKO',
                            airport: 'Amausi Arpt',
                            city: 'Lucknow',
                            country: 'India',
                          },
                          departureTime: 1666262100000,
                          departureDate: '2022-10-20T16:05',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666270200000,
                          arrrivalDate: '2022-10-20T18:20',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '548',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666284000000,
                        departure: 1666276200000,
                        numberOfStops: 0,
                        duration: 130,
                      },
                      key: 'AI|805|1666276200000|1666284000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_12DELBOMAI805_18185015035576@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6161.94,
                            publishedFare: 6270,
                            comission: 108.06,
                            bookingClass: 'S',
                            baseFare: 5430,
                            taxes: {
                              total: 840,
                              breakups: {
                                'Carrier Misc': 170,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '757',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '805',
                          durationInMin: 130,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666276200000,
                          departureDate: '2022-10-20T20:00',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666284000000,
                          arrrivalDate: '2022-10-20T22:10',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '805',
                        equipment: '77W',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666343700000,
                        departure: 1666271400000,
                        numberOfStops: 1,
                        duration: 1205,
                      },
                      key: 'UK|855|1666271400000|1666343700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_42DELGOIUK855GOIBOMUK842_18185015113461@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 12138.31,
                            publishedFare: 12360,
                            comission: 221.69,
                            bookingClass: 'Q',
                            baseFare: 11140,
                            taxes: {
                              total: 1220,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 570,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_42DELGOIUK855GOIBOMUK842_18185015112961@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 10490.15,
                            publishedFare: 10680,
                            comission: 189.85,
                            bookingClass: 'Q',
                            baseFare: 9540,
                            taxes: {
                              total: 1140,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 490,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '845',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '855',
                          durationInMin: 160,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666271400000,
                          departureDate: '2022-10-20T18:40',
                          arrival: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          arrivalTime: 1666281000000,
                          arrrivalDate: '2022-10-20T21:20',
                        },
                        {
                          id: '846',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '842',
                          durationInMin: 85,
                          departure: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          departureTime: 1666338600000,
                          departureDate: '2022-10-21T13:20',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666343700000,
                          arrrivalDate: '2022-10-21T14:45',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '855',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666327500000,
                        departure: 1666269000000,
                        numberOfStops: 1,
                        duration: 975,
                      },
                      key: 'UK|515|1666269000000|1666327500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_131DELBLRUK515BLRBOMUK846_18185015286680@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 18124.16,
                            publishedFare: 18927,
                            comission: 802.84,
                            bookingClass: 'O',
                            baseFare: 17394,
                            taxes: {
                              total: 1533,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 883,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '246',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '515',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666269000000,
                          departureDate: '2022-10-20T18:00',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666278900000,
                          arrrivalDate: '2022-10-20T20:45',
                        },
                        {
                          id: '247',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '846',
                          durationInMin: 105,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666321200000,
                          departureDate: '2022-10-21T08:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666327500000,
                          arrrivalDate: '2022-10-21T10:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '515',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666270200000,
                        departure: 1666252200000,
                        numberOfStops: 1,
                        duration: 300,
                      },
                      key: 'UK|673|1666252200000|1666270200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_132DELVNSUK673VNSBOMUK622_18185015288140@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 20173.09,
                            publishedFare: 20550,
                            comission: 376.91,
                            bookingClass: 'B',
                            baseFare: 18940,
                            taxes: {
                              total: 1610,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 960,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '386',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '673',
                          durationInMin: 85,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666252200000,
                          departureDate: '2022-10-20T13:20',
                          arrival: {
                            code: 'VNS',
                            airport: 'Lal Bahadur Shastri Arpt',
                            city: 'Varanasi',
                            country: 'India',
                          },
                          arrivalTime: 1666257300000,
                          arrrivalDate: '2022-10-20T14:45',
                        },
                        {
                          id: '387',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '622',
                          durationInMin: 130,
                          departure: {
                            code: 'VNS',
                            airport: 'Lal Bahadur Shastri Arpt',
                            city: 'Varanasi',
                            country: 'India',
                          },
                          departureTime: 1666262400000,
                          departureDate: '2022-10-20T16:10',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666270200000,
                          arrrivalDate: '2022-10-20T18:20',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '673',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 8,
                      details: {
                        arrival: 1666243800000,
                        departure: 1666224000000,
                        numberOfStops: 1,
                        duration: 330,
                      },
                      key: '6E|307|1666224000000|1666243800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_29DELLKO6E307LKOBOM6E5364_18188342602215@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 13904.36,
                            publishedFare: 14674,
                            comission: 769.64,
                            bookingClass: 'M',
                            baseFare: 13000,
                            taxes: {
                              total: 1674,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1674,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_29DELLKO6E307LKOBOM6E5364_18188342602645@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 13408.33,
                            publishedFare: 13672,
                            comission: 263.67,
                            bookingClass: 'R',
                            baseFare: 12500,
                            taxes: {
                              total: 1172,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1172,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '610',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '307',
                          durationInMin: 70,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666224000000,
                          departureDate: '2022-10-20T05:30',
                          arrival: {
                            code: 'LKO',
                            airport: 'Amausi Arpt',
                            city: 'Lucknow',
                            country: 'India',
                          },
                          arrivalTime: 1666228200000,
                          arrrivalDate: '2022-10-20T06:40',
                        },
                        {
                          id: '611',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '5364',
                          durationInMin: 140,
                          departure: {
                            code: 'LKO',
                            airport: 'Amausi Arpt',
                            city: 'Lucknow',
                            country: 'India',
                          },
                          departureTime: 1666235400000,
                          departureDate: '2022-10-20T08:40',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666243800000,
                          arrrivalDate: '2022-10-20T11:00',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '307',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666320300000,
                        departure: 1666275600000,
                        numberOfStops: 1,
                        duration: 745,
                      },
                      key: 'UK|809|1666275600000|1666320300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_93DELBLRUK809BLRBOMUK858_18185015214036@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 19142.99,
                            publishedFare: 19500,
                            comission: 357.01,
                            bookingClass: 'K',
                            baseFare: 17940,
                            taxes: {
                              total: 1560,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 910,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_93DELBLRUK809BLRBOMUK858_18185015213806@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 31693.02,
                            publishedFare: 32254,
                            comission: 560.98,
                            bookingClass: 'R',
                            baseFare: 28190,
                            taxes: {
                              total: 4064,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 3414,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '211',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '809',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666275600000,
                          departureDate: '2022-10-20T19:50',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666285800000,
                          arrrivalDate: '2022-10-20T22:40',
                        },
                        {
                          id: '212',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '858',
                          durationInMin: 95,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666314600000,
                          departureDate: '2022-10-21T06:40',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666320300000,
                          arrrivalDate: '2022-10-21T08:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '809',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666289700000,
                        departure: 1666242600000,
                        numberOfStops: 1,
                        duration: 785,
                      },
                      key: 'I5|552|1666242600000|1666289700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_0DELGOII5552GOIBOMI5472_18182007819444@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7299.5,
                            publishedFare: 7398,
                            comission: 98.5,
                            bookingClass: 'SM',
                            baseFare: 6600,
                            taxes: {
                              total: 798,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 334,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_0DELGOII5552GOIBOMI5472_18182007820414@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'PROMO',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 10477.69,
                            publishedFare: 10622,
                            comission: 144.31,
                            bookingClass: 'BT',
                            baseFare: 9670,
                            taxes: {
                              total: 952,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 488,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_0DELGOII5552GOIBOMI5472_18182007819754@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7299.5,
                            publishedFare: 7398,
                            comission: 98.5,
                            bookingClass: 'EC',
                            baseFare: 6600,
                            taxes: {
                              total: 798,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 334,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '907',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '552',
                          durationInMin: 155,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666242600000,
                          departureDate: '2022-10-20T10:40',
                          arrival: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          arrivalTime: 1666251900000,
                          arrrivalDate: '2022-10-20T13:15',
                        },
                        {
                          id: '908',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '472',
                          durationInMin: 75,
                          departure: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          departureTime: 1666285200000,
                          departureDate: '2022-10-20T22:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666289700000,
                          arrrivalDate: '2022-10-20T23:45',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '552',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666284600000,
                        departure: 1666253400000,
                        numberOfStops: 1,
                        duration: 520,
                      },
                      key: 'UK|706|1666253400000|1666284600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_91DELIXCUK706IXCBOMUK652_18185015209846@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 27898.84,
                            publishedFare: 28425,
                            comission: 526.16,
                            bookingClass: 'Y',
                            baseFare: 26440,
                            taxes: {
                              total: 1985,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1335,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_91DELIXCUK706IXCBOMUK652_18185015209646@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 30867.94,
                            publishedFare: 31414,
                            comission: 546.06,
                            bookingClass: 'S',
                            baseFare: 27440,
                            taxes: {
                              total: 3974,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 3324,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '987',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '706',
                          durationInMin: 65,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666253400000,
                          departureDate: '2022-10-20T13:40',
                          arrival: {
                            code: 'IXC',
                            airport: 'Chandigarh Arpt',
                            city: 'Chandigarh',
                            country: 'India',
                          },
                          arrivalTime: 1666257300000,
                          arrrivalDate: '2022-10-20T14:45',
                        },
                        {
                          id: '988',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '652',
                          durationInMin: 145,
                          departure: {
                            code: 'IXC',
                            airport: 'Chandigarh Arpt',
                            city: 'Chandigarh',
                            country: 'India',
                          },
                          departureTime: 1666275900000,
                          departureDate: '2022-10-20T19:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666284600000,
                          arrrivalDate: '2022-10-20T22:20',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '706',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666277400000,
                        departure: 1666231200000,
                        numberOfStops: 1,
                        duration: 770,
                      },
                      key: 'UK|705|1666231200000|1666277400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_124DELCCUUK705CCUBOMUK776_18185015274060@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 22232.31,
                            publishedFare: 22649,
                            comission: 416.69,
                            bookingClass: 'A',
                            baseFare: 20939,
                            taxes: {
                              total: 1710,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1060,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_124DELCCUUK705CCUBOMUK776_18185015273830@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 52353.3,
                            publishedFare: 53288,
                            comission: 934.7,
                            bookingClass: 'T',
                            baseFare: 46970,
                            taxes: {
                              total: 6318,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 5668,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '470',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '705',
                          durationInMin: 125,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666231200000,
                          departureDate: '2022-10-20T07:30',
                          arrival: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          arrivalTime: 1666238700000,
                          arrrivalDate: '2022-10-20T09:35',
                        },
                        {
                          id: '471',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '776',
                          durationInMin: 165,
                          departure: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          departureTime: 1666267500000,
                          departureDate: '2022-10-20T17:35',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666277400000,
                          arrrivalDate: '2022-10-20T20:20',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '705',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666281000000,
                        departure: 1666238700000,
                        numberOfStops: 1,
                        duration: 705,
                      },
                      key: 'I5|1529|1666238700000|1666281000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_9DELBLRI51529BLRBOMI51782_18182007844696@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 16201.14,
                            publishedFare: 16428,
                            comission: 226.86,
                            bookingClass: 'SM',
                            baseFare: 15200,
                            taxes: {
                              total: 1228,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 764,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_9DELBLRI51529BLRBOMI51782_18182007844916@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'PROMO',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 14552.9,
                            publishedFare: 14756,
                            comission: 203.1,
                            bookingClass: 'BT',
                            baseFare: 13608,
                            taxes: {
                              total: 1148,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 684,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_9DELBLRI51529BLRBOMI51782_18182007845126@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PREMIUM_FLEX',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 16910.9,
                            publishedFare: 17148,
                            comission: 237.1,
                            bookingClass: 'HF',
                            baseFare: 15886,
                            taxes: {
                              total: 1262,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 798,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '423',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '1529',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666238700000,
                          departureDate: '2022-10-20T09:35',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666248900000,
                          arrrivalDate: '2022-10-20T12:25',
                        },
                        {
                          id: '424',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '1782',
                          durationInMin: 110,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666274400000,
                          departureDate: '2022-10-20T19:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666281000000,
                          arrrivalDate: '2022-10-20T21:20',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '1529',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666272000000,
                        departure: 1666242600000,
                        numberOfStops: 1,
                        duration: 490,
                      },
                      key: 'G8|286|1666242600000|1666272000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_10DELGOIG8286GOIBOMG82606_18183418299866@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 11079.06,
                            publishedFare: 11388,
                            comission: 308.94,
                            bookingClass: 'SP',
                            baseFare: 10350,
                            taxes: {
                              total: 1038,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1038,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_10DELGOIG8286GOIBOMG82606_18183418300386@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'GOMORE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 26585.33,
                            publishedFare: 27348,
                            comission: 762.67,
                            bookingClass: 'MG',
                            baseFare: 25550,
                            taxes: {
                              total: 1798,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1798,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_10DELGOIG8286GOIBOMG82606_18183418299546@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 11841.01,
                            publishedFare: 12628,
                            comission: 786.99,
                            bookingClass: 'BC',
                            baseFare: 11140,
                            taxes: {
                              total: 1488,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1488,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_10DELGOIG8286GOIBOMG82606_18183418300076@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 11895.17,
                            publishedFare: 12228,
                            comission: 332.83,
                            bookingClass: 'GS',
                            baseFare: 11150,
                            taxes: {
                              total: 1078,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1078,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '868',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 0,
                          flightNumber: '286',
                          durationInMin: 160,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666242600000,
                          departureDate: '2022-10-20T10:40',
                          arrival: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          arrivalTime: 1666252200000,
                          arrrivalDate: '2022-10-20T13:20',
                        },
                        {
                          id: '869',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 1,
                          flightNumber: '2606',
                          durationInMin: 85,
                          departure: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          departureTime: 1666266900000,
                          departureDate: '2022-10-20T17:25',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666272000000,
                          arrrivalDate: '2022-10-20T18:50',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '286',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666291200000,
                        departure: 1666283100000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: '6E|2114|1666283100000|1666291200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_5DELBOM6E2114_18188342548131@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6128.49,
                            publishedFare: 6244,
                            comission: 115.51,
                            bookingClass: 'T',
                            baseFare: 5476,
                            taxes: {
                              total: 768,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 768,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_5DELBOM6E2114_18188342547661@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7173.18,
                            publishedFare: 7558,
                            comission: 384.82,
                            bookingClass: 'M',
                            baseFare: 6500,
                            taxes: {
                              total: 1058,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1058,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_5DELBOM6E2114_18188342548421@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7181.89,
                            publishedFare: 7319,
                            comission: 137.11,
                            bookingClass: 'R',
                            baseFare: 6500,
                            taxes: {
                              total: 819,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 819,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '34',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '2114',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666283100000,
                          departureDate: '2022-10-20T21:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666291200000,
                          arrrivalDate: '2022-10-21T00:10',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '2114',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666254000000,
                        departure: 1666234200000,
                        numberOfStops: 1,
                        duration: 330,
                      },
                      key: '6E|308|1666234200000|1666254000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_24DELBLR6E308BLRBOM6E6379_18188342586064@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 12045.25,
                            publishedFare: 12281,
                            comission: 235.75,
                            bookingClass: 'T',
                            baseFare: 11176,
                            taxes: {
                              total: 1105,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1105,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_24DELBLR6E308BLRBOM6E6379_18188342585634@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 13242.49,
                            publishedFare: 13974,
                            comission: 731.51,
                            bookingClass: 'M',
                            baseFare: 12356,
                            taxes: {
                              total: 1618,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1618,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_24DELBLR6E308BLRBOM6E6379_18188342585864@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'FLEXI_PLUS',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 14288.27,
                            publishedFare: 14570,
                            comission: 281.73,
                            bookingClass: 'J',
                            baseFare: 13356,
                            taxes: {
                              total: 1214,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1214,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '456',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '308',
                          durationInMin: 160,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666234200000,
                          departureDate: '2022-10-20T08:20',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666243800000,
                          arrrivalDate: '2022-10-20T11:00',
                        },
                        {
                          id: '457',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '6379',
                          durationInMin: 90,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666248600000,
                          departureDate: '2022-10-20T12:20',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666254000000,
                          arrrivalDate: '2022-10-20T13:50',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '308',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 16,
                      details: {
                        arrival: 1666336200000,
                        departure: 1666245600000,
                        numberOfStops: 1,
                        duration: 1510,
                      },
                      key: 'SG|8485|1666245600000|1666336200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '4-2158784108_7DELDBRSG8485DBRBOMSG366_18183742818142@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 16,
                            offeredFare: 16812.05,
                            publishedFare: 17167,
                            comission: 354.95,
                            bookingClass: 'DD',
                            baseFare: 15855,
                            taxes: {
                              total: 1312,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 514,
                                'Management Fees': 0,
                                'Gst Charges': 798,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_7DELDBRSG8485DBRBOMSG366_18183742817842@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 16,
                            offeredFare: 17283.13,
                            publishedFare: 18318,
                            comission: 1034.87,
                            bookingClass: 'CP',
                            baseFare: 16379,
                            taxes: {
                              total: 1939,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1087,
                                'Management Fees': 0,
                                'Gst Charges': 852,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_7DELDBRSG8485DBRBOMSG366_18183742818292@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 16,
                            offeredFare: 13887.76,
                            publishedFare: 14179,
                            comission: 291.24,
                            bookingClass: 'RS',
                            baseFare: 13009,
                            taxes: {
                              total: 1170,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 514,
                                'Management Fees': 0,
                                'Gst Charges': 656,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '448',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 0,
                          flightNumber: '8485',
                          durationInMin: 105,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666245600000,
                          departureDate: '2022-10-20T11:30',
                          arrival: {
                            code: 'DBR',
                            airport: 'Darbhanga Airport',
                            city: 'Darbhanga',
                            country: 'India',
                          },
                          arrivalTime: 1666251900000,
                          arrrivalDate: '2022-10-20T13:15',
                        },
                        {
                          id: '449',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 1,
                          flightNumber: '366',
                          durationInMin: 160,
                          departure: {
                            code: 'DBR',
                            airport: 'Darbhanga Airport',
                            city: 'Darbhanga',
                            country: 'India',
                          },
                          departureTime: 1666326600000,
                          departureDate: '2022-10-21T10:00',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666336200000,
                          arrrivalDate: '2022-10-21T12:40',
                        },
                      ],
                      operator: {
                        name: 'SpiceJet',
                        code: 'SG',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/SG.png',
                        number: '8485',
                        equipment: '7M8',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666278600000,
                        departure: 1666236900000,
                        numberOfStops: 1,
                        duration: 695,
                      },
                      key: 'UK|801|1666236900000|1666278600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_80DELBLRUK801BLRBOMUK864_18185015189005@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 18124.16,
                            publishedFare: 18927,
                            comission: 802.84,
                            bookingClass: 'O',
                            baseFare: 17394,
                            taxes: {
                              total: 1533,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 883,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_80DELBLRUK801BLRBOMUK864_18185015188795@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 23442.27,
                            publishedFare: 23854,
                            comission: 411.73,
                            bookingClass: 'U',
                            baseFare: 20690,
                            taxes: {
                              total: 3164,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 2514,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '933',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '801',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666236900000,
                          departureDate: '2022-10-20T09:05',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666246800000,
                          arrrivalDate: '2022-10-20T11:50',
                        },
                        {
                          id: '934',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '864',
                          durationInMin: 105,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666272300000,
                          departureDate: '2022-10-20T18:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666278600000,
                          arrrivalDate: '2022-10-20T20:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '801',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 16,
                      details: {
                        arrival: 1666272300000,
                        departure: 1666245600000,
                        numberOfStops: 1,
                        duration: 445,
                      },
                      key: 'SG|8485|1666245600000|1666272300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '4-2158784108_6DELDBRSG8485DBRBOMSG116_18183742816372@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 16,
                            offeredFare: 16267.3,
                            publishedFare: 16647,
                            comission: 379.7,
                            bookingClass: 'HR',
                            baseFare: 15805,
                            taxes: {
                              total: 842,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 52,
                                'Management Fees': 0,
                                'Gst Charges': 790,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_6DELDBRSG8485DBRBOMSG116_18183742815892@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 16,
                            offeredFare: 17283.13,
                            publishedFare: 18318,
                            comission: 1034.87,
                            bookingClass: 'CP',
                            baseFare: 16379,
                            taxes: {
                              total: 1939,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1087,
                                'Management Fees': 0,
                                'Gst Charges': 852,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_6DELDBRSG8485DBRBOMSG116_18183742816222@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 16,
                            offeredFare: 13887.76,
                            publishedFare: 14179,
                            comission: 291.24,
                            bookingClass: 'RS',
                            baseFare: 13009,
                            taxes: {
                              total: 1170,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 514,
                                'Management Fees': 0,
                                'Gst Charges': 656,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '386',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 0,
                          flightNumber: '8485',
                          durationInMin: 105,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666245600000,
                          departureDate: '2022-10-20T11:30',
                          arrival: {
                            code: 'DBR',
                            airport: 'Darbhanga Airport',
                            city: 'Darbhanga',
                            country: 'India',
                          },
                          arrivalTime: 1666251900000,
                          arrrivalDate: '2022-10-20T13:15',
                        },
                        {
                          id: '387',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 1,
                          flightNumber: '116',
                          durationInMin: 165,
                          departure: {
                            code: 'DBR',
                            airport: 'Darbhanga Airport',
                            city: 'Darbhanga',
                            country: 'India',
                          },
                          departureTime: 1666262400000,
                          departureDate: '2022-10-20T16:10',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666272300000,
                          arrrivalDate: '2022-10-20T18:55',
                        },
                      ],
                      operator: {
                        name: 'SpiceJet',
                        code: 'SG',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/SG.png',
                        number: '8485',
                        equipment: '7M8',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666274400000,
                        departure: 1666222200000,
                        numberOfStops: 1,
                        duration: 870,
                      },
                      key: 'G8|7134|1666222200000|1666274400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_5DELCCUG87134CCUBOMG8519_18183418276664@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 6,
                            offeredFare: 16203.08,
                            publishedFare: 16662,
                            comission: 458.92,
                            bookingClass: 'SP',
                            baseFare: 15374,
                            taxes: {
                              total: 1288,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1288,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_5DELCCUG87134CCUBOMG8519_18183418277164@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'GOMORE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 33240.59,
                            publishedFare: 34198,
                            comission: 957.41,
                            bookingClass: 'MG',
                            baseFare: 32074,
                            taxes: {
                              total: 2124,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 2124,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_5DELCCUG87134CCUBOMG8519_18183418276444@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 6,
                            offeredFare: 16990.78,
                            publishedFare: 18136,
                            comission: 1145.22,
                            bookingClass: 'BC',
                            baseFare: 16211,
                            taxes: {
                              total: 1925,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1925,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_5DELCCUG87134CCUBOMG8519_18183418276894@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7125.72,
                            publishedFare: 7319,
                            comission: 193.28,
                            bookingClass: 'GS',
                            baseFare: 6475,
                            taxes: {
                              total: 844,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 844,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '738',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 0,
                          flightNumber: '7134',
                          durationInMin: 125,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666222200000,
                          departureDate: '2022-10-20T05:00',
                          arrival: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          arrivalTime: 1666229700000,
                          arrrivalDate: '2022-10-20T07:05',
                        },
                        {
                          id: '739',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 1,
                          flightNumber: '519',
                          durationInMin: 165,
                          departure: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          departureTime: 1666264500000,
                          departureDate: '2022-10-20T16:45',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666274400000,
                          arrrivalDate: '2022-10-20T19:30',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '7134',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666319700000,
                        departure: 1666283400000,
                        numberOfStops: 1,
                        duration: 605,
                      },
                      key: 'AI|883|1666283400000|1666319700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_39DELGOIAI883GOIBOMAI684_18185015108091@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 10491.75,
                            publishedFare: 10680,
                            comission: 188.25,
                            bookingClass: 'S',
                            baseFare: 9460,
                            taxes: {
                              total: 1220,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 490,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '329',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '883',
                          durationInMin: 160,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666283400000,
                          departureDate: '2022-10-20T22:00',
                          arrival: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          arrivalTime: 1666293000000,
                          arrrivalDate: '2022-10-21T00:40',
                        },
                        {
                          id: '330',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '684',
                          durationInMin: 75,
                          departure: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          departureTime: 1666315200000,
                          departureDate: '2022-10-21T06:50',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666319700000,
                          arrrivalDate: '2022-10-21T08:05',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '883',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666278900000,
                        departure: 1666258200000,
                        numberOfStops: 1,
                        duration: 345,
                      },
                      key: '6E|2187|1666258200000|1666278900000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_40DELHYD6E2187HYDBOM6E5389_18188342631736@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 23511.82,
                            publishedFare: 24835,
                            comission: 1323.18,
                            bookingClass: 'M',
                            baseFare: 22350,
                            taxes: {
                              total: 2485,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 2485,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_40DELHYD6E2187HYDBOM6E5389_18188342632166@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 23491.6,
                            publishedFare: 23962,
                            comission: 470.4,
                            bookingClass: 'R',
                            baseFare: 22300,
                            taxes: {
                              total: 1662,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1662,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '526',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '2187',
                          durationInMin: 130,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666258200000,
                          departureDate: '2022-10-20T15:00',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666266000000,
                          arrrivalDate: '2022-10-20T17:10',
                        },
                        {
                          id: '527',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '5389',
                          durationInMin: 70,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666274700000,
                          departureDate: '2022-10-20T19:35',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666278900000,
                          arrrivalDate: '2022-10-20T20:45',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '2187',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666262700000,
                        departure: 1666254600000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: 'AI|863|1666254600000|1666262700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_20DELBOMAI863_18185015062588@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7192.04,
                            publishedFare: 7320,
                            comission: 127.96,
                            bookingClass: 'G',
                            baseFare: 6430,
                            taxes: {
                              total: 890,
                              breakups: {
                                'Carrier Misc': 170,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 330,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '508',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '863',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666254600000,
                          departureDate: '2022-10-20T14:00',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666262700000,
                          arrrivalDate: '2022-10-20T16:15',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '863',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666276800000,
                        departure: 1666268700000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: 'AI|441|1666268700000|1666276800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_15DELBOMAI441_18185015046887@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6161.94,
                            publishedFare: 6270,
                            comission: 108.06,
                            bookingClass: 'S',
                            baseFare: 5430,
                            taxes: {
                              total: 840,
                              breakups: {
                                'Carrier Misc': 170,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '269',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '441',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666268700000,
                          departureDate: '2022-10-20T17:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666276800000,
                          arrrivalDate: '2022-10-20T20:10',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '441',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666313100000,
                        departure: 1666227600000,
                        numberOfStops: 1,
                        duration: 1425,
                      },
                      key: 'UK|959|1666227600000|1666313100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_26DELAMDUK959AMDBOMUK918_18185015081539@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 9491.46,
                            publishedFare: 9662,
                            comission: 170.54,
                            bookingClass: 'N',
                            baseFare: 8570,
                            taxes: {
                              total: 1092,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 442,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_26DELAMDUK959AMDBOMUK918_18185015080959@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 9491.46,
                            publishedFare: 9662,
                            comission: 170.54,
                            bookingClass: 'N',
                            baseFare: 8570,
                            taxes: {
                              total: 1092,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 442,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '172',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '959',
                          durationInMin: 85,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666227600000,
                          departureDate: '2022-10-20T06:30',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666232700000,
                          arrrivalDate: '2022-10-20T07:55',
                        },
                        {
                          id: '173',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '918',
                          durationInMin: 70,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666308900000,
                          departureDate: '2022-10-21T05:05',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666313100000,
                          arrrivalDate: '2022-10-21T06:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '959',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666276500000,
                        departure: 1666268100000,
                        numberOfStops: 0,
                        duration: 140,
                      },
                      key: 'UK|955|1666268100000|1666276500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_68DELBOMUK955_18185015166003@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 15535.06,
                            publishedFare: 15825,
                            comission: 289.94,
                            bookingClass: 'M',
                            baseFare: 14570,
                            taxes: {
                              total: 1255,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 735,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_68DELBOMUK955_18185015165753@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 18764.26,
                            publishedFare: 19094,
                            comission: 329.74,
                            bookingClass: 'R',
                            baseFare: 16570,
                            taxes: {
                              total: 2524,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 2004,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '633',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '955',
                          durationInMin: 140,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666268100000,
                          departureDate: '2022-10-20T17:45',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666276500000,
                          arrrivalDate: '2022-10-20T20:05',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '955',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666212300000,
                        departure: 1666204800000,
                        numberOfStops: 0,
                        duration: 125,
                      },
                      key: '6E|2519|1666204800000|1666212300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_4DELBOM6E2519_18188342546541@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 5772.78,
                            publishedFare: 5881,
                            comission: 108.22,
                            bookingClass: 'T',
                            baseFare: 5130,
                            taxes: {
                              total: 751,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 751,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_4DELBOM6E2519_18188342545991@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7173.18,
                            publishedFare: 7558,
                            comission: 384.82,
                            bookingClass: 'M',
                            baseFare: 6500,
                            taxes: {
                              total: 1058,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1058,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_4DELBOM6E2519_18188342546751@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7181.89,
                            publishedFare: 7319,
                            comission: 137.11,
                            bookingClass: 'R',
                            baseFare: 6500,
                            taxes: {
                              total: 819,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 819,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '178',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '2519',
                          durationInMin: 125,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666204800000,
                          departureDate: '2022-10-20T00:10',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666212300000,
                          arrrivalDate: '2022-10-20T02:15',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '2519',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666248000000,
                        departure: 1666228200000,
                        numberOfStops: 1,
                        duration: 330,
                      },
                      key: '6E|2107|1666228200000|1666248000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_21DELHYD6E2107HYDBOM6E5387_18188342576712@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 10424.49,
                            publishedFare: 10627,
                            comission: 202.51,
                            bookingClass: 'T',
                            baseFare: 9600,
                            taxes: {
                              total: 1027,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1027,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_21DELHYD6E2107HYDBOM6E5387_18188342576252@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 11747.69,
                            publishedFare: 12393,
                            comission: 645.31,
                            bookingClass: 'M',
                            baseFare: 10900,
                            taxes: {
                              total: 1493,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1493,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_21DELHYD6E2107HYDBOM6E5387_18188342576482@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'FLEXI_PLUS',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 12790.98,
                            publishedFare: 13042,
                            comission: 251.02,
                            bookingClass: 'J',
                            baseFare: 11900,
                            taxes: {
                              total: 1142,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1142,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '281',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '2107',
                          durationInMin: 140,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666228200000,
                          departureDate: '2022-10-20T06:40',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666236600000,
                          arrrivalDate: '2022-10-20T09:00',
                        },
                        {
                          id: '282',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '5387',
                          durationInMin: 70,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666243800000,
                          departureDate: '2022-10-20T11:00',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666248000000,
                          arrrivalDate: '2022-10-20T12:10',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '2107',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666327500000,
                        departure: 1666278600000,
                        numberOfStops: 1,
                        duration: 815,
                      },
                      key: 'UK|807|1666278600000|1666327500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_108DELBLRUK807BLRBOMUK846_18185015243917@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 20482.12,
                            publishedFare: 20865,
                            comission: 382.88,
                            bookingClass: 'Q',
                            baseFare: 19240,
                            taxes: {
                              total: 1625,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 975,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_108DELBLRUK807BLRBOMUK846_18185015243707@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 37578.55,
                            publishedFare: 38246,
                            comission: 667.45,
                            bookingClass: 'R',
                            baseFare: 33540,
                            taxes: {
                              total: 4706,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 4056,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '626',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '807',
                          durationInMin: 160,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666278600000,
                          departureDate: '2022-10-20T20:40',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666288200000,
                          arrrivalDate: '2022-10-20T23:20',
                        },
                        {
                          id: '627',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '846',
                          durationInMin: 105,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666321200000,
                          departureDate: '2022-10-21T08:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666327500000,
                          arrrivalDate: '2022-10-21T10:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '807',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666264200000,
                        departure: 1666242600000,
                        numberOfStops: 1,
                        duration: 360,
                      },
                      key: '6E|6788|1666242600000|1666264200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_31DELIXD6E6788IXDBOM6E6938_18188342607655@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 15071.16,
                            publishedFare: 15908,
                            comission: 836.84,
                            bookingClass: 'M',
                            baseFare: 14135,
                            taxes: {
                              total: 1773,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1773,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_31DELIXD6E6788IXDBOM6E6938_18188342608085@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 14540.12,
                            publishedFare: 14827,
                            comission: 286.88,
                            bookingClass: 'R',
                            baseFare: 13600,
                            taxes: {
                              total: 1227,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1227,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '208',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '6788',
                          durationInMin: 90,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666242600000,
                          departureDate: '2022-10-20T10:40',
                          arrival: {
                            code: 'IXD',
                            airport: 'Prayagraj airport',
                            city: 'Prayagraj',
                            country: 'India',
                          },
                          arrivalTime: 1666248000000,
                          arrrivalDate: '2022-10-20T12:10',
                        },
                        {
                          id: '209',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '6938',
                          durationInMin: 130,
                          departure: {
                            code: 'IXD',
                            airport: 'Prayagraj airport',
                            city: 'Prayagraj',
                            country: 'India',
                          },
                          departureTime: 1666256400000,
                          departureDate: '2022-10-20T14:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666264200000,
                          arrrivalDate: '2022-10-20T16:40',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '6788',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666282800000,
                        departure: 1666226700000,
                        numberOfStops: 1,
                        duration: 935,
                      },
                      key: 'UK|811|1666226700000|1666282800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_85DELBLRUK811BLRBOMUK850_18185015198975@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 20194.7,
                            publishedFare: 21027,
                            comission: 832.3,
                            bookingClass: 'O',
                            baseFare: 19394,
                            taxes: {
                              total: 1633,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 983,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_85DELBLRUK811BLRBOMUK850_18185015198745@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 23442.27,
                            publishedFare: 23854,
                            comission: 411.73,
                            bookingClass: 'U',
                            baseFare: 20690,
                            taxes: {
                              total: 3164,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 2514,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '974',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '811',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666226700000,
                          departureDate: '2022-10-20T06:15',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666236600000,
                          arrrivalDate: '2022-10-20T09:00',
                        },
                        {
                          id: '975',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '850',
                          durationInMin: 115,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666275900000,
                          departureDate: '2022-10-20T19:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666282800000,
                          arrrivalDate: '2022-10-20T21:50',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '811',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666245900000,
                        departure: 1666238400000,
                        numberOfStops: 0,
                        duration: 125,
                      },
                      key: 'UK|927|1666238400000|1666245900000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_1DELBOMUK927_18185014998204@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7191.25,
                            publishedFare: 7320,
                            comission: 128.75,
                            bookingClass: 'Q',
                            baseFare: 6470,
                            taxes: {
                              total: 850,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 330,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_1DELBOMUK927_18185014995234@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6161.15,
                            publishedFare: 6270,
                            comission: 108.85,
                            bookingClass: 'Q',
                            baseFare: 5470,
                            taxes: {
                              total: 800,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '931',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '927',
                          durationInMin: 125,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666238400000,
                          departureDate: '2022-10-20T09:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666245900000,
                          arrrivalDate: '2022-10-20T11:35',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '927',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666251000000,
                        departure: 1666231200000,
                        numberOfStops: 1,
                        duration: 330,
                      },
                      key: 'UK|705|1666231200000|1666251000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_120DELCCUUK705CCUBOMUK772_18185015266108@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 22232.31,
                            publishedFare: 22649,
                            comission: 416.69,
                            bookingClass: 'A',
                            baseFare: 20939,
                            taxes: {
                              total: 1710,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1060,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_120DELCCUUK705CCUBOMUK772_18185015265868@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 45092.64,
                            publishedFare: 45896,
                            comission: 803.36,
                            bookingClass: 'T',
                            baseFare: 40370,
                            taxes: {
                              total: 5526,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 4876,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '430',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '705',
                          durationInMin: 125,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666231200000,
                          departureDate: '2022-10-20T07:30',
                          arrival: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          arrivalTime: 1666238700000,
                          arrrivalDate: '2022-10-20T09:35',
                        },
                        {
                          id: '431',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '772',
                          durationInMin: 155,
                          departure: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          departureTime: 1666241700000,
                          departureDate: '2022-10-20T10:25',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666251000000,
                          arrrivalDate: '2022-10-20T13:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '705',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666323300000,
                        departure: 1666273500000,
                        numberOfStops: 1,
                        duration: 830,
                      },
                      key: 'G8|713|1666273500000|1666323300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_13DELAMDG8713AMDBOMG8459_18183418310506@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6446.57,
                            publishedFare: 6620,
                            comission: 173.43,
                            bookingClass: 'SP',
                            baseFare: 5810,
                            taxes: {
                              total: 810,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 810,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_13DELAMDG8713AMDBOMG8459_18183418311026@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'GOMORE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 28013.55,
                            publishedFare: 28818,
                            comission: 804.45,
                            bookingClass: 'MG',
                            baseFare: 26950,
                            taxes: {
                              total: 1868,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1868,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_13DELAMDG8713AMDBOMG8459_18183418309956@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7382.87,
                            publishedFare: 7860,
                            comission: 477.13,
                            bookingClass: 'BC',
                            baseFare: 6754,
                            taxes: {
                              total: 1106,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1106,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_13DELAMDG8713AMDBOMG8459_18183418310726@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6753.62,
                            publishedFare: 6936,
                            comission: 182.38,
                            bookingClass: 'GS',
                            baseFare: 6110,
                            taxes: {
                              total: 826,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 826,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '229',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 0,
                          flightNumber: '713',
                          durationInMin: 90,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666273500000,
                          departureDate: '2022-10-20T19:15',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666278900000,
                          arrrivalDate: '2022-10-20T20:45',
                        },
                        {
                          id: '230',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 1,
                          flightNumber: '459',
                          durationInMin: 75,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666318800000,
                          departureDate: '2022-10-21T07:50',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666323300000,
                          arrrivalDate: '2022-10-21T09:05',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '713',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666326900000,
                        departure: 1666278900000,
                        numberOfStops: 1,
                        duration: 800,
                      },
                      key: 'G8|119|1666278900000|1666326900000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_15DELBLRG8119BLRBOMG8395_18183418331007@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 15797.94,
                            publishedFare: 16245,
                            comission: 447.06,
                            bookingClass: 'SP',
                            baseFare: 14977,
                            taxes: {
                              total: 1268,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1268,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_15DELBLRG8119BLRBOMG8395_18183418331477@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'GOMORE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 30046.09,
                            publishedFare: 30910,
                            comission: 863.91,
                            bookingClass: 'MG',
                            baseFare: 28942,
                            taxes: {
                              total: 1968,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1968,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_15DELBLRG8119BLRBOMG8395_18183418330547@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 17060.05,
                            publishedFare: 18210,
                            comission: 1149.95,
                            bookingClass: 'BC',
                            baseFare: 16278,
                            taxes: {
                              total: 1932,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1932,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_15DELBLRG8119BLRBOMG8395_18183418331247@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 16410.03,
                            publishedFare: 16875,
                            comission: 464.97,
                            bookingClass: 'GS',
                            baseFare: 15577,
                            taxes: {
                              total: 1298,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1298,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '140',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 0,
                          flightNumber: '119',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666278900000,
                          departureDate: '2022-10-20T20:45',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666288800000,
                          arrrivalDate: '2022-10-20T23:30',
                        },
                        {
                          id: '141',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 1,
                          flightNumber: '395',
                          durationInMin: 110,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666320300000,
                          departureDate: '2022-10-21T08:15',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666326900000,
                          arrrivalDate: '2022-10-21T10:05',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '119',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666266600000,
                        departure: 1666259700000,
                        numberOfStops: 0,
                        duration: 115,
                      },
                      key: '6E|5306|1666259700000|1666266600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_10DELBOM6E5306_18188342556571@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6128.49,
                            publishedFare: 6244,
                            comission: 115.51,
                            bookingClass: 'T',
                            baseFare: 5476,
                            taxes: {
                              total: 768,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 768,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_10DELBOM6E5306_18188342556141@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7173.18,
                            publishedFare: 7558,
                            comission: 384.82,
                            bookingClass: 'M',
                            baseFare: 6500,
                            taxes: {
                              total: 1058,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1058,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_10DELBOM6E5306_18188342556781@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7181.89,
                            publishedFare: 7319,
                            comission: 137.11,
                            bookingClass: 'R',
                            baseFare: 6500,
                            taxes: {
                              total: 819,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 819,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '717',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '5306',
                          durationInMin: 115,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666259700000,
                          departureDate: '2022-10-20T15:25',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666266600000,
                          arrrivalDate: '2022-10-20T17:20',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '5306',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666279200000,
                        departure: 1666258200000,
                        numberOfStops: 1,
                        duration: 350,
                      },
                      key: '6E|6817|1666258200000|1666279200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_26DELSXR6E6817SXRBOM6E609_18188342594234@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 12342.18,
                            publishedFare: 12584,
                            comission: 241.82,
                            bookingClass: 'T',
                            baseFare: 11464,
                            taxes: {
                              total: 1120,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1120,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_26DELSXR6E6817SXRBOM6E609_18188342593824@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 13802.28,
                            publishedFare: 14566,
                            comission: 763.72,
                            bookingClass: 'M',
                            baseFare: 12900,
                            taxes: {
                              total: 1666,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1666,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_26DELSXR6E6817SXRBOM6E609_18188342594444@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 12650.85,
                            publishedFare: 12899,
                            comission: 248.15,
                            bookingClass: 'R',
                            baseFare: 11764,
                            taxes: {
                              total: 1135,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1135,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '761',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '6817',
                          durationInMin: 80,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666258200000,
                          departureDate: '2022-10-20T15:00',
                          arrival: {
                            code: 'SXR',
                            airport: 'Srinagar Arpt',
                            city: 'Srinagar',
                            country: 'India',
                          },
                          arrivalTime: 1666263000000,
                          arrrivalDate: '2022-10-20T16:20',
                        },
                        {
                          id: '762',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '609',
                          durationInMin: 150,
                          departure: {
                            code: 'SXR',
                            airport: 'Srinagar Arpt',
                            city: 'Srinagar',
                            country: 'India',
                          },
                          departureTime: 1666270200000,
                          departureDate: '2022-10-20T18:20',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666279200000,
                          arrrivalDate: '2022-10-20T20:50',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '6817',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666342800000,
                        departure: 1666275900000,
                        numberOfStops: 1,
                        duration: 1115,
                      },
                      key: 'UK|835|1666275900000|1666342800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_114DELMAAUK835MAABOMUK826_18185015254268@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 19142.99,
                            publishedFare: 19500,
                            comission: 357.01,
                            bookingClass: 'N',
                            baseFare: 17940,
                            taxes: {
                              total: 1560,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 910,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_114DELMAAUK835MAABOMUK826_18185015254028@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 37798.57,
                            publishedFare: 38470,
                            comission: 671.43,
                            bookingClass: 'T',
                            baseFare: 33740,
                            taxes: {
                              total: 4730,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 4080,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '83',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '835',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666275900000,
                          departureDate: '2022-10-20T19:55',
                          arrival: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          arrivalTime: 1666286100000,
                          arrrivalDate: '2022-10-20T22:45',
                        },
                        {
                          id: '84',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '826',
                          durationInMin: 120,
                          departure: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          departureTime: 1666335600000,
                          departureDate: '2022-10-21T12:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666342800000,
                          arrrivalDate: '2022-10-21T14:30',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '835',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666250100000,
                        departure: 1666229100000,
                        numberOfStops: 1,
                        duration: 350,
                      },
                      key: '6E|5009|1666229100000|1666250100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_28DELBLR6E5009BLRBOM6E5354_18188342599914@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 12662.6,
                            publishedFare: 12911,
                            comission: 248.4,
                            bookingClass: 'T',
                            baseFare: 11776,
                            taxes: {
                              total: 1135,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1135,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_28DELBLR6E5009BLRBOM6E5354_18188342599434@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 13858.97,
                            publishedFare: 14626,
                            comission: 767.03,
                            bookingClass: 'M',
                            baseFare: 12956,
                            taxes: {
                              total: 1670,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1670,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_28DELBLR6E5009BLRBOM6E5354_18188342599644@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'FLEXI_PLUS',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 14905.61,
                            publishedFare: 15200,
                            comission: 294.39,
                            bookingClass: 'J',
                            baseFare: 13956,
                            taxes: {
                              total: 1244,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1244,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '207',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '5009',
                          durationInMin: 175,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666229100000,
                          departureDate: '2022-10-20T06:55',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666239600000,
                          arrrivalDate: '2022-10-20T09:50',
                        },
                        {
                          id: '208',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '5354',
                          durationInMin: 105,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666243800000,
                          departureDate: '2022-10-20T11:00',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666250100000,
                          arrrivalDate: '2022-10-20T12:45',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '5009',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666254900000,
                        departure: 1666233000000,
                        numberOfStops: 1,
                        duration: 365,
                      },
                      key: 'I5|744|1666233000000|1666254900000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_5DELIXRI5744IXRBOMI5632_18182007835065@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 11632.03,
                            publishedFare: 11793,
                            comission: 160.97,
                            bookingClass: 'SM',
                            baseFare: 10785,
                            taxes: {
                              total: 1008,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 544,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_5DELIXRI5744IXRBOMI5632_18182007835255@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 11632.03,
                            publishedFare: 11793,
                            comission: 160.97,
                            bookingClass: 'EC',
                            baseFare: 10785,
                            taxes: {
                              total: 1008,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 544,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '839',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '744',
                          durationInMin: 110,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666233000000,
                          departureDate: '2022-10-20T08:00',
                          arrival: {
                            code: 'IXR',
                            airport: 'Birsa Munda Arpt',
                            city: 'Ranchi',
                            country: 'India',
                          },
                          arrivalTime: 1666239600000,
                          arrrivalDate: '2022-10-20T09:50',
                        },
                        {
                          id: '840',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '632',
                          durationInMin: 150,
                          departure: {
                            code: 'IXR',
                            airport: 'Birsa Munda Arpt',
                            city: 'Ranchi',
                            country: 'India',
                          },
                          departureTime: 1666245900000,
                          departureDate: '2022-10-20T11:35',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666254900000,
                          arrrivalDate: '2022-10-20T14:05',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '744',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666267800000,
                        departure: 1666260000000,
                        numberOfStops: 0,
                        duration: 130,
                      },
                      key: 'UK|933|1666260000000|1666267800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_2DELBOMUK933_18185015001214@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7191.25,
                            publishedFare: 7320,
                            comission: 128.75,
                            bookingClass: 'Q',
                            baseFare: 6470,
                            taxes: {
                              total: 850,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 330,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_2DELBOMUK933_18185014999874@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6161.15,
                            publishedFare: 6270,
                            comission: 108.85,
                            bookingClass: 'Q',
                            baseFare: 5470,
                            taxes: {
                              total: 800,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '997',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '933',
                          durationInMin: 130,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666260000000,
                          departureDate: '2022-10-20T15:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666267800000,
                          arrrivalDate: '2022-10-20T17:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '933',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666350300000,
                        departure: 1666283400000,
                        numberOfStops: 1,
                        duration: 1115,
                      },
                      key: 'AI|883|1666283400000|1666350300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_54DELGOIAI883GOIBOMAI664_18185015137682@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 11727.87,
                            publishedFare: 11940,
                            comission: 212.13,
                            bookingClass: 'S',
                            baseFare: 10660,
                            taxes: {
                              total: 1280,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 550,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '775',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '883',
                          durationInMin: 160,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666283400000,
                          departureDate: '2022-10-20T22:00',
                          arrival: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          arrivalTime: 1666293000000,
                          arrrivalDate: '2022-10-21T00:40',
                        },
                        {
                          id: '776',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '664',
                          durationInMin: 80,
                          departure: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          departureTime: 1666345500000,
                          departureDate: '2022-10-21T15:15',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666350300000,
                          arrrivalDate: '2022-10-21T16:35',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '883',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666292700000,
                        departure: 1666242900000,
                        numberOfStops: 1,
                        duration: 830,
                      },
                      key: 'AI|885|1666242900000|1666292700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_41DELGOIAI885GOIBOMAI697_18185015111231@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 10491.75,
                            publishedFare: 10680,
                            comission: 188.25,
                            bookingClass: 'S',
                            baseFare: 9460,
                            taxes: {
                              total: 1220,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 490,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '6',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '885',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666242900000,
                          departureDate: '2022-10-20T10:45',
                          arrival: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          arrivalTime: 1666253100000,
                          arrrivalDate: '2022-10-20T13:35',
                        },
                        {
                          id: '7',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '697',
                          durationInMin: 85,
                          departure: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          departureTime: 1666287600000,
                          departureDate: '2022-10-20T23:10',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666292700000,
                          arrrivalDate: '2022-10-21T00:35',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '885',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666289100000,
                        departure: 1666280700000,
                        numberOfStops: 0,
                        duration: 140,
                      },
                      key: 'AI|605|1666280700000|1666289100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_17DELBOMAI605_18185015050767@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6161.94,
                            publishedFare: 6270,
                            comission: 108.06,
                            bookingClass: 'S',
                            baseFare: 5430,
                            taxes: {
                              total: 840,
                              breakups: {
                                'Carrier Misc': 170,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '711',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '605',
                          durationInMin: 140,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666280700000,
                          departureDate: '2022-10-20T21:15',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666289100000,
                          arrrivalDate: '2022-10-20T23:35',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '605',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 7,
                      details: {
                        arrival: 1666258200000,
                        departure: 1666250100000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: 'UK|993|1666250100000|1666258200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_7DELBOMUK993_18185015022546@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7191.25,
                            publishedFare: 7320,
                            comission: 128.75,
                            bookingClass: 'Q',
                            baseFare: 6470,
                            taxes: {
                              total: 850,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 330,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_7DELBOMUK993_18185015017235@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6161.15,
                            publishedFare: 6270,
                            comission: 108.85,
                            bookingClass: 'Q',
                            baseFare: 5470,
                            taxes: {
                              total: 800,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '127',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '993',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666250100000,
                          departureDate: '2022-10-20T12:45',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666258200000,
                          arrrivalDate: '2022-10-20T15:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '993',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666278600000,
                        departure: 1666255500000,
                        numberOfStops: 1,
                        duration: 385,
                      },
                      key: 'UK|819|1666255500000|1666278600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_78DELBLRUK819BLRBOMUK864_18185015185484@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 18124.16,
                            publishedFare: 18927,
                            comission: 802.84,
                            bookingClass: 'O',
                            baseFare: 17394,
                            taxes: {
                              total: 1533,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 883,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_78DELBLRUK819BLRBOMUK864_18185015185294@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 23442.27,
                            publishedFare: 23854,
                            comission: 411.73,
                            bookingClass: 'U',
                            baseFare: 20690,
                            taxes: {
                              total: 3164,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 2514,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '896',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '819',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666255500000,
                          departureDate: '2022-10-20T14:15',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666265700000,
                          arrrivalDate: '2022-10-20T17:05',
                        },
                        {
                          id: '897',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '864',
                          durationInMin: 105,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666272300000,
                          departureDate: '2022-10-20T18:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666278600000,
                          arrrivalDate: '2022-10-20T20:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '819',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666289400000,
                        departure: 1666267200000,
                        numberOfStops: 1,
                        duration: 370,
                      },
                      key: 'UK|707|1666267200000|1666289400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_129DELCCUUK707CCUBOMUK774_18185015283460@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 25837.66,
                            publishedFare: 26324,
                            comission: 486.34,
                            bookingClass: 'M',
                            baseFare: 24439,
                            taxes: {
                              total: 1885,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1235,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_129DELCCUUK707CCUBOMUK774_18185015283210@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 63024.27,
                            publishedFare: 64152,
                            comission: 1127.73,
                            bookingClass: 'S',
                            baseFare: 56670,
                            taxes: {
                              total: 7482,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 6832,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '509',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '707',
                          durationInMin: 125,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666267200000,
                          departureDate: '2022-10-20T17:30',
                          arrival: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          arrivalTime: 1666274700000,
                          arrrivalDate: '2022-10-20T19:35',
                        },
                        {
                          id: '510',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '774',
                          durationInMin: 175,
                          departure: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          departureTime: 1666278900000,
                          departureDate: '2022-10-20T20:45',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666289400000,
                          arrrivalDate: '2022-10-20T23:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '707',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666293300000,
                        departure: 1666274400000,
                        numberOfStops: 1,
                        duration: 315,
                      },
                      key: '6E|2104|1666274400000|1666293300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_33DELHYD6E2104HYDBOM6E171_18188342613485@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 15774.81,
                            publishedFare: 16087,
                            comission: 312.19,
                            bookingClass: 'T',
                            baseFare: 14800,
                            taxes: {
                              total: 1287,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1287,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_33DELHYD6E2104HYDBOM6E171_18188342613075@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 16781.6,
                            publishedFare: 17717,
                            comission: 935.4,
                            bookingClass: 'M',
                            baseFare: 15800,
                            taxes: {
                              total: 1917,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1917,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_33DELHYD6E2104HYDBOM6E171_18188342613265@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'FLEXI_PLUS',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 17832.62,
                            publishedFare: 18187,
                            comission: 354.38,
                            bookingClass: 'J',
                            baseFare: 16800,
                            taxes: {
                              total: 1387,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1387,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '240',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '2104',
                          durationInMin: 140,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666274400000,
                          departureDate: '2022-10-20T19:30',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666282800000,
                          arrrivalDate: '2022-10-20T21:50',
                        },
                        {
                          id: '241',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '171',
                          durationInMin: 90,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666287900000,
                          departureDate: '2022-10-20T23:15',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666293300000,
                          arrrivalDate: '2022-10-21T00:45',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '2104',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666342800000,
                        departure: 1666266900000,
                        numberOfStops: 1,
                        duration: 1265,
                      },
                      key: 'UK|837|1666266900000|1666342800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_106DELMAAUK837MAABOMUK826_18185015239897@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 17494.83,
                            publishedFare: 17820,
                            comission: 325.17,
                            bookingClass: 'K',
                            baseFare: 16340,
                            taxes: {
                              total: 1480,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 830,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_106DELMAAUK837MAABOMUK826_18185015239637@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 34993.32,
                            publishedFare: 35614,
                            comission: 620.68,
                            bookingClass: 'P',
                            baseFare: 31190,
                            taxes: {
                              total: 4424,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 3774,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '132',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '837',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666266900000,
                          departureDate: '2022-10-20T17:25',
                          arrival: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          arrivalTime: 1666277100000,
                          arrrivalDate: '2022-10-20T20:15',
                        },
                        {
                          id: '133',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '826',
                          durationInMin: 120,
                          departure: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          departureTime: 1666335600000,
                          departureDate: '2022-10-21T12:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666342800000,
                          arrrivalDate: '2022-10-21T14:30',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '837',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666326600000,
                        departure: 1666278300000,
                        numberOfStops: 1,
                        duration: 805,
                      },
                      key: 'UK|871|1666278300000|1666326600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_49DELHYDUK871HYDBOMUK874_18185015126262@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 12344.33,
                            publishedFare: 12570,
                            comission: 225.67,
                            bookingClass: 'Q',
                            baseFare: 11340,
                            taxes: {
                              total: 1230,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 580,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_49DELHYDUK871HYDBOMUK874_18185015125752@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 11314.23,
                            publishedFare: 11520,
                            comission: 205.77,
                            bookingClass: 'Q',
                            baseFare: 10340,
                            taxes: {
                              total: 1180,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 530,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '876',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '871',
                          durationInMin: 140,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666278300000,
                          departureDate: '2022-10-20T20:35',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666286700000,
                          arrrivalDate: '2022-10-20T22:55',
                        },
                        {
                          id: '877',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '874',
                          durationInMin: 90,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666321200000,
                          departureDate: '2022-10-21T08:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666326600000,
                          arrrivalDate: '2022-10-21T10:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '871',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666252500000,
                        departure: 1666232700000,
                        numberOfStops: 1,
                        duration: 330,
                      },
                      key: '6E|2774|1666232700000|1666252500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_20DELHYD6E2774HYDBOM6E247_18188342573252@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 11747.69,
                            publishedFare: 12393,
                            comission: 645.31,
                            bookingClass: 'M',
                            baseFare: 10900,
                            taxes: {
                              total: 1493,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1493,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_20DELHYD6E2774HYDBOM6E247_18188342573762@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 11146.69,
                            publishedFare: 11364,
                            comission: 217.31,
                            bookingClass: 'R',
                            baseFare: 10302,
                            taxes: {
                              total: 1062,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1062,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '572',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '2774',
                          durationInMin: 140,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666232700000,
                          departureDate: '2022-10-20T07:55',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666241100000,
                          arrrivalDate: '2022-10-20T10:15',
                        },
                        {
                          id: '573',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '247',
                          durationInMin: 85,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666247400000,
                          departureDate: '2022-10-20T12:00',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666252500000,
                          arrrivalDate: '2022-10-20T13:25',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '2774',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666363500000,
                        departure: 1666278600000,
                        numberOfStops: 1,
                        duration: 1415,
                      },
                      key: 'UK|807|1666278600000|1666363500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_110DELBLRUK807BLRBOMUK854_18185015247207@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 22542.32,
                            publishedFare: 22965,
                            comission: 422.68,
                            bookingClass: 'Q',
                            baseFare: 21240,
                            taxes: {
                              total: 1725,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1075,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_110DELBLRUK807BLRBOMUK854_18185015246957@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 37578.55,
                            publishedFare: 38246,
                            comission: 667.45,
                            bookingClass: 'R',
                            baseFare: 33540,
                            taxes: {
                              total: 4706,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 4056,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '781',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '807',
                          durationInMin: 160,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666278600000,
                          departureDate: '2022-10-20T20:40',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666288200000,
                          arrrivalDate: '2022-10-20T23:20',
                        },
                        {
                          id: '782',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '854',
                          durationInMin: 110,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666356900000,
                          departureDate: '2022-10-21T18:25',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666363500000,
                          arrrivalDate: '2022-10-21T20:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '807',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666270200000,
                        departure: 1666251000000,
                        numberOfStops: 1,
                        duration: 320,
                      },
                      key: 'I5|745|1666251000000|1666270200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_3DELLKOI5745LKOBOMI5338_18182007829454@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 10326.83,
                            publishedFare: 10469,
                            comission: 142.17,
                            bookingClass: 'SM',
                            baseFare: 9525,
                            taxes: {
                              total: 944,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 480,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_3DELLKOI5745LKOBOMI5338_18182007829644@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 10447.1,
                            publishedFare: 10591,
                            comission: 143.9,
                            bookingClass: 'EC',
                            baseFare: 9641,
                            taxes: {
                              total: 950,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 486,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '990',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '745',
                          durationInMin: 70,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666251000000,
                          departureDate: '2022-10-20T13:00',
                          arrival: {
                            code: 'LKO',
                            airport: 'Amausi Arpt',
                            city: 'Lucknow',
                            country: 'India',
                          },
                          arrivalTime: 1666255200000,
                          arrrivalDate: '2022-10-20T14:10',
                        },
                        {
                          id: '991',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '338',
                          durationInMin: 135,
                          departure: {
                            code: 'LKO',
                            airport: 'Amausi Arpt',
                            city: 'Lucknow',
                            country: 'India',
                          },
                          departureTime: 1666262100000,
                          departureDate: '2022-10-20T16:05',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666270200000,
                          arrrivalDate: '2022-10-20T18:20',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '745',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666285500000,
                        departure: 1666253100000,
                        numberOfStops: 1,
                        duration: 540,
                      },
                      key: 'UK|839|1666253100000|1666285500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_107DELMAAUK839MAABOMUK824_18185015241947@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 18112.89,
                            publishedFare: 18450,
                            comission: 337.11,
                            bookingClass: 'Q',
                            baseFare: 16940,
                            taxes: {
                              total: 1510,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 860,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_107DELMAAUK839MAABOMUK824_18185015241707@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 35851.8,
                            publishedFare: 36488,
                            comission: 636.2,
                            bookingClass: 'U',
                            baseFare: 31970,
                            taxes: {
                              total: 4518,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 3868,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '113',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '839',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666253100000,
                          departureDate: '2022-10-20T13:35',
                          arrival: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          arrivalTime: 1666263300000,
                          arrrivalDate: '2022-10-20T16:25',
                        },
                        {
                          id: '114',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '824',
                          durationInMin: 125,
                          departure: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          departureTime: 1666278000000,
                          departureDate: '2022-10-20T20:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666285500000,
                          arrrivalDate: '2022-10-20T22:35',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '839',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666277400000,
                        departure: 1666226700000,
                        numberOfStops: 1,
                        duration: 845,
                      },
                      key: 'UK|747|1666226700000|1666277400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_125DELCCUUK747CCUBOMUK776_18185015275910@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 20172.11,
                            publishedFare: 20549,
                            comission: 376.89,
                            bookingClass: 'H',
                            baseFare: 18939,
                            taxes: {
                              total: 1610,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 960,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_125DELCCUUK747CCUBOMUK776_18185015275660@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 52353.3,
                            publishedFare: 53288,
                            comission: 934.7,
                            bookingClass: 'T',
                            baseFare: 46970,
                            taxes: {
                              total: 6318,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 5668,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '348',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '747',
                          durationInMin: 125,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666226700000,
                          departureDate: '2022-10-20T06:15',
                          arrival: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          arrivalTime: 1666234200000,
                          arrrivalDate: '2022-10-20T08:20',
                        },
                        {
                          id: '349',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '776',
                          durationInMin: 165,
                          departure: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          departureTime: 1666267500000,
                          departureDate: '2022-10-20T17:35',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666277400000,
                          arrrivalDate: '2022-10-20T20:20',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '747',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666247400000,
                        departure: 1666238700000,
                        numberOfStops: 0,
                        duration: 145,
                      },
                      key: '6E|5328|1666238700000|1666247400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_12DELBOM6E5328_18188342559841@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6128.49,
                            publishedFare: 6244,
                            comission: 115.51,
                            bookingClass: 'T',
                            baseFare: 5476,
                            taxes: {
                              total: 768,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 768,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_12DELBOM6E5328_18188342559431@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7173.18,
                            publishedFare: 7558,
                            comission: 384.82,
                            bookingClass: 'M',
                            baseFare: 6500,
                            taxes: {
                              total: 1058,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1058,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_12DELBOM6E5328_18188342560051@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7181.89,
                            publishedFare: 7319,
                            comission: 137.11,
                            bookingClass: 'R',
                            baseFare: 6500,
                            taxes: {
                              total: 819,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 819,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '481',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '5328',
                          durationInMin: 145,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666238700000,
                          departureDate: '2022-10-20T09:35',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666247400000,
                          arrrivalDate: '2022-10-20T12:00',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '5328',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 16,
                      details: {
                        arrival: 1666336200000,
                        departure: 1666255800000,
                        numberOfStops: 1,
                        duration: 1340,
                      },
                      key: 'SG|751|1666255800000|1666336200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '4-2158784108_9DELDBRSG751DBRBOMSG366_18183742821623@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 16,
                            offeredFare: 18250.7,
                            publishedFare: 18637,
                            comission: 386.3,
                            bookingClass: 'DD',
                            baseFare: 17255,
                            taxes: {
                              total: 1382,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1382,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_9DELDBRSG751DBRBOMSG366_18183742821333@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 16,
                            offeredFare: 18757.27,
                            publishedFare: 19883,
                            comission: 1125.73,
                            bookingClass: 'CP',
                            baseFare: 17817,
                            taxes: {
                              total: 2066,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1138,
                                'Management Fees': 0,
                                'Gst Charges': 928,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_9DELDBRSG751DBRBOMSG366_18183742821763@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 16,
                            offeredFare: 15326.41,
                            publishedFare: 15649,
                            comission: 322.59,
                            bookingClass: 'RS',
                            baseFare: 14409,
                            taxes: {
                              total: 1240,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 514,
                                'Management Fees': 0,
                                'Gst Charges': 726,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '314',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 0,
                          flightNumber: '751',
                          durationInMin: 95,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666255800000,
                          departureDate: '2022-10-20T14:20',
                          arrival: {
                            code: 'DBR',
                            airport: 'Darbhanga Airport',
                            city: 'Darbhanga',
                            country: 'India',
                          },
                          arrivalTime: 1666261500000,
                          arrrivalDate: '2022-10-20T15:55',
                        },
                        {
                          id: '315',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 1,
                          flightNumber: '366',
                          durationInMin: 160,
                          departure: {
                            code: 'DBR',
                            airport: 'Darbhanga Airport',
                            city: 'Darbhanga',
                            country: 'India',
                          },
                          departureTime: 1666326600000,
                          departureDate: '2022-10-21T10:00',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666336200000,
                          arrrivalDate: '2022-10-21T12:40',
                        },
                      ],
                      operator: {
                        name: 'SpiceJet',
                        code: 'SG',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/SG.png',
                        number: '751',
                        equipment: '737',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666323000000,
                        departure: 1666275900000,
                        numberOfStops: 1,
                        duration: 785,
                      },
                      key: 'UK|835|1666275900000|1666323000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_112DELMAAUK835MAABOMUK828_18185015250488@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 17629.25,
                            publishedFare: 17957,
                            comission: 327.75,
                            bookingClass: 'N',
                            baseFare: 16470,
                            taxes: {
                              total: 1487,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 837,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_112DELMAAUK835MAABOMUK828_18185015250278@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 37798.57,
                            publishedFare: 38470,
                            comission: 671.43,
                            bookingClass: 'T',
                            baseFare: 33740,
                            taxes: {
                              total: 4730,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 4080,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '405',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '835',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666275900000,
                          departureDate: '2022-10-20T19:55',
                          arrival: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          arrivalTime: 1666286100000,
                          arrrivalDate: '2022-10-20T22:45',
                        },
                        {
                          id: '406',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '828',
                          durationInMin: 120,
                          departure: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          departureTime: 1666315800000,
                          departureDate: '2022-10-21T07:00',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666323000000,
                          arrrivalDate: '2022-10-21T09:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '835',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666316700000,
                        departure: 1666276200000,
                        numberOfStops: 1,
                        duration: 675,
                      },
                      key: 'I5|773|1666276200000|1666316700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_1DELGOII5773GOIBOMI5335_18182007823084@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7299.5,
                            publishedFare: 7398,
                            comission: 98.5,
                            bookingClass: 'SM',
                            baseFare: 6600,
                            taxes: {
                              total: 798,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 334,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_1DELGOII5773GOIBOMI5335_18182007823974@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'PROMO',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 10477.69,
                            publishedFare: 10622,
                            comission: 144.31,
                            bookingClass: 'BT',
                            baseFare: 9670,
                            taxes: {
                              total: 952,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 488,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_1DELGOII5773GOIBOMI5335_18182007823314@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7299.5,
                            publishedFare: 7398,
                            comission: 98.5,
                            bookingClass: 'EC',
                            baseFare: 6600,
                            taxes: {
                              total: 798,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 334,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '358',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '773',
                          durationInMin: 155,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666276200000,
                          departureDate: '2022-10-20T20:00',
                          arrival: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          arrivalTime: 1666285500000,
                          arrrivalDate: '2022-10-20T22:35',
                        },
                        {
                          id: '359',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '335',
                          durationInMin: 65,
                          departure: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          departureTime: 1666312800000,
                          departureDate: '2022-10-21T06:10',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666316700000,
                          arrrivalDate: '2022-10-21T07:15',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '773',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666254600000,
                        departure: 1666246200000,
                        numberOfStops: 0,
                        duration: 140,
                      },
                      key: 'UK|945|1666246200000|1666254600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_9DELBOMUK945_18185015030066@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7191.25,
                            publishedFare: 7320,
                            comission: 128.75,
                            bookingClass: 'Q',
                            baseFare: 6470,
                            taxes: {
                              total: 850,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 330,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_9DELBOMUK945_18185015027186@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6161.15,
                            publishedFare: 6270,
                            comission: 108.85,
                            bookingClass: 'Q',
                            baseFare: 5470,
                            taxes: {
                              total: 800,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '291',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '945',
                          durationInMin: 140,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666246200000,
                          departureDate: '2022-10-20T11:40',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666254600000,
                          arrrivalDate: '2022-10-20T14:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '945',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666288500000,
                        departure: 1666269000000,
                        numberOfStops: 1,
                        duration: 325,
                      },
                      key: 'UK|515|1666269000000|1666288500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_133DELBLRUK515BLRBOMUK866_18185015289590@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 26509.84,
                            publishedFare: 27432,
                            comission: 922.16,
                            bookingClass: 'O',
                            baseFare: 25494,
                            taxes: {
                              total: 1938,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1288,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '781',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '515',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666269000000,
                          departureDate: '2022-10-20T18:00',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666278900000,
                          arrrivalDate: '2022-10-20T20:45',
                        },
                        {
                          id: '782',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '866',
                          durationInMin: 105,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666282200000,
                          departureDate: '2022-10-20T21:40',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666288500000,
                          arrrivalDate: '2022-10-20T23:25',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '515',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666363500000,
                        departure: 1666275600000,
                        numberOfStops: 1,
                        duration: 1465,
                      },
                      key: 'UK|809|1666275600000|1666363500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_111DELBLRUK809BLRBOMUK854_18185015248797@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 37578.55,
                            publishedFare: 38246,
                            comission: 667.45,
                            bookingClass: 'R',
                            baseFare: 33540,
                            taxes: {
                              total: 4706,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 4056,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '917',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '809',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666275600000,
                          departureDate: '2022-10-20T19:50',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666285800000,
                          arrrivalDate: '2022-10-20T22:40',
                        },
                        {
                          id: '918',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '854',
                          durationInMin: 110,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666356900000,
                          departureDate: '2022-10-21T18:25',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666363500000,
                          arrrivalDate: '2022-10-21T20:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '809',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666349100000,
                        departure: 1666246500000,
                        numberOfStops: 1,
                        duration: 1710,
                      },
                      key: 'UK|533|1666246500000|1666349100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_77DELCJBUK533CJBBOMUK522_18185015182304@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 20534.13,
                            publishedFare: 20918,
                            comission: 383.87,
                            bookingClass: 'Q',
                            baseFare: 19290,
                            taxes: {
                              total: 1628,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 978,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_77DELCJBUK533CJBBOMUK522_18185015182094@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 22562.19,
                            publishedFare: 22958,
                            comission: 395.81,
                            bookingClass: 'U',
                            baseFare: 19890,
                            taxes: {
                              total: 3068,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 2418,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '692',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '533',
                          durationInMin: 175,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666246500000,
                          departureDate: '2022-10-20T11:45',
                          arrival: {
                            code: 'CJB',
                            airport: 'Peelamedu Airport',
                            city: 'Coimbatore',
                            country: 'India',
                          },
                          arrivalTime: 1666257000000,
                          arrrivalDate: '2022-10-20T14:40',
                        },
                        {
                          id: '693',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '522',
                          durationInMin: 110,
                          departure: {
                            code: 'CJB',
                            airport: 'Peelamedu Airport',
                            city: 'Coimbatore',
                            country: 'India',
                          },
                          departureTime: 1666342500000,
                          departureDate: '2022-10-21T14:25',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666349100000,
                          arrrivalDate: '2022-10-21T16:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '533',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666253400000,
                        departure: 1666239600000,
                        numberOfStops: 1,
                        duration: 230,
                      },
                      key: '6E|184|1666239600000|1666253400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_1DELAMD6E184AMDBOM6E5268_18188342537200@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 5676.46,
                            publishedFare: 5972,
                            comission: 295.54,
                            bookingClass: 'M',
                            baseFare: 4992,
                            taxes: {
                              total: 980,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 980,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_1DELAMD6E184AMDBOM6E5268_18188342537680@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 5682.69,
                            publishedFare: 5788,
                            comission: 105.31,
                            bookingClass: 'R',
                            baseFare: 4992,
                            taxes: {
                              total: 796,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 796,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '304',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '184',
                          durationInMin: 90,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666239600000,
                          departureDate: '2022-10-20T09:50',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666245000000,
                          arrrivalDate: '2022-10-20T11:20',
                        },
                        {
                          id: '305',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '5268',
                          durationInMin: 70,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666249200000,
                          departureDate: '2022-10-20T12:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666253400000,
                          arrrivalDate: '2022-10-20T13:40',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '184',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666317300000,
                        departure: 1666282800000,
                        numberOfStops: 1,
                        duration: 575,
                      },
                      key: 'I5|1732|1666282800000|1666317300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_7DELBLRI51732BLRBOMI5941_18182007840216@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 14493.76,
                            publishedFare: 14696,
                            comission: 202.24,
                            bookingClass: 'SM',
                            baseFare: 13550,
                            taxes: {
                              total: 1146,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 682,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_7DELBLRI51732BLRBOMI5941_18182007840646@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PREMIUM_FLEX',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 15425.32,
                            publishedFare: 15641,
                            comission: 215.68,
                            bookingClass: 'HF',
                            baseFare: 14451,
                            taxes: {
                              total: 1190,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 726,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '202',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '1732',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666282800000,
                          departureDate: '2022-10-20T21:50',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666292700000,
                          arrrivalDate: '2022-10-21T00:35',
                        },
                        {
                          id: '203',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '941',
                          durationInMin: 110,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666310700000,
                          departureDate: '2022-10-21T05:35',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666317300000,
                          arrrivalDate: '2022-10-21T07:25',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '1732',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 8,
                      details: {
                        arrival: 1666289400000,
                        departure: 1666281600000,
                        numberOfStops: 0,
                        duration: 130,
                      },
                      key: 'UK|981|1666281600000|1666289400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_24DELBOMUK981_18185015075939@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 8530.38,
                            publishedFare: 8685,
                            comission: 154.62,
                            bookingClass: 'L',
                            baseFare: 7770,
                            taxes: {
                              total: 915,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 395,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_24DELBOMUK981_18185015075039@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 8530.38,
                            publishedFare: 8685,
                            comission: 154.62,
                            bookingClass: 'L',
                            baseFare: 7770,
                            taxes: {
                              total: 915,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 395,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '563',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '981',
                          durationInMin: 130,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666281600000,
                          departureDate: '2022-10-20T21:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666289400000,
                          arrrivalDate: '2022-10-20T23:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '981',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666219200000,
                        departure: 1666212000000,
                        numberOfStops: 0,
                        duration: 120,
                      },
                      key: '6E|2009|1666212000000|1666219200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_18DELBOM6E2009_18188342570022@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 5772.78,
                            publishedFare: 5881,
                            comission: 108.22,
                            bookingClass: 'T',
                            baseFare: 5130,
                            taxes: {
                              total: 751,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 751,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_18DELBOM6E2009_18188342569602@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7173.18,
                            publishedFare: 7558,
                            comission: 384.82,
                            bookingClass: 'M',
                            baseFare: 6500,
                            taxes: {
                              total: 1058,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1058,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_18DELBOM6E2009_18188342570242@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7181.89,
                            publishedFare: 7319,
                            comission: 137.11,
                            bookingClass: 'R',
                            baseFare: 6500,
                            taxes: {
                              total: 819,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 819,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '869',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '2009',
                          durationInMin: 120,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666212000000,
                          departureDate: '2022-10-20T02:10',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666219200000,
                          arrrivalDate: '2022-10-20T04:10',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '2009',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666330200000,
                        departure: 1666229400000,
                        numberOfStops: 1,
                        duration: 1680,
                      },
                      key: 'UK|785|1666229400000|1666330200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_75DELBBIUK785BBIBOMUK542_18185015178624@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 20070.08,
                            publishedFare: 20445,
                            comission: 374.92,
                            bookingClass: 'A',
                            baseFare: 18840,
                            taxes: {
                              total: 1605,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 955,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_75DELBBIUK785BBIBOMUK542_18185015178424@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 19426.9,
                            publishedFare: 19766,
                            comission: 339.1,
                            bookingClass: 'T',
                            baseFare: 17040,
                            taxes: {
                              total: 2726,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 2076,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '482',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '785',
                          durationInMin: 115,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666229400000,
                          departureDate: '2022-10-20T07:00',
                          arrival: {
                            code: 'BBI',
                            airport: 'Biju Patnaik Arpt',
                            city: 'Bhubaneswar',
                            country: 'India',
                          },
                          arrivalTime: 1666236300000,
                          arrrivalDate: '2022-10-20T08:55',
                        },
                        {
                          id: '483',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '542',
                          durationInMin: 145,
                          departure: {
                            code: 'BBI',
                            airport: 'Biju Patnaik Arpt',
                            city: 'Bhubaneswar',
                            country: 'India',
                          },
                          departureTime: 1666321500000,
                          departureDate: '2022-10-21T08:35',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666330200000,
                          arrrivalDate: '2022-10-21T11:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '785',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 6,
                      details: {
                        arrival: 1666280100000,
                        departure: 1666245600000,
                        numberOfStops: 1,
                        duration: 575,
                      },
                      key: 'G8|1165|1666245600000|1666280100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_11DELPATG81165PATBOMG8352_18183418304946@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 6,
                            offeredFare: 18552.37,
                            publishedFare: 19080,
                            comission: 527.63,
                            bookingClass: 'SP',
                            baseFare: 17676,
                            taxes: {
                              total: 1404,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1404,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_11DELPATG81165PATBOMG8352_18183418304656@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 6,
                            offeredFare: 19105.76,
                            publishedFare: 20398,
                            comission: 1292.24,
                            bookingClass: 'BC',
                            baseFare: 18292,
                            taxes: {
                              total: 2106,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 2106,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_11DELPATG81165PATBOMG8352_18183418305236@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 6,
                            offeredFare: 19368.5,
                            publishedFare: 19920,
                            comission: 551.5,
                            bookingClass: 'GS',
                            baseFare: 18476,
                            taxes: {
                              total: 1444,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1444,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '197',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 0,
                          flightNumber: '1165',
                          durationInMin: 90,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666245600000,
                          departureDate: '2022-10-20T11:30',
                          arrival: {
                            code: 'PAT',
                            airport: 'Jai Prakash Narayan Arpt',
                            city: 'Patna',
                            country: 'India',
                          },
                          arrivalTime: 1666251000000,
                          arrrivalDate: '2022-10-20T13:00',
                        },
                        {
                          id: '198',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 1,
                          flightNumber: '352',
                          durationInMin: 155,
                          departure: {
                            code: 'PAT',
                            airport: 'Jai Prakash Narayan Arpt',
                            city: 'Patna',
                            country: 'India',
                          },
                          departureTime: 1666270800000,
                          departureDate: '2022-10-20T18:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666280100000,
                          arrrivalDate: '2022-10-20T21:05',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '1165',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666320300000,
                        departure: 1666269000000,
                        numberOfStops: 1,
                        duration: 855,
                      },
                      key: 'UK|515|1666269000000|1666320300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_57DELBLRUK515BLRBOMUK858_18185015143532@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 14655.52,
                            publishedFare: 15409,
                            comission: 753.48,
                            bookingClass: 'O',
                            baseFare: 14044,
                            taxes: {
                              total: 1365,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 715,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_57DELBLRUK515BLRBOMUK858_18185015143312@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 14655.52,
                            publishedFare: 15409,
                            comission: 753.48,
                            bookingClass: 'O',
                            baseFare: 14044,
                            taxes: {
                              total: 1365,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 715,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '829',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '515',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666269000000,
                          departureDate: '2022-10-20T18:00',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666278900000,
                          arrrivalDate: '2022-10-20T20:45',
                        },
                        {
                          id: '830',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '858',
                          durationInMin: 95,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666314600000,
                          departureDate: '2022-10-21T06:40',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666320300000,
                          arrrivalDate: '2022-10-21T08:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '515',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666293000000,
                        departure: 1666238700000,
                        numberOfStops: 1,
                        duration: 905,
                      },
                      key: 'I5|1529|1666238700000|1666293000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_13DELBLRI51529BLRBOMI5334_18182007856126@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 22118.82,
                            publishedFare: 22431,
                            comission: 312.18,
                            bookingClass: 'SM',
                            baseFare: 20917,
                            taxes: {
                              total: 1514,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 1050,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_13DELBLRI51529BLRBOMI5334_18182007856326@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'PROMO',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 20593.8,
                            publishedFare: 20884,
                            comission: 290.2,
                            bookingClass: 'BT',
                            baseFare: 19444,
                            taxes: {
                              total: 1440,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 976,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_13DELBLRI51529BLRBOMI5334_18182007856536@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PREMIUM_FLEX',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 23158.81,
                            publishedFare: 23486,
                            comission: 327.19,
                            bookingClass: 'HF',
                            baseFare: 21922,
                            taxes: {
                              total: 1564,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 1100,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '688',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '1529',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666238700000,
                          departureDate: '2022-10-20T09:35',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666248900000,
                          arrrivalDate: '2022-10-20T12:25',
                        },
                        {
                          id: '689',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '334',
                          durationInMin: 105,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666286700000,
                          departureDate: '2022-10-20T22:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666293000000,
                          arrrivalDate: '2022-10-21T00:40',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '1529',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666272600000,
                        departure: 1666255200000,
                        numberOfStops: 1,
                        duration: 290,
                      },
                      key: 'UK|643|1666255200000|1666272600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_56DELSXRUK643SXRBOMUK648_18185015141032@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 15535.65,
                            publishedFare: 15825,
                            comission: 289.35,
                            bookingClass: 'N',
                            baseFare: 14540,
                            taxes: {
                              total: 1285,
                              breakups: {
                                'Carrier Misc': 160,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 735,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_56DELSXRUK643SXRBOMUK648_18185015140532@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 14505.55,
                            publishedFare: 14775,
                            comission: 269.45,
                            bookingClass: 'N',
                            baseFare: 13540,
                            taxes: {
                              total: 1235,
                              breakups: {
                                'Carrier Misc': 160,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 685,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '707',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '643',
                          durationInMin: 85,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666255200000,
                          departureDate: '2022-10-20T14:10',
                          arrival: {
                            code: 'SXR',
                            airport: 'Srinagar Arpt',
                            city: 'Srinagar',
                            country: 'India',
                          },
                          arrivalTime: 1666260300000,
                          arrrivalDate: '2022-10-20T15:35',
                        },
                        {
                          id: '708',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '648',
                          durationInMin: 165,
                          departure: {
                            code: 'SXR',
                            airport: 'Srinagar Arpt',
                            city: 'Srinagar',
                            country: 'India',
                          },
                          departureTime: 1666262700000,
                          departureDate: '2022-10-20T16:15',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666272600000,
                          arrrivalDate: '2022-10-20T19:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '643',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666241400000,
                        departure: 1666233000000,
                        numberOfStops: 0,
                        duration: 140,
                      },
                      key: '6E|2129|1666233000000|1666241400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_14DELBOM6E2129_18188342563392@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6128.49,
                            publishedFare: 6244,
                            comission: 115.51,
                            bookingClass: 'T',
                            baseFare: 5476,
                            taxes: {
                              total: 768,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 768,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_14DELBOM6E2129_18188342562962@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7173.18,
                            publishedFare: 7558,
                            comission: 384.82,
                            bookingClass: 'M',
                            baseFare: 6500,
                            taxes: {
                              total: 1058,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1058,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_14DELBOM6E2129_18188342563602@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7181.89,
                            publishedFare: 7319,
                            comission: 137.11,
                            bookingClass: 'R',
                            baseFare: 6500,
                            taxes: {
                              total: 819,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 819,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '982',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '2129',
                          durationInMin: 140,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666233000000,
                          departureDate: '2022-10-20T08:00',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666241400000,
                          arrrivalDate: '2022-10-20T10:20',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '2129',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666320300000,
                        departure: 1666267500000,
                        numberOfStops: 1,
                        duration: 880,
                      },
                      key: 'UK|813|1666267500000|1666320300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_58DELBLRUK813BLRBOMUK858_18185015145742@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 14655.52,
                            publishedFare: 15409,
                            comission: 753.48,
                            bookingClass: 'O',
                            baseFare: 14044,
                            taxes: {
                              total: 1365,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 715,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_58DELBLRUK813BLRBOMUK858_18185015145272@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 14655.52,
                            publishedFare: 15409,
                            comission: 753.48,
                            bookingClass: 'O',
                            baseFare: 14044,
                            taxes: {
                              total: 1365,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 715,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '972',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '813',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666267500000,
                          departureDate: '2022-10-20T17:35',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666277700000,
                          arrrivalDate: '2022-10-20T20:25',
                        },
                        {
                          id: '973',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '858',
                          durationInMin: 95,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666314600000,
                          departureDate: '2022-10-21T06:40',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666320300000,
                          arrrivalDate: '2022-10-21T08:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '813',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666321800000,
                        departure: 1666267500000,
                        numberOfStops: 1,
                        duration: 905,
                      },
                      key: 'UK|879|1666267500000|1666321800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_31DELHYDUK879HYDBOMUK872_18185015094450@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 11828.3,
                            publishedFare: 12044,
                            comission: 215.7,
                            bookingClass: 'Q',
                            baseFare: 10839,
                            taxes: {
                              total: 1205,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 555,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_31DELHYDUK879HYDBOMUK872_18185015094010@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 10180.14,
                            publishedFare: 10364,
                            comission: 183.86,
                            bookingClass: 'Q',
                            baseFare: 9239,
                            taxes: {
                              total: 1125,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 475,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '62',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '879',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666267500000,
                          departureDate: '2022-10-20T17:35',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666275600000,
                          arrrivalDate: '2022-10-20T19:50',
                        },
                        {
                          id: '63',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '872',
                          durationInMin: 90,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666316400000,
                          departureDate: '2022-10-21T07:10',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666321800000,
                          arrrivalDate: '2022-10-21T08:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '879',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666293000000,
                        departure: 1666285200000,
                        numberOfStops: 0,
                        duration: 130,
                      },
                      key: 'AI|814|1666285200000|1666293000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_13DELBOMAI814_18185015037396@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6161.94,
                            publishedFare: 6270,
                            comission: 108.06,
                            bookingClass: 'S',
                            baseFare: 5430,
                            taxes: {
                              total: 840,
                              breakups: {
                                'Carrier Misc': 170,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '966',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '814',
                          durationInMin: 130,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666285200000,
                          departureDate: '2022-10-20T22:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666293000000,
                          arrrivalDate: '2022-10-21T00:40',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '814',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666274400000,
                        departure: 1666224900000,
                        numberOfStops: 1,
                        duration: 825,
                      },
                      key: 'G8|101|1666224900000|1666274400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_7DELCCUG8101CCUBOMG8519_18183418289945@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 20794.76,
                            publishedFare: 21388,
                            comission: 593.24,
                            bookingClass: 'SP',
                            baseFare: 19874,
                            taxes: {
                              total: 1514,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1514,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_7DELCCUG8101CCUBOMG8519_18183418289605@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 21519.05,
                            publishedFare: 22979,
                            comission: 1459.95,
                            bookingClass: 'BC',
                            baseFare: 20666,
                            taxes: {
                              total: 2313,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 2313,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_7DELCCUG8101CCUBOMG8519_18183418290185@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 21814.91,
                            publishedFare: 22438,
                            comission: 623.09,
                            bookingClass: 'GS',
                            baseFare: 20874,
                            taxes: {
                              total: 1564,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1564,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '365',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 0,
                          flightNumber: '101',
                          durationInMin: 125,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666224900000,
                          departureDate: '2022-10-20T05:45',
                          arrival: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          arrivalTime: 1666232400000,
                          arrrivalDate: '2022-10-20T07:50',
                        },
                        {
                          id: '366',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 1,
                          flightNumber: '519',
                          durationInMin: 165,
                          departure: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          departureTime: 1666264500000,
                          departureDate: '2022-10-20T16:45',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666274400000,
                          arrrivalDate: '2022-10-20T19:30',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '101',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 6,
                      details: {
                        arrival: 1666288500000,
                        departure: 1666233600000,
                        numberOfStops: 1,
                        duration: 915,
                      },
                      key: 'UK|815|1666233600000|1666288500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_102DELBLRUK815BLRBOMUK866_18185015231027@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 26509.84,
                            publishedFare: 27432,
                            comission: 922.16,
                            bookingClass: 'O',
                            baseFare: 25494,
                            taxes: {
                              total: 1938,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1288,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_102DELBLRUK815BLRBOMUK866_18185015230787@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 6,
                            offeredFare: 31803.03,
                            publishedFare: 32366,
                            comission: 562.97,
                            bookingClass: 'U',
                            baseFare: 28290,
                            taxes: {
                              total: 4076,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 3426,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '288',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '815',
                          durationInMin: 160,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666233600000,
                          departureDate: '2022-10-20T08:10',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666243200000,
                          arrrivalDate: '2022-10-20T10:50',
                        },
                        {
                          id: '289',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '866',
                          durationInMin: 105,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666282200000,
                          departureDate: '2022-10-20T21:40',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666288500000,
                          arrrivalDate: '2022-10-20T23:25',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '815',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666287300000,
                        departure: 1666279200000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: 'G8|330|1666279200000|1666287300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_4DELBOMG8330_18183418274363@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 5728.62,
                            publishedFare: 5881,
                            comission: 152.38,
                            bookingClass: 'SP',
                            baseFare: 5105,
                            taxes: {
                              total: 776,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 776,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_4DELBOMG8330_18183418274813@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'GOMORE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 15490.95,
                            publishedFare: 15929,
                            comission: 438.05,
                            bookingClass: 'MG',
                            baseFare: 14675,
                            taxes: {
                              total: 1254,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1254,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_4DELBOMG8330_18183418274153@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7034.1,
                            publishedFare: 7487,
                            comission: 452.9,
                            bookingClass: 'BC',
                            baseFare: 6411,
                            taxes: {
                              total: 1076,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1076,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_4DELBOMG8330_18183418274573@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7125.72,
                            publishedFare: 7319,
                            comission: 193.28,
                            bookingClass: 'GS',
                            baseFare: 6475,
                            taxes: {
                              total: 844,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 844,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '11-2158784108_0DELBOMG8330_18181209942529@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'GROUP',
                          message:
                            'Travel on a group ticket, Non Refundable, Paid -  Seats &  Meals.',
                          typeKey: 'OFFER_FARE_WITHOUT_PNR',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'NONREFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 5600,
                            publishedFare: 5600,
                            bookingClass: 'G',
                            baseFare: 5300,
                            taxes: {
                              total: 300,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 300,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '752',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 0,
                          flightNumber: '330',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666279200000,
                          departureDate: '2022-10-20T20:50',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666287300000,
                          arrrivalDate: '2022-10-20T23:05',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '330',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666236900000,
                        departure: 1666229400000,
                        numberOfStops: 0,
                        duration: 125,
                      },
                      key: 'AI|887|1666229400000|1666236900000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_10DELBOMAI887_18185015031876@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6161.94,
                            publishedFare: 6270,
                            comission: 108.06,
                            bookingClass: 'S',
                            baseFare: 5430,
                            taxes: {
                              total: 840,
                              breakups: {
                                'Carrier Misc': 170,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '734',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '887',
                          durationInMin: 125,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666229400000,
                          departureDate: '2022-10-20T07:00',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666236900000,
                          arrrivalDate: '2022-10-20T09:05',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '887',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666280700000,
                        departure: 1666272600000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: 'AI|624|1666272600000|1666280700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_16DELBOMAI624_18185015048757@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6161.94,
                            publishedFare: 6270,
                            comission: 108.06,
                            bookingClass: 'S',
                            baseFare: 5430,
                            taxes: {
                              total: 840,
                              breakups: {
                                'Carrier Misc': 170,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '280',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '624',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666272600000,
                          departureDate: '2022-10-20T19:00',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666280700000,
                          arrrivalDate: '2022-10-20T21:15',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '624',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666231800000,
                        departure: 1666224000000,
                        numberOfStops: 0,
                        duration: 130,
                      },
                      key: '6E|2112|1666224000000|1666231800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_17DELBOM6E2112_18188342568542@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 5772.78,
                            publishedFare: 5881,
                            comission: 108.22,
                            bookingClass: 'T',
                            baseFare: 5130,
                            taxes: {
                              total: 751,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 751,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_17DELBOM6E2112_18188342568112@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7173.18,
                            publishedFare: 7558,
                            comission: 384.82,
                            bookingClass: 'M',
                            baseFare: 6500,
                            taxes: {
                              total: 1058,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1058,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_17DELBOM6E2112_18188342568762@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7181.89,
                            publishedFare: 7319,
                            comission: 137.11,
                            bookingClass: 'R',
                            baseFare: 6500,
                            taxes: {
                              total: 819,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 819,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '48',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '2112',
                          durationInMin: 130,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666224000000,
                          departureDate: '2022-10-20T05:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666231800000,
                          arrrivalDate: '2022-10-20T07:40',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '2112',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666250400000,
                        departure: 1666242900000,
                        numberOfStops: 0,
                        duration: 125,
                      },
                      key: '6E|5342|1666242900000|1666250400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_11DELBOM6E5342_18188342558221@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6128.49,
                            publishedFare: 6244,
                            comission: 115.51,
                            bookingClass: 'T',
                            baseFare: 5476,
                            taxes: {
                              total: 768,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 768,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_11DELBOM6E5342_18188342557791@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7173.18,
                            publishedFare: 7558,
                            comission: 384.82,
                            bookingClass: 'M',
                            baseFare: 6500,
                            taxes: {
                              total: 1058,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1058,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_11DELBOM6E5342_18188342558431@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7181.89,
                            publishedFare: 7319,
                            comission: 137.11,
                            bookingClass: 'R',
                            baseFare: 6500,
                            taxes: {
                              total: 819,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 819,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '541',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '5342',
                          durationInMin: 125,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666242900000,
                          departureDate: '2022-10-20T10:45',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666250400000,
                          arrrivalDate: '2022-10-20T12:50',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '5342',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666282800000,
                        departure: 1666236900000,
                        numberOfStops: 1,
                        duration: 765,
                      },
                      key: 'UK|801|1666236900000|1666282800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_82DELBLRUK801BLRBOMUK850_18185015193115@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 20194.7,
                            publishedFare: 21027,
                            comission: 832.3,
                            bookingClass: 'O',
                            baseFare: 19394,
                            taxes: {
                              total: 1633,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 983,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_82DELBLRUK801BLRBOMUK850_18185015192885@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 23442.27,
                            publishedFare: 23854,
                            comission: 411.73,
                            bookingClass: 'U',
                            baseFare: 20690,
                            taxes: {
                              total: 3164,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 2514,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '34',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '801',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666236900000,
                          departureDate: '2022-10-20T09:05',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666246800000,
                          arrrivalDate: '2022-10-20T11:50',
                        },
                        {
                          id: '35',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '850',
                          durationInMin: 115,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666275900000,
                          departureDate: '2022-10-20T19:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666282800000,
                          arrrivalDate: '2022-10-20T21:50',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '801',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666337400000,
                        departure: 1666278000000,
                        numberOfStops: 1,
                        duration: 990,
                      },
                      key: 'UK|727|1666278000000|1666337400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_118DELCCUUK727CCUBOMUK772_18185015261928@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 22232.31,
                            publishedFare: 22649,
                            comission: 416.69,
                            bookingClass: 'A',
                            baseFare: 20939,
                            taxes: {
                              total: 1710,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1060,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_118DELCCUUK727CCUBOMUK772_18185015261708@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 43772.52,
                            publishedFare: 44552,
                            comission: 779.48,
                            bookingClass: 'T',
                            baseFare: 39170,
                            taxes: {
                              total: 5382,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 4732,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '190',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '727',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666278000000,
                          departureDate: '2022-10-20T20:30',
                          arrival: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          arrivalTime: 1666286100000,
                          arrrivalDate: '2022-10-20T22:45',
                        },
                        {
                          id: '191',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '772',
                          durationInMin: 155,
                          departure: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          departureTime: 1666328100000,
                          departureDate: '2022-10-21T10:25',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666337400000,
                          arrrivalDate: '2022-10-21T13:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '727',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666323000000,
                        departure: 1666253100000,
                        numberOfStops: 1,
                        duration: 1165,
                      },
                      key: 'UK|839|1666253100000|1666323000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_103DELMAAUK839MAABOMUK828_18185015233107@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 15157.01,
                            publishedFare: 15437,
                            comission: 279.99,
                            bookingClass: 'Q',
                            baseFare: 14070,
                            taxes: {
                              total: 1367,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 717,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_103DELMAAUK839MAABOMUK828_18185015232887@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 33508.18,
                            publishedFare: 34102,
                            comission: 593.82,
                            bookingClass: 'U',
                            baseFare: 29840,
                            taxes: {
                              total: 4262,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 3612,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '233',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '839',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666253100000,
                          departureDate: '2022-10-20T13:35',
                          arrival: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          arrivalTime: 1666263300000,
                          arrrivalDate: '2022-10-20T16:25',
                        },
                        {
                          id: '234',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '828',
                          durationInMin: 120,
                          departure: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          departureTime: 1666315800000,
                          departureDate: '2022-10-21T07:00',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666323000000,
                          arrrivalDate: '2022-10-21T09:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '839',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666321500000,
                        departure: 1666264500000,
                        numberOfStops: 1,
                        duration: 950,
                      },
                      key: 'AI|807|1666264500000|1666321500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_46DELBLRAI807BLRBOMAI640_18185015121161@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 11120.61,
                            publishedFare: 11321,
                            comission: 200.39,
                            bookingClass: 'S',
                            baseFare: 10070,
                            taxes: {
                              total: 1251,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 521,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '725',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '807',
                          durationInMin: 210,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666264500000,
                          departureDate: '2022-10-20T16:45',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666277100000,
                          arrrivalDate: '2022-10-20T20:15',
                        },
                        {
                          id: '726',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '640',
                          durationInMin: 110,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666314900000,
                          departureDate: '2022-10-21T06:45',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666321500000,
                          arrrivalDate: '2022-10-21T08:35',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '807',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 6,
                      details: {
                        arrival: 1666245000000,
                        departure: 1666227600000,
                        numberOfStops: 1,
                        duration: 290,
                      },
                      key: '6E|6373|1666227600000|1666245000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_2DELAMD6E6373AMDBOM6E6425_18188342540451@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 6,
                            offeredFare: 5958.18,
                            publishedFare: 6270,
                            comission: 311.82,
                            bookingClass: 'M',
                            baseFare: 5267,
                            taxes: {
                              total: 1003,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1003,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_2DELAMD6E6373AMDBOM6E6425_18188342540891@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 6,
                            offeredFare: 5965.9,
                            publishedFare: 6077,
                            comission: 111.1,
                            bookingClass: 'R',
                            baseFare: 5267,
                            taxes: {
                              total: 810,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 810,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '891',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '6373',
                          durationInMin: 95,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666227600000,
                          departureDate: '2022-10-20T06:30',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666233300000,
                          arrrivalDate: '2022-10-20T08:05',
                        },
                        {
                          id: '892',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '6425',
                          durationInMin: 85,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666239900000,
                          departureDate: '2022-10-20T09:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666245000000,
                          arrrivalDate: '2022-10-20T11:20',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '6373',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666288500000,
                        departure: 1666262100000,
                        numberOfStops: 1,
                        duration: 440,
                      },
                      key: 'UK|817|1666262100000|1666288500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_115DELBLRUK817BLRBOMUK866_18185015256128@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 26509.84,
                            publishedFare: 27432,
                            comission: 922.16,
                            bookingClass: 'O',
                            baseFare: 25494,
                            taxes: {
                              total: 1938,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1288,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_115DELBLRUK817BLRBOMUK866_18185015255898@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 39338.71,
                            publishedFare: 40038,
                            comission: 699.29,
                            bookingClass: 'T',
                            baseFare: 35140,
                            taxes: {
                              total: 4898,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 4248,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '202',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '817',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666262100000,
                          departureDate: '2022-10-20T16:05',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666272000000,
                          arrrivalDate: '2022-10-20T18:50',
                        },
                        {
                          id: '203',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '866',
                          durationInMin: 105,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666282200000,
                          departureDate: '2022-10-20T21:40',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666288500000,
                          arrrivalDate: '2022-10-20T23:25',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '817',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666269600000,
                        departure: 1666256400000,
                        numberOfStops: 1,
                        duration: 220,
                      },
                      key: 'AI|636|1666256400000|1666269600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_21DELIDRAI636IDRBOMAI636_18185015064749@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 7192.04,
                            publishedFare: 7320,
                            comission: 127.96,
                            bookingClass: 'G',
                            baseFare: 6430,
                            taxes: {
                              total: 890,
                              breakups: {
                                'Carrier Misc': 170,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 330,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '8',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '636',
                          durationInMin: 90,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666256400000,
                          departureDate: '2022-10-20T14:30',
                          arrival: {
                            code: 'IDR',
                            airport: 'Devi Ahilya Bai Holkar Arpt',
                            city: 'Indore',
                            country: 'India',
                          },
                          arrivalTime: 1666261800000,
                          arrrivalDate: '2022-10-20T16:00',
                        },
                        {
                          id: '9',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '636',
                          durationInMin: 95,
                          departure: {
                            code: 'IDR',
                            airport: 'Devi Ahilya Bai Holkar Arpt',
                            city: 'Indore',
                            country: 'India',
                          },
                          departureTime: 1666263900000,
                          departureDate: '2022-10-20T16:35',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666269600000,
                          arrrivalDate: '2022-10-20T18:10',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '636',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666287000000,
                        departure: 1666230600000,
                        numberOfStops: 1,
                        duration: 940,
                      },
                      key: 'UK|829|1666230600000|1666287000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_86DELHYDUK829HYDBOMUK876_18185015201015@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 23263.39,
                            publishedFare: 23700,
                            comission: 436.61,
                            bookingClass: 'Q',
                            baseFare: 21940,
                            taxes: {
                              total: 1760,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1110,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_86DELHYDUK829HYDBOMUK876_18185015200785@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 25037.41,
                            publishedFare: 25478,
                            comission: 440.59,
                            bookingClass: 'U',
                            baseFare: 22140,
                            taxes: {
                              total: 3338,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 2688,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '52',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '829',
                          durationInMin: 125,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666230600000,
                          departureDate: '2022-10-20T07:20',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666238100000,
                          arrrivalDate: '2022-10-20T09:25',
                        },
                        {
                          id: '53',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '876',
                          durationInMin: 85,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666281900000,
                          departureDate: '2022-10-20T21:35',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666287000000,
                          arrrivalDate: '2022-10-20T23:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '829',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 45,
                      details: {
                        arrival: 1666290300000,
                        departure: 1666282200000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: 'SG|711|1666282200000|1666290300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '4-2158784108_3DELBOMSG711_18183742808292@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 45,
                            offeredFare: 5551.37,
                            publishedFare: 5661,
                            comission: 109.63,
                            bookingClass: 'DD',
                            baseFare: 4897,
                            taxes: {
                              total: 764,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 514,
                                'Management Fees': 0,
                                'Gst Charges': 250,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_3DELBOMSG711_18183742808732@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 45,
                            offeredFare: 6049.56,
                            publishedFare: 6170,
                            comission: 120.44,
                            bookingClass: 'SA',
                            baseFare: 5380,
                            taxes: {
                              total: 790,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 514,
                                'Management Fees': 0,
                                'Gst Charges': 276,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_3DELBOMSG711_18183742807992@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 45,
                            offeredFare: 5721.03,
                            publishedFare: 6042,
                            comission: 320.97,
                            bookingClass: 'CP',
                            baseFare: 5080,
                            taxes: {
                              total: 962,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 692,
                                'Management Fees': 0,
                                'Gst Charges': 270,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_3DELBOMSG711_18183742809162@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 45,
                            offeredFare: 6474.06,
                            publishedFare: 7319,
                            comission: 844.94,
                            bookingClass: 'HR',
                            baseFare: 5075,
                            taxes: {
                              total: 2244,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1984,
                                'Management Fees': 0,
                                'Gst Charges': 260,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '972',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 0,
                          flightNumber: '711',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666282200000,
                          departureDate: '2022-10-20T21:40',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666290300000,
                          arrrivalDate: '2022-10-20T23:55',
                        },
                      ],
                      operator: {
                        name: 'SpiceJet',
                        code: 'SG',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/SG.png',
                        number: '711',
                        equipment: '737',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666289700000,
                        departure: 1666251000000,
                        numberOfStops: 2,
                        duration: 645,
                      },
                      key: 'I5|745|1666251000000|1666289700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_16DELLKOI5745LKOGOII5745GOIBOMI5472_18182007866717@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 24170.22,
                            publishedFare: 24512,
                            comission: 341.78,
                            bookingClass: 'SM',
                            baseFare: 22900,
                            taxes: {
                              total: 1612,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 1148,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_16DELLKOI5745LKOGOII5745GOIBOMI5472_18182007866987@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 23561.03,
                            publishedFare: 23894,
                            comission: 332.97,
                            bookingClass: 'EC',
                            baseFare: 22310,
                            taxes: {
                              total: 1584,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 1120,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '468',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '745',
                          durationInMin: 70,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666251000000,
                          departureDate: '2022-10-20T13:00',
                          arrival: {
                            code: 'LKO',
                            airport: 'Amausi Arpt',
                            city: 'Lucknow',
                            country: 'India',
                          },
                          arrivalTime: 1666255200000,
                          arrrivalDate: '2022-10-20T14:10',
                        },
                        {
                          id: '469',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '745',
                          durationInMin: 145,
                          departure: {
                            code: 'LKO',
                            airport: 'Amausi Arpt',
                            city: 'Lucknow',
                            country: 'India',
                          },
                          departureTime: 1666257600000,
                          departureDate: '2022-10-20T14:50',
                          arrival: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          arrivalTime: 1666266300000,
                          arrrivalDate: '2022-10-20T17:15',
                        },
                        {
                          id: '470',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 2,
                          flightNumber: '472',
                          durationInMin: 75,
                          departure: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          departureTime: 1666285200000,
                          departureDate: '2022-10-20T22:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666289700000,
                          arrrivalDate: '2022-10-20T23:45',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '745',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666270800000,
                        departure: 1666251000000,
                        numberOfStops: 1,
                        duration: 330,
                      },
                      key: '6E|5186|1666251000000|1666270800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_27DELBLR6E5186BLRBOM6E5251_18188342597284@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 12662.6,
                            publishedFare: 12911,
                            comission: 248.4,
                            bookingClass: 'T',
                            baseFare: 11776,
                            taxes: {
                              total: 1135,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1135,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_27DELBLR6E5186BLRBOM6E5251_18188342596844@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 13858.97,
                            publishedFare: 14626,
                            comission: 767.03,
                            bookingClass: 'M',
                            baseFare: 12956,
                            taxes: {
                              total: 1670,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1670,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_27DELBLR6E5186BLRBOM6E5251_18188342597054@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'FLEXI_PLUS',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 14905.61,
                            publishedFare: 15200,
                            comission: 294.39,
                            bookingClass: 'J',
                            baseFare: 13956,
                            taxes: {
                              total: 1244,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1244,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '236',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '5186',
                          durationInMin: 155,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666251000000,
                          departureDate: '2022-10-20T13:00',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666260300000,
                          arrrivalDate: '2022-10-20T15:35',
                        },
                        {
                          id: '237',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '5251',
                          durationInMin: 100,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666264800000,
                          departureDate: '2022-10-20T16:50',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666270800000,
                          arrrivalDate: '2022-10-20T18:30',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '5186',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666344600000,
                        departure: 1666281600000,
                        numberOfStops: 1,
                        duration: 1050,
                      },
                      key: 'AI|839|1666281600000|1666344600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_55DELHYDAI839HYDBOMAI618_18185015139112@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 12242.92,
                            publishedFare: 12465,
                            comission: 222.08,
                            bookingClass: 'S',
                            baseFare: 11160,
                            taxes: {
                              total: 1305,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 575,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '886',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '839',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666281600000,
                          departureDate: '2022-10-20T21:30',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666289700000,
                          arrrivalDate: '2022-10-20T23:45',
                        },
                        {
                          id: '887',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '618',
                          durationInMin: 105,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666338300000,
                          departureDate: '2022-10-21T13:15',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666344600000,
                          arrrivalDate: '2022-10-21T15:00',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '839',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666326600000,
                        departure: 1666267500000,
                        numberOfStops: 1,
                        duration: 985,
                      },
                      key: 'UK|879|1666267500000|1666326600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_50DELHYDUK879HYDBOMUK874_18185015128762@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 12344.33,
                            publishedFare: 12570,
                            comission: 225.67,
                            bookingClass: 'Q',
                            baseFare: 11340,
                            taxes: {
                              total: 1230,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 580,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_50DELHYDUK879HYDBOMUK874_18185015128292@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 11314.23,
                            publishedFare: 11520,
                            comission: 205.77,
                            bookingClass: 'Q',
                            baseFare: 10340,
                            taxes: {
                              total: 1180,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 530,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '820',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '879',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666267500000,
                          departureDate: '2022-10-20T17:35',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666275600000,
                          arrrivalDate: '2022-10-20T19:50',
                        },
                        {
                          id: '821',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '874',
                          durationInMin: 90,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666321200000,
                          departureDate: '2022-10-21T08:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666326600000,
                          arrrivalDate: '2022-10-21T10:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '879',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666285500000,
                        departure: 1666229400000,
                        numberOfStops: 1,
                        duration: 935,
                      },
                      key: 'UK|833|1666229400000|1666285500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_117DELMAAUK833MAABOMUK824_18185015259988@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 20585.13,
                            publishedFare: 20970,
                            comission: 384.87,
                            bookingClass: 'N',
                            baseFare: 19340,
                            taxes: {
                              total: 1630,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 980,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_117DELMAAUK833MAABOMUK824_18185015259758@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 40142.19,
                            publishedFare: 40856,
                            comission: 713.81,
                            bookingClass: 'T',
                            baseFare: 35870,
                            taxes: {
                              total: 4986,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 4336,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '230',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '833',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666229400000,
                          departureDate: '2022-10-20T07:00',
                          arrival: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          arrivalTime: 1666239600000,
                          arrrivalDate: '2022-10-20T09:50',
                        },
                        {
                          id: '231',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '824',
                          durationInMin: 125,
                          departure: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          departureTime: 1666278000000,
                          departureDate: '2022-10-20T20:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666285500000,
                          arrrivalDate: '2022-10-20T22:35',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '833',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 16,
                      details: {
                        arrival: 1666339500000,
                        departure: 1666255800000,
                        numberOfStops: 1,
                        duration: 1395,
                      },
                      key: 'SG|751|1666255800000|1666339500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '4-2158784108_10DELDBRSG751DBRBOMSG951_18183742823333@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 16,
                            offeredFare: 18250.7,
                            publishedFare: 18637,
                            comission: 386.3,
                            bookingClass: 'DD',
                            baseFare: 17255,
                            taxes: {
                              total: 1382,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1382,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_10DELDBRSG751DBRBOMSG951_18183742823013@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 16,
                            offeredFare: 18757.27,
                            publishedFare: 19883,
                            comission: 1125.73,
                            bookingClass: 'CP',
                            baseFare: 17817,
                            taxes: {
                              total: 2066,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1138,
                                'Management Fees': 0,
                                'Gst Charges': 928,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_10DELDBRSG751DBRBOMSG951_18183742823473@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 16,
                            offeredFare: 15326.41,
                            publishedFare: 15649,
                            comission: 322.59,
                            bookingClass: 'RS',
                            baseFare: 14409,
                            taxes: {
                              total: 1240,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 514,
                                'Management Fees': 0,
                                'Gst Charges': 726,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '327',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 0,
                          flightNumber: '751',
                          durationInMin: 95,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666255800000,
                          departureDate: '2022-10-20T14:20',
                          arrival: {
                            code: 'DBR',
                            airport: 'Darbhanga Airport',
                            city: 'Darbhanga',
                            country: 'India',
                          },
                          arrivalTime: 1666261500000,
                          arrrivalDate: '2022-10-20T15:55',
                        },
                        {
                          id: '328',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 1,
                          flightNumber: '951',
                          durationInMin: 160,
                          departure: {
                            code: 'DBR',
                            airport: 'Darbhanga Airport',
                            city: 'Darbhanga',
                            country: 'India',
                          },
                          departureTime: 1666329900000,
                          departureDate: '2022-10-21T10:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666339500000,
                          arrrivalDate: '2022-10-21T13:35',
                        },
                      ],
                      operator: {
                        name: 'SpiceJet',
                        code: 'SG',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/SG.png',
                        number: '751',
                        equipment: '737',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666287000000,
                        departure: 1666241700000,
                        numberOfStops: 1,
                        duration: 755,
                      },
                      key: 'UK|859|1666241700000|1666287000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_89DELHYDUK859HYDBOMUK876_18185015206945@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 23263.39,
                            publishedFare: 23700,
                            comission: 436.61,
                            bookingClass: 'Q',
                            baseFare: 21940,
                            taxes: {
                              total: 1760,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1110,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_89DELHYDUK859HYDBOMUK876_18185015206705@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 26467.54,
                            publishedFare: 26934,
                            comission: 466.46,
                            bookingClass: 'U',
                            baseFare: 23440,
                            taxes: {
                              total: 3494,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 2844,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '705',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '859',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666241700000,
                          departureDate: '2022-10-20T10:25',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666249800000,
                          arrrivalDate: '2022-10-20T12:40',
                        },
                        {
                          id: '706',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '876',
                          durationInMin: 85,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666281900000,
                          departureDate: '2022-10-20T21:35',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666287000000,
                          arrrivalDate: '2022-10-20T23:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '859',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666251000000,
                        departure: 1666226700000,
                        numberOfStops: 1,
                        duration: 405,
                      },
                      key: 'UK|747|1666226700000|1666251000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_121DELCCUUK747CCUBOMUK772_18185015268108@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 20172.11,
                            publishedFare: 20549,
                            comission: 376.89,
                            bookingClass: 'H',
                            baseFare: 18939,
                            taxes: {
                              total: 1610,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 960,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_121DELCCUUK747CCUBOMUK772_18185015267898@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 45092.64,
                            publishedFare: 45896,
                            comission: 803.36,
                            bookingClass: 'T',
                            baseFare: 40370,
                            taxes: {
                              total: 5526,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 4876,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '921',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '747',
                          durationInMin: 125,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666226700000,
                          departureDate: '2022-10-20T06:15',
                          arrival: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          arrivalTime: 1666234200000,
                          arrrivalDate: '2022-10-20T08:20',
                        },
                        {
                          id: '922',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '772',
                          durationInMin: 155,
                          departure: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          departureTime: 1666241700000,
                          departureDate: '2022-10-20T10:25',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666251000000,
                          arrrivalDate: '2022-10-20T13:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '747',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666273500000,
                        departure: 1666252500000,
                        numberOfStops: 1,
                        duration: 350,
                      },
                      key: '6E|2153|1666252500000|1666273500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_39DELHYD6E2153HYDBOM6E6268_18188342629226@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 23511.82,
                            publishedFare: 24835,
                            comission: 1323.18,
                            bookingClass: 'M',
                            baseFare: 22350,
                            taxes: {
                              total: 2485,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 2485,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_39DELHYD6E2153HYDBOM6E6268_18188342629656@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 23491.6,
                            publishedFare: 23962,
                            comission: 470.4,
                            bookingClass: 'R',
                            baseFare: 22300,
                            taxes: {
                              total: 1662,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1662,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '956',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '2153',
                          durationInMin: 125,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666252500000,
                          departureDate: '2022-10-20T13:25',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666260000000,
                          arrrivalDate: '2022-10-20T15:30',
                        },
                        {
                          id: '957',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '6268',
                          durationInMin: 85,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666268400000,
                          departureDate: '2022-10-20T17:50',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666273500000,
                          arrrivalDate: '2022-10-20T19:15',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '2153',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666240800000,
                        departure: 1666233000000,
                        numberOfStops: 0,
                        duration: 130,
                      },
                      key: 'AI|665|1666233000000|1666240800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_11DELBOMAI665_18185015033826@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6161.94,
                            publishedFare: 6270,
                            comission: 108.06,
                            bookingClass: 'S',
                            baseFare: 5430,
                            taxes: {
                              total: 840,
                              breakups: {
                                'Carrier Misc': 170,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '819',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '665',
                          durationInMin: 130,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666233000000,
                          departureDate: '2022-10-20T08:00',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666240800000,
                          arrrivalDate: '2022-10-20T10:10',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '665',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666288800000,
                        departure: 1666229400000,
                        numberOfStops: 1,
                        duration: 990,
                      },
                      key: 'UK|785|1666229400000|1666288800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_72DELBBIUK785BBIBOMUK546_18185015173244@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 20070.08,
                            publishedFare: 20445,
                            comission: 374.92,
                            bookingClass: 'A',
                            baseFare: 18840,
                            taxes: {
                              total: 1605,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 955,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_72DELBBIUK785BBIBOMUK546_18185015173064@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 19426.9,
                            publishedFare: 19766,
                            comission: 339.1,
                            bookingClass: 'T',
                            baseFare: 17040,
                            taxes: {
                              total: 2726,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 2076,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '947',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '785',
                          durationInMin: 115,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666229400000,
                          departureDate: '2022-10-20T07:00',
                          arrival: {
                            code: 'BBI',
                            airport: 'Biju Patnaik Arpt',
                            city: 'Bhubaneswar',
                            country: 'India',
                          },
                          arrivalTime: 1666236300000,
                          arrrivalDate: '2022-10-20T08:55',
                        },
                        {
                          id: '948',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '546',
                          durationInMin: 140,
                          departure: {
                            code: 'BBI',
                            airport: 'Biju Patnaik Arpt',
                            city: 'Bhubaneswar',
                            country: 'India',
                          },
                          departureTime: 1666280400000,
                          departureDate: '2022-10-20T21:10',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666288800000,
                          arrrivalDate: '2022-10-20T23:30',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '785',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666363800000,
                        departure: 1666278000000,
                        numberOfStops: 1,
                        duration: 1430,
                      },
                      key: 'UK|727|1666278000000|1666363800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_126DELCCUUK727CCUBOMUK776_18185015277950@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 22232.31,
                            publishedFare: 22649,
                            comission: 416.69,
                            bookingClass: 'A',
                            baseFare: 20939,
                            taxes: {
                              total: 1710,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1060,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_126DELCCUUK727CCUBOMUK776_18185015277580@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 52353.3,
                            publishedFare: 53288,
                            comission: 934.7,
                            bookingClass: 'T',
                            baseFare: 46970,
                            taxes: {
                              total: 6318,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 5668,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '543',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '727',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666278000000,
                          departureDate: '2022-10-20T20:30',
                          arrival: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          arrivalTime: 1666286100000,
                          arrrivalDate: '2022-10-20T22:45',
                        },
                        {
                          id: '544',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '776',
                          durationInMin: 165,
                          departure: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          departureTime: 1666353900000,
                          departureDate: '2022-10-21T17:35',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666363800000,
                          arrrivalDate: '2022-10-21T20:20',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '727',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666375800000,
                        departure: 1666278000000,
                        numberOfStops: 1,
                        duration: 1630,
                      },
                      key: 'UK|727|1666278000000|1666375800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_119DELCCUUK727CCUBOMUK774_18185015263948@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 23160.38,
                            publishedFare: 23595,
                            comission: 434.62,
                            bookingClass: 'A',
                            baseFare: 21840,
                            taxes: {
                              total: 1755,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1105,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_119DELCCUUK727CCUBOMUK774_18185015263738@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 43772.52,
                            publishedFare: 44552,
                            comission: 779.48,
                            bookingClass: 'T',
                            baseFare: 39170,
                            taxes: {
                              total: 5382,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 4732,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '918',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '727',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666278000000,
                          departureDate: '2022-10-20T20:30',
                          arrival: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          arrivalTime: 1666286100000,
                          arrrivalDate: '2022-10-20T22:45',
                        },
                        {
                          id: '919',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '774',
                          durationInMin: 175,
                          departure: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          departureTime: 1666365300000,
                          departureDate: '2022-10-21T20:45',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666375800000,
                          arrrivalDate: '2022-10-21T23:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '727',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666321500000,
                        departure: 1666252800000,
                        numberOfStops: 1,
                        duration: 1145,
                      },
                      key: 'AI|502|1666252800000|1666321500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_47DELBLRAI502BLRBOMAI640_18185015122661@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 11120.61,
                            publishedFare: 11321,
                            comission: 200.39,
                            bookingClass: 'S',
                            baseFare: 10070,
                            taxes: {
                              total: 1251,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 521,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '305',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '502',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666252800000,
                          departureDate: '2022-10-20T13:30',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666262700000,
                          arrrivalDate: '2022-10-20T16:15',
                        },
                        {
                          id: '306',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '640',
                          durationInMin: 110,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666314900000,
                          departureDate: '2022-10-21T06:45',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666321500000,
                          arrrivalDate: '2022-10-21T08:35',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '502',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666239300000,
                        departure: 1666231200000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: 'UK|943|1666231200000|1666239300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_5DELBOMUK943_18185015011005@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7191.25,
                            publishedFare: 7320,
                            comission: 128.75,
                            bookingClass: 'Q',
                            baseFare: 6470,
                            taxes: {
                              total: 850,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 330,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_5DELBOMUK943_18185015009145@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6161.15,
                            publishedFare: 6270,
                            comission: 108.85,
                            bookingClass: 'Q',
                            baseFare: 5470,
                            taxes: {
                              total: 800,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '403',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '943',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666231200000,
                          departureDate: '2022-10-20T07:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666239300000,
                          arrrivalDate: '2022-10-20T09:45',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '943',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666244700000,
                        departure: 1666236600000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: 'AI|678|1666236600000|1666244700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_14DELBOMAI678_18185015044817@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6161.94,
                            publishedFare: 6270,
                            comission: 108.06,
                            bookingClass: 'S',
                            baseFare: 5430,
                            taxes: {
                              total: 840,
                              breakups: {
                                'Carrier Misc': 170,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '661',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '678',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666236600000,
                          departureDate: '2022-10-20T09:00',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666244700000,
                          arrrivalDate: '2022-10-20T11:15',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '678',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 8,
                      details: {
                        arrival: 1666258200000,
                        departure: 1666239300000,
                        numberOfStops: 1,
                        duration: 315,
                      },
                      key: 'AI|542|1666239300000|1666258200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_48DELHYDAI542HYDBOMAI618_18185015124091@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 11212.82,
                            publishedFare: 11415,
                            comission: 202.18,
                            bookingClass: 'G',
                            baseFare: 10160,
                            taxes: {
                              total: 1255,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 525,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '805',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '542',
                          durationInMin: 125,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666239300000,
                          departureDate: '2022-10-20T09:45',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666246800000,
                          arrrivalDate: '2022-10-20T11:50',
                        },
                        {
                          id: '806',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '618',
                          durationInMin: 105,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666251900000,
                          departureDate: '2022-10-20T13:15',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666258200000,
                          arrrivalDate: '2022-10-20T15:00',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '542',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666287900000,
                        departure: 1666269600000,
                        numberOfStops: 1,
                        duration: 305,
                      },
                      key: 'UK|969|1666269600000|1666287900000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_76DELAMDUK969AMDBOMUK956_18185015180504@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 17306.8,
                            publishedFare: 18098,
                            comission: 791.2,
                            bookingClass: 'O',
                            baseFare: 16605,
                            taxes: {
                              total: 1493,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 843,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_76DELAMDUK969AMDBOMUK956_18185015180294@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 21132.06,
                            publishedFare: 21502,
                            comission: 369.94,
                            bookingClass: 'U',
                            baseFare: 18590,
                            taxes: {
                              total: 2912,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 2262,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '542',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '969',
                          durationInMin: 105,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666269600000,
                          departureDate: '2022-10-20T18:10',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666275900000,
                          arrrivalDate: '2022-10-20T19:55',
                        },
                        {
                          id: '543',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '956',
                          durationInMin: 65,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666284000000,
                          departureDate: '2022-10-20T22:10',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666287900000,
                          arrrivalDate: '2022-10-20T23:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '969',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666286400000,
                        departure: 1666278600000,
                        numberOfStops: 0,
                        duration: 130,
                      },
                      key: 'UK|953|1666278600000|1666286400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_36DELBOMUK953_18185015103180@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 10384.56,
                            publishedFare: 10575,
                            comission: 190.44,
                            bookingClass: 'H',
                            baseFare: 9570,
                            taxes: {
                              total: 1005,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 485,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_36DELBOMUK953_18185015102530@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 10384.56,
                            publishedFare: 10575,
                            comission: 190.44,
                            bookingClass: 'H',
                            baseFare: 9570,
                            taxes: {
                              total: 1005,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 485,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '141',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '953',
                          durationInMin: 130,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666278600000,
                          departureDate: '2022-10-20T20:40',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666286400000,
                          arrrivalDate: '2022-10-20T22:50',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '953',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666325400000,
                        departure: 1666281000000,
                        numberOfStops: 1,
                        duration: 740,
                      },
                      key: 'UK|929|1666281000000|1666325400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_37DELAMDUK929AMDBOMUK936_18185015104870@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 10490.15,
                            publishedFare: 10680,
                            comission: 189.85,
                            bookingClass: 'V',
                            baseFare: 9540,
                            taxes: {
                              total: 1140,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 490,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_37DELAMDUK929AMDBOMUK936_18185015104400@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 10490.15,
                            publishedFare: 10680,
                            comission: 189.85,
                            bookingClass: 'V',
                            baseFare: 9540,
                            taxes: {
                              total: 1140,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 490,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '723',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '929',
                          durationInMin: 100,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666281000000,
                          departureDate: '2022-10-20T21:20',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666287000000,
                          arrrivalDate: '2022-10-20T23:00',
                        },
                        {
                          id: '724',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '936',
                          durationInMin: 75,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666320900000,
                          departureDate: '2022-10-21T08:25',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666325400000,
                          arrrivalDate: '2022-10-21T09:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '929',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666260900000,
                        departure: 1666239600000,
                        numberOfStops: 1,
                        duration: 355,
                      },
                      key: '6E|184|1666239600000|1666260900000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_3DELAMD6E184AMDBOM6E5274_18188342543441@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6811.04,
                            publishedFare: 7172,
                            comission: 360.96,
                            bookingClass: 'M',
                            baseFare: 6097,
                            taxes: {
                              total: 1075,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1075,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_3DELAMD6E184AMDBOM6E5274_18188342543921@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6819.39,
                            publishedFare: 6948,
                            comission: 128.61,
                            bookingClass: 'R',
                            baseFare: 6097,
                            taxes: {
                              total: 851,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 851,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '117',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '184',
                          durationInMin: 90,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666239600000,
                          departureDate: '2022-10-20T09:50',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666245000000,
                          arrrivalDate: '2022-10-20T11:20',
                        },
                        {
                          id: '118',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '5274',
                          durationInMin: 75,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666256400000,
                          departureDate: '2022-10-20T14:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666260900000,
                          arrrivalDate: '2022-10-20T15:45',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '184',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666265100000,
                        departure: 1666244700000,
                        numberOfStops: 1,
                        duration: 340,
                      },
                      key: '6E|6022|1666244700000|1666265100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_34DELNAG6E6022NAGBOM6E6292_18188342615765@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 17597.59,
                            publishedFare: 18580,
                            comission: 982.41,
                            bookingClass: 'M',
                            baseFare: 16594,
                            taxes: {
                              total: 1986,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1986,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_34DELNAG6E6022NAGBOM6E6292_18188342616215@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 17619.96,
                            publishedFare: 17970,
                            comission: 350.04,
                            bookingClass: 'R',
                            baseFare: 16594,
                            taxes: {
                              total: 1376,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1376,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '716',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '6022',
                          durationInMin: 90,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666244700000,
                          departureDate: '2022-10-20T11:15',
                          arrival: {
                            code: 'NAG',
                            airport: 'Dr Ambedkar Intl Arpt',
                            city: 'Nagpur',
                            country: 'India',
                          },
                          arrivalTime: 1666250100000,
                          arrrivalDate: '2022-10-20T12:45',
                        },
                        {
                          id: '717',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '6292',
                          durationInMin: 85,
                          departure: {
                            code: 'NAG',
                            airport: 'Dr Ambedkar Intl Arpt',
                            city: 'Nagpur',
                            country: 'India',
                          },
                          departureTime: 1666260000000,
                          departureDate: '2022-10-20T15:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666265100000,
                          arrrivalDate: '2022-10-20T16:55',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '6022',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666259400000,
                        departure: 1666252200000,
                        numberOfStops: 0,
                        duration: 120,
                      },
                      key: 'G8|328|1666252200000|1666259400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_1DELBOMG8328_18183418269253@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 5728.62,
                            publishedFare: 5881,
                            comission: 152.38,
                            bookingClass: 'SP',
                            baseFare: 5105,
                            taxes: {
                              total: 776,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 776,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_1DELBOMG8328_18183418269703@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'GOMORE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 15490.95,
                            publishedFare: 15929,
                            comission: 438.05,
                            bookingClass: 'MG',
                            baseFare: 14675,
                            taxes: {
                              total: 1254,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1254,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_1DELBOMG8328_18183418269053@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7034.1,
                            publishedFare: 7487,
                            comission: 452.9,
                            bookingClass: 'BC',
                            baseFare: 6411,
                            taxes: {
                              total: 1076,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1076,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_1DELBOMG8328_18183418269493@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7125.72,
                            publishedFare: 7319,
                            comission: 193.28,
                            bookingClass: 'GS',
                            baseFare: 6475,
                            taxes: {
                              total: 844,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 844,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '353',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 0,
                          flightNumber: '328',
                          durationInMin: 120,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666252200000,
                          departureDate: '2022-10-20T13:20',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666259400000,
                          arrrivalDate: '2022-10-20T15:20',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '328',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666249500000,
                        departure: 1666240200000,
                        numberOfStops: 0,
                        duration: 155,
                      },
                      key: 'AI|865|1666240200000|1666249500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_18DELBOMAI865_18185015052427@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6161.94,
                            publishedFare: 6270,
                            comission: 108.06,
                            bookingClass: 'S',
                            baseFare: 5430,
                            taxes: {
                              total: 840,
                              breakups: {
                                'Carrier Misc': 170,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '164',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '865',
                          durationInMin: 155,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666240200000,
                          departureDate: '2022-10-20T10:00',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666249500000,
                          arrrivalDate: '2022-10-20T12:35',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '865',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666348200000,
                        departure: 1666252200000,
                        numberOfStops: 1,
                        duration: 1600,
                      },
                      key: 'UK|627|1666252200000|1666348200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_23DELUDRUK627UDRBOMUK614_18185015073389@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 9664.11,
                            publishedFare: 9838,
                            comission: 173.89,
                            bookingClass: 'Q',
                            baseFare: 8738,
                            taxes: {
                              total: 1100,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 450,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_23DELUDRUK627UDRBOMUK614_18185015072909@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 8427.99,
                            publishedFare: 8578,
                            comission: 150.01,
                            bookingClass: 'Q',
                            baseFare: 7538,
                            taxes: {
                              total: 1040,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 390,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '969',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '627',
                          durationInMin: 85,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666252200000,
                          departureDate: '2022-10-20T13:20',
                          arrival: {
                            code: 'UDR',
                            airport: 'Maharana Pratap Arpt',
                            city: 'Udaipur',
                            country: 'India',
                          },
                          arrivalTime: 1666257300000,
                          arrrivalDate: '2022-10-20T14:45',
                        },
                        {
                          id: '970',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '614',
                          durationInMin: 100,
                          departure: {
                            code: 'UDR',
                            airport: 'Maharana Pratap Arpt',
                            city: 'Udaipur',
                            country: 'India',
                          },
                          departureTime: 1666342200000,
                          departureDate: '2022-10-21T14:20',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666348200000,
                          arrrivalDate: '2022-10-21T16:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '627',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666374300000,
                        departure: 1666281000000,
                        numberOfStops: 1,
                        duration: 1555,
                      },
                      key: 'UK|929|1666281000000|1666374300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_38DELAMDUK929AMDBOMUK956_18185015106451@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 10490.15,
                            publishedFare: 10680,
                            comission: 189.85,
                            bookingClass: 'V',
                            baseFare: 9540,
                            taxes: {
                              total: 1140,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 490,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '439',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '929',
                          durationInMin: 100,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666281000000,
                          departureDate: '2022-10-20T21:20',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666287000000,
                          arrrivalDate: '2022-10-20T23:00',
                        },
                        {
                          id: '440',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '956',
                          durationInMin: 65,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666370400000,
                          departureDate: '2022-10-21T22:10',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666374300000,
                          arrrivalDate: '2022-10-21T23:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '929',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666278600000,
                        departure: 1666238700000,
                        numberOfStops: 1,
                        duration: 665,
                      },
                      key: 'I5|1529|1666238700000|1666278600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_12DELBLRI51529BLRBOMI5612_18182007853346@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 22118.82,
                            publishedFare: 22431,
                            comission: 312.18,
                            bookingClass: 'SM',
                            baseFare: 20917,
                            taxes: {
                              total: 1514,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 1050,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_12DELBLRI51529BLRBOMI5612_18182007853606@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'PROMO',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 20593.8,
                            publishedFare: 20884,
                            comission: 290.2,
                            bookingClass: 'BT',
                            baseFare: 19444,
                            taxes: {
                              total: 1440,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 976,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_12DELBLRI51529BLRBOMI5612_18182007853806@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PREMIUM_FLEX',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 23158.81,
                            publishedFare: 23486,
                            comission: 327.19,
                            bookingClass: 'HF',
                            baseFare: 21922,
                            taxes: {
                              total: 1564,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 1100,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '115',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '1529',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666238700000,
                          departureDate: '2022-10-20T09:35',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666248900000,
                          arrrivalDate: '2022-10-20T12:25',
                        },
                        {
                          id: '116',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '612',
                          durationInMin: 115,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666271700000,
                          departureDate: '2022-10-20T18:45',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666278600000,
                          arrrivalDate: '2022-10-20T20:40',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '1529',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666287000000,
                        departure: 1666257300000,
                        numberOfStops: 1,
                        duration: 495,
                      },
                      key: 'UK|899|1666257300000|1666287000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_88DELHYDUK899HYDBOMUK876_18185015205095@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 23263.39,
                            publishedFare: 23700,
                            comission: 436.61,
                            bookingClass: 'Q',
                            baseFare: 21940,
                            taxes: {
                              total: 1760,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1110,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_88DELHYDUK899HYDBOMUK876_18185015204865@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 26467.54,
                            publishedFare: 26934,
                            comission: 466.46,
                            bookingClass: 'U',
                            baseFare: 23440,
                            taxes: {
                              total: 3494,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 2844,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '681',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '899',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666257300000,
                          departureDate: '2022-10-20T14:45',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666265400000,
                          arrrivalDate: '2022-10-20T17:00',
                        },
                        {
                          id: '682',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '876',
                          durationInMin: 85,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666281900000,
                          departureDate: '2022-10-20T21:35',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666287000000,
                          arrrivalDate: '2022-10-20T23:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '899',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666245900000,
                        departure: 1666225800000,
                        numberOfStops: 1,
                        duration: 335,
                      },
                      key: '6E|6612|1666225800000|1666245900000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_30DELBLR6E6612BLRBOM6E488_18188342605265@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 13838.49,
                            publishedFare: 14111,
                            comission: 272.51,
                            bookingClass: 'T',
                            baseFare: 12919,
                            taxes: {
                              total: 1192,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1192,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_30DELBLR6E6612BLRBOM6E488_18188342604845@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 15033.3,
                            publishedFare: 15868,
                            comission: 834.7,
                            bookingClass: 'M',
                            baseFare: 14099,
                            taxes: {
                              total: 1769,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1769,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_30DELBLR6E6612BLRBOM6E488_18188342605045@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'FLEXI_PLUS',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 16081.5,
                            publishedFare: 16400,
                            comission: 318.5,
                            bookingClass: 'J',
                            baseFare: 15099,
                            taxes: {
                              total: 1301,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1301,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '838',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '6612',
                          durationInMin: 155,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666225800000,
                          departureDate: '2022-10-20T06:00',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666235100000,
                          arrrivalDate: '2022-10-20T08:35',
                        },
                        {
                          id: '839',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '488',
                          durationInMin: 95,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666240200000,
                          departureDate: '2022-10-20T10:00',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666245900000,
                          arrrivalDate: '2022-10-20T11:35',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '6612',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666280700000,
                        departure: 1666272600000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: 'UK|977|1666272600000|1666280700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_90DELBOMUK977_18185015208506@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 25836.06,
                            publishedFare: 26325,
                            comission: 488.94,
                            bookingClass: 'Y',
                            baseFare: 24570,
                            taxes: {
                              total: 1755,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1235,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_90DELBOMUK977_18185015208266@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 28665.16,
                            publishedFare: 29174,
                            comission: 508.84,
                            bookingClass: 'S',
                            baseFare: 25570,
                            taxes: {
                              total: 3604,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 3084,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '804',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '977',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666272600000,
                          departureDate: '2022-10-20T19:00',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666280700000,
                          arrrivalDate: '2022-10-20T21:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '977',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 6,
                      details: {
                        arrival: 1666321800000,
                        departure: 1666241700000,
                        numberOfStops: 1,
                        duration: 1335,
                      },
                      key: 'UK|859|1666241700000|1666321800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_65DELHYDUK859HYDBOMUK872_18185015161343@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 6,
                            offeredFare: 12716.29,
                            publishedFare: 12934,
                            comission: 217.71,
                            bookingClass: 'U',
                            baseFare: 10940,
                            taxes: {
                              total: 1994,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1344,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '810',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '859',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666241700000,
                          departureDate: '2022-10-20T10:25',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666249800000,
                          arrrivalDate: '2022-10-20T12:40',
                        },
                        {
                          id: '811',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '872',
                          durationInMin: 90,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666316400000,
                          departureDate: '2022-10-21T07:10',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666321800000,
                          arrrivalDate: '2022-10-21T08:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '859',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 8,
                      details: {
                        arrival: 1666272600000,
                        departure: 1666241400000,
                        numberOfStops: 1,
                        duration: 520,
                      },
                      key: 'UK|611|1666241400000|1666272600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_130DELSXRUK611SXRBOMUK648_18185015285160@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 18110.9,
                            publishedFare: 18450,
                            comission: 339.1,
                            bookingClass: 'A',
                            baseFare: 17040,
                            taxes: {
                              total: 1410,
                              breakups: {
                                'Carrier Misc': 160,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 860,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '783',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '611',
                          durationInMin: 100,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666241400000,
                          departureDate: '2022-10-20T10:20',
                          arrival: {
                            code: 'SXR',
                            airport: 'Srinagar Arpt',
                            city: 'Srinagar',
                            country: 'India',
                          },
                          arrivalTime: 1666247400000,
                          arrrivalDate: '2022-10-20T12:00',
                        },
                        {
                          id: '784',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '648',
                          durationInMin: 165,
                          departure: {
                            code: 'SXR',
                            airport: 'Srinagar Arpt',
                            city: 'Srinagar',
                            country: 'India',
                          },
                          departureTime: 1666262700000,
                          departureDate: '2022-10-20T16:15',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666272600000,
                          arrrivalDate: '2022-10-20T19:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '611',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666379100000,
                        departure: 1666283400000,
                        numberOfStops: 1,
                        duration: 1595,
                      },
                      key: 'AI|883|1666283400000|1666379100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_40DELGOIAI883GOIBOMAI697_18185015109631@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 10491.75,
                            publishedFare: 10680,
                            comission: 188.25,
                            bookingClass: 'S',
                            baseFare: 9460,
                            taxes: {
                              total: 1220,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 490,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '915',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '883',
                          durationInMin: 160,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666283400000,
                          departureDate: '2022-10-20T22:00',
                          arrival: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          arrivalTime: 1666293000000,
                          arrrivalDate: '2022-10-21T00:40',
                        },
                        {
                          id: '916',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '697',
                          durationInMin: 85,
                          departure: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          departureTime: 1666374000000,
                          departureDate: '2022-10-21T23:10',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666379100000,
                          arrrivalDate: '2022-10-22T00:35',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '883',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666327500000,
                        departure: 1666267500000,
                        numberOfStops: 1,
                        duration: 1000,
                      },
                      key: 'UK|813|1666267500000|1666327500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_123DELBLRUK813BLRBOMUK846_18185015272020@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 18124.16,
                            publishedFare: 18927,
                            comission: 802.84,
                            bookingClass: 'O',
                            baseFare: 17394,
                            taxes: {
                              total: 1533,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 883,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_123DELBLRUK813BLRBOMUK846_18185015271780@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 47479.45,
                            publishedFare: 48326,
                            comission: 846.55,
                            bookingClass: 'S',
                            baseFare: 42540,
                            taxes: {
                              total: 5786,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 5136,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '521',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '813',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666267500000,
                          departureDate: '2022-10-20T17:35',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666277700000,
                          arrrivalDate: '2022-10-20T20:25',
                        },
                        {
                          id: '522',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '846',
                          durationInMin: 105,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666321200000,
                          departureDate: '2022-10-21T08:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666327500000,
                          arrrivalDate: '2022-10-21T10:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '813',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666332600000,
                        departure: 1666275900000,
                        numberOfStops: 1,
                        duration: 945,
                      },
                      key: 'UK|835|1666275900000|1666332600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_113DELMAAUK835MAABOMUK822_18185015252508@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 17629.25,
                            publishedFare: 17957,
                            comission: 327.75,
                            bookingClass: 'N',
                            baseFare: 16470,
                            taxes: {
                              total: 1487,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 837,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_113DELMAAUK835MAABOMUK822_18185015252328@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 37798.57,
                            publishedFare: 38470,
                            comission: 671.43,
                            bookingClass: 'T',
                            baseFare: 33740,
                            taxes: {
                              total: 4730,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 4080,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '377',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '835',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666275900000,
                          departureDate: '2022-10-20T19:55',
                          arrival: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          arrivalTime: 1666286100000,
                          arrrivalDate: '2022-10-20T22:45',
                        },
                        {
                          id: '378',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '822',
                          durationInMin: 115,
                          departure: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          departureTime: 1666325700000,
                          departureDate: '2022-10-21T09:45',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666332600000,
                          arrrivalDate: '2022-10-21T11:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '835',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666287600000,
                        departure: 1666269300000,
                        numberOfStops: 1,
                        duration: 305,
                      },
                      key: '6E|6823|1666269300000|1666287600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_38DELHYD6E6823HYDBOM6E5316_18188342626496@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 22793.26,
                            publishedFare: 24075,
                            comission: 1281.74,
                            bookingClass: 'M',
                            baseFare: 21650,
                            taxes: {
                              total: 2425,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 2425,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_38DELHYD6E6823HYDBOM6E5316_18188342626926@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 22822.31,
                            publishedFare: 23279,
                            comission: 456.69,
                            bookingClass: 'R',
                            baseFare: 21650,
                            taxes: {
                              total: 1629,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1629,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '591',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '6823',
                          durationInMin: 110,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666269300000,
                          departureDate: '2022-10-20T18:05',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666275900000,
                          arrrivalDate: '2022-10-20T19:55',
                        },
                        {
                          id: '592',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '5316',
                          durationInMin: 75,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666283100000,
                          departureDate: '2022-10-20T21:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666287600000,
                          arrrivalDate: '2022-10-20T23:10',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '6823',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666266600000,
                        departure: 1666259100000,
                        numberOfStops: 0,
                        duration: 125,
                      },
                      key: 'G8|336|1666259100000|1666266600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_2DELBOMG8336_18183418270823@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 5728.62,
                            publishedFare: 5881,
                            comission: 152.38,
                            bookingClass: 'SP',
                            baseFare: 5105,
                            taxes: {
                              total: 776,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 776,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_2DELBOMG8336_18183418271433@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'GOMORE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 15490.95,
                            publishedFare: 15929,
                            comission: 438.05,
                            bookingClass: 'MG',
                            baseFare: 14675,
                            taxes: {
                              total: 1254,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1254,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_2DELBOMG8336_18183418270583@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7034.1,
                            publishedFare: 7487,
                            comission: 452.9,
                            bookingClass: 'BC',
                            baseFare: 6411,
                            taxes: {
                              total: 1076,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1076,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_2DELBOMG8336_18183418271093@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7125.72,
                            publishedFare: 7319,
                            comission: 193.28,
                            bookingClass: 'GS',
                            baseFare: 6475,
                            taxes: {
                              total: 844,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 844,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '11-2158784108_4DELBOMG8336_18181209945099@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'GROUP',
                          message:
                            'Travel on a group ticket, Non Refundable, Paid -  Seats &  Meals.',
                          typeKey: 'OFFER_FARE_WITHOUT_PNR',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'NONREFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 5700,
                            publishedFare: 5700,
                            bookingClass: 'G',
                            baseFare: 5400,
                            taxes: {
                              total: 300,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 300,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '994',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 0,
                          flightNumber: '336',
                          durationInMin: 125,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666259100000,
                          departureDate: '2022-10-20T15:15',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666266600000,
                          arrrivalDate: '2022-10-20T17:20',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '336',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666268700000,
                        departure: 1666248900000,
                        numberOfStops: 1,
                        duration: 330,
                      },
                      key: '6E|5161|1666248900000|1666268700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_23DELGOI6E5161GOIBOM6E714_18188342582824@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 10425.48,
                            publishedFare: 10628,
                            comission: 202.52,
                            bookingClass: 'T',
                            baseFare: 9601,
                            taxes: {
                              total: 1027,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1027,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_23DELGOI6E5161GOIBOM6E714_18188342582374@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 12054.93,
                            publishedFare: 12718,
                            comission: 663.07,
                            bookingClass: 'M',
                            baseFare: 11200,
                            taxes: {
                              total: 1518,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1518,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_23DELGOI6E5161GOIBOM6E714_18188342582604@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'FLEXI_PLUS',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 13099.66,
                            publishedFare: 13357,
                            comission: 257.34,
                            bookingClass: 'J',
                            baseFare: 12200,
                            taxes: {
                              total: 1157,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1157,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '555',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '5161',
                          durationInMin: 145,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666248900000,
                          departureDate: '2022-10-20T12:25',
                          arrival: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          arrivalTime: 1666257600000,
                          arrrivalDate: '2022-10-20T14:50',
                        },
                        {
                          id: '556',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '714',
                          durationInMin: 85,
                          departure: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          departureTime: 1666263600000,
                          departureDate: '2022-10-20T16:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666268700000,
                          arrrivalDate: '2022-10-20T17:55',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '5161',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 7,
                      details: {
                        arrival: 1666292400000,
                        departure: 1666270800000,
                        numberOfStops: 1,
                        duration: 360,
                      },
                      key: '6E|2015|1666270800000|1666292400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_36DELBLR6E2015BLRBOM6E5344_18188342621215@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 7,
                            offeredFare: 18016.44,
                            publishedFare: 19023,
                            comission: 1006.56,
                            bookingClass: 'M',
                            baseFare: 17002,
                            taxes: {
                              total: 2021,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 2021,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_36DELBLR6E2015BLRBOM6E5344_18188342621635@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 7,
                            offeredFare: 18040.36,
                            publishedFare: 18399,
                            comission: 358.64,
                            bookingClass: 'R',
                            baseFare: 17002,
                            taxes: {
                              total: 1397,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1397,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '679',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '2015',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666270800000,
                          departureDate: '2022-10-20T18:30',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666280700000,
                          arrrivalDate: '2022-10-20T21:15',
                        },
                        {
                          id: '680',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '5344',
                          durationInMin: 95,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666286700000,
                          departureDate: '2022-10-20T22:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666292400000,
                          arrrivalDate: '2022-10-21T00:30',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '2015',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 16,
                      details: {
                        arrival: 1666339500000,
                        departure: 1666245600000,
                        numberOfStops: 1,
                        duration: 1565,
                      },
                      key: 'SG|8485|1666245600000|1666339500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '4-2158784108_8DELDBRSG8485DBRBOMSG951_18183742819803@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 16,
                            offeredFare: 16812.05,
                            publishedFare: 17167,
                            comission: 354.95,
                            bookingClass: 'DD',
                            baseFare: 15855,
                            taxes: {
                              total: 1312,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 514,
                                'Management Fees': 0,
                                'Gst Charges': 798,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_8DELDBRSG8485DBRBOMSG951_18183742819513@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 16,
                            offeredFare: 17283.13,
                            publishedFare: 18318,
                            comission: 1034.87,
                            bookingClass: 'CP',
                            baseFare: 16379,
                            taxes: {
                              total: 1939,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1087,
                                'Management Fees': 0,
                                'Gst Charges': 852,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_8DELDBRSG8485DBRBOMSG951_18183742819973@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 16,
                            offeredFare: 13887.76,
                            publishedFare: 14179,
                            comission: 291.24,
                            bookingClass: 'RS',
                            baseFare: 13009,
                            taxes: {
                              total: 1170,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 514,
                                'Management Fees': 0,
                                'Gst Charges': 656,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '38',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 0,
                          flightNumber: '8485',
                          durationInMin: 105,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666245600000,
                          departureDate: '2022-10-20T11:30',
                          arrival: {
                            code: 'DBR',
                            airport: 'Darbhanga Airport',
                            city: 'Darbhanga',
                            country: 'India',
                          },
                          arrivalTime: 1666251900000,
                          arrrivalDate: '2022-10-20T13:15',
                        },
                        {
                          id: '39',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 1,
                          flightNumber: '951',
                          durationInMin: 160,
                          departure: {
                            code: 'DBR',
                            airport: 'Darbhanga Airport',
                            city: 'Darbhanga',
                            country: 'India',
                          },
                          departureTime: 1666329900000,
                          departureDate: '2022-10-21T10:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666339500000,
                          arrrivalDate: '2022-10-21T13:35',
                        },
                      ],
                      operator: {
                        name: 'SpiceJet',
                        code: 'SG',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/SG.png',
                        number: '8485',
                        equipment: '7M8',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666254300000,
                        departure: 1666247100000,
                        numberOfStops: 0,
                        duration: 120,
                      },
                      key: '6E|2138|1666247100000|1666254300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_19DELBOM6E2138_18188342571532@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6128.49,
                            publishedFare: 6244,
                            comission: 115.51,
                            bookingClass: 'T',
                            baseFare: 5476,
                            taxes: {
                              total: 768,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 768,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_19DELBOM6E2138_18188342571102@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7173.18,
                            publishedFare: 7558,
                            comission: 384.82,
                            bookingClass: 'M',
                            baseFare: 6500,
                            taxes: {
                              total: 1058,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1058,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_19DELBOM6E2138_18188342571752@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7181.89,
                            publishedFare: 7319,
                            comission: 137.11,
                            bookingClass: 'R',
                            baseFare: 6500,
                            taxes: {
                              total: 819,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 819,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '659',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '2138',
                          durationInMin: 120,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666247100000,
                          departureDate: '2022-10-20T11:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666254300000,
                          arrrivalDate: '2022-10-20T13:55',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '2138',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666287000000,
                        departure: 1666267500000,
                        numberOfStops: 1,
                        duration: 325,
                      },
                      key: 'UK|879|1666267500000|1666287000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_87DELHYDUK879HYDBOMUK876_18185015203175@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 23263.39,
                            publishedFare: 23700,
                            comission: 436.61,
                            bookingClass: 'Q',
                            baseFare: 21940,
                            taxes: {
                              total: 1760,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1110,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_87DELHYDUK879HYDBOMUK876_18185015202935@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 26467.54,
                            publishedFare: 26934,
                            comission: 466.46,
                            bookingClass: 'U',
                            baseFare: 23440,
                            taxes: {
                              total: 3494,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 2844,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '278',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '879',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666267500000,
                          departureDate: '2022-10-20T17:35',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666275600000,
                          arrrivalDate: '2022-10-20T19:50',
                        },
                        {
                          id: '279',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '876',
                          durationInMin: 85,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666281900000,
                          departureDate: '2022-10-20T21:35',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666287000000,
                          arrrivalDate: '2022-10-20T23:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '879',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 59,
                      details: {
                        arrival: 1666237800000,
                        departure: 1666229100000,
                        numberOfStops: 0,
                        duration: 145,
                      },
                      key: 'SG|8703|1666229100000|1666237800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '4-2158784108_0DELBOMSG8703_18183742802632@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 59,
                            offeredFare: 4903.52,
                            publishedFare: 4999,
                            comission: 95.48,
                            bookingClass: 'DD',
                            baseFare: 4265,
                            taxes: {
                              total: 734,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 514,
                                'Management Fees': 0,
                                'Gst Charges': 220,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_0DELBOMSG8703_18183742803322@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 178,
                            offeredFare: 6049.56,
                            publishedFare: 6170,
                            comission: 120.44,
                            bookingClass: 'SA',
                            baseFare: 5380,
                            taxes: {
                              total: 790,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 514,
                                'Management Fees': 0,
                                'Gst Charges': 276,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_0DELBOMSG8703_18183742802022@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 178,
                            offeredFare: 5721.03,
                            publishedFare: 6042,
                            comission: 320.97,
                            bookingClass: 'CP',
                            baseFare: 5080,
                            taxes: {
                              total: 962,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 692,
                                'Management Fees': 0,
                                'Gst Charges': 270,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_0DELBOMSG8703_18183742803562@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 178,
                            offeredFare: 6474.06,
                            publishedFare: 7319,
                            comission: 844.94,
                            bookingClass: 'HR',
                            baseFare: 5075,
                            taxes: {
                              total: 2244,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1984,
                                'Management Fees': 0,
                                'Gst Charges': 260,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '11-2158784108_3DELBOMSG8703_18181209944579@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'GROUP',
                          message:
                            'Travel on a group ticket, Non Refundable, Paid -  Seats &  Meals.',
                          typeKey: 'OFFER_FARE_WITHOUT_PNR',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'NONREFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 15,
                            offeredFare: 4750,
                            publishedFare: 4750,
                            bookingClass: 'G',
                            baseFare: 4450,
                            taxes: {
                              total: 300,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 300,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '512',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 0,
                          flightNumber: '8703',
                          durationInMin: 145,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666229100000,
                          departureDate: '2022-10-20T06:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666237800000,
                          arrrivalDate: '2022-10-20T09:20',
                        },
                      ],
                      operator: {
                        name: 'SpiceJet',
                        code: 'SG',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/SG.png',
                        number: '8703',
                        equipment: '737',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666280100000,
                        departure: 1666249800000,
                        numberOfStops: 1,
                        duration: 505,
                      },
                      key: 'G8|165|1666249800000|1666280100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_12DELPATG8165PATBOMG8352_18183418307826@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 23142.05,
                            publishedFare: 23804,
                            comission: 661.95,
                            bookingClass: 'SP',
                            baseFare: 22176,
                            taxes: {
                              total: 1628,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1628,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_12DELPATG8165PATBOMG8352_18183418307606@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 23634.03,
                            publishedFare: 25241,
                            comission: 1606.97,
                            bookingClass: 'BC',
                            baseFare: 22747,
                            taxes: {
                              total: 2494,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 2494,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_12DELPATG8165PATBOMG8352_18183418308106@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 23958.17,
                            publishedFare: 24644,
                            comission: 685.83,
                            bookingClass: 'GS',
                            baseFare: 22976,
                            taxes: {
                              total: 1668,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1668,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '218',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 0,
                          flightNumber: '165',
                          durationInMin: 90,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666249800000,
                          departureDate: '2022-10-20T12:40',
                          arrival: {
                            code: 'PAT',
                            airport: 'Jai Prakash Narayan Arpt',
                            city: 'Patna',
                            country: 'India',
                          },
                          arrivalTime: 1666255200000,
                          arrrivalDate: '2022-10-20T14:10',
                        },
                        {
                          id: '219',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 1,
                          flightNumber: '352',
                          durationInMin: 155,
                          departure: {
                            code: 'PAT',
                            airport: 'Jai Prakash Narayan Arpt',
                            city: 'Patna',
                            country: 'India',
                          },
                          departureTime: 1666270800000,
                          departureDate: '2022-10-20T18:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666280100000,
                          arrrivalDate: '2022-10-20T21:05',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '165',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 8,
                      details: {
                        arrival: 1666288800000,
                        departure: 1666242000000,
                        numberOfStops: 1,
                        duration: 780,
                      },
                      key: 'UK|787|1666242000000|1666288800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_71DELBBIUK787BBIBOMUK546_18185015171394@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 20070.08,
                            publishedFare: 20445,
                            comission: 374.92,
                            bookingClass: 'A',
                            baseFare: 18840,
                            taxes: {
                              total: 1605,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 955,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_71DELBBIUK787BBIBOMUK546_18185015171194@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 19426.9,
                            publishedFare: 19766,
                            comission: 339.1,
                            bookingClass: 'T',
                            baseFare: 17040,
                            taxes: {
                              total: 2726,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 2076,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '34',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '787',
                          durationInMin: 115,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666242000000,
                          departureDate: '2022-10-20T10:30',
                          arrival: {
                            code: 'BBI',
                            airport: 'Biju Patnaik Arpt',
                            city: 'Bhubaneswar',
                            country: 'India',
                          },
                          arrivalTime: 1666248900000,
                          arrrivalDate: '2022-10-20T12:25',
                        },
                        {
                          id: '35',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '546',
                          durationInMin: 140,
                          departure: {
                            code: 'BBI',
                            airport: 'Biju Patnaik Arpt',
                            city: 'Bhubaneswar',
                            country: 'India',
                          },
                          departureTime: 1666280400000,
                          departureDate: '2022-10-20T21:10',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666288800000,
                          arrrivalDate: '2022-10-20T23:30',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '787',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666293000000,
                        departure: 1666273200000,
                        numberOfStops: 1,
                        duration: 330,
                      },
                      key: 'I5|857|1666273200000|1666293000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_15DELBLRI5857BLRBOMI5334_18182007861327@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 23354.99,
                            publishedFare: 23685,
                            comission: 330.01,
                            bookingClass: 'SM',
                            baseFare: 22111,
                            taxes: {
                              total: 1574,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 1110,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_15DELBLRI5857BLRBOMI5334_18182007861757@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PREMIUM_FLEX',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 24443.28,
                            publishedFare: 24789,
                            comission: 345.72,
                            bookingClass: 'HF',
                            baseFare: 23163,
                            taxes: {
                              total: 1626,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 1162,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '795',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '857',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666273200000,
                          departureDate: '2022-10-20T19:10',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666283100000,
                          arrrivalDate: '2022-10-20T21:55',
                        },
                        {
                          id: '796',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '334',
                          durationInMin: 105,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666286700000,
                          departureDate: '2022-10-20T22:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666293000000,
                          arrrivalDate: '2022-10-21T00:40',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '857',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666237200000,
                        departure: 1666229400000,
                        numberOfStops: 0,
                        duration: 130,
                      },
                      key: 'G8|334|1666229400000|1666237200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_0DELBOMG8334_18183418267523@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 5728.62,
                            publishedFare: 5881,
                            comission: 152.38,
                            bookingClass: 'SP',
                            baseFare: 5105,
                            taxes: {
                              total: 776,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 776,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_0DELBOMG8334_18183418268103@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'GOMORE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 15490.95,
                            publishedFare: 15929,
                            comission: 438.05,
                            bookingClass: 'MG',
                            baseFare: 14675,
                            taxes: {
                              total: 1254,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1254,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_0DELBOMG8334_18183418267183@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7034.1,
                            publishedFare: 7487,
                            comission: 452.9,
                            bookingClass: 'BC',
                            baseFare: 6411,
                            taxes: {
                              total: 1076,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1076,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_0DELBOMG8334_18183418267793@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7125.72,
                            publishedFare: 7319,
                            comission: 193.28,
                            bookingClass: 'GS',
                            baseFare: 6475,
                            taxes: {
                              total: 844,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 844,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '11-2158784108_1DELBOMG8334_18181209943319@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'GROUP',
                          message:
                            'Travel on a group ticket, Non Refundable, Paid -  Seats &  Meals.',
                          typeKey: 'OFFER_FARE_WITHOUT_PNR',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'NONREFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 5300,
                            publishedFare: 5300,
                            bookingClass: 'G',
                            baseFare: 5000,
                            taxes: {
                              total: 300,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 300,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '68',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 0,
                          flightNumber: '334',
                          durationInMin: 130,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666229400000,
                          departureDate: '2022-10-20T07:00',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666237200000,
                          arrrivalDate: '2022-10-20T09:10',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '334',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666256400000,
                        departure: 1666230600000,
                        numberOfStops: 1,
                        duration: 430,
                      },
                      key: 'UK|829|1666230600000|1666256400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_29DELHYDUK829HYDBOMUK878_18185015089670@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 11828.3,
                            publishedFare: 12044,
                            comission: 215.7,
                            bookingClass: 'Q',
                            baseFare: 10839,
                            taxes: {
                              total: 1205,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 555,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_29DELHYDUK829HYDBOMUK878_18185015088730@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 10180.14,
                            publishedFare: 10364,
                            comission: 183.86,
                            bookingClass: 'Q',
                            baseFare: 9239,
                            taxes: {
                              total: 1125,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 475,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '788',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '829',
                          durationInMin: 125,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666230600000,
                          departureDate: '2022-10-20T07:20',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666238100000,
                          arrrivalDate: '2022-10-20T09:25',
                        },
                        {
                          id: '789',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '878',
                          durationInMin: 95,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666250700000,
                          departureDate: '2022-10-20T12:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666256400000,
                          arrrivalDate: '2022-10-20T14:30',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '829',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666224000000,
                        departure: 1666205100000,
                        numberOfStops: 1,
                        duration: 315,
                      },
                      key: '6E|2033|1666205100000|1666224000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_0DELAMD6E2033AMDBOM6E605_18188342533210@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 5039.16,
                            publishedFare: 5298,
                            comission: 258.84,
                            bookingClass: 'M',
                            baseFare: 4372,
                            taxes: {
                              total: 926,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 926,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_0DELAMD6E2033AMDBOM6E605_18188342533970@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 5044.78,
                            publishedFare: 5137,
                            comission: 92.22,
                            bookingClass: 'R',
                            baseFare: 4372,
                            taxes: {
                              total: 765,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 765,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '212',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '2033',
                          durationInMin: 95,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666205100000,
                          departureDate: '2022-10-20T00:15',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666210800000,
                          arrrivalDate: '2022-10-20T01:50',
                        },
                        {
                          id: '213',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '605',
                          durationInMin: 65,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666220100000,
                          departureDate: '2022-10-20T04:25',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666224000000,
                          arrrivalDate: '2022-10-20T05:30',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '2033',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666266600000,
                        departure: 1666224900000,
                        numberOfStops: 1,
                        duration: 695,
                      },
                      key: 'SG|3701|1666224900000|1666266600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '4-2158784108_4DELRAJSG3701RAJBOMSG2902_18183742810562@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 19251.92,
                            publishedFare: 19660,
                            comission: 408.08,
                            bookingClass: 'DD',
                            baseFare: 18228,
                            taxes: {
                              total: 1432,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 514,
                                'Management Fees': 0,
                                'Gst Charges': 918,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_4DELRAJSG3701RAJBOMSG2902_18183742811102@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 21774.93,
                            publishedFare: 22238,
                            comission: 463.07,
                            bookingClass: 'SA',
                            baseFare: 20684,
                            taxes: {
                              total: 1554,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 514,
                                'Management Fees': 0,
                                'Gst Charges': 1040,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_4DELRAJSG3701RAJBOMSG2902_18183742810212@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 19849.86,
                            publishedFare: 21043,
                            comission: 1193.14,
                            bookingClass: 'CP',
                            baseFare: 18884,
                            taxes: {
                              total: 2159,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1175,
                                'Management Fees': 0,
                                'Gst Charges': 984,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_4DELRAJSG3701RAJBOMSG2902_18183742811272@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 19811.9,
                            publishedFare: 20601,
                            comission: 789.1,
                            bookingClass: 'HR',
                            baseFare: 18403,
                            taxes: {
                              total: 2198,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1272,
                                'Management Fees': 0,
                                'Gst Charges': 926,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '512',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 0,
                          flightNumber: '3701',
                          durationInMin: 120,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666224900000,
                          departureDate: '2022-10-20T05:45',
                          arrival: {
                            code: 'RAJ',
                            airport: 'Rajkot Civil Arpt',
                            city: 'Rajkot',
                            country: 'India',
                          },
                          arrivalTime: 1666232100000,
                          arrrivalDate: '2022-10-20T07:45',
                        },
                        {
                          id: '513',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 1,
                          flightNumber: '2902',
                          durationInMin: 70,
                          departure: {
                            code: 'RAJ',
                            airport: 'Rajkot Civil Arpt',
                            city: 'Rajkot',
                            country: 'India',
                          },
                          departureTime: 1666262400000,
                          departureDate: '2022-10-20T16:10',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666266600000,
                          arrrivalDate: '2022-10-20T17:20',
                        },
                      ],
                      operator: {
                        name: 'SpiceJet',
                        code: 'SG',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/SG.png',
                        number: '3701',
                        equipment: 'DH8',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666269300000,
                        departure: 1666252200000,
                        numberOfStops: 1,
                        duration: 285,
                      },
                      key: 'AI|471|1666252200000|1666269300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_27DELUDRAI471UDRBOMAI644_18185015083969@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 9667.67,
                            publishedFare: 9840,
                            comission: 172.33,
                            bookingClass: 'G',
                            baseFare: 8660,
                            taxes: {
                              total: 1180,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 450,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '403',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '471',
                          durationInMin: 75,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666252200000,
                          departureDate: '2022-10-20T13:20',
                          arrival: {
                            code: 'UDR',
                            airport: 'Maharana Pratap Arpt',
                            city: 'Udaipur',
                            country: 'India',
                          },
                          arrivalTime: 1666256700000,
                          arrrivalDate: '2022-10-20T14:35',
                        },
                        {
                          id: '404',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '644',
                          durationInMin: 95,
                          departure: {
                            code: 'UDR',
                            airport: 'Maharana Pratap Arpt',
                            city: 'Udaipur',
                            country: 'India',
                          },
                          departureTime: 1666263600000,
                          departureDate: '2022-10-20T16:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666269300000,
                          arrrivalDate: '2022-10-20T18:05',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '471',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666326000000,
                        departure: 1666281600000,
                        numberOfStops: 1,
                        duration: 740,
                      },
                      key: 'AI|839|1666281600000|1666326000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_35DELHYDAI839HYDBOMAI616_18185015101570@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 10182.72,
                            publishedFare: 10365,
                            comission: 182.28,
                            bookingClass: 'S',
                            baseFare: 9160,
                            taxes: {
                              total: 1205,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 475,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '502',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '839',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666281600000,
                          departureDate: '2022-10-20T21:30',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666289700000,
                          arrrivalDate: '2022-10-20T23:45',
                        },
                        {
                          id: '503',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '616',
                          durationInMin: 100,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666320000000,
                          departureDate: '2022-10-21T08:10',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666326000000,
                          arrrivalDate: '2022-10-21T09:50',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '839',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666291500000,
                        departure: 1666240200000,
                        numberOfStops: 1,
                        duration: 855,
                      },
                      key: 'G8|7136|1666240200000|1666291500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_9DELBLRG87136BLRBOMG8399_18183418296245@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 13576.95,
                            publishedFare: 13959,
                            comission: 382.05,
                            bookingClass: 'SP',
                            baseFare: 12799,
                            taxes: {
                              total: 1160,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1160,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_9DELBLRG87136BLRBOMG8399_18183418296746@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'GOMORE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 30046.09,
                            publishedFare: 30910,
                            comission: 863.91,
                            bookingClass: 'MG',
                            baseFare: 28942,
                            taxes: {
                              total: 1968,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1968,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_9DELBLRG87136BLRBOMG8399_18183418295805@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 16365.44,
                            publishedFare: 17467,
                            comission: 1101.56,
                            bookingClass: 'BC',
                            baseFare: 15593,
                            taxes: {
                              total: 1874,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1874,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_9DELBLRG87136BLRBOMG8399_18183418296485@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 15716.33,
                            publishedFare: 16161,
                            comission: 444.67,
                            bookingClass: 'GS',
                            baseFare: 14897,
                            taxes: {
                              total: 1264,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1264,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '430',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 0,
                          flightNumber: '7136',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666240200000,
                          departureDate: '2022-10-20T10:00',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666250100000,
                          arrrivalDate: '2022-10-20T12:45',
                        },
                        {
                          id: '431',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 1,
                          flightNumber: '399',
                          durationInMin: 110,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666284900000,
                          departureDate: '2022-10-20T22:25',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666291500000,
                          arrrivalDate: '2022-10-21T00:15',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '7136',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 21,
                      details: {
                        arrival: 1666283400000,
                        departure: 1666275300000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: 'SG|8169|1666275300000|1666283400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '4-2158784108_2DELBOMSG8169_18183742806572@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 21,
                            offeredFare: 5551.37,
                            publishedFare: 5661,
                            comission: 109.63,
                            bookingClass: 'DD',
                            baseFare: 4897,
                            taxes: {
                              total: 764,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 514,
                                'Management Fees': 0,
                                'Gst Charges': 250,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_2DELBOMSG8169_18183742807012@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 21,
                            offeredFare: 6049.56,
                            publishedFare: 6170,
                            comission: 120.44,
                            bookingClass: 'SA',
                            baseFare: 5380,
                            taxes: {
                              total: 790,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 514,
                                'Management Fees': 0,
                                'Gst Charges': 276,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_2DELBOMSG8169_18183742806262@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 21,
                            offeredFare: 5721.03,
                            publishedFare: 6042,
                            comission: 320.97,
                            bookingClass: 'CP',
                            baseFare: 5080,
                            taxes: {
                              total: 962,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 692,
                                'Management Fees': 0,
                                'Gst Charges': 270,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_2DELBOMSG8169_18183742807412@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 21,
                            offeredFare: 6474.06,
                            publishedFare: 7319,
                            comission: 844.94,
                            bookingClass: 'HR',
                            baseFare: 5075,
                            taxes: {
                              total: 2244,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1984,
                                'Management Fees': 0,
                                'Gst Charges': 260,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '11-2158784108_2DELBOMSG8169_18181209943989@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'GROUP',
                          message:
                            'Travel on a group ticket, Non Refundable, Paid -  Seats &  Meals.',
                          typeKey: 'OFFER_FARE_WITHOUT_PNR',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'NONREFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 6,
                            offeredFare: 6000,
                            publishedFare: 6000,
                            bookingClass: 'G',
                            baseFare: 5700,
                            taxes: {
                              total: 300,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 300,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '659',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 0,
                          flightNumber: '8169',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666275300000,
                          departureDate: '2022-10-20T19:45',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666283400000,
                          arrrivalDate: '2022-10-20T22:00',
                        },
                      ],
                      operator: {
                        name: 'SpiceJet',
                        code: 'SG',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/SG.png',
                        number: '8169',
                        equipment: '737',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666232700000,
                        departure: 1666225500000,
                        numberOfStops: 0,
                        duration: 120,
                      },
                      key: 'UK|975|1666225500000|1666232700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_0DELBOMUK975_18185014993614@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7191.25,
                            publishedFare: 7320,
                            comission: 128.75,
                            bookingClass: 'Q',
                            baseFare: 6470,
                            taxes: {
                              total: 850,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 330,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_0DELBOMUK975_18185014990644@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6161.15,
                            publishedFare: 6270,
                            comission: 108.85,
                            bookingClass: 'Q',
                            baseFare: 5470,
                            taxes: {
                              total: 800,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '890',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '975',
                          durationInMin: 120,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666225500000,
                          departureDate: '2022-10-20T05:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666232700000,
                          arrrivalDate: '2022-10-20T07:55',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '975',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666285200000,
                        departure: 1666240200000,
                        numberOfStops: 1,
                        duration: 750,
                      },
                      key: 'G8|7136|1666240200000|1666285200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_8DELBLRG87136BLRBOMG8320_18183418292905@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 18824.43,
                            publishedFare: 19360,
                            comission: 535.57,
                            bookingClass: 'SP',
                            baseFare: 17942,
                            taxes: {
                              total: 1418,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1418,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_8DELBLRG87136BLRBOMG8320_18183418293375@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'GOMORE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 30046.09,
                            publishedFare: 30910,
                            comission: 863.91,
                            bookingClass: 'MG',
                            baseFare: 28942,
                            taxes: {
                              total: 1968,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1968,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_8DELBLRG87136BLRBOMG8320_18183418292435@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 22206.36,
                            publishedFare: 23714,
                            comission: 1507.64,
                            bookingClass: 'BC',
                            baseFare: 21341,
                            taxes: {
                              total: 2373,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 2373,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_8DELBLRG87136BLRBOMG8320_18183418293135@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 21328.15,
                            publishedFare: 21937,
                            comission: 608.85,
                            bookingClass: 'GS',
                            baseFare: 20397,
                            taxes: {
                              total: 1540,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1540,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '553',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 0,
                          flightNumber: '7136',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666240200000,
                          departureDate: '2022-10-20T10:00',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666250100000,
                          arrrivalDate: '2022-10-20T12:45',
                        },
                        {
                          id: '554',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 1,
                          flightNumber: '320',
                          durationInMin: 105,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666278900000,
                          departureDate: '2022-10-20T20:45',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666285200000,
                          arrrivalDate: '2022-10-20T22:30',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '7136',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666326000000,
                        departure: 1666266300000,
                        numberOfStops: 1,
                        duration: 995,
                      },
                      key: 'AI|544|1666266300000|1666326000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_34DELHYDAI544HYDBOMAI616_18185015100230@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 10182.72,
                            publishedFare: 10365,
                            comission: 182.28,
                            bookingClass: 'S',
                            baseFare: 9160,
                            taxes: {
                              total: 1205,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 475,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '689',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '544',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666266300000,
                          departureDate: '2022-10-20T17:15',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666274400000,
                          arrrivalDate: '2022-10-20T19:30',
                        },
                        {
                          id: '690',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '616',
                          durationInMin: 100,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666320000000,
                          departureDate: '2022-10-21T08:10',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666326000000,
                          arrrivalDate: '2022-10-21T09:50',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '544',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 119,
                      details: {
                        arrival: 1666242600000,
                        departure: 1666234200000,
                        numberOfStops: 0,
                        duration: 140,
                      },
                      key: 'SG|8701|1666234200000|1666242600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '4-2158784108_1DELBOMSG8701_18183742804692@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 119,
                            offeredFare: 5551.37,
                            publishedFare: 5661,
                            comission: 109.63,
                            bookingClass: 'DD',
                            baseFare: 4897,
                            taxes: {
                              total: 764,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 514,
                                'Management Fees': 0,
                                'Gst Charges': 250,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_1DELBOMSG8701_18183742805382@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 119,
                            offeredFare: 6049.56,
                            publishedFare: 6170,
                            comission: 120.44,
                            bookingClass: 'SA',
                            baseFare: 5380,
                            taxes: {
                              total: 790,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 514,
                                'Management Fees': 0,
                                'Gst Charges': 276,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_1DELBOMSG8701_18183742804312@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 119,
                            offeredFare: 5721.03,
                            publishedFare: 6042,
                            comission: 320.97,
                            bookingClass: 'CP',
                            baseFare: 5080,
                            taxes: {
                              total: 962,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 692,
                                'Management Fees': 0,
                                'Gst Charges': 270,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_1DELBOMSG8701_18183742805752@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 119,
                            offeredFare: 6474.06,
                            publishedFare: 7319,
                            comission: 844.94,
                            bookingClass: 'HR',
                            baseFare: 5075,
                            taxes: {
                              total: 2244,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1984,
                                'Management Fees': 0,
                                'Gst Charges': 260,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '16',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 0,
                          flightNumber: '8701',
                          durationInMin: 140,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666234200000,
                          departureDate: '2022-10-20T08:20',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666242600000,
                          arrrivalDate: '2022-10-20T10:40',
                        },
                      ],
                      operator: {
                        name: 'SpiceJet',
                        code: 'SG',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/SG.png',
                        number: '8701',
                        equipment: '7M8',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666319700000,
                        departure: 1666242900000,
                        numberOfStops: 1,
                        duration: 1280,
                      },
                      key: 'AI|885|1666242900000|1666319700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_44DELGOIAI885GOIBOMAI684_18185015117911@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 10491.75,
                            publishedFare: 10680,
                            comission: 188.25,
                            bookingClass: 'S',
                            baseFare: 9460,
                            taxes: {
                              total: 1220,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 490,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '652',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '885',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666242900000,
                          departureDate: '2022-10-20T10:45',
                          arrival: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          arrivalTime: 1666253100000,
                          arrrivalDate: '2022-10-20T13:35',
                        },
                        {
                          id: '653',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '684',
                          durationInMin: 75,
                          departure: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          departureTime: 1666315200000,
                          departureDate: '2022-10-21T06:50',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666319700000,
                          arrrivalDate: '2022-10-21T08:05',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '885',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666258200000,
                        departure: 1666223700000,
                        numberOfStops: 1,
                        duration: 575,
                      },
                      key: 'G8|719|1666223700000|1666258200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_6DELAMDG8719AMDBOMG82505_18183418280174@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 5018.36,
                            publishedFare: 5150,
                            comission: 131.64,
                            bookingClass: 'SP',
                            baseFare: 4410,
                            taxes: {
                              total: 740,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 740,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_6DELAMDG8719AMDBOMG82505_18183418280584@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'GOMORE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 28013.55,
                            publishedFare: 28818,
                            comission: 804.45,
                            bookingClass: 'MG',
                            baseFare: 26950,
                            taxes: {
                              total: 1868,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1868,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_6DELAMDG8719AMDBOMG82505_18183418279694@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6032.82,
                            publishedFare: 6416,
                            comission: 383.18,
                            bookingClass: 'BC',
                            baseFare: 5424,
                            taxes: {
                              total: 992,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 992,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_6DELAMDG8719AMDBOMG82505_18183418280374@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 5325.41,
                            publishedFare: 5466,
                            comission: 140.59,
                            bookingClass: 'GS',
                            baseFare: 4710,
                            taxes: {
                              total: 756,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 756,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '697',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 0,
                          flightNumber: '719',
                          durationInMin: 95,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666223700000,
                          departureDate: '2022-10-20T05:25',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666229400000,
                          arrrivalDate: '2022-10-20T07:00',
                        },
                        {
                          id: '698',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 1,
                          flightNumber: '2505',
                          durationInMin: 70,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666254000000,
                          departureDate: '2022-10-20T13:50',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666258200000,
                          arrrivalDate: '2022-10-20T15:00',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '719',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666325400000,
                        departure: 1666269600000,
                        numberOfStops: 1,
                        duration: 930,
                      },
                      key: 'UK|969|1666269600000|1666325400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_28DELAMDUK969AMDBOMUK936_18185015086730@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 9491.01,
                            publishedFare: 10171,
                            comission: 679.99,
                            bookingClass: 'O',
                            baseFare: 9055,
                            taxes: {
                              total: 1116,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 466,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_28DELAMDUK969AMDBOMUK936_18185015086110@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 9491.01,
                            publishedFare: 10171,
                            comission: 679.99,
                            bookingClass: 'O',
                            baseFare: 9055,
                            taxes: {
                              total: 1116,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 466,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '893',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '969',
                          durationInMin: 105,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666269600000,
                          departureDate: '2022-10-20T18:10',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666275900000,
                          arrrivalDate: '2022-10-20T19:55',
                        },
                        {
                          id: '894',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '936',
                          durationInMin: 75,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666320900000,
                          departureDate: '2022-10-21T08:25',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666325400000,
                          arrrivalDate: '2022-10-21T09:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '969',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666323000000,
                        departure: 1666266900000,
                        numberOfStops: 1,
                        duration: 935,
                      },
                      key: 'UK|837|1666266900000|1666323000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_104DELMAAUK837MAABOMUK828_18185015235157@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 15981.09,
                            publishedFare: 16277,
                            comission: 295.91,
                            bookingClass: 'K',
                            baseFare: 14870,
                            taxes: {
                              total: 1407,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 757,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_104DELMAAUK837MAABOMUK828_18185015234927@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 34993.32,
                            publishedFare: 35614,
                            comission: 620.68,
                            bookingClass: 'P',
                            baseFare: 31190,
                            taxes: {
                              total: 4424,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 3774,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '237',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '837',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666266900000,
                          departureDate: '2022-10-20T17:25',
                          arrival: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          arrivalTime: 1666277100000,
                          arrrivalDate: '2022-10-20T20:15',
                        },
                        {
                          id: '238',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '828',
                          durationInMin: 120,
                          departure: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          departureTime: 1666315800000,
                          departureDate: '2022-10-21T07:00',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666323000000,
                          arrrivalDate: '2022-10-21T09:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '837',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666272900000,
                        departure: 1666265400000,
                        numberOfStops: 0,
                        duration: 125,
                      },
                      key: '6E|5318|1666265400000|1666272900000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_8DELBOM6E5318_18188342553071@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6128.49,
                            publishedFare: 6244,
                            comission: 115.51,
                            bookingClass: 'T',
                            baseFare: 5476,
                            taxes: {
                              total: 768,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 768,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_8DELBOM6E5318_18188342552641@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7173.18,
                            publishedFare: 7558,
                            comission: 384.82,
                            bookingClass: 'M',
                            baseFare: 6500,
                            taxes: {
                              total: 1058,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1058,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_8DELBOM6E5318_18188342553291@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7181.89,
                            publishedFare: 7319,
                            comission: 137.11,
                            bookingClass: 'R',
                            baseFare: 6500,
                            taxes: {
                              total: 819,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 819,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '939',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '5318',
                          durationInMin: 125,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666265400000,
                          departureDate: '2022-10-20T17:00',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666272900000,
                          arrrivalDate: '2022-10-20T19:05',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '5318',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666289700000,
                        departure: 1666250400000,
                        numberOfStops: 1,
                        duration: 655,
                      },
                      key: 'I5|798|1666250400000|1666289700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_2DELGOII5798GOIBOMI5472_18182007826244@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7299.5,
                            publishedFare: 7398,
                            comission: 98.5,
                            bookingClass: 'SM',
                            baseFare: 6600,
                            taxes: {
                              total: 798,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 334,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_2DELGOII5798GOIBOMI5472_18182007827124@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'PROMO',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 10477.69,
                            publishedFare: 10622,
                            comission: 144.31,
                            bookingClass: 'BT',
                            baseFare: 9670,
                            taxes: {
                              total: 952,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 488,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_2DELGOII5798GOIBOMI5472_18182007826464@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7299.5,
                            publishedFare: 7398,
                            comission: 98.5,
                            bookingClass: 'EC',
                            baseFare: 6600,
                            taxes: {
                              total: 798,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 334,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '722',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '798',
                          durationInMin: 155,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666250400000,
                          departureDate: '2022-10-20T12:50',
                          arrival: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          arrivalTime: 1666259700000,
                          arrrivalDate: '2022-10-20T15:25',
                        },
                        {
                          id: '723',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '472',
                          durationInMin: 75,
                          departure: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          departureTime: 1666285200000,
                          departureDate: '2022-10-20T22:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666289700000,
                          arrrivalDate: '2022-10-20T23:45',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '798',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666293000000,
                        departure: 1666274700000,
                        numberOfStops: 1,
                        duration: 305,
                      },
                      key: '6E|153|1666274700000|1666293000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_35DELAMD6E153AMDBOM6E6794_18188342618495@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 17822.62,
                            publishedFare: 18818,
                            comission: 995.38,
                            bookingClass: 'M',
                            baseFare: 16813,
                            taxes: {
                              total: 2005,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 2005,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_35DELAMD6E153AMDBOM6E6794_18188342618925@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 17845.34,
                            publishedFare: 18200,
                            comission: 354.66,
                            bookingClass: 'R',
                            baseFare: 16813,
                            taxes: {
                              total: 1387,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1387,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '428',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '153',
                          durationInMin: 95,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666274700000,
                          departureDate: '2022-10-20T19:35',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666280400000,
                          arrrivalDate: '2022-10-20T21:10',
                        },
                        {
                          id: '429',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '6794',
                          durationInMin: 75,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666288500000,
                          departureDate: '2022-10-20T23:25',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666293000000,
                          arrrivalDate: '2022-10-21T00:40',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '153',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666342800000,
                        departure: 1666278300000,
                        numberOfStops: 1,
                        duration: 1075,
                      },
                      key: 'UK|871|1666278300000|1666342800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_51DELHYDUK871HYDBOMUK878_18185015131902@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 12344.33,
                            publishedFare: 12570,
                            comission: 225.67,
                            bookingClass: 'Q',
                            baseFare: 11340,
                            taxes: {
                              total: 1230,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 580,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_51DELHYDUK871HYDBOMUK878_18185015131442@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 11314.23,
                            publishedFare: 11520,
                            comission: 205.77,
                            bookingClass: 'Q',
                            baseFare: 10340,
                            taxes: {
                              total: 1180,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 530,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '477',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '871',
                          durationInMin: 140,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666278300000,
                          departureDate: '2022-10-20T20:35',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666286700000,
                          arrrivalDate: '2022-10-20T22:55',
                        },
                        {
                          id: '478',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '878',
                          durationInMin: 95,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666337100000,
                          departureDate: '2022-10-21T12:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666342800000,
                          arrrivalDate: '2022-10-21T14:30',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '871',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666313100000,
                        departure: 1666281000000,
                        numberOfStops: 1,
                        duration: 535,
                      },
                      key: 'UK|929|1666281000000|1666313100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_22DELAMDUK929AMDBOMUK918_18185015068079@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 7451.86,
                            publishedFare: 7583,
                            comission: 131.14,
                            bookingClass: 'V',
                            baseFare: 6590,
                            taxes: {
                              total: 993,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 343,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_22DELAMDUK929AMDBOMUK918_18185015066209@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 7451.86,
                            publishedFare: 7583,
                            comission: 131.14,
                            bookingClass: 'V',
                            baseFare: 6590,
                            taxes: {
                              total: 993,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 343,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '74',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '929',
                          durationInMin: 100,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666281000000,
                          departureDate: '2022-10-20T21:20',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666287000000,
                          arrrivalDate: '2022-10-20T23:00',
                        },
                        {
                          id: '75',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '918',
                          durationInMin: 70,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666308900000,
                          departureDate: '2022-10-21T05:05',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666313100000,
                          arrrivalDate: '2022-10-21T06:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '929',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 8,
                      details: {
                        arrival: 1666249500000,
                        departure: 1666241400000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: 'UK|995|1666241400000|1666249500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_6DELBOMUK995_18185015015635@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7191.25,
                            publishedFare: 7320,
                            comission: 128.75,
                            bookingClass: 'Q',
                            baseFare: 6470,
                            taxes: {
                              total: 850,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 330,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_6DELBOMUK995_18185015012625@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6161.15,
                            publishedFare: 6270,
                            comission: 108.85,
                            bookingClass: 'Q',
                            baseFare: 5470,
                            taxes: {
                              total: 800,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '929',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '995',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666241400000,
                          departureDate: '2022-10-20T10:20',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666249500000,
                          arrrivalDate: '2022-10-20T12:35',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '995',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666290900000,
                        departure: 1666272300000,
                        numberOfStops: 1,
                        duration: 310,
                      },
                      key: '6E|761|1666272300000|1666290900000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_32DELBHO6E761BHOBOM6E983_18188342610395@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 15494.72,
                            publishedFare: 16356,
                            comission: 861.28,
                            bookingClass: 'M',
                            baseFare: 14548,
                            taxes: {
                              total: 1808,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1808,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_32DELBHO6E761BHOBOM6E983_18188342610845@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 14565.6,
                            publishedFare: 14853,
                            comission: 287.4,
                            bookingClass: 'R',
                            baseFare: 13625,
                            taxes: {
                              total: 1228,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1228,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '621',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '761',
                          durationInMin: 80,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666272300000,
                          departureDate: '2022-10-20T18:55',
                          arrival: {
                            code: 'BHO',
                            airport: 'Raja Bhoj Arpt',
                            city: 'Bhopal',
                            country: 'India',
                          },
                          arrivalTime: 1666277100000,
                          arrrivalDate: '2022-10-20T20:15',
                        },
                        {
                          id: '622',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '983',
                          durationInMin: 115,
                          departure: {
                            code: 'BHO',
                            airport: 'Raja Bhoj Arpt',
                            city: 'Bhopal',
                            country: 'India',
                          },
                          departureTime: 1666284000000,
                          departureDate: '2022-10-20T22:10',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666290900000,
                          arrrivalDate: '2022-10-21T00:05',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '761',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 8,
                      details: {
                        arrival: 1666258200000,
                        departure: 1666230000000,
                        numberOfStops: 1,
                        duration: 470,
                      },
                      key: 'AI|560|1666230000000|1666258200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_33DELHYDAI560HYDBOMAI618_18185015098390@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 10182.72,
                            publishedFare: 10365,
                            comission: 182.28,
                            bookingClass: 'S',
                            baseFare: 9160,
                            taxes: {
                              total: 1205,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 475,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '172',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '560',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666230000000,
                          departureDate: '2022-10-20T07:10',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666238100000,
                          arrrivalDate: '2022-10-20T09:25',
                        },
                        {
                          id: '173',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '618',
                          durationInMin: 105,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666251900000,
                          departureDate: '2022-10-20T13:15',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666258200000,
                          arrrivalDate: '2022-10-20T15:00',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '560',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666282800000,
                        departure: 1666255500000,
                        numberOfStops: 1,
                        duration: 455,
                      },
                      key: 'UK|819|1666255500000|1666282800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_79DELBLRUK819BLRBOMUK850_18185015187304@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 20194.7,
                            publishedFare: 21027,
                            comission: 832.3,
                            bookingClass: 'O',
                            baseFare: 19394,
                            taxes: {
                              total: 1633,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 983,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_79DELBLRUK819BLRBOMUK850_18185015187114@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 23442.27,
                            publishedFare: 23854,
                            comission: 411.73,
                            bookingClass: 'U',
                            baseFare: 20690,
                            taxes: {
                              total: 3164,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 2514,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '254',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '819',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666255500000,
                          departureDate: '2022-10-20T14:15',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666265700000,
                          arrrivalDate: '2022-10-20T17:05',
                        },
                        {
                          id: '255',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '850',
                          durationInMin: 115,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666275900000,
                          departureDate: '2022-10-20T19:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666282800000,
                          arrrivalDate: '2022-10-20T21:50',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '819',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666343700000,
                        departure: 1666244400000,
                        numberOfStops: 1,
                        duration: 1655,
                      },
                      key: 'UK|847|1666244400000|1666343700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_43DELGOIUK847GOIBOMUK842_18185015116011@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 12138.31,
                            publishedFare: 12360,
                            comission: 221.69,
                            bookingClass: 'Q',
                            baseFare: 11140,
                            taxes: {
                              total: 1220,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 570,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_43DELGOIUK847GOIBOMUK842_18185015115481@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 10490.15,
                            publishedFare: 10680,
                            comission: 189.85,
                            bookingClass: 'Q',
                            baseFare: 9540,
                            taxes: {
                              total: 1140,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 490,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '639',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '847',
                          durationInMin: 160,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666244400000,
                          departureDate: '2022-10-20T11:10',
                          arrival: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          arrivalTime: 1666254000000,
                          arrrivalDate: '2022-10-20T13:50',
                        },
                        {
                          id: '640',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '842',
                          durationInMin: 85,
                          departure: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          departureTime: 1666338600000,
                          departureDate: '2022-10-21T13:20',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666343700000,
                          arrrivalDate: '2022-10-21T14:45',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '847',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666277700000,
                        departure: 1666269600000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: '6E|2017|1666269600000|1666277700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_7DELBOM6E2017_18188342551311@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6128.49,
                            publishedFare: 6244,
                            comission: 115.51,
                            bookingClass: 'T',
                            baseFare: 5476,
                            taxes: {
                              total: 768,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 768,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_7DELBOM6E2017_18188342550891@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7173.18,
                            publishedFare: 7558,
                            comission: 384.82,
                            bookingClass: 'M',
                            baseFare: 6500,
                            taxes: {
                              total: 1058,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1058,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_7DELBOM6E2017_18188342551541@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7181.89,
                            publishedFare: 7319,
                            comission: 137.11,
                            bookingClass: 'R',
                            baseFare: 6500,
                            taxes: {
                              total: 819,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 819,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '644',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '2017',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666269600000,
                          departureDate: '2022-10-20T18:10',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666277700000,
                          arrrivalDate: '2022-10-20T20:25',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '2017',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666331700000,
                        departure: 1666269000000,
                        numberOfStops: 1,
                        duration: 1045,
                      },
                      key: 'UK|515|1666269000000|1666331700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_60DELBLRUK515BLRBOMUK852_18185015150673@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 14655.52,
                            publishedFare: 15409,
                            comission: 753.48,
                            bookingClass: 'O',
                            baseFare: 14044,
                            taxes: {
                              total: 1365,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 715,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_60DELBLRUK515BLRBOMUK852_18185015150443@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 14655.52,
                            publishedFare: 15409,
                            comission: 753.48,
                            bookingClass: 'O',
                            baseFare: 14044,
                            taxes: {
                              total: 1365,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 715,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '434',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '515',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666269000000,
                          departureDate: '2022-10-20T18:00',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666278900000,
                          arrrivalDate: '2022-10-20T20:45',
                        },
                        {
                          id: '435',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '852',
                          durationInMin: 115,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666324800000,
                          departureDate: '2022-10-21T09:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666331700000,
                          arrrivalDate: '2022-10-21T11:25',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '515',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666277100000,
                        departure: 1666236900000,
                        numberOfStops: 1,
                        duration: 670,
                      },
                      key: 'UK|801|1666236900000|1666277100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_98DELBLRUK801BLRBOMUK854_18185015223506@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 26509.84,
                            publishedFare: 27432,
                            comission: 922.16,
                            bookingClass: 'O',
                            baseFare: 25494,
                            taxes: {
                              total: 1938,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1288,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_98DELBLRUK801BLRBOMUK854_18185015223306@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 31803.03,
                            publishedFare: 32366,
                            comission: 562.97,
                            bookingClass: 'U',
                            baseFare: 28290,
                            taxes: {
                              total: 4076,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 3426,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '409',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '801',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666236900000,
                          departureDate: '2022-10-20T09:05',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666246800000,
                          arrrivalDate: '2022-10-20T11:50',
                        },
                        {
                          id: '410',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '854',
                          durationInMin: 110,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666270500000,
                          departureDate: '2022-10-20T18:25',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666277100000,
                          arrrivalDate: '2022-10-20T20:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '801',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666317300000,
                        departure: 1666279800000,
                        numberOfStops: 1,
                        duration: 625,
                      },
                      key: 'I5|721|1666279800000|1666317300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_6DELBLRI5721BLRBOMI5941_18182007838075@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 14493.76,
                            publishedFare: 14696,
                            comission: 202.24,
                            bookingClass: 'SM',
                            baseFare: 13550,
                            taxes: {
                              total: 1146,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 682,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_6DELBLRI5721BLRBOMI5941_18182007838505@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PREMIUM_FLEX',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 15425.32,
                            publishedFare: 15641,
                            comission: 215.68,
                            bookingClass: 'HF',
                            baseFare: 14451,
                            taxes: {
                              total: 1190,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 726,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '665',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '721',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666279800000,
                          departureDate: '2022-10-20T21:00',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666290000000,
                          arrrivalDate: '2022-10-20T23:50',
                        },
                        {
                          id: '666',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '941',
                          durationInMin: 110,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666310700000,
                          departureDate: '2022-10-21T05:35',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666317300000,
                          arrrivalDate: '2022-10-21T07:25',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '721',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666283100000,
                        departure: 1666275300000,
                        numberOfStops: 0,
                        duration: 130,
                      },
                      key: '6E|6261|1666275300000|1666283100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_6DELBOM6E6261_18188342549741@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 6128.49,
                            publishedFare: 6244,
                            comission: 115.51,
                            bookingClass: 'T',
                            baseFare: 5476,
                            taxes: {
                              total: 768,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 768,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_6DELBOM6E6261_18188342549331@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 7173.18,
                            publishedFare: 7558,
                            comission: 384.82,
                            bookingClass: 'M',
                            baseFare: 6500,
                            taxes: {
                              total: 1058,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1058,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_6DELBOM6E6261_18188342550031@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 7181.89,
                            publishedFare: 7319,
                            comission: 137.11,
                            bookingClass: 'R',
                            baseFare: 6500,
                            taxes: {
                              total: 819,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 819,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '528',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '6261',
                          durationInMin: 130,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666275300000,
                          departureDate: '2022-10-20T19:45',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666283100000,
                          arrrivalDate: '2022-10-20T21:55',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '6261',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666278600000,
                        departure: 1666233600000,
                        numberOfStops: 1,
                        duration: 750,
                      },
                      key: 'I5|740|1666233600000|1666278600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_14DELBLRI5740BLRBOMI5612_18182007858796@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 22118.82,
                            publishedFare: 22431,
                            comission: 312.18,
                            bookingClass: 'SM',
                            baseFare: 20917,
                            taxes: {
                              total: 1514,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 1050,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_14DELBLRI5740BLRBOMI5612_18182007859016@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'PROMO',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 20593.8,
                            publishedFare: 20884,
                            comission: 290.2,
                            bookingClass: 'BT',
                            baseFare: 19444,
                            taxes: {
                              total: 1440,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 976,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_14DELBLRI5740BLRBOMI5612_18182007859256@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PREMIUM_FLEX',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 23158.81,
                            publishedFare: 23486,
                            comission: 327.19,
                            bookingClass: 'HF',
                            baseFare: 21922,
                            taxes: {
                              total: 1564,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 1100,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '937',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '740',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666233600000,
                          departureDate: '2022-10-20T08:10',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666243500000,
                          arrrivalDate: '2022-10-20T10:55',
                        },
                        {
                          id: '938',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '612',
                          durationInMin: 115,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666271700000,
                          departureDate: '2022-10-20T18:45',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666278600000,
                          arrrivalDate: '2022-10-20T20:40',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '740',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 6,
                      details: {
                        arrival: 1666330200000,
                        departure: 1666242000000,
                        numberOfStops: 1,
                        duration: 1470,
                      },
                      key: 'UK|787|1666242000000|1666330200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_74DELBBIUK787BBIBOMUK542_18185015176864@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 20070.08,
                            publishedFare: 20445,
                            comission: 374.92,
                            bookingClass: 'A',
                            baseFare: 18840,
                            taxes: {
                              total: 1605,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 955,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_74DELBBIUK787BBIBOMUK542_18185015176674@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 6,
                            offeredFare: 19426.9,
                            publishedFare: 19766,
                            comission: 339.1,
                            bookingClass: 'T',
                            baseFare: 17040,
                            taxes: {
                              total: 2726,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 2076,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '958',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '787',
                          durationInMin: 115,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666242000000,
                          departureDate: '2022-10-20T10:30',
                          arrival: {
                            code: 'BBI',
                            airport: 'Biju Patnaik Arpt',
                            city: 'Bhubaneswar',
                            country: 'India',
                          },
                          arrivalTime: 1666248900000,
                          arrrivalDate: '2022-10-20T12:25',
                        },
                        {
                          id: '959',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '542',
                          durationInMin: 145,
                          departure: {
                            code: 'BBI',
                            airport: 'Biju Patnaik Arpt',
                            city: 'Bhubaneswar',
                            country: 'India',
                          },
                          departureTime: 1666321500000,
                          departureDate: '2022-10-21T08:35',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666330200000,
                          arrrivalDate: '2022-10-21T11:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '787',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666320300000,
                        departure: 1666255500000,
                        numberOfStops: 1,
                        duration: 1080,
                      },
                      key: 'UK|819|1666255500000|1666320300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_62DELBLRUK819BLRBOMUK858_18185015155333@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 14655.52,
                            publishedFare: 15409,
                            comission: 753.48,
                            bookingClass: 'O',
                            baseFare: 14044,
                            taxes: {
                              total: 1365,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 715,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_62DELBLRUK819BLRBOMUK858_18185015154883@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 14655.52,
                            publishedFare: 15409,
                            comission: 753.48,
                            bookingClass: 'O',
                            baseFare: 14044,
                            taxes: {
                              total: 1365,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 715,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '233',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '819',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666255500000,
                          departureDate: '2022-10-20T14:15',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666265700000,
                          arrrivalDate: '2022-10-20T17:05',
                        },
                        {
                          id: '234',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '858',
                          durationInMin: 95,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666314600000,
                          departureDate: '2022-10-21T06:40',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666320300000,
                          arrrivalDate: '2022-10-21T08:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '819',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666327500000,
                        departure: 1666275600000,
                        numberOfStops: 1,
                        duration: 865,
                      },
                      key: 'UK|809|1666275600000|1666327500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_109DELBLRUK809BLRBOMUK846_18185015245527@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 37578.55,
                            publishedFare: 38246,
                            comission: 667.45,
                            bookingClass: 'R',
                            baseFare: 33540,
                            taxes: {
                              total: 4706,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 4056,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '54',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '809',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666275600000,
                          departureDate: '2022-10-20T19:50',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666285800000,
                          arrrivalDate: '2022-10-20T22:40',
                        },
                        {
                          id: '55',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '846',
                          durationInMin: 105,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666321200000,
                          departureDate: '2022-10-21T08:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666327500000,
                          arrrivalDate: '2022-10-21T10:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '809',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666316700000,
                        departure: 1666278900000,
                        numberOfStops: 1,
                        duration: 630,
                      },
                      key: 'G8|119|1666278900000|1666316700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_14DELBLRG8119BLRBOMG8385_18183418313656@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 12941.52,
                            publishedFare: 13305,
                            comission: 363.48,
                            bookingClass: 'SP',
                            baseFare: 12177,
                            taxes: {
                              total: 1128,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1128,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_14DELBLRG8119BLRBOMG8385_18183418314116@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'GOMORE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 30046.09,
                            publishedFare: 30910,
                            comission: 863.91,
                            bookingClass: 'MG',
                            baseFare: 28942,
                            taxes: {
                              total: 1968,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1968,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_14DELBLRG8119BLRBOMG8385_18183418313206@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 14087.74,
                            publishedFare: 15031,
                            comission: 943.26,
                            bookingClass: 'BC',
                            baseFare: 13352,
                            taxes: {
                              total: 1679,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1679,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_14DELBLRG8119BLRBOMG8385_18183418313886@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 13553.61,
                            publishedFare: 13935,
                            comission: 381.39,
                            bookingClass: 'GS',
                            baseFare: 12777,
                            taxes: {
                              total: 1158,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1158,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '835',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 0,
                          flightNumber: '119',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666278900000,
                          departureDate: '2022-10-20T20:45',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666288800000,
                          arrrivalDate: '2022-10-20T23:30',
                        },
                        {
                          id: '836',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 1,
                          flightNumber: '385',
                          durationInMin: 90,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666311300000,
                          departureDate: '2022-10-21T05:45',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666316700000,
                          arrrivalDate: '2022-10-21T07:15',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '119',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666289400000,
                        departure: 1666231200000,
                        numberOfStops: 1,
                        duration: 970,
                      },
                      key: 'UK|705|1666231200000|1666289400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_122DELCCUUK705CCUBOMUK774_18185015270080@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 22232.31,
                            publishedFare: 22649,
                            comission: 416.69,
                            bookingClass: 'A',
                            baseFare: 20939,
                            taxes: {
                              total: 1710,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1060,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_122DELCCUUK705CCUBOMUK774_18185015269850@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 45092.64,
                            publishedFare: 45896,
                            comission: 803.36,
                            bookingClass: 'T',
                            baseFare: 40370,
                            taxes: {
                              total: 5526,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 4876,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '460',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '705',
                          durationInMin: 125,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666231200000,
                          departureDate: '2022-10-20T07:30',
                          arrival: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          arrivalTime: 1666238700000,
                          arrrivalDate: '2022-10-20T09:35',
                        },
                        {
                          id: '461',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '774',
                          durationInMin: 175,
                          departure: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          departureTime: 1666278900000,
                          departureDate: '2022-10-20T20:45',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666289400000,
                          arrrivalDate: '2022-10-20T23:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '705',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666317300000,
                        departure: 1666273200000,
                        numberOfStops: 1,
                        duration: 735,
                      },
                      key: 'I5|857|1666273200000|1666317300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_8DELBLRI5857BLRBOMI5941_18182007842416@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 15469.67,
                            publishedFare: 15686,
                            comission: 216.33,
                            bookingClass: 'SM',
                            baseFare: 14494,
                            taxes: {
                              total: 1192,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 728,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_8DELBLRI5857BLRBOMI5941_18182007842866@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PREMIUM_FLEX',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 16352.95,
                            publishedFare: 16582,
                            comission: 229.05,
                            bookingClass: 'HF',
                            baseFare: 15346,
                            taxes: {
                              total: 1236,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 772,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '87',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '857',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666273200000,
                          departureDate: '2022-10-20T19:10',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666283100000,
                          arrrivalDate: '2022-10-20T21:55',
                        },
                        {
                          id: '88',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '941',
                          durationInMin: 110,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666310700000,
                          departureDate: '2022-10-21T05:35',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666317300000,
                          arrrivalDate: '2022-10-21T07:25',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '857',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666330200000,
                        departure: 1666268400000,
                        numberOfStops: 1,
                        duration: 1030,
                      },
                      key: 'UK|781|1666268400000|1666330200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_73DELBBIUK781BBIBOMUK542_18185015175224@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 17494.83,
                            publishedFare: 17820,
                            comission: 325.17,
                            bookingClass: 'N',
                            baseFare: 16340,
                            taxes: {
                              total: 1480,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 830,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_73DELBBIUK781BBIBOMUK542_18185015175034@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 6,
                            offeredFare: 19426.9,
                            publishedFare: 19766,
                            comission: 339.1,
                            bookingClass: 'T',
                            baseFare: 17040,
                            taxes: {
                              total: 2726,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 2076,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '324',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '781',
                          durationInMin: 125,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666268400000,
                          departureDate: '2022-10-20T17:50',
                          arrival: {
                            code: 'BBI',
                            airport: 'Biju Patnaik Arpt',
                            city: 'Bhubaneswar',
                            country: 'India',
                          },
                          arrivalTime: 1666275900000,
                          arrrivalDate: '2022-10-20T19:55',
                        },
                        {
                          id: '325',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '542',
                          durationInMin: 145,
                          departure: {
                            code: 'BBI',
                            airport: 'Biju Patnaik Arpt',
                            city: 'Bhubaneswar',
                            country: 'India',
                          },
                          departureTime: 1666321500000,
                          departureDate: '2022-10-21T08:35',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666330200000,
                          arrrivalDate: '2022-10-21T11:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '781',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 6,
                      details: {
                        arrival: 1666277100000,
                        departure: 1666233600000,
                        numberOfStops: 1,
                        duration: 725,
                      },
                      key: 'UK|815|1666233600000|1666277100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_99DELBLRUK815BLRBOMUK854_18185015225406@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 26509.84,
                            publishedFare: 27432,
                            comission: 922.16,
                            bookingClass: 'O',
                            baseFare: 25494,
                            taxes: {
                              total: 1938,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1288,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_99DELBLRUK815BLRBOMUK854_18185015225166@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 6,
                            offeredFare: 31803.03,
                            publishedFare: 32366,
                            comission: 562.97,
                            bookingClass: 'U',
                            baseFare: 28290,
                            taxes: {
                              total: 4076,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 3426,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '395',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '815',
                          durationInMin: 160,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666233600000,
                          departureDate: '2022-10-20T08:10',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666243200000,
                          arrrivalDate: '2022-10-20T10:50',
                        },
                        {
                          id: '396',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '854',
                          durationInMin: 110,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666270500000,
                          departureDate: '2022-10-20T18:25',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666277100000,
                          arrrivalDate: '2022-10-20T20:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '815',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666288500000,
                        departure: 1666255500000,
                        numberOfStops: 1,
                        duration: 550,
                      },
                      key: 'UK|819|1666255500000|1666288500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_97DELBLRUK819BLRBOMUK866_18185015221686@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 26509.84,
                            publishedFare: 27432,
                            comission: 922.16,
                            bookingClass: 'O',
                            baseFare: 25494,
                            taxes: {
                              total: 1938,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1288,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_97DELBLRUK819BLRBOMUK866_18185015221446@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 31803.03,
                            publishedFare: 32366,
                            comission: 562.97,
                            bookingClass: 'U',
                            baseFare: 28290,
                            taxes: {
                              total: 4076,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 3426,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '401',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '819',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666255500000,
                          departureDate: '2022-10-20T14:15',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666265700000,
                          arrrivalDate: '2022-10-20T17:05',
                        },
                        {
                          id: '402',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '866',
                          durationInMin: 105,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666282200000,
                          departureDate: '2022-10-20T21:40',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666288500000,
                          arrrivalDate: '2022-10-20T23:25',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '819',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666256400000,
                        departure: 1666229400000,
                        numberOfStops: 1,
                        duration: 450,
                      },
                      key: 'UK|833|1666229400000|1666256400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_116DELMAAUK833MAABOMUK826_18185015258118@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 15569.05,
                            publishedFare: 15857,
                            comission: 287.95,
                            bookingClass: 'N',
                            baseFare: 14470,
                            taxes: {
                              total: 1387,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 737,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_116DELMAAUK833MAABOMUK826_18185015257898@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 40142.19,
                            publishedFare: 40856,
                            comission: 713.81,
                            bookingClass: 'T',
                            baseFare: 35870,
                            taxes: {
                              total: 4986,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 4336,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '404',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '833',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666229400000,
                          departureDate: '2022-10-20T07:00',
                          arrival: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          arrivalTime: 1666239600000,
                          arrrivalDate: '2022-10-20T09:50',
                        },
                        {
                          id: '405',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '826',
                          durationInMin: 120,
                          departure: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          departureTime: 1666249200000,
                          departureDate: '2022-10-20T12:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666256400000,
                          arrrivalDate: '2022-10-20T14:30',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '833',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666284000000,
                        departure: 1666268400000,
                        numberOfStops: 1,
                        duration: 260,
                      },
                      key: '6E|2168|1666268400000|1666284000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_22DELIDR6E2168IDRBOM6E6598_18188342579663@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 11952.85,
                            publishedFare: 12610,
                            comission: 657.15,
                            bookingClass: 'M',
                            baseFare: 11100,
                            taxes: {
                              total: 1510,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1510,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_22DELIDR6E2168IDRBOM6E6598_18188342580073@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 11915.91,
                            publishedFare: 12149,
                            comission: 233.09,
                            bookingClass: 'R',
                            baseFare: 11050,
                            taxes: {
                              total: 1099,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1099,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '399',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '2168',
                          durationInMin: 85,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666268400000,
                          departureDate: '2022-10-20T17:50',
                          arrival: {
                            code: 'IDR',
                            airport: 'Devi Ahilya Bai Holkar Arpt',
                            city: 'Indore',
                            country: 'India',
                          },
                          arrivalTime: 1666273500000,
                          arrrivalDate: '2022-10-20T19:15',
                        },
                        {
                          id: '400',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '6598',
                          durationInMin: 70,
                          departure: {
                            code: 'IDR',
                            airport: 'Devi Ahilya Bai Holkar Arpt',
                            city: 'Indore',
                            country: 'India',
                          },
                          departureTime: 1666279800000,
                          departureDate: '2022-10-20T21:00',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666284000000,
                          arrrivalDate: '2022-10-20T22:10',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '2168',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666321800000,
                        departure: 1666278300000,
                        numberOfStops: 1,
                        duration: 725,
                      },
                      key: 'UK|871|1666278300000|1666321800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_30DELHYDUK871HYDBOMUK872_18185015092340@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 11828.3,
                            publishedFare: 12044,
                            comission: 215.7,
                            bookingClass: 'Q',
                            baseFare: 10839,
                            taxes: {
                              total: 1205,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 555,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_30DELHYDUK871HYDBOMUK872_18185015091960@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 10180.14,
                            publishedFare: 10364,
                            comission: 183.86,
                            bookingClass: 'Q',
                            baseFare: 9239,
                            taxes: {
                              total: 1125,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 475,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '495',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '871',
                          durationInMin: 140,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666278300000,
                          departureDate: '2022-10-20T20:35',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666286700000,
                          arrrivalDate: '2022-10-20T22:55',
                        },
                        {
                          id: '496',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '872',
                          durationInMin: 90,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666316400000,
                          departureDate: '2022-10-21T07:10',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666321800000,
                          arrrivalDate: '2022-10-21T08:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '871',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 8,
                      details: {
                        arrival: 1666321800000,
                        departure: 1666230600000,
                        numberOfStops: 1,
                        duration: 1520,
                      },
                      key: 'UK|829|1666230600000|1666321800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_64DELHYDUK829HYDBOMUK872_18185015159983@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 11286.16,
                            publishedFare: 11478,
                            comission: 191.84,
                            bookingClass: 'U',
                            baseFare: 9640,
                            taxes: {
                              total: 1838,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1188,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '578',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '829',
                          durationInMin: 125,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666230600000,
                          departureDate: '2022-10-20T07:20',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666238100000,
                          arrrivalDate: '2022-10-20T09:25',
                        },
                        {
                          id: '579',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '872',
                          durationInMin: 90,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666316400000,
                          departureDate: '2022-10-21T07:10',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666321800000,
                          arrrivalDate: '2022-10-21T08:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '829',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666288500000,
                        departure: 1666267500000,
                        numberOfStops: 1,
                        duration: 350,
                      },
                      key: 'UK|813|1666267500000|1666288500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_127DELBLRUK813BLRBOMUK866_18185015279760@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 26509.84,
                            publishedFare: 27432,
                            comission: 922.16,
                            bookingClass: 'O',
                            baseFare: 25494,
                            taxes: {
                              total: 1938,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1288,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_127DELBLRUK813BLRBOMUK866_18185015279520@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 55840.21,
                            publishedFare: 56838,
                            comission: 997.79,
                            bookingClass: 'S',
                            baseFare: 50140,
                            taxes: {
                              total: 6698,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 6048,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '134',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '813',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666267500000,
                          departureDate: '2022-10-20T17:35',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666277700000,
                          arrrivalDate: '2022-10-20T20:25',
                        },
                        {
                          id: '135',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '866',
                          durationInMin: 105,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666282200000,
                          departureDate: '2022-10-20T21:40',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666288500000,
                          arrrivalDate: '2022-10-20T23:25',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '813',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666332600000,
                        departure: 1666266900000,
                        numberOfStops: 1,
                        duration: 1095,
                      },
                      key: 'UK|837|1666266900000|1666332600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_105DELMAAUK837MAABOMUK822_18185015237237@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 15981.09,
                            publishedFare: 16277,
                            comission: 295.91,
                            bookingClass: 'K',
                            baseFare: 14870,
                            taxes: {
                              total: 1407,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 757,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_105DELMAAUK837MAABOMUK822_18185015237007@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 34993.32,
                            publishedFare: 35614,
                            comission: 620.68,
                            bookingClass: 'P',
                            baseFare: 31190,
                            taxes: {
                              total: 4424,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 3774,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '819',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '837',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666266900000,
                          departureDate: '2022-10-20T17:25',
                          arrival: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          arrivalTime: 1666277100000,
                          arrrivalDate: '2022-10-20T20:15',
                        },
                        {
                          id: '820',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '822',
                          durationInMin: 115,
                          departure: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          departureTime: 1666325700000,
                          departureDate: '2022-10-21T09:45',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666332600000,
                          arrrivalDate: '2022-10-21T11:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '837',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666270500000,
                        departure: 1666262700000,
                        numberOfStops: 0,
                        duration: 130,
                      },
                      key: '6E|864|1666262700000|1666270500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_9DELBOM6E864_18188342554861@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6128.49,
                            publishedFare: 6244,
                            comission: 115.51,
                            bookingClass: 'T',
                            baseFare: 5476,
                            taxes: {
                              total: 768,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 768,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_9DELBOM6E864_18188342554381@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7173.18,
                            publishedFare: 7558,
                            comission: 384.82,
                            bookingClass: 'M',
                            baseFare: 6500,
                            taxes: {
                              total: 1058,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1058,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_9DELBOM6E864_18188342555081@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7181.89,
                            publishedFare: 7319,
                            comission: 137.11,
                            bookingClass: 'R',
                            baseFare: 6500,
                            taxes: {
                              total: 819,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 819,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '387',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '864',
                          durationInMin: 130,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666262700000,
                          departureDate: '2022-10-20T16:15',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666270500000,
                          arrrivalDate: '2022-10-20T18:25',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '864',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 6,
                      details: {
                        arrival: 1666263900000,
                        departure: 1666242900000,
                        numberOfStops: 1,
                        duration: 350,
                      },
                      key: 'AI|885|1666242900000|1666263900000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_53DELGOIAI885GOIBOMAI664_18185015136372@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 6,
                            offeredFare: 11727.87,
                            publishedFare: 11940,
                            comission: 212.13,
                            bookingClass: 'S',
                            baseFare: 10660,
                            taxes: {
                              total: 1280,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 550,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '91',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '885',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666242900000,
                          departureDate: '2022-10-20T10:45',
                          arrival: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          arrivalTime: 1666253100000,
                          arrrivalDate: '2022-10-20T13:35',
                        },
                        {
                          id: '92',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '664',
                          durationInMin: 80,
                          departure: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          departureTime: 1666259100000,
                          departureDate: '2022-10-20T15:15',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666263900000,
                          arrrivalDate: '2022-10-20T16:35',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '885',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666235100000,
                        departure: 1666227600000,
                        numberOfStops: 0,
                        duration: 125,
                      },
                      key: '6E|5023|1666227600000|1666235100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_16DELBOM6E5023_18188342566912@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 5772.78,
                            publishedFare: 5881,
                            comission: 108.22,
                            bookingClass: 'T',
                            baseFare: 5130,
                            taxes: {
                              total: 751,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 751,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_16DELBOM6E5023_18188342566492@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7173.18,
                            publishedFare: 7558,
                            comission: 384.82,
                            bookingClass: 'M',
                            baseFare: 6500,
                            taxes: {
                              total: 1058,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1058,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_16DELBOM6E5023_18188342567122@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7181.89,
                            publishedFare: 7319,
                            comission: 137.11,
                            bookingClass: 'R',
                            baseFare: 6500,
                            taxes: {
                              total: 819,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 819,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '422',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '5023',
                          durationInMin: 125,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666227600000,
                          departureDate: '2022-10-20T06:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666235100000,
                          arrrivalDate: '2022-10-20T08:35',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '5023',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666321500000,
                        departure: 1666280700000,
                        numberOfStops: 1,
                        duration: 680,
                      },
                      key: 'AI|504|1666280700000|1666321500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_45DELBLRAI504BLRBOMAI640_18185015119651@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 11120.61,
                            publishedFare: 11321,
                            comission: 200.39,
                            bookingClass: 'S',
                            baseFare: 10070,
                            taxes: {
                              total: 1251,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 521,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '571',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '504',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666280700000,
                          departureDate: '2022-10-20T21:15',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666290900000,
                          arrrivalDate: '2022-10-21T00:05',
                        },
                        {
                          id: '572',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '640',
                          durationInMin: 110,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666314900000,
                          departureDate: '2022-10-21T06:45',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666321500000,
                          arrrivalDate: '2022-10-21T08:35',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '504',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 6,
                      details: {
                        arrival: 1666320300000,
                        departure: 1666233600000,
                        numberOfStops: 1,
                        duration: 1445,
                      },
                      key: 'UK|815|1666233600000|1666320300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_63DELBLRUK815BLRBOMUK858_18185015157663@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 14655.52,
                            publishedFare: 15409,
                            comission: 753.48,
                            bookingClass: 'O',
                            baseFare: 14044,
                            taxes: {
                              total: 1365,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 715,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_63DELBLRUK815BLRBOMUK858_18185015157233@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 14655.52,
                            publishedFare: 15409,
                            comission: 753.48,
                            bookingClass: 'O',
                            baseFare: 14044,
                            taxes: {
                              total: 1365,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 715,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '872',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '815',
                          durationInMin: 160,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666233600000,
                          departureDate: '2022-10-20T08:10',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666243200000,
                          arrrivalDate: '2022-10-20T10:50',
                        },
                        {
                          id: '873',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '858',
                          durationInMin: 95,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666314600000,
                          departureDate: '2022-10-21T06:40',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666320300000,
                          arrrivalDate: '2022-10-21T08:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '815',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666277100000,
                        departure: 1666255500000,
                        numberOfStops: 1,
                        duration: 360,
                      },
                      key: 'UK|819|1666255500000|1666277100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_96DELBLRUK819BLRBOMUK854_18185015219726@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 26509.84,
                            publishedFare: 27432,
                            comission: 922.16,
                            bookingClass: 'O',
                            baseFare: 25494,
                            taxes: {
                              total: 1938,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1288,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_96DELBLRUK819BLRBOMUK854_18185015219516@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 31803.03,
                            publishedFare: 32366,
                            comission: 562.97,
                            bookingClass: 'U',
                            baseFare: 28290,
                            taxes: {
                              total: 4076,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 3426,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '428',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '819',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666255500000,
                          departureDate: '2022-10-20T14:15',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666265700000,
                          arrrivalDate: '2022-10-20T17:05',
                        },
                        {
                          id: '429',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '854',
                          durationInMin: 110,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666270500000,
                          departureDate: '2022-10-20T18:25',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666277100000,
                          arrrivalDate: '2022-10-20T20:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '819',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666331700000,
                        departure: 1666267500000,
                        numberOfStops: 1,
                        duration: 1070,
                      },
                      key: 'UK|813|1666267500000|1666331700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_61DELBLRUK813BLRBOMUK852_18185015152943@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 14655.52,
                            publishedFare: 15409,
                            comission: 753.48,
                            bookingClass: 'O',
                            baseFare: 14044,
                            taxes: {
                              total: 1365,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 715,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_61DELBLRUK813BLRBOMUK852_18185015152513@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 14655.52,
                            publishedFare: 15409,
                            comission: 753.48,
                            bookingClass: 'O',
                            baseFare: 14044,
                            taxes: {
                              total: 1365,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 715,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '341',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '813',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666267500000,
                          departureDate: '2022-10-20T17:35',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666277700000,
                          arrrivalDate: '2022-10-20T20:25',
                        },
                        {
                          id: '342',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '852',
                          durationInMin: 115,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666324800000,
                          departureDate: '2022-10-21T09:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666331700000,
                          arrrivalDate: '2022-10-21T11:25',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '813',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666331700000,
                        departure: 1666275600000,
                        numberOfStops: 1,
                        duration: 935,
                      },
                      key: 'UK|809|1666275600000|1666331700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_95DELBLRUK809BLRBOMUK852_18185015217926@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 19142.99,
                            publishedFare: 19500,
                            comission: 357.01,
                            bookingClass: 'K',
                            baseFare: 17940,
                            taxes: {
                              total: 1560,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 910,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_95DELBLRUK809BLRBOMUK852_18185015217706@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 31693.02,
                            publishedFare: 32254,
                            comission: 560.98,
                            bookingClass: 'R',
                            baseFare: 28190,
                            taxes: {
                              total: 4064,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 3414,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '506',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '809',
                          durationInMin: 170,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666275600000,
                          departureDate: '2022-10-20T19:50',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666285800000,
                          arrrivalDate: '2022-10-20T22:40',
                        },
                        {
                          id: '507',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '852',
                          durationInMin: 115,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666324800000,
                          departureDate: '2022-10-21T09:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666331700000,
                          arrrivalDate: '2022-10-21T11:25',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '809',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666280100000,
                        departure: 1666272000000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: 'G8|323|1666272000000|1666280100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_3DELBOMG8323_18183418272543@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 5728.62,
                            publishedFare: 5881,
                            comission: 152.38,
                            bookingClass: 'SP',
                            baseFare: 5105,
                            taxes: {
                              total: 776,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 776,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_3DELBOMG8323_18183418272953@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'GOMORE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 15490.95,
                            publishedFare: 15929,
                            comission: 438.05,
                            bookingClass: 'MG',
                            baseFare: 14675,
                            taxes: {
                              total: 1254,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1254,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_3DELBOMG8323_18183418272323@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7034.1,
                            publishedFare: 7487,
                            comission: 452.9,
                            bookingClass: 'BC',
                            baseFare: 6411,
                            taxes: {
                              total: 1076,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1076,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_3DELBOMG8323_18183418272733@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7125.72,
                            publishedFare: 7319,
                            comission: 193.28,
                            bookingClass: 'GS',
                            baseFare: 6475,
                            taxes: {
                              total: 844,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 844,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '814',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 0,
                          flightNumber: '323',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666272000000,
                          departureDate: '2022-10-20T18:50',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666280100000,
                          arrrivalDate: '2022-10-20T21:05',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '323',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666238100000,
                        departure: 1666230600000,
                        numberOfStops: 0,
                        duration: 125,
                      },
                      key: '6E|6814|1666230600000|1666238100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_15DELBOM6E6814_18188342564952@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6128.49,
                            publishedFare: 6244,
                            comission: 115.51,
                            bookingClass: 'T',
                            baseFare: 5476,
                            taxes: {
                              total: 768,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 768,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_15DELBOM6E6814_18188342564522@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7173.18,
                            publishedFare: 7558,
                            comission: 384.82,
                            bookingClass: 'M',
                            baseFare: 6500,
                            taxes: {
                              total: 1058,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1058,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_15DELBOM6E6814_18188342565172@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7181.89,
                            publishedFare: 7319,
                            comission: 137.11,
                            bookingClass: 'R',
                            baseFare: 6500,
                            taxes: {
                              total: 819,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 819,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '861',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '6814',
                          durationInMin: 125,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666230600000,
                          departureDate: '2022-10-20T07:20',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666238100000,
                          arrrivalDate: '2022-10-20T09:25',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '6814',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666283100000,
                        departure: 1666275300000,
                        numberOfStops: 0,
                        duration: 130,
                      },
                      key: 'UK|985|1666275300000|1666283100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_4DELBOMUK985_18185015007535@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 7191.25,
                            publishedFare: 7320,
                            comission: 128.75,
                            bookingClass: 'Q',
                            baseFare: 6470,
                            taxes: {
                              total: 850,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 330,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_4DELBOMUK985_18185015005985@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 6161.15,
                            publishedFare: 6270,
                            comission: 108.85,
                            bookingClass: 'Q',
                            baseFare: 5470,
                            taxes: {
                              total: 800,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '809',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '985',
                          durationInMin: 130,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666275300000,
                          departureDate: '2022-10-20T19:45',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666283100000,
                          arrrivalDate: '2022-10-20T21:55',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '985',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666274100000,
                        departure: 1666254300000,
                        numberOfStops: 1,
                        duration: 330,
                      },
                      key: '6E|2134|1666254300000|1666274100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_25DELDED6E2134DEDBOM6E933_18188342588294@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 13646.24,
                            publishedFare: 14407,
                            comission: 760.76,
                            bookingClass: 'M',
                            baseFare: 12850,
                            taxes: {
                              total: 1557,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1557,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_25DELDED6E2134DEDBOM6E933_18188342588704@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 13148.49,
                            publishedFare: 13409,
                            comission: 260.51,
                            bookingClass: 'R',
                            baseFare: 12350,
                            taxes: {
                              total: 1059,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1059,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '281',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '2134',
                          durationInMin: 60,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666254300000,
                          departureDate: '2022-10-20T13:55',
                          arrival: {
                            code: 'DED',
                            airport: 'Jolly Grant Airport',
                            city: 'Dehra Dun',
                            country: 'India',
                          },
                          arrivalTime: 1666257900000,
                          arrrivalDate: '2022-10-20T14:55',
                        },
                        {
                          id: '282',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '933',
                          durationInMin: 130,
                          departure: {
                            code: 'DED',
                            airport: 'Jolly Grant Airport',
                            city: 'Dehra Dun',
                            country: 'India',
                          },
                          departureTime: 1666266300000,
                          departureDate: '2022-10-20T17:15',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666274100000,
                          arrrivalDate: '2022-10-20T19:25',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '2134',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666331100000,
                        departure: 1666266000000,
                        numberOfStops: 1,
                        duration: 1085,
                      },
                      key: 'UK|637|1666266000000|1666331100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_66DELIXCUK637IXCBOMUK654_18185015162783@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 14971.5,
                            publishedFare: 15230,
                            comission: 258.5,
                            bookingClass: 'P',
                            baseFare: 12990,
                            taxes: {
                              total: 2240,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1590,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '813',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '637',
                          durationInMin: 65,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666266000000,
                          departureDate: '2022-10-20T17:10',
                          arrival: {
                            code: 'IXC',
                            airport: 'Chandigarh Arpt',
                            city: 'Chandigarh',
                            country: 'India',
                          },
                          arrivalTime: 1666269900000,
                          arrrivalDate: '2022-10-20T18:15',
                        },
                        {
                          id: '814',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '654',
                          durationInMin: 145,
                          departure: {
                            code: 'IXC',
                            airport: 'Chandigarh Arpt',
                            city: 'Chandigarh',
                            country: 'India',
                          },
                          departureTime: 1666322400000,
                          departureDate: '2022-10-21T08:50',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666331100000,
                          arrrivalDate: '2022-10-21T11:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '637',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666278600000,
                        departure: 1666233600000,
                        numberOfStops: 1,
                        duration: 750,
                      },
                      key: 'UK|815|1666233600000|1666278600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_81DELBLRUK815BLRBOMUK864_18185015191235@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 18124.16,
                            publishedFare: 18927,
                            comission: 802.84,
                            bookingClass: 'O',
                            baseFare: 17394,
                            taxes: {
                              total: 1533,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 883,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_81DELBLRUK815BLRBOMUK864_18185015191025@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 6,
                            offeredFare: 23442.27,
                            publishedFare: 23854,
                            comission: 411.73,
                            bookingClass: 'U',
                            baseFare: 20690,
                            taxes: {
                              total: 3164,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 2514,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '665',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '815',
                          durationInMin: 160,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666233600000,
                          departureDate: '2022-10-20T08:10',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666243200000,
                          arrrivalDate: '2022-10-20T10:50',
                        },
                        {
                          id: '666',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '864',
                          durationInMin: 105,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666272300000,
                          departureDate: '2022-10-20T18:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666278600000,
                          arrrivalDate: '2022-10-20T20:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '815',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666278600000,
                        departure: 1666226700000,
                        numberOfStops: 1,
                        duration: 865,
                      },
                      key: 'UK|811|1666226700000|1666278600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_84DELBLRUK811BLRBOMUK864_18185015196815@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 18124.16,
                            publishedFare: 18927,
                            comission: 802.84,
                            bookingClass: 'O',
                            baseFare: 17394,
                            taxes: {
                              total: 1533,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 883,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_84DELBLRUK811BLRBOMUK864_18185015196595@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 23442.27,
                            publishedFare: 23854,
                            comission: 411.73,
                            bookingClass: 'U',
                            baseFare: 20690,
                            taxes: {
                              total: 3164,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 2514,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '543',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '811',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666226700000,
                          departureDate: '2022-10-20T06:15',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666236600000,
                          arrrivalDate: '2022-10-20T09:00',
                        },
                        {
                          id: '544',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '864',
                          durationInMin: 105,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666272300000,
                          departureDate: '2022-10-20T18:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666278600000,
                          arrrivalDate: '2022-10-20T20:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '811',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666288800000,
                        departure: 1666268400000,
                        numberOfStops: 1,
                        duration: 340,
                      },
                      key: 'UK|781|1666268400000|1666288800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_70DELBBIUK781BBIBOMUK546_18185015169474@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 17494.83,
                            publishedFare: 17820,
                            comission: 325.17,
                            bookingClass: 'N',
                            baseFare: 16340,
                            taxes: {
                              total: 1480,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 830,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_70DELBBIUK781BBIBOMUK546_18185015169274@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 19426.9,
                            publishedFare: 19766,
                            comission: 339.1,
                            bookingClass: 'T',
                            baseFare: 17040,
                            taxes: {
                              total: 2726,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 2076,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '620',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '781',
                          durationInMin: 125,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666268400000,
                          departureDate: '2022-10-20T17:50',
                          arrival: {
                            code: 'BBI',
                            airport: 'Biju Patnaik Arpt',
                            city: 'Bhubaneswar',
                            country: 'India',
                          },
                          arrivalTime: 1666275900000,
                          arrrivalDate: '2022-10-20T19:55',
                        },
                        {
                          id: '621',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '546',
                          durationInMin: 140,
                          departure: {
                            code: 'BBI',
                            airport: 'Biju Patnaik Arpt',
                            city: 'Bhubaneswar',
                            country: 'India',
                          },
                          departureTime: 1666280400000,
                          departureDate: '2022-10-20T21:10',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666288800000,
                          arrrivalDate: '2022-10-20T23:30',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '781',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666289400000,
                        departure: 1666261500000,
                        numberOfStops: 1,
                        duration: 465,
                      },
                      key: 'UK|737|1666261500000|1666289400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_128DELCCUUK737CCUBOMUK774_18185015281570@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 30782.14,
                            publishedFare: 31364,
                            comission: 581.86,
                            bookingClass: 'B',
                            baseFare: 29239,
                            taxes: {
                              total: 2125,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1475,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_128DELCCUUK737CCUBOMUK774_18185015281360@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '30 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 60966.48,
                            publishedFare: 62057,
                            comission: 1090.52,
                            bookingClass: 'Z',
                            baseFare: 54800,
                            taxes: {
                              total: 7257,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 6607,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '455',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '737',
                          durationInMin: 130,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666261500000,
                          departureDate: '2022-10-20T15:55',
                          arrival: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          arrivalTime: 1666269300000,
                          arrrivalDate: '2022-10-20T18:05',
                        },
                        {
                          id: '456',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '774',
                          durationInMin: 175,
                          departure: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          departureTime: 1666278900000,
                          departureDate: '2022-10-20T20:45',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666289400000,
                          arrrivalDate: '2022-10-20T23:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '737',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666284600000,
                        departure: 1666266000000,
                        numberOfStops: 1,
                        duration: 310,
                      },
                      key: 'UK|637|1666266000000|1666284600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_69DELIXCUK637IXCBOMUK652_18185015167564@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 17082.79,
                            publishedFare: 17400,
                            comission: 317.21,
                            bookingClass: 'K',
                            baseFare: 15940,
                            taxes: {
                              total: 1460,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 810,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_69DELIXCUK637IXCBOMUK652_18185015167364@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 19151.88,
                            publishedFare: 19486,
                            comission: 334.12,
                            bookingClass: 'P',
                            baseFare: 16790,
                            taxes: {
                              total: 2696,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 2046,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '48',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '637',
                          durationInMin: 65,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666266000000,
                          departureDate: '2022-10-20T17:10',
                          arrival: {
                            code: 'IXC',
                            airport: 'Chandigarh Arpt',
                            city: 'Chandigarh',
                            country: 'India',
                          },
                          arrivalTime: 1666269900000,
                          arrrivalDate: '2022-10-20T18:15',
                        },
                        {
                          id: '49',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '652',
                          durationInMin: 145,
                          departure: {
                            code: 'IXC',
                            airport: 'Chandigarh Arpt',
                            city: 'Chandigarh',
                            country: 'India',
                          },
                          departureTime: 1666275900000,
                          departureDate: '2022-10-20T19:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666284600000,
                          arrrivalDate: '2022-10-20T22:20',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '637',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666287000000,
                        departure: 1666266300000,
                        numberOfStops: 1,
                        duration: 345,
                      },
                      key: '6E|2487|1666266300000|1666287000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_37DELBLR6E2487BLRBOM6E6818_18188342623836@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 18844.72,
                            publishedFare: 19899,
                            comission: 1054.28,
                            bookingClass: 'M',
                            baseFare: 17808,
                            taxes: {
                              total: 2091,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 2091,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_37DELBLR6E2487BLRBOM6E6818_18188342624256@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 18869.36,
                            publishedFare: 19245,
                            comission: 375.64,
                            bookingClass: 'R',
                            baseFare: 17808,
                            taxes: {
                              total: 1437,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1437,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '277',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '2487',
                          durationInMin: 160,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666266300000,
                          departureDate: '2022-10-20T17:15',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666275900000,
                          arrrivalDate: '2022-10-20T19:55',
                        },
                        {
                          id: '278',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '6818',
                          durationInMin: 95,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666281300000,
                          departureDate: '2022-10-20T21:25',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666287000000,
                          arrrivalDate: '2022-10-20T23:00',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '2487',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666320300000,
                        departure: 1666262100000,
                        numberOfStops: 1,
                        duration: 970,
                      },
                      key: 'UK|817|1666262100000|1666320300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_59DELBLRUK817BLRBOMUK858_18185015148283@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 14655.52,
                            publishedFare: 15409,
                            comission: 753.48,
                            bookingClass: 'O',
                            baseFare: 14044,
                            taxes: {
                              total: 1365,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 715,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_59DELBLRUK817BLRBOMUK858_18185015147783@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 14655.52,
                            publishedFare: 15409,
                            comission: 753.48,
                            bookingClass: 'O',
                            baseFare: 14044,
                            taxes: {
                              total: 1365,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 715,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '684',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '817',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666262100000,
                          departureDate: '2022-10-20T16:05',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666272000000,
                          arrrivalDate: '2022-10-20T18:50',
                        },
                        {
                          id: '685',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '858',
                          durationInMin: 95,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666314600000,
                          departureDate: '2022-10-21T06:40',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666320300000,
                          arrrivalDate: '2022-10-21T08:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '817',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666243800000,
                        departure: 1666235700000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: '6E|6278|1666235700000|1666243800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_13DELBOM6E6278_18188342561542@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6128.49,
                            publishedFare: 6244,
                            comission: 115.51,
                            bookingClass: 'T',
                            baseFare: 5476,
                            taxes: {
                              total: 768,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 768,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_13DELBOM6E6278_18188342561102@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7173.18,
                            publishedFare: 7558,
                            comission: 384.82,
                            bookingClass: 'M',
                            baseFare: 6500,
                            taxes: {
                              total: 1058,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1058,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_13DELBOM6E6278_18188342561752@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7181.89,
                            publishedFare: 7319,
                            comission: 137.11,
                            bookingClass: 'R',
                            baseFare: 6500,
                            taxes: {
                              total: 819,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 819,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '940',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '6278',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666235700000,
                          departureDate: '2022-10-20T08:45',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666243800000,
                          arrrivalDate: '2022-10-20T11:00',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '6278',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666244400000,
                        departure: 1666236000000,
                        numberOfStops: 0,
                        duration: 140,
                      },
                      key: 'UK|963|1666236000000|1666244400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_8DELBOMUK963_18185015025586@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7191.25,
                            publishedFare: 7320,
                            comission: 128.75,
                            bookingClass: 'Q',
                            baseFare: 6470,
                            taxes: {
                              total: 850,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 330,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_8DELBOMUK963_18185015024196@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6161.15,
                            publishedFare: 6270,
                            comission: 108.85,
                            bookingClass: 'Q',
                            baseFare: 5470,
                            taxes: {
                              total: 800,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '46',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '963',
                          durationInMin: 140,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666236000000,
                          departureDate: '2022-10-20T08:50',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666244400000,
                          arrrivalDate: '2022-10-20T11:10',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '963',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666313100000,
                        departure: 1666269600000,
                        numberOfStops: 1,
                        duration: 725,
                      },
                      key: 'UK|969|1666269600000|1666313100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_19DELAMDUK969AMDBOMUK918_18185015057048@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 6436.47,
                            publishedFare: 7073,
                            comission: 636.53,
                            bookingClass: 'O',
                            baseFare: 6105,
                            taxes: {
                              total: 968,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 318,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_19DELAMDUK969AMDBOMUK918_18185015054898@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 6436.47,
                            publishedFare: 7073,
                            comission: 636.53,
                            bookingClass: 'O',
                            baseFare: 6105,
                            taxes: {
                              total: 968,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 318,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '728',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '969',
                          durationInMin: 105,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666269600000,
                          departureDate: '2022-10-20T18:10',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666275900000,
                          arrrivalDate: '2022-10-20T19:55',
                        },
                        {
                          id: '729',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '918',
                          durationInMin: 70,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666308900000,
                          departureDate: '2022-10-21T05:05',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666313100000,
                          arrrivalDate: '2022-10-21T06:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '969',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666240200000,
                        departure: 1666223400000,
                        numberOfStops: 1,
                        duration: 280,
                      },
                      key: 'AI|443|1666223400000|1666240200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_25DELIXUAI443IXUBOMAI400_18185015078849@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 9226.61,
                            publishedFare: 9387,
                            comission: 160.39,
                            bookingClass: 'S',
                            baseFare: 8060,
                            taxes: {
                              total: 1327,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 567,
                                'Management Fees': 0,
                                'Gst Charges': 420,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '881',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '443',
                          durationInMin: 115,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666223400000,
                          departureDate: '2022-10-20T05:20',
                          arrival: {
                            code: 'IXU',
                            airport: 'Aurangabad airport',
                            city: 'Aurangabad',
                            country: 'India',
                          },
                          arrivalTime: 1666230300000,
                          arrrivalDate: '2022-10-20T07:15',
                        },
                        {
                          id: '882',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '400',
                          durationInMin: 70,
                          departure: {
                            code: 'IXU',
                            airport: 'Aurangabad airport',
                            city: 'Aurangabad',
                            country: 'India',
                          },
                          departureTime: 1666236000000,
                          departureDate: '2022-10-20T08:50',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666240200000,
                          arrrivalDate: '2022-10-20T10:00',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '443',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 7,
                      details: {
                        arrival: 1666321800000,
                        departure: 1666257300000,
                        numberOfStops: 1,
                        duration: 1075,
                      },
                      key: 'UK|899|1666257300000|1666321800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_32DELHYDUK899HYDBOMUK872_18185015096710@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 11828.3,
                            publishedFare: 12044,
                            comission: 215.7,
                            bookingClass: 'Q',
                            baseFare: 10839,
                            taxes: {
                              total: 1205,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 555,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_32DELHYDUK899HYDBOMUK872_18185015096270@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 10180.14,
                            publishedFare: 10364,
                            comission: 183.86,
                            bookingClass: 'Q',
                            baseFare: 9239,
                            taxes: {
                              total: 1125,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 475,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '329',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '899',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666257300000,
                          departureDate: '2022-10-20T14:45',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666265400000,
                          arrrivalDate: '2022-10-20T17:00',
                        },
                        {
                          id: '330',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '872',
                          durationInMin: 90,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666316400000,
                          departureDate: '2022-10-21T07:10',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666321800000,
                          arrrivalDate: '2022-10-21T08:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '899',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666277100000,
                        departure: 1666226700000,
                        numberOfStops: 1,
                        duration: 840,
                      },
                      key: 'UK|811|1666226700000|1666277100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_100DELBLRUK811BLRBOMUK854_18185015227446@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 26509.84,
                            publishedFare: 27432,
                            comission: 922.16,
                            bookingClass: 'O',
                            baseFare: 25494,
                            taxes: {
                              total: 1938,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1288,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_100DELBLRUK811BLRBOMUK854_18185015227176@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 31803.03,
                            publishedFare: 32366,
                            comission: 562.97,
                            bookingClass: 'U',
                            baseFare: 28290,
                            taxes: {
                              total: 4076,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 3426,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '501',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '811',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666226700000,
                          departureDate: '2022-10-20T06:15',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666236600000,
                          arrrivalDate: '2022-10-20T09:00',
                        },
                        {
                          id: '502',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '854',
                          durationInMin: 110,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666270500000,
                          departureDate: '2022-10-20T18:25',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666277100000,
                          arrrivalDate: '2022-10-20T20:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '811',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666282800000,
                        departure: 1666262100000,
                        numberOfStops: 1,
                        duration: 345,
                      },
                      key: 'UK|817|1666262100000|1666282800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_92DELBLRUK817BLRBOMUK850_18185015211896@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 20194.7,
                            publishedFare: 21027,
                            comission: 832.3,
                            bookingClass: 'O',
                            baseFare: 19394,
                            taxes: {
                              total: 1633,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 983,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_92DELBLRUK817BLRBOMUK850_18185015211696@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 30977.95,
                            publishedFare: 31526,
                            comission: 548.05,
                            bookingClass: 'T',
                            baseFare: 27540,
                            taxes: {
                              total: 3986,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 3336,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '257',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '817',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666262100000,
                          departureDate: '2022-10-20T16:05',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666272000000,
                          arrrivalDate: '2022-10-20T18:50',
                        },
                        {
                          id: '258',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '850',
                          durationInMin: 115,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666275900000,
                          departureDate: '2022-10-20T19:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666282800000,
                          arrrivalDate: '2022-10-20T21:50',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '817',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666293000000,
                        departure: 1666233600000,
                        numberOfStops: 1,
                        duration: 990,
                      },
                      key: 'I5|740|1666233600000|1666293000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_11DELBLRI5740BLRBOMI5334_18182007850296@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 17391.98,
                            publishedFare: 17636,
                            comission: 244.02,
                            bookingClass: 'SM',
                            baseFare: 16350,
                            taxes: {
                              total: 1286,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 822,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_11DELBLRI5740BLRBOMI5334_18182007850536@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 17391.98,
                            publishedFare: 17636,
                            comission: 244.02,
                            bookingClass: 'EC',
                            baseFare: 16350,
                            taxes: {
                              total: 1286,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 822,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '413',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '740',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666233600000,
                          departureDate: '2022-10-20T08:10',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666243500000,
                          arrrivalDate: '2022-10-20T10:55',
                        },
                        {
                          id: '414',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '334',
                          durationInMin: 105,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666286700000,
                          departureDate: '2022-10-20T22:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666293000000,
                          arrrivalDate: '2022-10-21T00:40',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '740',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666326600000,
                        departure: 1666257300000,
                        numberOfStops: 1,
                        duration: 1155,
                      },
                      key: 'UK|899|1666257300000|1666326600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_52DELHYDUK899HYDBOMUK874_18185015134202@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 12344.33,
                            publishedFare: 12570,
                            comission: 225.67,
                            bookingClass: 'Q',
                            baseFare: 11340,
                            taxes: {
                              total: 1230,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 580,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_52DELHYDUK899HYDBOMUK874_18185015133762@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 11314.23,
                            publishedFare: 11520,
                            comission: 205.77,
                            bookingClass: 'Q',
                            baseFare: 10340,
                            taxes: {
                              total: 1180,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 530,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '885',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '899',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666257300000,
                          departureDate: '2022-10-20T14:45',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666265400000,
                          arrrivalDate: '2022-10-20T17:00',
                        },
                        {
                          id: '886',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '874',
                          durationInMin: 90,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666321200000,
                          departureDate: '2022-10-21T08:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666326600000,
                          arrrivalDate: '2022-10-21T10:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '899',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666272900000,
                        departure: 1666265100000,
                        numberOfStops: 0,
                        duration: 130,
                      },
                      key: 'UK|941|1666265100000|1666272900000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_3DELBOMUK941_18185015004275@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7191.25,
                            publishedFare: 7320,
                            comission: 128.75,
                            bookingClass: 'Q',
                            baseFare: 6470,
                            taxes: {
                              total: 850,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 330,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_3DELBOMUK941_18185015002785@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6161.15,
                            publishedFare: 6270,
                            comission: 108.85,
                            bookingClass: 'Q',
                            baseFare: 5470,
                            taxes: {
                              total: 800,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '495',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '941',
                          durationInMin: 130,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666265100000,
                          departureDate: '2022-10-20T16:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666272900000,
                          arrrivalDate: '2022-10-20T19:05',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '941',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666281000000,
                        departure: 1666233600000,
                        numberOfStops: 1,
                        duration: 790,
                      },
                      key: 'I5|740|1666233600000|1666281000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_10DELBLRI5740BLRBOMI51782_18182007847506@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 16201.14,
                            publishedFare: 16428,
                            comission: 226.86,
                            bookingClass: 'SM',
                            baseFare: 15200,
                            taxes: {
                              total: 1228,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 764,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_10DELBLRI5740BLRBOMI51782_18182007847706@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'PROMO',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 14552.9,
                            publishedFare: 14756,
                            comission: 203.1,
                            bookingClass: 'BT',
                            baseFare: 13608,
                            taxes: {
                              total: 1148,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 684,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_10DELBLRI5740BLRBOMI51782_18182007847916@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PREMIUM_FLEX',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 16910.9,
                            publishedFare: 17148,
                            comission: 237.1,
                            bookingClass: 'HF',
                            baseFare: 15886,
                            taxes: {
                              total: 1262,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 464,
                                'Management Fees': 0,
                                'Gst Charges': 798,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '662',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '740',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666233600000,
                          departureDate: '2022-10-20T08:10',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666243500000,
                          arrrivalDate: '2022-10-20T10:55',
                        },
                        {
                          id: '663',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '1782',
                          durationInMin: 110,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666274400000,
                          departureDate: '2022-10-20T19:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666281000000,
                          arrrivalDate: '2022-10-20T21:20',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '740',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666288500000,
                        departure: 1666236900000,
                        numberOfStops: 1,
                        duration: 860,
                      },
                      key: 'UK|801|1666236900000|1666288500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_101DELBLRUK801BLRBOMUK866_18185015229237@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 26509.84,
                            publishedFare: 27432,
                            comission: 922.16,
                            bookingClass: 'O',
                            baseFare: 25494,
                            taxes: {
                              total: 1938,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1288,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_101DELBLRUK801BLRBOMUK866_18185015229007@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 31803.03,
                            publishedFare: 32366,
                            comission: 562.97,
                            bookingClass: 'U',
                            baseFare: 28290,
                            taxes: {
                              total: 4076,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 3426,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '900',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '801',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666236900000,
                          departureDate: '2022-10-20T09:05',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666246800000,
                          arrrivalDate: '2022-10-20T11:50',
                        },
                        {
                          id: '901',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '866',
                          durationInMin: 105,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666282200000,
                          departureDate: '2022-10-20T21:40',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666288500000,
                          arrrivalDate: '2022-10-20T23:25',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '801',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666247100000,
                        departure: 1666226700000,
                        numberOfStops: 1,
                        duration: 340,
                      },
                      key: 'SG|2962|1666226700000|1666247100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '4-2158784108_5DELJLRSG2962JLRBOMSG3002_18183742813742@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 18496.35,
                            publishedFare: 18888,
                            comission: 391.65,
                            bookingClass: 'DD',
                            baseFare: 17494,
                            taxes: {
                              total: 1394,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 514,
                                'Management Fees': 0,
                                'Gst Charges': 880,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_5DELJLRSG2962JLRBOMSG3002_18183742814042@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 20971.46,
                            publishedFare: 21417,
                            comission: 445.54,
                            bookingClass: 'SA',
                            baseFare: 19901,
                            taxes: {
                              total: 1516,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 514,
                                'Management Fees': 0,
                                'Gst Charges': 1002,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_5DELJLRSG2962JLRBOMSG3002_18183742813432@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 19054.01,
                            publishedFare: 20198,
                            comission: 1143.99,
                            bookingClass: 'CP',
                            baseFare: 18106,
                            taxes: {
                              total: 2092,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1148,
                                'Management Fees': 0,
                                'Gst Charges': 944,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_5DELJLRSG2962JLRBOMSG3002_18183742814212@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 18579.53,
                            publishedFare: 18973,
                            comission: 393.47,
                            bookingClass: 'RS',
                            baseFare: 17575,
                            taxes: {
                              total: 1398,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 514,
                                'Management Fees': 0,
                                'Gst Charges': 884,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '613',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 0,
                          flightNumber: '2962',
                          durationInMin: 110,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666226700000,
                          departureDate: '2022-10-20T06:15',
                          arrival: {
                            code: 'JLR',
                            airport: 'Jabalpur Airport',
                            city: 'Jabalpur',
                            country: 'India',
                          },
                          arrivalTime: 1666233300000,
                          arrrivalDate: '2022-10-20T08:05',
                        },
                        {
                          id: '614',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 1,
                          flightNumber: '3002',
                          durationInMin: 110,
                          departure: {
                            code: 'JLR',
                            airport: 'Jabalpur Airport',
                            city: 'Jabalpur',
                            country: 'India',
                          },
                          departureTime: 1666240500000,
                          departureDate: '2022-10-20T10:05',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666247100000,
                          arrrivalDate: '2022-10-20T11:55',
                        },
                      ],
                      operator: {
                        name: 'SpiceJet',
                        code: 'SG',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/SG.png',
                        number: '2962',
                        equipment: 'DH8',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666331700000,
                        departure: 1666278600000,
                        numberOfStops: 1,
                        duration: 885,
                      },
                      key: 'UK|807|1666278600000|1666331700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_94DELBLRUK807BLRBOMUK852_18185015216016@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 17031.79,
                            publishedFare: 17348,
                            comission: 316.21,
                            bookingClass: 'Q',
                            baseFare: 15890,
                            taxes: {
                              total: 1458,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 808,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_94DELBLRUK807BLRBOMUK852_18185015215786@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 31693.02,
                            publishedFare: 32254,
                            comission: 560.98,
                            bookingClass: 'R',
                            baseFare: 28190,
                            taxes: {
                              total: 4064,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 3414,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '464',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '807',
                          durationInMin: 160,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666278600000,
                          departureDate: '2022-10-20T20:40',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666288200000,
                          arrrivalDate: '2022-10-20T23:20',
                        },
                        {
                          id: '465',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '852',
                          durationInMin: 115,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666324800000,
                          departureDate: '2022-10-21T09:30',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666331700000,
                          arrrivalDate: '2022-10-21T11:25',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '807',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 6,
                      details: {
                        arrival: 1666282800000,
                        departure: 1666233600000,
                        numberOfStops: 1,
                        duration: 820,
                      },
                      key: 'UK|815|1666233600000|1666282800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_83DELBLRUK815BLRBOMUK850_18185015194925@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 20194.7,
                            publishedFare: 21027,
                            comission: 832.3,
                            bookingClass: 'O',
                            baseFare: 19394,
                            taxes: {
                              total: 1633,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 983,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_83DELBLRUK815BLRBOMUK850_18185015194695@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 6,
                            offeredFare: 23442.27,
                            publishedFare: 23854,
                            comission: 411.73,
                            bookingClass: 'U',
                            baseFare: 20690,
                            taxes: {
                              total: 3164,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 2514,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '648',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '815',
                          durationInMin: 160,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666233600000,
                          departureDate: '2022-10-20T08:10',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666243200000,
                          arrrivalDate: '2022-10-20T10:50',
                        },
                        {
                          id: '649',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '850',
                          durationInMin: 115,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666275900000,
                          departureDate: '2022-10-20T19:55',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666282800000,
                          arrrivalDate: '2022-10-20T21:50',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '815',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666320300000,
                        departure: 1666226700000,
                        numberOfStops: 1,
                        duration: 1560,
                      },
                      key: 'UK|811|1666226700000|1666320300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_67DELBLRUK811BLRBOMUK858_18185015164523@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 14655.52,
                            publishedFare: 15409,
                            comission: 753.48,
                            bookingClass: 'O',
                            baseFare: 14044,
                            taxes: {
                              total: 1365,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 715,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_67DELBLRUK811BLRBOMUK858_18185015164303@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 17556.73,
                            publishedFare: 17862,
                            comission: 305.27,
                            bookingClass: 'U',
                            baseFare: 15340,
                            taxes: {
                              total: 2522,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 390,
                                'Management Fees': 0,
                                'Gst Charges': 1872,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '821',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '811',
                          durationInMin: 165,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1666226700000,
                          departureDate: '2022-10-20T06:15',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666236600000,
                          arrrivalDate: '2022-10-20T09:00',
                        },
                        {
                          id: '822',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '858',
                          durationInMin: 95,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666314600000,
                          departureDate: '2022-10-21T06:40',
                          arrival: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1666320300000,
                          arrrivalDate: '2022-10-21T08:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '811',
                        equipment: '320',
                      },
                    },
                  ],
                  RETURN: [
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666634100000,
                        departure: 1666613400000,
                        numberOfStops: 1,
                        duration: 345,
                      },
                      key: '6E|6515|1666613400000|1666634100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_27BOMJAI6E6515JAIDEL6E2405_18188342290357@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 8994.58,
                            publishedFare: 9168,
                            comission: 173.42,
                            bookingClass: 'T',
                            baseFare: 8221,
                            taxes: {
                              total: 947,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 947,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_27BOMJAI6E6515JAIDEL6E2405_18188342289937@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 10194.5,
                            publishedFare: 10751,
                            comission: 556.5,
                            bookingClass: 'M',
                            baseFare: 9400,
                            taxes: {
                              total: 1351,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1351,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_27BOMJAI6E6515JAIDEL6E2405_18188342290607@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 9590.37,
                            publishedFare: 9776,
                            comission: 185.63,
                            bookingClass: 'R',
                            baseFare: 8800,
                            taxes: {
                              total: 976,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 976,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '392',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '6515',
                          durationInMin: 115,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666613400000,
                          departureDate: '2022-10-24T17:40',
                          arrival: {
                            code: 'JAI',
                            airport: 'Sanganeer Arpt',
                            city: 'Jaipur',
                            country: 'India',
                          },
                          arrivalTime: 1666620300000,
                          arrrivalDate: '2022-10-24T19:35',
                        },
                        {
                          id: '393',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '2405',
                          durationInMin: 60,
                          departure: {
                            code: 'JAI',
                            airport: 'Sanganeer Arpt',
                            city: 'Jaipur',
                            country: 'India',
                          },
                          departureTime: 1666630500000,
                          departureDate: '2022-10-24T22:25',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666634100000,
                          arrrivalDate: '2022-10-24T23:25',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '6515',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 8,
                      details: {
                        arrival: 1666595400000,
                        departure: 1666587300000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: 'UK|994|1666587300000|1666595400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_38BOMDELUK994_18185014691898@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 11505.67,
                            publishedFare: 11718,
                            comission: 212.33,
                            bookingClass: 'N',
                            baseFare: 10670,
                            taxes: {
                              total: 1048,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 540,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_38BOMDELUK994_18185014691588@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 11505.67,
                            publishedFare: 11718,
                            comission: 212.33,
                            bookingClass: 'N',
                            baseFare: 10670,
                            taxes: {
                              total: 1048,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 540,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '206',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '994',
                          durationInMin: 135,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666587300000,
                          departureDate: '2022-10-24T10:25',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666595400000,
                          arrrivalDate: '2022-10-24T12:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '994',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666671300000,
                        departure: 1666625400000,
                        numberOfStops: 1,
                        duration: 765,
                      },
                      key: 'UK|827|1666625400000|1666671300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_74BOMMAAUK827MAADELUK832_18185014736691@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 13672.44,
                            publishedFare: 13924,
                            comission: 251.56,
                            bookingClass: 'Q',
                            baseFare: 12641,
                            taxes: {
                              total: 1283,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 645,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_74BOMMAAUK827MAADELUK832_18185014736531@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '35 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 31956.04,
                            publishedFare: 32522,
                            comission: 565.96,
                            bookingClass: 'Z',
                            baseFare: 28440,
                            taxes: {
                              total: 4082,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 3444,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '124',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '827',
                          durationInMin: 125,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666625400000,
                          departureDate: '2022-10-24T21:00',
                          arrival: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          arrivalTime: 1666632900000,
                          arrrivalDate: '2022-10-24T23:05',
                        },
                        {
                          id: '125',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '832',
                          durationInMin: 160,
                          departure: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          departureTime: 1666661700000,
                          departureDate: '2022-10-25T07:05',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666671300000,
                          arrrivalDate: '2022-10-25T09:45',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '827',
                        equipment: '73H',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666621500000,
                        departure: 1666578300000,
                        numberOfStops: 1,
                        duration: 720,
                      },
                      key: 'I5|631|1666578300000|1666621500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_7BOMIXRI5631IXRDELI5560_18182007595282@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 10467.67,
                            publishedFare: 10612,
                            comission: 144.33,
                            bookingClass: 'SM',
                            baseFare: 9671,
                            taxes: {
                              total: 941,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 488,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_7BOMIXRI5631IXRDELI5560_18182007595572@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 10467.67,
                            publishedFare: 10612,
                            comission: 144.33,
                            bookingClass: 'EC',
                            baseFare: 9671,
                            taxes: {
                              total: 941,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 488,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '93',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '631',
                          durationInMin: 160,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666578300000,
                          departureDate: '2022-10-24T07:55',
                          arrival: {
                            code: 'IXR',
                            airport: 'Birsa Munda Arpt',
                            city: 'Ranchi',
                            country: 'India',
                          },
                          arrivalTime: 1666587900000,
                          arrrivalDate: '2022-10-24T10:35',
                        },
                        {
                          id: '94',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '560',
                          durationInMin: 120,
                          departure: {
                            code: 'IXR',
                            airport: 'Birsa Munda Arpt',
                            city: 'Ranchi',
                            country: 'India',
                          },
                          departureTime: 1666614300000,
                          departureDate: '2022-10-24T17:55',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666621500000,
                          arrrivalDate: '2022-10-24T19:55',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '631',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666594200000,
                        departure: 1666586400000,
                        numberOfStops: 0,
                        duration: 130,
                      },
                      key: '6E|6821|1666586400000|1666594200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_16BOMDEL6E6821_18188342260986@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 7984.82,
                            publishedFare: 8417,
                            comission: 432.18,
                            bookingClass: 'M',
                            baseFare: 7300,
                            taxes: {
                              total: 1117,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1117,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_16BOMDEL6E6821_18188342261416@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 7994.01,
                            publishedFare: 8148,
                            comission: 153.99,
                            bookingClass: 'R',
                            baseFare: 7300,
                            taxes: {
                              total: 848,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 848,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '844',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '6821',
                          durationInMin: 130,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666586400000,
                          departureDate: '2022-10-24T10:10',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666594200000,
                          arrrivalDate: '2022-10-24T12:20',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '6821',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666590600000,
                        departure: 1666582200000,
                        numberOfStops: 0,
                        duration: 140,
                      },
                      key: 'AI|866|1666582200000|1666590600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_5BOMDELAI866_18185014631606@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6149.94,
                            publishedFare: 6258,
                            comission: 108.06,
                            bookingClass: 'S',
                            baseFare: 5430,
                            taxes: {
                              total: 828,
                              breakups: {
                                'Carrier Misc': 170,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '852',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '866',
                          durationInMin: 140,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666582200000,
                          departureDate: '2022-10-24T09:00',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666590600000,
                          arrrivalDate: '2022-10-24T11:20',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '866',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666648800000,
                        departure: 1666596900000,
                        numberOfStops: 1,
                        duration: 865,
                      },
                      key: 'I5|942|1666596900000|1666648800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_3BOMBLRI5942BLRDELI5722_18182007584371@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 10122.62,
                            publishedFare: 10262,
                            comission: 139.38,
                            bookingClass: 'SM',
                            baseFare: 9339,
                            taxes: {
                              total: 923,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 470,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_3BOMBLRI5942BLRDELI5722_18182007584791@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PREMIUM_FLEX',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 10223.18,
                            publishedFare: 10364,
                            comission: 140.82,
                            bookingClass: 'HF',
                            baseFare: 9435,
                            taxes: {
                              total: 929,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 476,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '831',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '942',
                          durationInMin: 100,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666596900000,
                          departureDate: '2022-10-24T13:05',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666602900000,
                          arrrivalDate: '2022-10-24T14:45',
                        },
                        {
                          id: '832',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '722',
                          durationInMin: 170,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666638600000,
                          departureDate: '2022-10-25T00:40',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666648800000,
                          arrrivalDate: '2022-10-25T03:30',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '942',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666639800000,
                        departure: 1666631700000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: 'UK|986|1666631700000|1666639800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_27BOMDELUK986_18185014672528@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 9960.52,
                            publishedFare: 10143,
                            comission: 182.48,
                            bookingClass: 'L',
                            baseFare: 9170,
                            taxes: {
                              total: 973,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 465,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_27BOMDELUK986_18185014672058@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 9960.52,
                            publishedFare: 10143,
                            comission: 182.48,
                            bookingClass: 'L',
                            baseFare: 9170,
                            taxes: {
                              total: 973,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 465,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '137',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '986',
                          durationInMin: 135,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666631700000,
                          departureDate: '2022-10-24T22:45',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666639800000,
                          arrrivalDate: '2022-10-25T01:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '986',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666593600000,
                        departure: 1666575900000,
                        numberOfStops: 1,
                        duration: 295,
                      },
                      key: '6E|818|1666575900000|1666593600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_18BOMHYD6E818HYDDEL6E2511_18188342264706@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 11442.4,
                            publishedFare: 11666,
                            comission: 223.6,
                            bookingClass: 'T',
                            baseFare: 10600,
                            taxes: {
                              total: 1066,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1066,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_18BOMHYD6E818HYDDEL6E2511_18188342264166@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 8277.91,
                            publishedFare: 8724,
                            comission: 446.09,
                            bookingClass: 'M',
                            baseFare: 7535,
                            taxes: {
                              total: 1189,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1189,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_18BOMHYD6E818HYDDEL6E2511_18188342264526@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 7583.51,
                            publishedFare: 7728,
                            comission: 144.49,
                            bookingClass: 'R',
                            baseFare: 6850,
                            taxes: {
                              total: 878,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 878,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '611',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '818',
                          durationInMin: 90,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666575900000,
                          departureDate: '2022-10-24T07:15',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666581300000,
                          arrrivalDate: '2022-10-24T08:45',
                        },
                        {
                          id: '612',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '2511',
                          durationInMin: 135,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666585500000,
                          departureDate: '2022-10-24T09:55',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666593600000,
                          arrrivalDate: '2022-10-24T12:10',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '818',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666675500000,
                        departure: 1666624200000,
                        numberOfStops: 1,
                        duration: 855,
                      },
                      key: 'I5|679|1666624200000|1666675500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_2BOMGOII5679GOIDELI5315_18182007581731@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 8891.39,
                            publishedFare: 9013,
                            comission: 121.61,
                            bookingClass: 'SM',
                            baseFare: 8148,
                            taxes: {
                              total: 865,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 412,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_2BOMGOII5679GOIDELI5315_18182007581951@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 8891.39,
                            publishedFare: 9013,
                            comission: 121.61,
                            bookingClass: 'EC',
                            baseFare: 8148,
                            taxes: {
                              total: 865,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 412,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '347',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '679',
                          durationInMin: 80,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666624200000,
                          departureDate: '2022-10-24T20:40',
                          arrival: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          arrivalTime: 1666629000000,
                          arrrivalDate: '2022-10-24T22:00',
                        },
                        {
                          id: '348',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '315',
                          durationInMin: 155,
                          departure: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          departureTime: 1666666200000,
                          departureDate: '2022-10-25T08:20',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666675500000,
                          arrrivalDate: '2022-10-25T10:55',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '679',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666599900000,
                        departure: 1666582200000,
                        numberOfStops: 1,
                        duration: 295,
                      },
                      key: '6E|6597|1666582200000|1666599900000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_24BOMHYD6E6597HYDDEL6E2171_18188342278357@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 9634.76,
                            publishedFare: 10159,
                            comission: 524.24,
                            bookingClass: 'M',
                            baseFare: 8855,
                            taxes: {
                              total: 1304,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1304,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_24BOMHYD6E6597HYDDEL6E2171_18188342278847@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 8818.19,
                            publishedFare: 8988,
                            comission: 169.81,
                            bookingClass: 'R',
                            baseFare: 8050,
                            taxes: {
                              total: 938,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 938,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '412',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '6597',
                          durationInMin: 85,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666582200000,
                          departureDate: '2022-10-24T09:00',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666587300000,
                          arrrivalDate: '2022-10-24T10:25',
                        },
                        {
                          id: '413',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '2171',
                          durationInMin: 135,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666591800000,
                          departureDate: '2022-10-24T11:40',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666599900000,
                          arrrivalDate: '2022-10-24T13:55',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '6597',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666625100000,
                        departure: 1666619700000,
                        numberOfStops: 0,
                        duration: 90,
                      },
                      key: 'G8|287|1666619700000|1666625100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_12BOMSXRG8287SXRDELG8192_18183418067893@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7115.08,
                            publishedFare: 7308,
                            comission: 192.92,
                            bookingClass: 'GS',
                            baseFare: 6463,
                            taxes: {
                              total: 845,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 845,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '909',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 1,
                          flightNumber: '192',
                          durationInMin: 90,
                          departure: {
                            code: 'SXR',
                            airport: 'Srinagar Arpt',
                            city: 'Srinagar',
                            country: 'India',
                          },
                          departureTime: 1666619700000,
                          departureDate: '2022-10-24T19:25',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666625100000,
                          arrrivalDate: '2022-10-24T20:55',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '287',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666712400000,
                        departure: 1666612200000,
                        numberOfStops: 1,
                        duration: 1670,
                      },
                      key: 'UK|773|1666612200000|1666712400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_92BOMCCUUK773CCUDELUK738_18185014757462@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 20985.17,
                            publishedFare: 21378,
                            comission: 392.83,
                            bookingClass: 'H',
                            baseFare: 19740,
                            taxes: {
                              total: 1638,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 1000,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '552',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '773',
                          durationInMin: 155,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666612200000,
                          departureDate: '2022-10-24T17:20',
                          arrival: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          arrivalTime: 1666621500000,
                          arrrivalDate: '2022-10-24T19:55',
                        },
                        {
                          id: '553',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '738',
                          durationInMin: 145,
                          departure: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          departureTime: 1666703700000,
                          departureDate: '2022-10-25T18:45',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666712400000,
                          arrrivalDate: '2022-10-25T21:10',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '773',
                        equipment: '73H',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666671000000,
                        departure: 1666612800000,
                        numberOfStops: 1,
                        duration: 970,
                      },
                      key: 'UK|843|1666612800000|1666671000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_41BOMBLRUK843BLRDELUK810_18185014694899@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 11275.61,
                            publishedFare: 11981,
                            comission: 705.39,
                            bookingClass: 'W',
                            baseFare: 10790,
                            taxes: {
                              total: 1191,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 553,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_41BOMBLRUK843BLRDELUK810_18185014694739@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 11275.61,
                            publishedFare: 11981,
                            comission: 705.39,
                            bookingClass: 'W',
                            baseFare: 10790,
                            taxes: {
                              total: 1191,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 553,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '750',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '843',
                          durationInMin: 105,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666612800000,
                          departureDate: '2022-10-24T17:30',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666619100000,
                          arrrivalDate: '2022-10-24T19:15',
                        },
                        {
                          id: '751',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '810',
                          durationInMin: 160,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666661400000,
                          departureDate: '2022-10-25T07:00',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666671000000,
                          arrrivalDate: '2022-10-25T09:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '843',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666591800000,
                        departure: 1666583700000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: '6E|2172|1666583700000|1666591800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_10BOMDEL6E2172_18188342246055@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 7675.58,
                            publishedFare: 8090,
                            comission: 414.42,
                            bookingClass: 'M',
                            baseFare: 7000,
                            taxes: {
                              total: 1090,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1090,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_10BOMDEL6E2172_18188342246535@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 7685.34,
                            publishedFare: 7833,
                            comission: 147.66,
                            bookingClass: 'R',
                            baseFare: 7000,
                            taxes: {
                              total: 833,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 833,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '360',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '2172',
                          durationInMin: 135,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666583700000,
                          departureDate: '2022-10-24T09:25',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666591800000,
                          arrrivalDate: '2022-10-24T11:40',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '2172',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666578900000,
                        departure: 1666571400000,
                        numberOfStops: 0,
                        duration: 125,
                      },
                      key: 'UK|954|1666571400000|1666578900000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_8BOMDELUK954_18185014637656@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 7694.3,
                            publishedFare: 7833,
                            comission: 138.7,
                            bookingClass: 'W',
                            baseFare: 6970,
                            taxes: {
                              total: 863,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 355,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_8BOMDELUK954_18185014635516@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 6664.2,
                            publishedFare: 6783,
                            comission: 118.8,
                            bookingClass: 'W',
                            baseFare: 5970,
                            taxes: {
                              total: 813,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 305,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '815',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '954',
                          durationInMin: 125,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666571400000,
                          departureDate: '2022-10-24T06:00',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666578900000,
                          arrrivalDate: '2022-10-24T08:05',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '954',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666611900000,
                        departure: 1666595400000,
                        numberOfStops: 1,
                        duration: 275,
                      },
                      key: '6E|5273|1666595400000|1666611900000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_25BOMAMD6E5273AMDDEL6E2142_18188342283427@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 10086.72,
                            publishedFare: 10637,
                            comission: 550.28,
                            bookingClass: 'M',
                            baseFare: 9295,
                            taxes: {
                              total: 1342,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1342,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_25BOMAMD6E5273AMDDEL6E2142_18188342283897@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 9229.76,
                            publishedFare: 9408,
                            comission: 178.24,
                            bookingClass: 'R',
                            baseFare: 8450,
                            taxes: {
                              total: 958,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 528,
                                'Management Fees': 0,
                                'Gst Charges': 430,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '930',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '5273',
                          durationInMin: 75,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666595400000,
                          departureDate: '2022-10-24T12:40',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666599900000,
                          arrrivalDate: '2022-10-24T13:55',
                        },
                        {
                          id: '931',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '2142',
                          durationInMin: 95,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666606200000,
                          departureDate: '2022-10-24T15:40',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666611900000,
                          arrrivalDate: '2022-10-24T17:15',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '5273',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666616400000,
                        departure: 1666589700000,
                        numberOfStops: 1,
                        duration: 445,
                      },
                      key: 'AI|617|1666589700000|1666616400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_54BOMHYDAI617HYDDELAI541_18185014710559@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 12436.94,
                            publishedFare: 12663,
                            comission: 226.06,
                            bookingClass: 'W',
                            baseFare: 11360,
                            taxes: {
                              total: 1303,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 585,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '566',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '617',
                          durationInMin: 85,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666589700000,
                          departureDate: '2022-10-24T11:05',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666594800000,
                          arrrivalDate: '2022-10-24T12:30',
                        },
                        {
                          id: '567',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '541',
                          durationInMin: 140,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666608000000,
                          departureDate: '2022-10-24T16:10',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666616400000,
                          arrrivalDate: '2022-10-24T18:30',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '617',
                        equipment: '319',
                      },
                    },
                    {
                      remaining: 8,
                      details: {
                        arrival: 1666707600000,
                        departure: 1666618200000,
                        numberOfStops: 1,
                        duration: 1490,
                      },
                      key: 'AI|641|1666618200000|1666707600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_21BOMBLRAI641BLRDELAI503_18185014662067@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 8832.57,
                            publishedFare: 8989,
                            comission: 156.43,
                            bookingClass: 'G',
                            baseFare: 7861,
                            taxes: {
                              total: 1128,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 410,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '742',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '641',
                          durationInMin: 100,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666618200000,
                          departureDate: '2022-10-24T19:00',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666624200000,
                          arrrivalDate: '2022-10-24T20:40',
                        },
                        {
                          id: '743',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '503',
                          durationInMin: 160,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666698000000,
                          departureDate: '2022-10-25T17:10',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666707600000,
                          arrrivalDate: '2022-10-25T19:50',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '641',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666718700000,
                        departure: 1666615500000,
                        numberOfStops: 1,
                        duration: 1720,
                      },
                      key: 'UK|545|1666615500000|1666718700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_88BOMBBIUK545BBIDELUK782_18185014753041@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 14804.57,
                            publishedFare: 15078,
                            comission: 273.43,
                            bookingClass: 'Q',
                            baseFare: 13740,
                            taxes: {
                              total: 1338,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 700,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '624',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '545',
                          durationInMin: 140,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666615500000,
                          departureDate: '2022-10-24T18:15',
                          arrival: {
                            code: 'BBI',
                            airport: 'Biju Patnaik Arpt',
                            city: 'Bhubaneswar',
                            country: 'India',
                          },
                          arrivalTime: 1666623900000,
                          arrrivalDate: '2022-10-24T20:35',
                        },
                        {
                          id: '625',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '782',
                          durationInMin: 145,
                          departure: {
                            code: 'BBI',
                            airport: 'Biju Patnaik Arpt',
                            city: 'Bhubaneswar',
                            country: 'India',
                          },
                          departureTime: 1666710000000,
                          departureDate: '2022-10-25T20:30',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666718700000,
                          arrrivalDate: '2022-10-25T22:55',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '545',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666668300000,
                        departure: 1666618200000,
                        numberOfStops: 1,
                        duration: 835,
                      },
                      key: 'I5|338|1666618200000|1666668300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_10BOMBLRI5338BLRDELI51528_18182007605142@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 11004.91,
                            publishedFare: 11157,
                            comission: 152.09,
                            bookingClass: 'SM',
                            baseFare: 10190,
                            taxes: {
                              total: 967,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 514,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_10BOMBLRI5338BLRDELI51528_18182007605742@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PREMIUM_FLEX',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 11118.26,
                            publishedFare: 11272,
                            comission: 153.74,
                            bookingClass: 'HF',
                            baseFare: 10301,
                            taxes: {
                              total: 971,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 518,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '450',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '338',
                          durationInMin: 110,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666618200000,
                          departureDate: '2022-10-24T19:00',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666624800000,
                          arrrivalDate: '2022-10-24T20:50',
                        },
                        {
                          id: '451',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '1528',
                          durationInMin: 165,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666658400000,
                          departureDate: '2022-10-25T06:10',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666668300000,
                          arrrivalDate: '2022-10-25T08:55',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '338',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666605000000,
                        departure: 1666587300000,
                        numberOfStops: 1,
                        duration: 295,
                      },
                      key: 'UK|647|1666587300000|1666605000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_66BOMSXRUK647SXRDELUK644_18185014726920@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 19438.03,
                            publishedFare: 19803,
                            comission: 364.97,
                            bookingClass: 'A',
                            baseFare: 18340,
                            taxes: {
                              total: 1463,
                              breakups: {
                                'Carrier Misc': 160,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 925,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_66BOMSXRUK647SXRDELUK644_18185014726770@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 20017.97,
                            publishedFare: 20370,
                            comission: 352.03,
                            bookingClass: 'T',
                            baseFare: 17690,
                            taxes: {
                              total: 2680,
                              breakups: {
                                'Carrier Misc': 160,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 2142,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '257',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '647',
                          durationInMin: 165,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666587300000,
                          departureDate: '2022-10-24T10:25',
                          arrival: {
                            code: 'SXR',
                            airport: 'Srinagar Arpt',
                            city: 'Srinagar',
                            country: 'India',
                          },
                          arrivalTime: 1666597200000,
                          arrrivalDate: '2022-10-24T13:10',
                        },
                        {
                          id: '258',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '644',
                          durationInMin: 95,
                          departure: {
                            code: 'SXR',
                            airport: 'Srinagar Arpt',
                            city: 'Srinagar',
                            country: 'India',
                          },
                          departureTime: 1666599300000,
                          departureDate: '2022-10-24T13:45',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666605000000,
                          arrrivalDate: '2022-10-24T15:20',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '647',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666621200000,
                        departure: 1666584000000,
                        numberOfStops: 1,
                        duration: 620,
                      },
                      key: 'AI|639|1666584000000|1666621200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_32BOMBLRAI639BLRDELAI503_18185014685728@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 10274.71,
                            publishedFare: 10459,
                            comission: 184.29,
                            bookingClass: 'W',
                            baseFare: 9261,
                            taxes: {
                              total: 1198,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 480,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '352',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '639',
                          durationInMin: 100,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666584000000,
                          departureDate: '2022-10-24T09:30',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666590000000,
                          arrrivalDate: '2022-10-24T11:10',
                        },
                        {
                          id: '353',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '503',
                          durationInMin: 170,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666611000000,
                          departureDate: '2022-10-24T17:00',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666621200000,
                          arrrivalDate: '2022-10-24T19:50',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '639',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666589100000,
                        departure: 1666569000000,
                        numberOfStops: 1,
                        duration: 335,
                      },
                      key: '6E|5324|1666569000000|1666589100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_38BOMRPR6E5324RPRDEL6E936_18188342322609@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 15512.13,
                            publishedFare: 16375,
                            comission: 862.87,
                            bookingClass: 'M',
                            baseFare: 14575,
                            taxes: {
                              total: 1800,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1800,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_38BOMRPR6E5324RPRDEL6E936_18188342323059@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 14168.5,
                            publishedFare: 14448,
                            comission: 279.5,
                            bookingClass: 'R',
                            baseFare: 13250,
                            taxes: {
                              total: 1198,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1198,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '288',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '5324',
                          durationInMin: 125,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666569000000,
                          departureDate: '2022-10-24T05:20',
                          arrival: {
                            code: 'RPR',
                            airport: 'Raipur Arpt',
                            city: 'Raipur',
                            country: 'India',
                          },
                          arrivalTime: 1666576500000,
                          arrrivalDate: '2022-10-24T07:25',
                        },
                        {
                          id: '289',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '936',
                          durationInMin: 105,
                          departure: {
                            code: 'RPR',
                            airport: 'Raipur Arpt',
                            city: 'Raipur',
                            country: 'India',
                          },
                          departureTime: 1666582800000,
                          departureDate: '2022-10-24T09:10',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666589100000,
                          arrrivalDate: '2022-10-24T10:55',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '5324',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666631400000,
                        departure: 1666575000000,
                        numberOfStops: 1,
                        duration: 940,
                      },
                      key: 'UK|771|1666575000000|1666631400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_79BOMCCUUK771CCUDELUK708_18185014742831@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 20985.17,
                            publishedFare: 21378,
                            comission: 392.83,
                            bookingClass: 'H',
                            baseFare: 19740,
                            taxes: {
                              total: 1638,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 1000,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_79BOMCCUUK771CCUDELUK708_18185014742671@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '35 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 38260.02,
                            publishedFare: 38940,
                            comission: 679.98,
                            bookingClass: 'Z',
                            baseFare: 34170,
                            taxes: {
                              total: 4770,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 4132,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '849',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '771',
                          durationInMin: 160,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666575000000,
                          departureDate: '2022-10-24T07:00',
                          arrival: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          arrivalTime: 1666584600000,
                          arrrivalDate: '2022-10-24T09:40',
                        },
                        {
                          id: '850',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '708',
                          durationInMin: 140,
                          departure: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          departureTime: 1666623000000,
                          departureDate: '2022-10-24T20:20',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666631400000,
                          arrrivalDate: '2022-10-24T22:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '771',
                        equipment: '73H',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666624200000,
                        departure: 1666616400000,
                        numberOfStops: 0,
                        duration: 130,
                      },
                      key: 'UK|996|1666616400000|1666624200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_58BOMDELUK996_18185014715650@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 15561.97,
                            publishedFare: 15834,
                            comission: 272.03,
                            bookingClass: 'T',
                            baseFare: 13670,
                            taxes: {
                              total: 2164,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 1656,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_58BOMDELUK996_18185014715500@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 15561.97,
                            publishedFare: 15834,
                            comission: 272.03,
                            bookingClass: 'T',
                            baseFare: 13670,
                            taxes: {
                              total: 2164,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 1656,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '450',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '996',
                          durationInMin: 130,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666616400000,
                          departureDate: '2022-10-24T18:30',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666624200000,
                          arrrivalDate: '2022-10-24T20:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '996',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666579500000,
                        departure: 1666571700000,
                        numberOfStops: 0,
                        duration: 130,
                      },
                      key: '6E|5332|1666571700000|1666579500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_17BOMDEL6E5332_18188342262476@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 7984.82,
                            publishedFare: 8417,
                            comission: 432.18,
                            bookingClass: 'M',
                            baseFare: 7300,
                            taxes: {
                              total: 1117,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1117,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_17BOMDEL6E5332_18188342262946@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 7994.01,
                            publishedFare: 8148,
                            comission: 153.99,
                            bookingClass: 'R',
                            baseFare: 7300,
                            taxes: {
                              total: 848,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 848,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '266',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '5332',
                          durationInMin: 130,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666571700000,
                          departureDate: '2022-10-24T06:05',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666579500000,
                          arrrivalDate: '2022-10-24T08:15',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '5332',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666566300000,
                        departure: 1666559400000,
                        numberOfStops: 0,
                        duration: 115,
                      },
                      key: '6E|2111|1666559400000|1666566300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_20BOMDEL6E2111_18188342269166@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 8908.54,
                            publishedFare: 9394,
                            comission: 485.46,
                            bookingClass: 'M',
                            baseFare: 8200,
                            taxes: {
                              total: 1194,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1194,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_20BOMDEL6E2111_18188342269536@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 8920.03,
                            publishedFare: 9093,
                            comission: 172.97,
                            bookingClass: 'R',
                            baseFare: 8200,
                            taxes: {
                              total: 893,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 893,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '283',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '2111',
                          durationInMin: 115,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666559400000,
                          departureDate: '2022-10-24T02:40',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666566300000,
                          arrrivalDate: '2022-10-24T04:35',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '2111',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666648800000,
                        departure: 1666626600000,
                        numberOfStops: 1,
                        duration: 370,
                      },
                      key: 'I5|1569|1666626600000|1666648800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_11BOMBLRI51569BLRDELI5722_18182007607892@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 11004.91,
                            publishedFare: 11157,
                            comission: 152.09,
                            bookingClass: 'SM',
                            baseFare: 10190,
                            taxes: {
                              total: 967,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 514,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_11BOMBLRI51569BLRDELI5722_18182007608352@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PREMIUM_FLEX',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 11118.26,
                            publishedFare: 11272,
                            comission: 153.74,
                            bookingClass: 'HF',
                            baseFare: 10301,
                            taxes: {
                              total: 971,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 518,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '891',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '1569',
                          durationInMin: 110,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666626600000,
                          departureDate: '2022-10-24T21:20',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666633200000,
                          arrrivalDate: '2022-10-24T23:10',
                        },
                        {
                          id: '892',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '722',
                          durationInMin: 170,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666638600000,
                          departureDate: '2022-10-25T00:40',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666648800000,
                          arrrivalDate: '2022-10-25T03:30',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '1569',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666620600000,
                        departure: 1666603500000,
                        numberOfStops: 1,
                        duration: 285,
                      },
                      key: '6E|5327|1666603500000|1666620600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_28BOMAMD6E5327AMDDEL6E2032_18188342293708@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 8666.31,
                            publishedFare: 8833,
                            comission: 166.69,
                            bookingClass: 'T',
                            baseFare: 7902,
                            taxes: {
                              total: 931,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 931,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_28BOMAMD6E5327AMDDEL6E2032_18188342293218@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 10342.91,
                            publishedFare: 10908,
                            comission: 565.09,
                            bookingClass: 'M',
                            baseFare: 9545,
                            taxes: {
                              total: 1363,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1363,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_28BOMAMD6E5327AMDDEL6E2032_18188342293468@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'FLEXI_PLUS',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 11385.56,
                            publishedFare: 11608,
                            comission: 222.44,
                            bookingClass: 'J',
                            baseFare: 10545,
                            taxes: {
                              total: 1063,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1063,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '267',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '5327',
                          durationInMin: 65,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666603500000,
                          departureDate: '2022-10-24T14:55',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666607400000,
                          arrrivalDate: '2022-10-24T16:00',
                        },
                        {
                          id: '268',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '2032',
                          durationInMin: 95,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666614900000,
                          departureDate: '2022-10-24T18:05',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666620600000,
                          arrrivalDate: '2022-10-24T19:40',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '5327',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666575600000,
                        departure: 1666567200000,
                        numberOfStops: 0,
                        duration: 140,
                      },
                      key: '6E|2056|1666567200000|1666575600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_22BOMDEL6E2056_18188342273097@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 9217.78,
                            publishedFare: 9721,
                            comission: 503.22,
                            bookingClass: 'M',
                            baseFare: 8500,
                            taxes: {
                              total: 1221,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1221,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_22BOMDEL6E2056_18188342273927@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 9228.7,
                            publishedFare: 9408,
                            comission: 179.3,
                            bookingClass: 'R',
                            baseFare: 8500,
                            taxes: {
                              total: 908,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 908,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '873',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '2056',
                          durationInMin: 140,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666567200000,
                          departureDate: '2022-10-24T04:50',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666575600000,
                          arrrivalDate: '2022-10-24T07:10',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '2056',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666602900000,
                        departure: 1666595400000,
                        numberOfStops: 0,
                        duration: 125,
                      },
                      key: '6E|5356|1666595400000|1666602900000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_7BOMDEL6E5356_18188342241285@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 7675.58,
                            publishedFare: 8090,
                            comission: 414.42,
                            bookingClass: 'M',
                            baseFare: 7000,
                            taxes: {
                              total: 1090,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1090,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_7BOMDEL6E5356_18188342241745@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 7685.34,
                            publishedFare: 7833,
                            comission: 147.66,
                            bookingClass: 'R',
                            baseFare: 7000,
                            taxes: {
                              total: 833,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 833,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '316',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '5356',
                          durationInMin: 125,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666595400000,
                          departureDate: '2022-10-24T12:40',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666602900000,
                          arrrivalDate: '2022-10-24T14:45',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '5356',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666624500000,
                        departure: 1666616700000,
                        numberOfStops: 0,
                        duration: 130,
                      },
                      key: '6E|2113|1666616700000|1666624500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_5BOMDEL6E2113_18188342235685@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6117.49,
                            publishedFare: 6233,
                            comission: 115.51,
                            bookingClass: 'T',
                            baseFare: 5476,
                            taxes: {
                              total: 757,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 757,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_5BOMDEL6E2113_18188342235225@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7162.18,
                            publishedFare: 7547,
                            comission: 384.82,
                            bookingClass: 'M',
                            baseFare: 6500,
                            taxes: {
                              total: 1047,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1047,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_5BOMDEL6E2113_18188342235885@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7170.89,
                            publishedFare: 7308,
                            comission: 137.11,
                            bookingClass: 'R',
                            baseFare: 6500,
                            taxes: {
                              total: 808,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 808,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '856',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '2113',
                          durationInMin: 130,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666616700000,
                          departureDate: '2022-10-24T18:35',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666624500000,
                          arrrivalDate: '2022-10-24T20:45',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '2113',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666615800000,
                        departure: 1666607400000,
                        numberOfStops: 0,
                        duration: 140,
                      },
                      key: 'AI|687|1666607400000|1666615800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_7BOMDELAI687_18185014634286@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6149.94,
                            publishedFare: 6258,
                            comission: 108.06,
                            bookingClass: 'S',
                            baseFare: 5430,
                            taxes: {
                              total: 828,
                              breakups: {
                                'Carrier Misc': 170,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '231',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '687',
                          durationInMin: 140,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666607400000,
                          departureDate: '2022-10-24T16:00',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666615800000,
                          arrrivalDate: '2022-10-24T18:20',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '687',
                        equipment: '77W',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666620600000,
                        departure: 1666601400000,
                        numberOfStops: 1,
                        duration: 320,
                      },
                      key: '6E|967|1666601400000|1666620600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_21BOMDED6E967DEDDEL6E942_18188342270796@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 9077.81,
                            publishedFare: 9576,
                            comission: 498.19,
                            bookingClass: 'M',
                            baseFare: 8415,
                            taxes: {
                              total: 1161,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1161,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_21BOMDED6E967DEDDEL6E942_18188342271216@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 8301.63,
                            publishedFare: 8463,
                            comission: 161.37,
                            bookingClass: 'R',
                            baseFare: 7650,
                            taxes: {
                              total: 813,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 813,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '749',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '967',
                          durationInMin: 135,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666601400000,
                          departureDate: '2022-10-24T14:20',
                          arrival: {
                            code: 'DED',
                            airport: 'Jolly Grant Airport',
                            city: 'Dehra Dun',
                            country: 'India',
                          },
                          arrivalTime: 1666609500000,
                          arrrivalDate: '2022-10-24T16:35',
                        },
                        {
                          id: '750',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '942',
                          durationInMin: 40,
                          departure: {
                            code: 'DED',
                            airport: 'Jolly Grant Airport',
                            city: 'Dehra Dun',
                            country: 'India',
                          },
                          departureTime: 1666618200000,
                          departureDate: '2022-10-24T19:00',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666620600000,
                          arrrivalDate: '2022-10-24T19:40',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '967',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666594200000,
                        departure: 1666585800000,
                        numberOfStops: 0,
                        duration: 140,
                      },
                      key: 'AI|809|1666585800000|1666594200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_6BOMDELAI809_18185014633146@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6149.94,
                            publishedFare: 6258,
                            comission: 108.06,
                            bookingClass: 'S',
                            baseFare: 5430,
                            taxes: {
                              total: 828,
                              breakups: {
                                'Carrier Misc': 170,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '345',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '809',
                          durationInMin: 140,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666585800000,
                          departureDate: '2022-10-24T10:00',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666594200000,
                          arrrivalDate: '2022-10-24T12:20',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '809',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666613700000,
                        departure: 1666575000000,
                        numberOfStops: 1,
                        duration: 645,
                      },
                      key: 'UK|771|1666575000000|1666613700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_91BOMCCUUK771CCUDELUK778_18185014756452@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 20985.17,
                            publishedFare: 21378,
                            comission: 392.83,
                            bookingClass: 'H',
                            baseFare: 19740,
                            taxes: {
                              total: 1638,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 1000,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '663',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '771',
                          durationInMin: 160,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666575000000,
                          departureDate: '2022-10-24T07:00',
                          arrival: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          arrivalTime: 1666584600000,
                          arrrivalDate: '2022-10-24T09:40',
                        },
                        {
                          id: '664',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '778',
                          durationInMin: 145,
                          departure: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          departureTime: 1666605000000,
                          departureDate: '2022-10-24T15:20',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666613700000,
                          arrrivalDate: '2022-10-24T17:45',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '771',
                        equipment: '73H',
                      },
                    },
                    {
                      remaining: 8,
                      details: {
                        arrival: 1666629300000,
                        departure: 1666597800000,
                        numberOfStops: 1,
                        duration: 525,
                      },
                      key: 'I5|765|1666597800000|1666629300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_13BOMLKOI5765LKODELI5330_18182007612763@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 11273.03,
                            publishedFare: 11429,
                            comission: 155.97,
                            bookingClass: 'SM',
                            baseFare: 10450,
                            taxes: {
                              total: 979,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 526,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_13BOMLKOI5765LKODELI5330_18182007613003@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 10652.97,
                            publishedFare: 10800,
                            comission: 147.03,
                            bookingClass: 'EC',
                            baseFare: 9851,
                            taxes: {
                              total: 949,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 496,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '993',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '765',
                          durationInMin: 135,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666597800000,
                          departureDate: '2022-10-24T13:20',
                          arrival: {
                            code: 'LKO',
                            airport: 'Amausi Arpt',
                            city: 'Lucknow',
                            country: 'India',
                          },
                          arrivalTime: 1666605900000,
                          arrrivalDate: '2022-10-24T15:35',
                        },
                        {
                          id: '994',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '330',
                          durationInMin: 75,
                          departure: {
                            code: 'LKO',
                            airport: 'Amausi Arpt',
                            city: 'Lucknow',
                            country: 'India',
                          },
                          departureTime: 1666624800000,
                          departureDate: '2022-10-24T20:50',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666629300000,
                          arrrivalDate: '2022-10-24T22:05',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '765',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666626900000,
                        departure: 1666607400000,
                        numberOfStops: 1,
                        duration: 325,
                      },
                      key: 'UK|853|1666607400000|1666626900000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_25BOMBLRUK853BLRDELUK802_18185014668017@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 9267.19,
                            publishedFare: 9944,
                            comission: 676.81,
                            bookingClass: 'Q',
                            baseFare: 8850,
                            taxes: {
                              total: 1094,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 456,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_25BOMBLRUK853BLRDELUK802_18185014667077@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 9267.19,
                            publishedFare: 9944,
                            comission: 676.81,
                            bookingClass: 'Q',
                            baseFare: 8850,
                            taxes: {
                              total: 1094,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 456,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '50',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '853',
                          durationInMin: 105,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666607400000,
                          departureDate: '2022-10-24T16:00',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666613700000,
                          arrrivalDate: '2022-10-24T17:45',
                        },
                        {
                          id: '51',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '802',
                          durationInMin: 160,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666617300000,
                          departureDate: '2022-10-24T18:45',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666626900000,
                          arrrivalDate: '2022-10-24T21:25',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '853',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666604700000,
                        departure: 1666570500000,
                        numberOfStops: 1,
                        duration: 570,
                      },
                      key: 'UK|543|1666570500000|1666604700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_67BOMBBIUK543BBIDELUK788_18185014728210@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 18203.9,
                            publishedFare: 18543,
                            comission: 339.1,
                            bookingClass: 'H',
                            baseFare: 17040,
                            taxes: {
                              total: 1503,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 865,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_67BOMBBIUK543BBIDELUK788_18185014728060@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 21065.05,
                            publishedFare: 21434,
                            comission: 368.95,
                            bookingClass: 'T',
                            baseFare: 18540,
                            taxes: {
                              total: 2894,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 2256,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '328',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '543',
                          durationInMin: 135,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666570500000,
                          departureDate: '2022-10-24T05:45',
                          arrival: {
                            code: 'BBI',
                            airport: 'Biju Patnaik Arpt',
                            city: 'Bhubaneswar',
                            country: 'India',
                          },
                          arrivalTime: 1666578600000,
                          arrrivalDate: '2022-10-24T08:00',
                        },
                        {
                          id: '329',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '788',
                          durationInMin: 135,
                          departure: {
                            code: 'BBI',
                            airport: 'Biju Patnaik Arpt',
                            city: 'Bhubaneswar',
                            country: 'India',
                          },
                          departureTime: 1666596600000,
                          departureDate: '2022-10-24T13:00',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666604700000,
                          arrrivalDate: '2022-10-24T15:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '543',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666625100000,
                        departure: 1666609800000,
                        numberOfStops: 1,
                        duration: 255,
                      },
                      key: '6E|802|1666609800000|1666625100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_6BOMAMD6E802AMDDEL6E6941_18188342238265@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 5615.83,
                            publishedFare: 5720,
                            comission: 104.17,
                            bookingClass: 'T',
                            baseFare: 4938,
                            taxes: {
                              total: 782,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 782,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_6BOMAMD6E802AMDDEL6E6941_18188342237675@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7297.39,
                            publishedFare: 7687,
                            comission: 389.61,
                            bookingClass: 'M',
                            baseFare: 6581,
                            taxes: {
                              total: 1106,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1106,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_6BOMAMD6E802AMDDEL6E6941_18188342238015@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'FLEXI_PLUS',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 8206.72,
                            publishedFare: 8364,
                            comission: 157.28,
                            bookingClass: 'J',
                            baseFare: 7456,
                            taxes: {
                              total: 908,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 908,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '340',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '802',
                          durationInMin: 75,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666609800000,
                          departureDate: '2022-10-24T16:40',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666614300000,
                          arrrivalDate: '2022-10-24T17:55',
                        },
                        {
                          id: '341',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '6941',
                          durationInMin: 90,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666619700000,
                          departureDate: '2022-10-24T19:25',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666625100000,
                          arrrivalDate: '2022-10-24T20:55',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '802',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666598700000,
                        departure: 1666578000000,
                        numberOfStops: 1,
                        duration: 345,
                      },
                      key: '6E|5275|1666578000000|1666598700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_30BOMAMD6E5275AMDDEL6E2146_18188342299138@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 11443.57,
                            publishedFare: 12072,
                            comission: 628.43,
                            bookingClass: 'M',
                            baseFare: 10615,
                            taxes: {
                              total: 1457,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1457,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_30BOMAMD6E5275AMDDEL6E2146_18188342299618@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 10464.44,
                            publishedFare: 10668,
                            comission: 203.56,
                            bookingClass: 'R',
                            baseFare: 9650,
                            taxes: {
                              total: 1018,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1018,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '595',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '5275',
                          durationInMin: 85,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666578000000,
                          departureDate: '2022-10-24T07:50',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666583100000,
                          arrrivalDate: '2022-10-24T09:15',
                        },
                        {
                          id: '596',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '2146',
                          durationInMin: 100,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666592700000,
                          departureDate: '2022-10-24T11:55',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666598700000,
                          arrrivalDate: '2022-10-24T13:35',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '5275',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666589400000,
                        departure: 1666581300000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: 'UK|970|1666581300000|1666589400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_13BOMDELUK970_18185014650436@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 8415.37,
                            publishedFare: 8568,
                            comission: 152.63,
                            bookingClass: 'K',
                            baseFare: 7670,
                            taxes: {
                              total: 898,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 390,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_13BOMDELUK970_18185014649046@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 8415.37,
                            publishedFare: 8568,
                            comission: 152.63,
                            bookingClass: 'K',
                            baseFare: 7670,
                            taxes: {
                              total: 898,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 390,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '750',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '970',
                          durationInMin: 135,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666581300000,
                          departureDate: '2022-10-24T08:45',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666589400000,
                          arrrivalDate: '2022-10-24T11:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '970',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666718100000,
                        departure: 1666626600000,
                        numberOfStops: 1,
                        duration: 1525,
                      },
                      key: 'UK|869|1666626600000|1666718100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_31BOMHYDUK869HYDDELUK870_18185014683778@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 11816.3,
                            publishedFare: 12032,
                            comission: 215.7,
                            bookingClass: 'Q',
                            baseFare: 10839,
                            taxes: {
                              total: 1193,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 555,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_31BOMHYDUK869HYDDELUK870_18185014682778@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 10168.14,
                            publishedFare: 10352,
                            comission: 183.86,
                            bookingClass: 'Q',
                            baseFare: 9239,
                            taxes: {
                              total: 1113,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 475,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '695',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '869',
                          durationInMin: 80,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666626600000,
                          departureDate: '2022-10-24T21:20',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666631400000,
                          arrrivalDate: '2022-10-24T22:40',
                        },
                        {
                          id: '696',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '870',
                          durationInMin: 135,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666710000000,
                          departureDate: '2022-10-25T20:30',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666718100000,
                          arrrivalDate: '2022-10-25T22:45',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '869',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666668300000,
                        departure: 1666609800000,
                        numberOfStops: 1,
                        duration: 975,
                      },
                      key: 'AI|607|1666609800000|1666668300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_24BOMBLRAI607BLRDELAI804_18185014665967@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 9450.63,
                            publishedFare: 9619,
                            comission: 168.37,
                            bookingClass: 'W',
                            baseFare: 8461,
                            taxes: {
                              total: 1158,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 440,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '638',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '607',
                          durationInMin: 100,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666609800000,
                          departureDate: '2022-10-24T16:40',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666615800000,
                          arrrivalDate: '2022-10-24T18:20',
                        },
                        {
                          id: '639',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '804',
                          durationInMin: 165,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666658400000,
                          departureDate: '2022-10-25T06:10',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666668300000,
                          arrrivalDate: '2022-10-25T08:55',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '607',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 6,
                      details: {
                        arrival: 1666594800000,
                        departure: 1666572600000,
                        numberOfStops: 1,
                        duration: 370,
                      },
                      key: 'UK|873|1666572600000|1666594800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_60BOMHYDUK873HYDDELUK830_18185014717910@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 16143.7,
                            publishedFare: 16443,
                            comission: 299.3,
                            bookingClass: 'A',
                            baseFare: 15040,
                            taxes: {
                              total: 1403,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 765,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_60BOMHYDUK873HYDDELUK830_18185014717750@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 6,
                            offeredFare: 16939.68,
                            publishedFare: 17234,
                            comission: 294.32,
                            bookingClass: 'T',
                            baseFare: 14790,
                            taxes: {
                              total: 2444,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 1806,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '854',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '873',
                          durationInMin: 90,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666572600000,
                          departureDate: '2022-10-24T06:20',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666578000000,
                          arrrivalDate: '2022-10-24T07:50',
                        },
                        {
                          id: '855',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '830',
                          durationInMin: 140,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666586400000,
                          departureDate: '2022-10-24T10:10',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666594800000,
                          arrrivalDate: '2022-10-24T12:30',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '873',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 8,
                      details: {
                        arrival: 1666715700000,
                        departure: 1666618200000,
                        numberOfStops: 1,
                        duration: 1625,
                      },
                      key: 'AI|641|1666618200000|1666715700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_17BOMBLRAI641BLRDELAI509_18185014655047@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 8575.54,
                            publishedFare: 8727,
                            comission: 151.46,
                            bookingClass: 'G',
                            baseFare: 7611,
                            taxes: {
                              total: 1116,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 398,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '556',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '641',
                          durationInMin: 100,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666618200000,
                          departureDate: '2022-10-24T19:00',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666624200000,
                          arrrivalDate: '2022-10-24T20:40',
                        },
                        {
                          id: '557',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '509',
                          durationInMin: 170,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666705500000,
                          departureDate: '2022-10-25T19:15',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666715700000,
                          arrrivalDate: '2022-10-25T22:05',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '641',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666578600000,
                        departure: 1666570800000,
                        numberOfStops: 0,
                        duration: 130,
                      },
                      key: 'G8|329|1666570800000|1666578600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_0BOMDELG8329_18183418036411@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7319.11,
                            publishedFare: 7518,
                            comission: 198.89,
                            bookingClass: 'SP',
                            baseFare: 6663,
                            taxes: {
                              total: 855,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 855,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_0BOMDELG8329_18183418036991@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'GOMORE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 15480.31,
                            publishedFare: 15918,
                            comission: 437.69,
                            bookingClass: 'MG',
                            baseFare: 14663,
                            taxes: {
                              total: 1255,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1255,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_0BOMDELG8329_18183418036051@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7725.06,
                            publishedFare: 8226,
                            comission: 500.94,
                            bookingClass: 'BC',
                            baseFare: 7091,
                            taxes: {
                              total: 1135,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1135,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_0BOMDELG8329_18183418036671@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7828.18,
                            publishedFare: 8042,
                            comission: 213.82,
                            bookingClass: 'GS',
                            baseFare: 7163,
                            taxes: {
                              total: 879,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 879,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '400',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 0,
                          flightNumber: '329',
                          durationInMin: 130,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666570800000,
                          departureDate: '2022-10-24T05:50',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666578600000,
                          arrrivalDate: '2022-10-24T08:00',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '329',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666611300000,
                        departure: 1666590900000,
                        numberOfStops: 1,
                        duration: 340,
                      },
                      key: 'UK|841|1666590900000|1666611300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_50BOMGOIUK841GOIDELUK848_18185014706249@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 13156.41,
                            publishedFare: 13398,
                            comission: 241.59,
                            bookingClass: 'L',
                            baseFare: 12140,
                            taxes: {
                              total: 1258,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 620,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_50BOMGOIUK841GOIDELUK848_18185014705949@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 12126.31,
                            publishedFare: 12348,
                            comission: 221.69,
                            bookingClass: 'L',
                            baseFare: 11140,
                            taxes: {
                              total: 1208,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 570,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '388',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '841',
                          durationInMin: 80,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666590900000,
                          departureDate: '2022-10-24T11:25',
                          arrival: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          arrivalTime: 1666595700000,
                          arrrivalDate: '2022-10-24T12:45',
                        },
                        {
                          id: '389',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '848',
                          durationInMin: 160,
                          departure: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          departureTime: 1666601700000,
                          departureDate: '2022-10-24T14:25',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666611300000,
                          arrrivalDate: '2022-10-24T17:05',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '841',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666633500000,
                        departure: 1666618200000,
                        numberOfStops: 1,
                        duration: 255,
                      },
                      key: '6E|6993|1666618200000|1666633500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_23BOMAMD6E6993AMDDEL6E984_18188342275887@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 7972.53,
                            publishedFare: 8125,
                            comission: 152.47,
                            bookingClass: 'T',
                            baseFare: 7228,
                            taxes: {
                              total: 897,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 897,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_23BOMAMD6E6993AMDDEL6E984_18188342275427@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 9475.94,
                            publishedFare: 9991,
                            comission: 515.06,
                            bookingClass: 'M',
                            baseFare: 8700,
                            taxes: {
                              total: 1291,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1291,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_23BOMAMD6E6993AMDDEL6E984_18188342275637@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'FLEXI_PLUS',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 10258.66,
                            publishedFare: 10458,
                            comission: 199.34,
                            bookingClass: 'J',
                            baseFare: 9450,
                            taxes: {
                              total: 1008,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1008,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '777',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '6993',
                          durationInMin: 80,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666618200000,
                          departureDate: '2022-10-24T19:00',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666623000000,
                          arrrivalDate: '2022-10-24T20:20',
                        },
                        {
                          id: '778',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '984',
                          durationInMin: 95,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666627800000,
                          departureDate: '2022-10-24T21:40',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666633500000,
                          arrrivalDate: '2022-10-24T23:15',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '6993',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 7,
                      details: {
                        arrival: 1666631400000,
                        departure: 1666613700000,
                        numberOfStops: 1,
                        duration: 295,
                      },
                      key: '6E|6735|1666613700000|1666631400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_29BOMBDQ6E6735BDQDEL6E6246_18188342296218@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 7,
                            offeredFare: 11318.73,
                            publishedFare: 11940,
                            comission: 621.27,
                            bookingClass: 'M',
                            baseFare: 10494,
                            taxes: {
                              total: 1446,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1446,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_29BOMBDQ6E6735BDQDEL6E6246_18188342296438@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'FLEXI_PLUS',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 7,
                            offeredFare: 12361.54,
                            publishedFare: 12604,
                            comission: 242.46,
                            bookingClass: 'J',
                            baseFare: 11494,
                            taxes: {
                              total: 1110,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1110,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '14',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '6735',
                          durationInMin: 65,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666613700000,
                          departureDate: '2022-10-24T17:45',
                          arrival: {
                            code: 'BDQ',
                            airport: 'Vadodara Arpt',
                            city: 'Vadodara',
                            country: 'India',
                          },
                          arrivalTime: 1666617600000,
                          arrrivalDate: '2022-10-24T18:50',
                        },
                        {
                          id: '15',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '6246',
                          durationInMin: 100,
                          departure: {
                            code: 'BDQ',
                            airport: 'Vadodara Arpt',
                            city: 'Vadodara',
                            country: 'India',
                          },
                          departureTime: 1666625400000,
                          departureDate: '2022-10-24T21:00',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666631400000,
                          arrrivalDate: '2022-10-24T22:40',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '6735',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 7,
                      details: {
                        arrival: 1666580700000,
                        departure: 1666573200000,
                        numberOfStops: 0,
                        duration: 125,
                      },
                      key: 'UK|928|1666573200000|1666580700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_57BOMDELUK928_18185014714980@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 7,
                            offeredFare: 15561.97,
                            publishedFare: 15834,
                            comission: 272.03,
                            bookingClass: 'T',
                            baseFare: 13670,
                            taxes: {
                              total: 2164,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 1656,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_57BOMDELUK928_18185014714830@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 7,
                            offeredFare: 15561.97,
                            publishedFare: 15834,
                            comission: 272.03,
                            bookingClass: 'T',
                            baseFare: 13670,
                            taxes: {
                              total: 2164,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 1656,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '522',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '928',
                          durationInMin: 125,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666573200000,
                          departureDate: '2022-10-24T06:30',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666580700000,
                          arrrivalDate: '2022-10-24T08:35',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '928',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666621200000,
                        departure: 1666572000000,
                        numberOfStops: 1,
                        duration: 820,
                      },
                      key: 'AI|603|1666572000000|1666621200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_42BOMBLRAI603BLRDELAI503_18185014696029@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 11788.47,
                            publishedFare: 12002,
                            comission: 213.53,
                            bookingClass: 'Q',
                            baseFare: 10730,
                            taxes: {
                              total: 1272,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 554,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '531',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '603',
                          durationInMin: 100,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666572000000,
                          departureDate: '2022-10-24T06:10',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666578000000,
                          arrrivalDate: '2022-10-24T07:50',
                        },
                        {
                          id: '532',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '503',
                          durationInMin: 170,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666611000000,
                          departureDate: '2022-10-24T17:00',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666621200000,
                          arrrivalDate: '2022-10-24T19:50',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '603',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666621800000,
                        departure: 1666584900000,
                        numberOfStops: 1,
                        duration: 615,
                      },
                      key: 'UK|825|1666584900000|1666621800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_76BOMMAAUK825MAADELUK834_18185014738961@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 16658.75,
                            publishedFare: 16968,
                            comission: 309.25,
                            bookingClass: 'K',
                            baseFare: 15540,
                            taxes: {
                              total: 1428,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 790,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_76BOMMAAUK825MAADELUK834_18185014738801@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '35 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 33496.18,
                            publishedFare: 34090,
                            comission: 593.82,
                            bookingClass: 'Z',
                            baseFare: 29840,
                            taxes: {
                              total: 4250,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 3612,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '244',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '825',
                          durationInMin: 125,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666584900000,
                          departureDate: '2022-10-24T09:45',
                          arrival: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          arrivalTime: 1666592400000,
                          arrrivalDate: '2022-10-24T11:50',
                        },
                        {
                          id: '245',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '834',
                          durationInMin: 165,
                          departure: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          departureTime: 1666611900000,
                          departureDate: '2022-10-24T17:15',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666621800000,
                          arrrivalDate: '2022-10-24T20:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '825',
                        equipment: '73H',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666634400000,
                        departure: 1666614900000,
                        numberOfStops: 1,
                        duration: 325,
                      },
                      key: '6E|5386|1666614900000|1666634400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_14BOMHYD6E5386HYDDEL6E6684_18188342257246@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 10105.8,
                            publishedFare: 10302,
                            comission: 196.2,
                            bookingClass: 'T',
                            baseFare: 9301,
                            taxes: {
                              total: 1001,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1001,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_14BOMHYD6E5386HYDDEL6E6684_18188342256586@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7825.96,
                            publishedFare: 8246,
                            comission: 420.04,
                            bookingClass: 'M',
                            baseFare: 7095,
                            taxes: {
                              total: 1151,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1151,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_14BOMHYD6E5386HYDDEL6E6684_18188342257026@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7171.94,
                            publishedFare: 7308,
                            comission: 136.06,
                            bookingClass: 'R',
                            baseFare: 6450,
                            taxes: {
                              total: 858,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 858,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '377',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '5386',
                          durationInMin: 85,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666614900000,
                          departureDate: '2022-10-24T18:05',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666620000000,
                          arrrivalDate: '2022-10-24T19:30',
                        },
                        {
                          id: '378',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '6684',
                          durationInMin: 130,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666626600000,
                          departureDate: '2022-10-24T21:20',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666634400000,
                          arrrivalDate: '2022-10-24T23:30',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '5386',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666670700000,
                        departure: 1666600500000,
                        numberOfStops: 1,
                        duration: 1170,
                      },
                      key: 'UK|775|1666600500000|1666670700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_82BOMCCUUK775CCUDELUK720_18185014746711@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 7,
                            offeredFare: 20985.17,
                            publishedFare: 21378,
                            comission: 392.83,
                            bookingClass: 'H',
                            baseFare: 19740,
                            taxes: {
                              total: 1638,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 1000,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_82BOMCCUUK775CCUDELUK720_18185014746551@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '35 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 41065.27,
                            publishedFare: 41796,
                            comission: 730.73,
                            bookingClass: 'Z',
                            baseFare: 36720,
                            taxes: {
                              total: 5076,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 4438,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '385',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '775',
                          durationInMin: 170,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666600500000,
                          departureDate: '2022-10-24T14:05',
                          arrival: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          arrivalTime: 1666610700000,
                          arrrivalDate: '2022-10-24T16:55',
                        },
                        {
                          id: '386',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '720',
                          durationInMin: 145,
                          departure: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          departureTime: 1666662000000,
                          departureDate: '2022-10-25T07:10',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666670700000,
                          arrrivalDate: '2022-10-25T09:35',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '775',
                        equipment: '73H',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666594200000,
                        departure: 1666571400000,
                        numberOfStops: 1,
                        duration: 380,
                      },
                      key: 'AI|615|1666571400000|1666594200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_35BOMHYDAI615HYDDELAI543_18185014688978@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 11200.82,
                            publishedFare: 11403,
                            comission: 202.18,
                            bookingClass: 'S',
                            baseFare: 10160,
                            taxes: {
                              total: 1243,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 525,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '474',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '615',
                          durationInMin: 90,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666571400000,
                          departureDate: '2022-10-24T06:00',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666576800000,
                          arrrivalDate: '2022-10-24T07:30',
                        },
                        {
                          id: '475',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '543',
                          durationInMin: 135,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666586100000,
                          departureDate: '2022-10-24T10:05',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666594200000,
                          arrrivalDate: '2022-10-24T12:20',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '615',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666585500000,
                        departure: 1666577700000,
                        numberOfStops: 0,
                        duration: 130,
                      },
                      key: '6E|5341|1666577700000|1666585500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_0BOMDEL6E5341_18188342225504@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 6117.49,
                            publishedFare: 6233,
                            comission: 115.51,
                            bookingClass: 'T',
                            baseFare: 5476,
                            taxes: {
                              total: 757,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 757,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_0BOMDEL6E5341_18188342224304@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 7162.18,
                            publishedFare: 7547,
                            comission: 384.82,
                            bookingClass: 'M',
                            baseFare: 6500,
                            taxes: {
                              total: 1047,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1047,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_0BOMDEL6E5341_18188342225924@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 7170.89,
                            publishedFare: 7308,
                            comission: 137.11,
                            bookingClass: 'R',
                            baseFare: 6500,
                            taxes: {
                              total: 808,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 808,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '29',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '5341',
                          durationInMin: 130,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666577700000,
                          departureDate: '2022-10-24T07:45',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666585500000,
                          arrrivalDate: '2022-10-24T09:55',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '5341',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666702800000,
                        departure: 1666622400000,
                        numberOfStops: 1,
                        duration: 1340,
                      },
                      key: 'AI|619|1666622400000|1666702800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_45BOMHYDAI619HYDDELAI541_18185014698889@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 11818.88,
                            publishedFare: 12033,
                            comission: 214.12,
                            bookingClass: 'G',
                            baseFare: 10760,
                            taxes: {
                              total: 1273,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 555,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '703',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '619',
                          durationInMin: 90,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666622400000,
                          departureDate: '2022-10-24T20:10',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666627800000,
                          arrrivalDate: '2022-10-24T21:40',
                        },
                        {
                          id: '704',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '541',
                          durationInMin: 140,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666694400000,
                          departureDate: '2022-10-25T16:10',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666702800000,
                          arrrivalDate: '2022-10-25T18:30',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '619',
                        equipment: '319',
                      },
                    },
                    {
                      remaining: 71,
                      details: {
                        arrival: 1666635000000,
                        departure: 1666626900000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: 'SG|8158|1666626900000|1666635000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '4-2158784108_2BOMDELSG8158_18183742624182@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 71,
                            offeredFare: 5545.37,
                            publishedFare: 5655,
                            comission: 109.63,
                            bookingClass: 'DD',
                            baseFare: 4897,
                            taxes: {
                              total: 758,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 508,
                                'Management Fees': 0,
                                'Gst Charges': 250,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_2BOMDELSG8158_18183742624722@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 71,
                            offeredFare: 6043.56,
                            publishedFare: 6164,
                            comission: 120.44,
                            bookingClass: 'SA',
                            baseFare: 5380,
                            taxes: {
                              total: 784,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 508,
                                'Management Fees': 0,
                                'Gst Charges': 276,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_2BOMDELSG8158_18183742623882@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 71,
                            offeredFare: 5715.03,
                            publishedFare: 6036,
                            comission: 320.97,
                            bookingClass: 'CP',
                            baseFare: 5080,
                            taxes: {
                              total: 956,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 686,
                                'Management Fees': 0,
                                'Gst Charges': 270,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_2BOMDELSG8158_18183742625202@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 71,
                            offeredFare: 6465.54,
                            publishedFare: 7308,
                            comission: 842.46,
                            bookingClass: 'HR',
                            baseFare: 5075,
                            taxes: {
                              total: 2233,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1973,
                                'Management Fees': 0,
                                'Gst Charges': 260,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '972',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 0,
                          flightNumber: '8158',
                          durationInMin: 135,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666626900000,
                          departureDate: '2022-10-24T21:25',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666635000000,
                          arrrivalDate: '2022-10-24T23:40',
                        },
                      ],
                      operator: {
                        name: 'SpiceJet',
                        code: 'SG',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/SG.png',
                        number: '8158',
                        equipment: '7M8',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666589100000,
                        departure: 1666565400000,
                        numberOfStops: 1,
                        duration: 395,
                      },
                      key: 'I5|331|1666565400000|1666589100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_0BOMGOII5331GOIDELI5315_18182007574591@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7288.5,
                            publishedFare: 7387,
                            comission: 98.5,
                            bookingClass: 'SM',
                            baseFare: 6600,
                            taxes: {
                              total: 787,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 334,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_0BOMGOII5331GOIDELI5315_18182007575221@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7288.5,
                            publishedFare: 7387,
                            comission: 98.5,
                            bookingClass: 'EC',
                            baseFare: 6600,
                            taxes: {
                              total: 787,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 334,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '931',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '331',
                          durationInMin: 70,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666565400000,
                          departureDate: '2022-10-24T04:20',
                          arrival: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          arrivalTime: 1666569600000,
                          arrrivalDate: '2022-10-24T05:30',
                        },
                        {
                          id: '932',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '315',
                          durationInMin: 155,
                          departure: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          departureTime: 1666579800000,
                          departureDate: '2022-10-24T08:20',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666589100000,
                          arrrivalDate: '2022-10-24T10:55',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '331',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666649700000,
                        departure: 1666568100000,
                        numberOfStops: 1,
                        duration: 1360,
                      },
                      key: 'AI|685|1666568100000|1666649700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_33BOMGOIAI685GOIDELAI884_18185014686738@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 11097.81,
                            publishedFare: 11298,
                            comission: 200.19,
                            bookingClass: 'G',
                            baseFare: 10060,
                            taxes: {
                              total: 1238,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 520,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '287',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '685',
                          durationInMin: 75,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666568100000,
                          departureDate: '2022-10-24T05:05',
                          arrival: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          arrivalTime: 1666572600000,
                          arrrivalDate: '2022-10-24T06:20',
                        },
                        {
                          id: '288',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '884',
                          durationInMin: 150,
                          departure: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          departureTime: 1666640700000,
                          departureDate: '2022-10-25T01:15',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666649700000,
                          arrrivalDate: '2022-10-25T03:45',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '685',
                        equipment: '319',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666600500000,
                        departure: 1666592700000,
                        numberOfStops: 0,
                        duration: 130,
                      },
                      key: 'UK|960|1666592700000|1666600500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_12BOMDELUK960_18185014645806@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 8415.37,
                            publishedFare: 8568,
                            comission: 152.63,
                            bookingClass: 'K',
                            baseFare: 7670,
                            taxes: {
                              total: 898,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 390,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_12BOMDELUK960_18185014645036@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 8415.37,
                            publishedFare: 8568,
                            comission: 152.63,
                            bookingClass: 'K',
                            baseFare: 7670,
                            taxes: {
                              total: 898,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 390,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '578',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '960',
                          durationInMin: 130,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666592700000,
                          departureDate: '2022-10-24T11:55',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666600500000,
                          arrrivalDate: '2022-10-24T14:05',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '960',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666717800000,
                        departure: 1666621800000,
                        numberOfStops: 1,
                        duration: 1600,
                      },
                      key: 'UK|949|1666621800000|1666717800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_16BOMAMDUK949AMDDELUK512_18185014653887@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 8552.37,
                            publishedFare: 8705,
                            comission: 152.63,
                            bookingClass: 'K',
                            baseFare: 7670,
                            taxes: {
                              total: 1035,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 397,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '770',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '949',
                          durationInMin: 85,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666621800000,
                          departureDate: '2022-10-24T20:00',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666626900000,
                          arrrivalDate: '2022-10-24T21:25',
                        },
                        {
                          id: '771',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '512',
                          durationInMin: 100,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666711800000,
                          departureDate: '2022-10-25T21:00',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666717800000,
                          arrrivalDate: '2022-10-25T22:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '949',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666632600000,
                        departure: 1666571400000,
                        numberOfStops: 1,
                        duration: 1020,
                      },
                      key: 'AI|615|1666571400000|1666632600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_37BOMHYDAI615HYDDELAI840_18185014690898@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 11200.82,
                            publishedFare: 11403,
                            comission: 202.18,
                            bookingClass: 'S',
                            baseFare: 10160,
                            taxes: {
                              total: 1243,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 525,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '431',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '615',
                          durationInMin: 90,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666571400000,
                          departureDate: '2022-10-24T06:00',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666576800000,
                          arrrivalDate: '2022-10-24T07:30',
                        },
                        {
                          id: '432',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '840',
                          durationInMin: 140,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666624200000,
                          departureDate: '2022-10-24T20:40',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666632600000,
                          arrrivalDate: '2022-10-24T23:00',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '615',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666648800000,
                        departure: 1666618200000,
                        numberOfStops: 1,
                        duration: 510,
                      },
                      key: 'I5|338|1666618200000|1666648800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_9BOMBLRI5338BLRDELI5722_18182007602252@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 11004.91,
                            publishedFare: 11157,
                            comission: 152.09,
                            bookingClass: 'SM',
                            baseFare: 10190,
                            taxes: {
                              total: 967,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 514,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_9BOMBLRI5338BLRDELI5722_18182007602822@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PREMIUM_FLEX',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 11118.26,
                            publishedFare: 11272,
                            comission: 153.74,
                            bookingClass: 'HF',
                            baseFare: 10301,
                            taxes: {
                              total: 971,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 518,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '429',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '338',
                          durationInMin: 110,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666618200000,
                          departureDate: '2022-10-24T19:00',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666624800000,
                          arrrivalDate: '2022-10-24T20:50',
                        },
                        {
                          id: '430',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '722',
                          durationInMin: 170,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666638600000,
                          departureDate: '2022-10-25T00:40',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666648800000,
                          arrrivalDate: '2022-10-25T03:30',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '338',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666666200000,
                        departure: 1666631100000,
                        numberOfStops: 1,
                        duration: 585,
                      },
                      key: 'UK|919|1666631100000|1666666200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_70BOMAMDUK919AMDDELUK926_18185014732180@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 17667.66,
                            publishedFare: 18464,
                            comission: 796.34,
                            bookingClass: 'O',
                            baseFare: 16965,
                            taxes: {
                              total: 1499,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 861,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_70BOMAMDUK919AMDDELUK926_18185014732030@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '30 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 21900.93,
                            publishedFare: 22285,
                            comission: 384.07,
                            bookingClass: 'Z',
                            baseFare: 19300,
                            taxes: {
                              total: 2985,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 2347,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '198',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '919',
                          durationInMin: 75,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666631100000,
                          departureDate: '2022-10-24T22:35',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666635600000,
                          arrrivalDate: '2022-10-24T23:50',
                        },
                        {
                          id: '199',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '926',
                          durationInMin: 95,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666660500000,
                          departureDate: '2022-10-25T06:45',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666666200000,
                          arrrivalDate: '2022-10-25T08:20',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '919',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 7,
                      details: {
                        arrival: 1666582500000,
                        departure: 1666575000000,
                        numberOfStops: 0,
                        duration: 125,
                      },
                      key: 'AI|864|1666575000000|1666582500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_14BOMDELAI864_18185014651697@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 7,
                            offeredFare: 8416.16,
                            publishedFare: 8568,
                            comission: 151.84,
                            bookingClass: 'W',
                            baseFare: 7630,
                            taxes: {
                              total: 938,
                              breakups: {
                                'Carrier Misc': 170,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 390,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '737',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '864',
                          durationInMin: 125,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666575000000,
                          departureDate: '2022-10-24T07:00',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666582500000,
                          arrrivalDate: '2022-10-24T09:05',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '864',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666670700000,
                        departure: 1666612200000,
                        numberOfStops: 1,
                        duration: 975,
                      },
                      key: 'UK|773|1666612200000|1666670700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_80BOMCCUUK773CCUDELUK720_18185014744121@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 20985.17,
                            publishedFare: 21378,
                            comission: 392.83,
                            bookingClass: 'H',
                            baseFare: 19740,
                            taxes: {
                              total: 1638,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 1000,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_80BOMCCUUK773CCUDELUK720_18185014743951@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '35 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 41065.27,
                            publishedFare: 41796,
                            comission: 730.73,
                            bookingClass: 'Z',
                            baseFare: 36720,
                            taxes: {
                              total: 5076,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 4438,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '283',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '773',
                          durationInMin: 155,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666612200000,
                          departureDate: '2022-10-24T17:20',
                          arrival: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          arrivalTime: 1666621500000,
                          arrrivalDate: '2022-10-24T19:55',
                        },
                        {
                          id: '284',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '720',
                          durationInMin: 145,
                          departure: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          departureTime: 1666662000000,
                          departureDate: '2022-10-25T07:10',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666670700000,
                          arrrivalDate: '2022-10-25T09:35',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '773',
                        equipment: '73H',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666629000000,
                        departure: 1666610700000,
                        numberOfStops: 1,
                        duration: 305,
                      },
                      key: '6E|399|1666610700000|1666629000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_12BOMGOI6E399GOIDEL6E2119_18188342250515@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 10413.5,
                            publishedFare: 10616,
                            comission: 202.5,
                            bookingClass: 'T',
                            baseFare: 9600,
                            taxes: {
                              total: 1016,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1016,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_12BOMGOI6E399GOIDEL6E2119_18188342249635@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7825.96,
                            publishedFare: 8246,
                            comission: 420.04,
                            bookingClass: 'M',
                            baseFare: 7095,
                            taxes: {
                              total: 1151,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1151,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_12BOMGOI6E399GOIDEL6E2119_18188342250275@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7171.94,
                            publishedFare: 7308,
                            comission: 136.06,
                            bookingClass: 'R',
                            baseFare: 6450,
                            taxes: {
                              total: 858,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 858,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '617',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '399',
                          durationInMin: 75,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666610700000,
                          departureDate: '2022-10-24T16:55',
                          arrival: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          arrivalTime: 1666615200000,
                          arrrivalDate: '2022-10-24T18:10',
                        },
                        {
                          id: '618',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '2119',
                          durationInMin: 165,
                          departure: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          departureTime: 1666619100000,
                          departureDate: '2022-10-24T19:15',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666629000000,
                          arrrivalDate: '2022-10-24T22:00',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '399',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666636800000,
                        departure: 1666609800000,
                        numberOfStops: 1,
                        duration: 450,
                      },
                      key: 'AI|607|1666609800000|1666636800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_22BOMBLRAI607BLRDELAI808_18185014663707@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 9450.63,
                            publishedFare: 9619,
                            comission: 168.37,
                            bookingClass: 'W',
                            baseFare: 8461,
                            taxes: {
                              total: 1158,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 440,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '959',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '607',
                          durationInMin: 100,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666609800000,
                          departureDate: '2022-10-24T16:40',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666615800000,
                          arrrivalDate: '2022-10-24T18:20',
                        },
                        {
                          id: '960',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '808',
                          durationInMin: 170,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666626600000,
                          departureDate: '2022-10-24T21:20',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666636800000,
                          arrrivalDate: '2022-10-25T00:10',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '607',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666631700000,
                        departure: 1666572600000,
                        numberOfStops: 1,
                        duration: 985,
                      },
                      key: 'UK|873|1666572600000|1666631700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_61BOMHYDUK873HYDDELUK870_18185014719300@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 16143.7,
                            publishedFare: 16443,
                            comission: 299.3,
                            bookingClass: 'A',
                            baseFare: 15040,
                            taxes: {
                              total: 1403,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 765,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_61BOMHYDUK873HYDDELUK870_18185014719140@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 16939.68,
                            publishedFare: 17234,
                            comission: 294.32,
                            bookingClass: 'T',
                            baseFare: 14790,
                            taxes: {
                              total: 2444,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 1806,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '459',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '873',
                          durationInMin: 90,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666572600000,
                          departureDate: '2022-10-24T06:20',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666578000000,
                          arrrivalDate: '2022-10-24T07:50',
                        },
                        {
                          id: '460',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '870',
                          durationInMin: 135,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666623600000,
                          departureDate: '2022-10-24T20:30',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666631700000,
                          arrrivalDate: '2022-10-24T22:45',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '873',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 7,
                      details: {
                        arrival: 1666623000000,
                        departure: 1666582500000,
                        numberOfStops: 1,
                        duration: 675,
                      },
                      key: 'UK|863|1666582500000|1666623000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [],
                      segments: [
                        {
                          id: '47',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '863',
                          durationInMin: 100,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666582500000,
                          departureDate: '2022-10-24T09:05',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666588500000,
                          arrrivalDate: '2022-10-24T10:45',
                        },
                        {
                          id: '48',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '820',
                          durationInMin: 155,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666613700000,
                          departureDate: '2022-10-24T17:45',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666623000000,
                          arrrivalDate: '2022-10-24T20:20',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '863',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666717800000,
                        departure: 1666631100000,
                        numberOfStops: 1,
                        duration: 1445,
                      },
                      key: 'UK|919|1666631100000|1666717800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_15BOMAMDUK919AMDDELUK512_18185014652807@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 8552.37,
                            publishedFare: 8705,
                            comission: 152.63,
                            bookingClass: 'K',
                            baseFare: 7670,
                            taxes: {
                              total: 1035,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 397,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '471',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '919',
                          durationInMin: 75,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666631100000,
                          departureDate: '2022-10-24T22:35',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666635600000,
                          arrrivalDate: '2022-10-24T23:50',
                        },
                        {
                          id: '472',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '512',
                          durationInMin: 100,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666711800000,
                          departureDate: '2022-10-25T21:00',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666717800000,
                          arrrivalDate: '2022-10-25T22:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '919',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666570200000,
                        departure: 1666551900000,
                        numberOfStops: 1,
                        duration: 305,
                      },
                      key: '6E|5293|1666551900000|1666570200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_33BOMAMD6E5293AMDDEL6E158_18188342308668@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 11576.63,
                            publishedFare: 11803,
                            comission: 226.37,
                            bookingClass: 'T',
                            baseFare: 10731,
                            taxes: {
                              total: 1072,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1072,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_33BOMAMD6E5293AMDDEL6E158_18188342308198@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 12487.35,
                            publishedFare: 13176,
                            comission: 688.65,
                            bookingClass: 'M',
                            baseFare: 11632,
                            taxes: {
                              total: 1544,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1544,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_33BOMAMD6E5293AMDDEL6E158_18188342308428@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'FLEXI_PLUS',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 13337.55,
                            publishedFare: 13600,
                            comission: 262.45,
                            bookingClass: 'J',
                            baseFare: 12442,
                            taxes: {
                              total: 1158,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1158,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '524',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '5293',
                          durationInMin: 70,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666551900000,
                          departureDate: '2022-10-24T00:35',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666556100000,
                          arrrivalDate: '2022-10-24T01:45',
                        },
                        {
                          id: '525',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '158',
                          durationInMin: 90,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666564800000,
                          departureDate: '2022-10-24T04:10',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666570200000,
                          arrrivalDate: '2022-10-24T05:40',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '5293',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666622700000,
                        departure: 1666617300000,
                        numberOfStops: 0,
                        duration: 90,
                      },
                      key: 'G8|287|1666617300000|1666622700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_11BOMSXRG8287SXRDELG8149_18183418066083@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7115.08,
                            publishedFare: 7308,
                            comission: 192.92,
                            bookingClass: 'GS',
                            baseFare: 6463,
                            taxes: {
                              total: 845,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 845,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '94',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 1,
                          flightNumber: '149',
                          durationInMin: 90,
                          departure: {
                            code: 'SXR',
                            airport: 'Srinagar Arpt',
                            city: 'Srinagar',
                            country: 'India',
                          },
                          departureTime: 1666617300000,
                          departureDate: '2022-10-24T18:45',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666622700000,
                          arrrivalDate: '2022-10-24T20:15',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '287',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666631400000,
                        departure: 1666600500000,
                        numberOfStops: 1,
                        duration: 515,
                      },
                      key: 'UK|775|1666600500000|1666631400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_78BOMCCUUK775CCUDELUK708_18185014741571@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 7,
                            offeredFare: 20985.17,
                            publishedFare: 21378,
                            comission: 392.83,
                            bookingClass: 'H',
                            baseFare: 19740,
                            taxes: {
                              total: 1638,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 1000,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_78BOMCCUUK775CCUDELUK708_18185014741421@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '35 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 38260.02,
                            publishedFare: 38940,
                            comission: 679.98,
                            bookingClass: 'Z',
                            baseFare: 34170,
                            taxes: {
                              total: 4770,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 4132,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '107',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '775',
                          durationInMin: 170,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666600500000,
                          departureDate: '2022-10-24T14:05',
                          arrival: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          arrivalTime: 1666610700000,
                          arrrivalDate: '2022-10-24T16:55',
                        },
                        {
                          id: '108',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '708',
                          durationInMin: 140,
                          departure: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          departureTime: 1666623000000,
                          departureDate: '2022-10-24T20:20',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666631400000,
                          arrrivalDate: '2022-10-24T22:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '775',
                        equipment: '73H',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666680600000,
                        departure: 1666622400000,
                        numberOfStops: 1,
                        duration: 970,
                      },
                      key: 'AI|619|1666622400000|1666680600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_44BOMHYDAI619HYDDELAI543_18185014697959@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 11818.88,
                            publishedFare: 12033,
                            comission: 214.12,
                            bookingClass: 'G',
                            baseFare: 10760,
                            taxes: {
                              total: 1273,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 555,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '556',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '619',
                          durationInMin: 90,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666622400000,
                          departureDate: '2022-10-24T20:10',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666627800000,
                          arrrivalDate: '2022-10-24T21:40',
                        },
                        {
                          id: '557',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '543',
                          durationInMin: 135,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666672500000,
                          departureDate: '2022-10-25T10:05',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666680600000,
                          arrrivalDate: '2022-10-25T12:20',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '619',
                        equipment: '319',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666584300000,
                        departure: 1666576200000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: 'SG|8152|1666576200000|1666584300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '4-2158784108_1BOMDELSG8152_18183742622622@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 7264.92,
                            publishedFare: 7412,
                            comission: 147.08,
                            bookingClass: 'DD',
                            baseFare: 6570,
                            taxes: {
                              total: 842,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 508,
                                'Management Fees': 0,
                                'Gst Charges': 334,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_1BOMDELSG8152_18183742623082@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 7602.55,
                            publishedFare: 7757,
                            comission: 154.45,
                            bookingClass: 'SA',
                            baseFare: 6899,
                            taxes: {
                              total: 858,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 508,
                                'Management Fees': 0,
                                'Gst Charges': 350,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_1BOMDELSG8152_18183742622282@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 6869.7,
                            publishedFare: 7262,
                            comission: 392.3,
                            bookingClass: 'CP',
                            baseFare: 6209,
                            taxes: {
                              total: 1053,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 725,
                                'Management Fees': 0,
                                'Gst Charges': 328,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_1BOMDELSG8152_18183742623312@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 7320.55,
                            publishedFare: 7518,
                            comission: 197.45,
                            bookingClass: 'HR',
                            baseFare: 6575,
                            taxes: {
                              total: 943,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 609,
                                'Management Fees': 0,
                                'Gst Charges': 334,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '11-2158784108_0BOMDELSG8152_18181209718707@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'GROUP',
                          message:
                            'Travel on a group ticket, Non Refundable, Paid -  Seats &  Meals.',
                          typeKey: 'OFFER_FARE_WITHOUT_PNR',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'NONREFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 6900,
                            publishedFare: 6900,
                            bookingClass: 'G',
                            baseFare: 6500,
                            taxes: {
                              total: 400,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 400,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '384',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 0,
                          flightNumber: '8152',
                          durationInMin: 135,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666576200000,
                          departureDate: '2022-10-24T07:20',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666584300000,
                          arrrivalDate: '2022-10-24T09:35',
                        },
                      ],
                      operator: {
                        name: 'SpiceJet',
                        code: 'SG',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/SG.png',
                        number: '8152',
                        equipment: '737',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666681800000,
                        departure: 1666612200000,
                        numberOfStops: 1,
                        duration: 1160,
                      },
                      key: 'UK|773|1666612200000|1666681800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_81BOMCCUUK773CCUDELUK706_18185014745431@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 20985.17,
                            publishedFare: 21378,
                            comission: 392.83,
                            bookingClass: 'H',
                            baseFare: 19740,
                            taxes: {
                              total: 1638,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 1000,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_81BOMCCUUK773CCUDELUK706_18185014745261@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '35 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 41065.27,
                            publishedFare: 41796,
                            comission: 730.73,
                            bookingClass: 'Z',
                            baseFare: 36720,
                            taxes: {
                              total: 5076,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 4438,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '992',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '773',
                          durationInMin: 155,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666612200000,
                          departureDate: '2022-10-24T17:20',
                          arrival: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          arrivalTime: 1666621500000,
                          arrrivalDate: '2022-10-24T19:55',
                        },
                        {
                          id: '993',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '706',
                          durationInMin: 145,
                          departure: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          departureTime: 1666673100000,
                          departureDate: '2022-10-25T10:15',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666681800000,
                          arrrivalDate: '2022-10-25T12:40',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '773',
                        equipment: '73H',
                      },
                    },
                    {
                      remaining: 8,
                      details: {
                        arrival: 1666629900000,
                        departure: 1666601100000,
                        numberOfStops: 1,
                        duration: 480,
                      },
                      key: 'SG|2901|1666601100000|1666629900000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '4-2158784108_7BOMRAJSG2901RAJDELSG3756_18183742638163@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 12760.2,
                            publishedFare: 13027,
                            comission: 266.8,
                            bookingClass: 'DD',
                            baseFare: 11917,
                            taxes: {
                              total: 1110,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 508,
                                'Management Fees': 0,
                                'Gst Charges': 602,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_7BOMRAJSG2901RAJDELSG3756_18183742638983@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 14495.4,
                            publishedFare: 14800,
                            comission: 304.6,
                            bookingClass: 'SA',
                            baseFare: 13606,
                            taxes: {
                              total: 1194,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 508,
                                'Management Fees': 0,
                                'Gst Charges': 686,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_7BOMRAJSG2901RAJDELSG3756_18183742637833@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 12795.93,
                            publishedFare: 13554,
                            comission: 758.07,
                            bookingClass: 'CP',
                            baseFare: 11998,
                            taxes: {
                              total: 1556,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 928,
                                'Management Fees': 0,
                                'Gst Charges': 628,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_7BOMRAJSG2901RAJDELSG3756_18183742639223@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 12989.05,
                            publishedFare: 13346,
                            comission: 356.95,
                            bookingClass: 'HR',
                            baseFare: 12055,
                            taxes: {
                              total: 1291,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 683,
                                'Management Fees': 0,
                                'Gst Charges': 608,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '888',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 0,
                          flightNumber: '2901',
                          durationInMin: 95,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666601100000,
                          departureDate: '2022-10-24T14:15',
                          arrival: {
                            code: 'RAJ',
                            airport: 'Rajkot Civil Arpt',
                            city: 'Rajkot',
                            country: 'India',
                          },
                          arrivalTime: 1666606800000,
                          arrrivalDate: '2022-10-24T15:50',
                        },
                        {
                          id: '889',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 1,
                          flightNumber: '3756',
                          durationInMin: 130,
                          departure: {
                            code: 'RAJ',
                            airport: 'Rajkot Civil Arpt',
                            city: 'Rajkot',
                            country: 'India',
                          },
                          departureTime: 1666622100000,
                          departureDate: '2022-10-24T20:05',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666629900000,
                          arrrivalDate: '2022-10-24T22:15',
                        },
                      ],
                      operator: {
                        name: 'SpiceJet',
                        code: 'SG',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/SG.png',
                        number: '2901',
                        equipment: 'DH8',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666636800000,
                        departure: 1666584000000,
                        numberOfStops: 1,
                        duration: 880,
                      },
                      key: 'AI|639|1666584000000|1666636800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_23BOMBLRAI639BLRDELAI808_18185014664997@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 9450.63,
                            publishedFare: 9619,
                            comission: 168.37,
                            bookingClass: 'W',
                            baseFare: 8461,
                            taxes: {
                              total: 1158,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 440,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '146',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '639',
                          durationInMin: 100,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666584000000,
                          departureDate: '2022-10-24T09:30',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666590000000,
                          arrrivalDate: '2022-10-24T11:10',
                        },
                        {
                          id: '147',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '808',
                          durationInMin: 170,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666626600000,
                          departureDate: '2022-10-24T21:20',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666636800000,
                          arrrivalDate: '2022-10-25T00:10',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '639',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666633500000,
                        departure: 1666625400000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: 'AI|816|1666625400000|1666633500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_9BOMDELAI816_18185014639216@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7180.04,
                            publishedFare: 7308,
                            comission: 127.96,
                            bookingClass: 'G',
                            baseFare: 6430,
                            taxes: {
                              total: 878,
                              breakups: {
                                'Carrier Misc': 170,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 330,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '544',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '816',
                          durationInMin: 135,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666625400000,
                          departureDate: '2022-10-24T21:00',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666633500000,
                          arrrivalDate: '2022-10-24T23:15',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '816',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666633800000,
                        departure: 1666616700000,
                        numberOfStops: 1,
                        duration: 285,
                      },
                      key: 'AI|637|1666616700000|1666633800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_39BOMAMDAI637AMDDELAI532_18185014692689@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 11582.45,
                            publishedFare: 11792,
                            comission: 209.55,
                            bookingClass: 'G',
                            baseFare: 10530,
                            taxes: {
                              total: 1262,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 544,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '72',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '637',
                          durationInMin: 85,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666616700000,
                          departureDate: '2022-10-24T18:35',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666621800000,
                          arrrivalDate: '2022-10-24T20:00',
                        },
                        {
                          id: '73',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '532',
                          durationInMin: 110,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666627200000,
                          departureDate: '2022-10-24T21:30',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666633800000,
                          arrrivalDate: '2022-10-24T23:20',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '637',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666632300000,
                        departure: 1666570500000,
                        numberOfStops: 1,
                        duration: 1030,
                      },
                      key: 'UK|543|1666570500000|1666632300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_68BOMBBIUK543BBIDELUK782_18185014729460@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 18203.9,
                            publishedFare: 18543,
                            comission: 339.1,
                            bookingClass: 'H',
                            baseFare: 17040,
                            taxes: {
                              total: 1503,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 865,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_68BOMBBIUK543BBIDELUK782_18185014729310@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 21065.05,
                            publishedFare: 21434,
                            comission: 368.95,
                            bookingClass: 'T',
                            baseFare: 18540,
                            taxes: {
                              total: 2894,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 2256,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '94',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '543',
                          durationInMin: 135,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666570500000,
                          departureDate: '2022-10-24T05:45',
                          arrival: {
                            code: 'BBI',
                            airport: 'Biju Patnaik Arpt',
                            city: 'Bhubaneswar',
                            country: 'India',
                          },
                          arrivalTime: 1666578600000,
                          arrrivalDate: '2022-10-24T08:00',
                        },
                        {
                          id: '95',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '782',
                          durationInMin: 145,
                          departure: {
                            code: 'BBI',
                            airport: 'Biju Patnaik Arpt',
                            city: 'Bhubaneswar',
                            country: 'India',
                          },
                          departureTime: 1666623600000,
                          departureDate: '2022-10-24T20:30',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666632300000,
                          arrrivalDate: '2022-10-24T22:55',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '543',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666666200000,
                        departure: 1666573500000,
                        numberOfStops: 1,
                        duration: 1545,
                      },
                      key: 'UK|939|1666573500000|1666666200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_72BOMAMDUK939AMDDELUK926_18185014734811@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '30 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 21900.93,
                            publishedFare: 22285,
                            comission: 384.07,
                            bookingClass: 'Z',
                            baseFare: 19300,
                            taxes: {
                              total: 2985,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 2347,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '964',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '939',
                          durationInMin: 75,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666573500000,
                          departureDate: '2022-10-24T06:35',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666578000000,
                          arrrivalDate: '2022-10-24T07:50',
                        },
                        {
                          id: '965',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '926',
                          durationInMin: 95,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666660500000,
                          departureDate: '2022-10-25T06:45',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666666200000,
                          arrrivalDate: '2022-10-25T08:20',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '939',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 6,
                      details: {
                        arrival: 1666696500000,
                        departure: 1666625400000,
                        numberOfStops: 1,
                        duration: 1185,
                      },
                      key: 'AI|683|1666625400000|1666696500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_52BOMGOIAI683GOIDELAI886_18185014708579@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 6,
                            offeredFare: 12333.93,
                            publishedFare: 12558,
                            comission: 224.07,
                            bookingClass: 'V',
                            baseFare: 11260,
                            taxes: {
                              total: 1298,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 580,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '273',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '683',
                          durationInMin: 80,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666625400000,
                          departureDate: '2022-10-24T21:00',
                          arrival: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          arrivalTime: 1666630200000,
                          arrrivalDate: '2022-10-24T22:20',
                        },
                        {
                          id: '274',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '886',
                          durationInMin: 150,
                          departure: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          departureTime: 1666687500000,
                          departureDate: '2022-10-25T14:15',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666696500000,
                          arrrivalDate: '2022-10-25T16:45',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '683',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666618800000,
                        departure: 1666611000000,
                        numberOfStops: 0,
                        duration: 130,
                      },
                      key: 'AI|442|1666611000000|1666618800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_0BOMDELAI442_18185014623125@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6149.94,
                            publishedFare: 6258,
                            comission: 108.06,
                            bookingClass: 'S',
                            baseFare: 5430,
                            taxes: {
                              total: 828,
                              breakups: {
                                'Carrier Misc': 170,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '462',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '442',
                          durationInMin: 130,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666611000000,
                          departureDate: '2022-10-24T17:00',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666618800000,
                          arrrivalDate: '2022-10-24T19:10',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '442',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666668300000,
                        departure: 1666626600000,
                        numberOfStops: 1,
                        duration: 695,
                      },
                      key: 'I5|1569|1666626600000|1666668300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_12BOMBLRI51569BLRDELI51528_18182007610232@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 11004.91,
                            publishedFare: 11157,
                            comission: 152.09,
                            bookingClass: 'SM',
                            baseFare: 10190,
                            taxes: {
                              total: 967,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 514,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_12BOMBLRI51569BLRDELI51528_18182007610672@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PREMIUM_FLEX',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 11118.26,
                            publishedFare: 11272,
                            comission: 153.74,
                            bookingClass: 'HF',
                            baseFare: 10301,
                            taxes: {
                              total: 971,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 518,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '536',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '1569',
                          durationInMin: 110,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666626600000,
                          departureDate: '2022-10-24T21:20',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666633200000,
                          arrrivalDate: '2022-10-24T23:10',
                        },
                        {
                          id: '537',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '1528',
                          durationInMin: 165,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666658400000,
                          departureDate: '2022-10-25T06:10',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666668300000,
                          arrrivalDate: '2022-10-25T08:55',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '1569',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666589400000,
                        departure: 1666576200000,
                        numberOfStops: 1,
                        duration: 220,
                      },
                      key: 'AI|635|1666576200000|1666589400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_10BOMIDRAI635IDRDELAI635_18185014640976@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 7180.04,
                            publishedFare: 7308,
                            comission: 127.96,
                            bookingClass: 'G',
                            baseFare: 6430,
                            taxes: {
                              total: 878,
                              breakups: {
                                'Carrier Misc': 170,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 330,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '338',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '635',
                          durationInMin: 80,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666576200000,
                          departureDate: '2022-10-24T07:20',
                          arrival: {
                            code: 'IDR',
                            airport: 'Devi Ahilya Bai Holkar Arpt',
                            city: 'Indore',
                            country: 'India',
                          },
                          arrivalTime: 1666581000000,
                          arrrivalDate: '2022-10-24T08:40',
                        },
                        {
                          id: '339',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '635',
                          durationInMin: 95,
                          departure: {
                            code: 'IDR',
                            airport: 'Devi Ahilya Bai Holkar Arpt',
                            city: 'Indore',
                            country: 'India',
                          },
                          departureTime: 1666583700000,
                          departureDate: '2022-10-24T09:25',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666589400000,
                          arrrivalDate: '2022-10-24T11:00',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '635',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666707600000,
                        departure: 1666611000000,
                        numberOfStops: 1,
                        duration: 1610,
                      },
                      key: 'UK|651|1666611000000|1666707600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_85BOMIXCUK651IXCDELUK638_18185014750011@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 13465.44,
                            publishedFare: 13713,
                            comission: 247.56,
                            bookingClass: 'W',
                            baseFare: 12440,
                            taxes: {
                              total: 1273,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 635,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '100',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '651',
                          durationInMin: 140,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666611000000,
                          departureDate: '2022-10-24T17:00',
                          arrival: {
                            code: 'IXC',
                            airport: 'Chandigarh Arpt',
                            city: 'Chandigarh',
                            country: 'India',
                          },
                          arrivalTime: 1666619400000,
                          arrrivalDate: '2022-10-24T19:20',
                        },
                        {
                          id: '101',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '638',
                          durationInMin: 60,
                          departure: {
                            code: 'IXC',
                            airport: 'Chandigarh Arpt',
                            city: 'Chandigarh',
                            country: 'India',
                          },
                          departureTime: 1666704000000,
                          departureDate: '2022-10-25T18:50',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666707600000,
                          arrrivalDate: '2022-10-25T19:50',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '651',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 8,
                      details: {
                        arrival: 1666640100000,
                        departure: 1666597800000,
                        numberOfStops: 1,
                        duration: 705,
                      },
                      key: 'I5|765|1666597800000|1666640100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_14BOMLKOI5765LKODELI5547_18182007615363@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 11273.03,
                            publishedFare: 11429,
                            comission: 155.97,
                            bookingClass: 'SM',
                            baseFare: 10450,
                            taxes: {
                              total: 979,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 526,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_14BOMLKOI5765LKODELI5547_18182007615644@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 10652.97,
                            publishedFare: 10800,
                            comission: 147.03,
                            bookingClass: 'EC',
                            baseFare: 9851,
                            taxes: {
                              total: 949,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 496,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '249',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '765',
                          durationInMin: 135,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666597800000,
                          departureDate: '2022-10-24T13:20',
                          arrival: {
                            code: 'LKO',
                            airport: 'Amausi Arpt',
                            city: 'Lucknow',
                            country: 'India',
                          },
                          arrivalTime: 1666605900000,
                          arrrivalDate: '2022-10-24T15:35',
                        },
                        {
                          id: '250',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '547',
                          durationInMin: 80,
                          departure: {
                            code: 'LKO',
                            airport: 'Amausi Arpt',
                            city: 'Lucknow',
                            country: 'India',
                          },
                          departureTime: 1666635300000,
                          departureDate: '2022-10-24T23:45',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666640100000,
                          arrrivalDate: '2022-10-25T01:05',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '765',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666610100000,
                        departure: 1666568100000,
                        numberOfStops: 1,
                        duration: 700,
                      },
                      key: 'AI|685|1666568100000|1666610100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_34BOMGOIAI685GOIDELAI886_18185014687998@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 11097.81,
                            publishedFare: 11298,
                            comission: 200.19,
                            bookingClass: 'G',
                            baseFare: 10060,
                            taxes: {
                              total: 1238,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 520,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '286',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '685',
                          durationInMin: 75,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666568100000,
                          departureDate: '2022-10-24T05:05',
                          arrival: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          arrivalTime: 1666572600000,
                          arrrivalDate: '2022-10-24T06:20',
                        },
                        {
                          id: '287',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '886',
                          durationInMin: 150,
                          departure: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          departureTime: 1666601100000,
                          departureDate: '2022-10-24T14:15',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666610100000,
                          arrrivalDate: '2022-10-24T16:45',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '685',
                        equipment: '319',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666673100000,
                        departure: 1666614000000,
                        numberOfStops: 1,
                        duration: 985,
                      },
                      key: 'SG|3003|1666614000000|1666673100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '4-2158784108_9BOMJLRSG3003JLRDELSG2963_18183742644563@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 19802.77,
                            publishedFare: 20223,
                            comission: 420.23,
                            bookingClass: 'DD',
                            baseFare: 18771,
                            taxes: {
                              total: 1452,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 508,
                                'Management Fees': 0,
                                'Gst Charges': 944,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_9BOMJLRSG3003JLRDELSG2963_18183742644883@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 22179,
                            publishedFare: 22651,
                            comission: 472,
                            bookingClass: 'SA',
                            baseFare: 21083,
                            taxes: {
                              total: 1568,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 508,
                                'Management Fees': 0,
                                'Gst Charges': 1060,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_9BOMJLRSG3003JLRDELSG2963_18183742644233@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 19970.96,
                            publishedFare: 21172,
                            comission: 1201.04,
                            bookingClass: 'CP',
                            baseFare: 19009,
                            taxes: {
                              total: 2163,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1173,
                                'Management Fees': 0,
                                'Gst Charges': 990,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_9BOMJLRSG3003JLRDELSG2963_18183742645033@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 19914.04,
                            publishedFare: 20433,
                            comission: 518.96,
                            bookingClass: 'HR',
                            baseFare: 18781,
                            taxes: {
                              total: 1652,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 706,
                                'Management Fees': 0,
                                'Gst Charges': 946,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '911',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 0,
                          flightNumber: '3003',
                          durationInMin: 140,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666614000000,
                          departureDate: '2022-10-24T17:50',
                          arrival: {
                            code: 'JLR',
                            airport: 'Jabalpur Airport',
                            city: 'Jabalpur',
                            country: 'India',
                          },
                          arrivalTime: 1666622400000,
                          arrrivalDate: '2022-10-24T20:10',
                        },
                        {
                          id: '912',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 1,
                          flightNumber: '2963',
                          durationInMin: 105,
                          departure: {
                            code: 'JLR',
                            airport: 'Jabalpur Airport',
                            city: 'Jabalpur',
                            country: 'India',
                          },
                          departureTime: 1666666800000,
                          departureDate: '2022-10-25T08:30',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666673100000,
                          arrrivalDate: '2022-10-25T10:15',
                        },
                      ],
                      operator: {
                        name: 'SpiceJet',
                        code: 'SG',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/SG.png',
                        number: '3003',
                        equipment: 'DH8',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666611000000,
                        departure: 1666593900000,
                        numberOfStops: 1,
                        duration: 285,
                      },
                      key: '6E|814|1666593900000|1666611000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_40BOMJDH6E814JDHDEL6E817_18188342328219@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 19345.3,
                            publishedFare: 20429,
                            comission: 1083.7,
                            bookingClass: 'M',
                            baseFare: 18305,
                            taxes: {
                              total: 2124,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 2124,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_40BOMJDH6E814JDHDEL6E817_18188342328679@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 17585.45,
                            publishedFare: 17935,
                            comission: 349.55,
                            bookingClass: 'R',
                            baseFare: 16571,
                            taxes: {
                              total: 1364,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1364,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '659',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '814',
                          durationInMin: 100,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666593900000,
                          departureDate: '2022-10-24T12:15',
                          arrival: {
                            code: 'JDH',
                            airport: 'Jodhpur Arpt',
                            city: 'Jodhpur',
                            country: 'India',
                          },
                          arrivalTime: 1666599900000,
                          arrrivalDate: '2022-10-24T13:55',
                        },
                        {
                          id: '660',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '817',
                          durationInMin: 80,
                          departure: {
                            code: 'JDH',
                            airport: 'Jodhpur Arpt',
                            city: 'Jodhpur',
                            country: 'India',
                          },
                          departureTime: 1666606200000,
                          departureDate: '2022-10-24T15:40',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666611000000,
                          arrrivalDate: '2022-10-24T17:00',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '814',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666695600000,
                        departure: 1666611000000,
                        numberOfStops: 1,
                        duration: 1410,
                      },
                      key: 'UK|651|1666611000000|1666695600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_64BOMIXCUK651IXCDELUK707_18185014724290@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 13465.44,
                            publishedFare: 13713,
                            comission: 247.56,
                            bookingClass: 'W',
                            baseFare: 12440,
                            taxes: {
                              total: 1273,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 635,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_64BOMIXCUK651IXCDELUK707_18185014724140@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 18754.84,
                            publishedFare: 19082,
                            comission: 327.16,
                            bookingClass: 'P',
                            baseFare: 16440,
                            taxes: {
                              total: 2642,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 2004,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '601',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '651',
                          durationInMin: 140,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666611000000,
                          departureDate: '2022-10-24T17:00',
                          arrival: {
                            code: 'IXC',
                            airport: 'Chandigarh Arpt',
                            city: 'Chandigarh',
                            country: 'India',
                          },
                          arrivalTime: 1666619400000,
                          arrrivalDate: '2022-10-24T19:20',
                        },
                        {
                          id: '602',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '707',
                          durationInMin: 65,
                          departure: {
                            code: 'IXC',
                            airport: 'Chandigarh Arpt',
                            city: 'Chandigarh',
                            country: 'India',
                          },
                          departureTime: 1666691700000,
                          departureDate: '2022-10-25T15:25',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666695600000,
                          arrrivalDate: '2022-10-25T16:30',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '651',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666616400000,
                        departure: 1666571400000,
                        numberOfStops: 1,
                        duration: 750,
                      },
                      key: 'AI|615|1666571400000|1666616400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_36BOMHYDAI615HYDDELAI541_18185014689958@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 11200.82,
                            publishedFare: 11403,
                            comission: 202.18,
                            bookingClass: 'S',
                            baseFare: 10160,
                            taxes: {
                              total: 1243,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 525,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '372',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '615',
                          durationInMin: 90,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666571400000,
                          departureDate: '2022-10-24T06:00',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666576800000,
                          arrrivalDate: '2022-10-24T07:30',
                        },
                        {
                          id: '373',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '541',
                          durationInMin: 140,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666608000000,
                          departureDate: '2022-10-24T16:10',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666616400000,
                          arrrivalDate: '2022-10-24T18:30',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '615',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666638000000,
                        departure: 1666630200000,
                        numberOfStops: 0,
                        duration: 130,
                      },
                      key: 'G8|345|1666630200000|1666638000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_3BOMDELG8345_18183418041591@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7319.11,
                            publishedFare: 7518,
                            comission: 198.89,
                            bookingClass: 'SP',
                            baseFare: 6663,
                            taxes: {
                              total: 855,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 855,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_3BOMDELG8345_18183418041291@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7725.06,
                            publishedFare: 8226,
                            comission: 500.94,
                            bookingClass: 'BC',
                            baseFare: 7091,
                            taxes: {
                              total: 1135,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1135,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_3BOMDELG8345_18183418041871@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7828.18,
                            publishedFare: 8042,
                            comission: 213.82,
                            bookingClass: 'GS',
                            baseFare: 7163,
                            taxes: {
                              total: 879,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 879,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '186',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 0,
                          flightNumber: '345',
                          durationInMin: 130,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666630200000,
                          departureDate: '2022-10-24T22:20',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666638000000,
                          arrrivalDate: '2022-10-25T00:30',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '345',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666609200000,
                        departure: 1666571400000,
                        numberOfStops: 1,
                        duration: 630,
                      },
                      key: 'UK|653|1666571400000|1666609200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_62BOMIXCUK653IXCDELUK707_18185014721420@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 17585.84,
                            publishedFare: 17913,
                            comission: 327.16,
                            bookingClass: 'A',
                            baseFare: 16440,
                            taxes: {
                              total: 1473,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 835,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_62BOMIXCUK653IXCDELUK707_18185014721260@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 18039.78,
                            publishedFare: 18354,
                            comission: 314.22,
                            bookingClass: 'T',
                            baseFare: 15790,
                            taxes: {
                              total: 2564,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 1926,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '567',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '653',
                          durationInMin: 135,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666571400000,
                          departureDate: '2022-10-24T06:00',
                          arrival: {
                            code: 'IXC',
                            airport: 'Chandigarh Arpt',
                            city: 'Chandigarh',
                            country: 'India',
                          },
                          arrivalTime: 1666579500000,
                          arrrivalDate: '2022-10-24T08:15',
                        },
                        {
                          id: '568',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '707',
                          durationInMin: 65,
                          departure: {
                            code: 'IXC',
                            airport: 'Chandigarh Arpt',
                            city: 'Chandigarh',
                            country: 'India',
                          },
                          departureTime: 1666605300000,
                          departureDate: '2022-10-24T15:25',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666609200000,
                          arrrivalDate: '2022-10-24T16:30',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '653',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666611000000,
                        departure: 1666587300000,
                        numberOfStops: 1,
                        duration: 395,
                      },
                      key: 'UK|647|1666587300000|1666611000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_90BOMSXRUK647SXRDELUK612_18185014755462@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 19438.03,
                            publishedFare: 19803,
                            comission: 364.97,
                            bookingClass: 'A',
                            baseFare: 18340,
                            taxes: {
                              total: 1463,
                              breakups: {
                                'Carrier Misc': 160,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 925,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '53',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '647',
                          durationInMin: 165,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666587300000,
                          departureDate: '2022-10-24T10:25',
                          arrival: {
                            code: 'SXR',
                            airport: 'Srinagar Arpt',
                            city: 'Srinagar',
                            country: 'India',
                          },
                          arrivalTime: 1666597200000,
                          arrrivalDate: '2022-10-24T13:10',
                        },
                        {
                          id: '54',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '612',
                          durationInMin: 100,
                          departure: {
                            code: 'SXR',
                            airport: 'Srinagar Arpt',
                            city: 'Srinagar',
                            country: 'India',
                          },
                          departureTime: 1666605000000,
                          departureDate: '2022-10-24T15:20',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666611000000,
                          arrrivalDate: '2022-10-24T17:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '647',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666627800000,
                        departure: 1666620000000,
                        numberOfStops: 0,
                        duration: 130,
                      },
                      key: '6E|439|1666620000000|1666627800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_3BOMDEL6E439_18188342231725@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6117.49,
                            publishedFare: 6233,
                            comission: 115.51,
                            bookingClass: 'T',
                            baseFare: 5476,
                            taxes: {
                              total: 757,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 757,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_3BOMDEL6E439_18188342231255@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7162.18,
                            publishedFare: 7547,
                            comission: 384.82,
                            bookingClass: 'M',
                            baseFare: 6500,
                            taxes: {
                              total: 1047,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1047,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_3BOMDEL6E439_18188342231955@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7170.89,
                            publishedFare: 7308,
                            comission: 137.11,
                            bookingClass: 'R',
                            baseFare: 6500,
                            taxes: {
                              total: 808,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 808,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '840',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '439',
                          durationInMin: 130,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666620000000,
                          departureDate: '2022-10-24T19:30',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666627800000,
                          arrrivalDate: '2022-10-24T21:40',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '439',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666582500000,
                        departure: 1666575000000,
                        numberOfStops: 0,
                        duration: 125,
                      },
                      key: '6E|5398|1666575000000|1666582500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_8BOMDEL6E5398_18188342242915@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 7675.58,
                            publishedFare: 8090,
                            comission: 414.42,
                            bookingClass: 'M',
                            baseFare: 7000,
                            taxes: {
                              total: 1090,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1090,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_8BOMDEL6E5398_18188342243435@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 7685.34,
                            publishedFare: 7833,
                            comission: 147.66,
                            bookingClass: 'R',
                            baseFare: 7000,
                            taxes: {
                              total: 833,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 833,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '464',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '5398',
                          durationInMin: 125,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666575000000,
                          departureDate: '2022-10-24T07:00',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666582500000,
                          arrrivalDate: '2022-10-24T09:05',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '5398',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666585800000,
                        departure: 1666578300000,
                        numberOfStops: 0,
                        duration: 125,
                      },
                      key: 'G8|327|1666578300000|1666585800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_1BOMDELG8327_18183418038401@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7319.11,
                            publishedFare: 7518,
                            comission: 198.89,
                            bookingClass: 'SP',
                            baseFare: 6663,
                            taxes: {
                              total: 855,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 855,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_1BOMDELG8327_18183418038881@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'GOMORE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 15480.31,
                            publishedFare: 15918,
                            comission: 437.69,
                            bookingClass: 'MG',
                            baseFare: 14663,
                            taxes: {
                              total: 1255,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1255,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_1BOMDELG8327_18183418038161@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7725.06,
                            publishedFare: 8226,
                            comission: 500.94,
                            bookingClass: 'BC',
                            baseFare: 7091,
                            taxes: {
                              total: 1135,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1135,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_1BOMDELG8327_18183418038641@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7828.18,
                            publishedFare: 8042,
                            comission: 213.82,
                            bookingClass: 'GS',
                            baseFare: 7163,
                            taxes: {
                              total: 879,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 879,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '364',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 0,
                          flightNumber: '327',
                          durationInMin: 125,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666578300000,
                          departureDate: '2022-10-24T07:55',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666585800000,
                          arrrivalDate: '2022-10-24T10:00',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '327',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666619700000,
                        departure: 1666614900000,
                        numberOfStops: 0,
                        duration: 80,
                      },
                      key: 'G8|287|1666614900000|1666619700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_10BOMSXRG8287SXRDELG8197_18183418063642@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7115.08,
                            publishedFare: 7308,
                            comission: 192.92,
                            bookingClass: 'GS',
                            baseFare: 6463,
                            taxes: {
                              total: 845,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 845,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '718',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 1,
                          flightNumber: '197',
                          durationInMin: 80,
                          departure: {
                            code: 'SXR',
                            airport: 'Srinagar Arpt',
                            city: 'Srinagar',
                            country: 'India',
                          },
                          departureTime: 1666614900000,
                          departureDate: '2022-10-24T18:05',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666619700000,
                          arrrivalDate: '2022-10-24T19:25',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '287',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666591500000,
                        departure: 1666583100000,
                        numberOfStops: 0,
                        duration: 140,
                      },
                      key: 'G8|381|1666583100000|1666591500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_7BOMIXCG8381IXCDELG8149_18183418056962@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7115.08,
                            publishedFare: 7308,
                            comission: 192.92,
                            bookingClass: 'GS',
                            baseFare: 6463,
                            taxes: {
                              total: 845,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 845,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '35',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 0,
                          flightNumber: '381',
                          durationInMin: 140,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666583100000,
                          departureDate: '2022-10-24T09:15',
                          arrival: {
                            code: 'IXC',
                            airport: 'Chandigarh Arpt',
                            city: 'Chandigarh',
                            country: 'India',
                          },
                          arrivalTime: 1666591500000,
                          arrrivalDate: '2022-10-24T11:35',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '381',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666593600000,
                        departure: 1666570500000,
                        numberOfStops: 1,
                        duration: 385,
                      },
                      key: 'UK|543|1666570500000|1666593600000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_65BOMBBIUK543BBIDELUK786_18185014725600@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 18203.9,
                            publishedFare: 18543,
                            comission: 339.1,
                            bookingClass: 'H',
                            baseFare: 17040,
                            taxes: {
                              total: 1503,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 865,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_65BOMBBIUK543BBIDELUK786_18185014725430@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 19634.92,
                            publishedFare: 19978,
                            comission: 343.08,
                            bookingClass: 'T',
                            baseFare: 17240,
                            taxes: {
                              total: 2738,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 2100,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '529',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '543',
                          durationInMin: 135,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666570500000,
                          departureDate: '2022-10-24T05:45',
                          arrival: {
                            code: 'BBI',
                            airport: 'Biju Patnaik Arpt',
                            city: 'Bhubaneswar',
                            country: 'India',
                          },
                          arrivalTime: 1666578600000,
                          arrrivalDate: '2022-10-24T08:00',
                        },
                        {
                          id: '530',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '786',
                          durationInMin: 150,
                          departure: {
                            code: 'BBI',
                            airport: 'Biju Patnaik Arpt',
                            city: 'Bhubaneswar',
                            country: 'India',
                          },
                          departureTime: 1666584600000,
                          departureDate: '2022-10-24T09:40',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666593600000,
                          arrrivalDate: '2022-10-24T12:10',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '543',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666691100000,
                        departure: 1666615500000,
                        numberOfStops: 1,
                        duration: 1260,
                      },
                      key: 'UK|545|1666615500000|1666691100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_87BOMBBIUK545BBIDELUK788_18185014752111@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 14804.57,
                            publishedFare: 15078,
                            comission: 273.43,
                            bookingClass: 'Q',
                            baseFare: 13740,
                            taxes: {
                              total: 1338,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 700,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '155',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '545',
                          durationInMin: 140,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666615500000,
                          departureDate: '2022-10-24T18:15',
                          arrival: {
                            code: 'BBI',
                            airport: 'Biju Patnaik Arpt',
                            city: 'Bhubaneswar',
                            country: 'India',
                          },
                          arrivalTime: 1666623900000,
                          arrrivalDate: '2022-10-24T20:35',
                        },
                        {
                          id: '156',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '788',
                          durationInMin: 135,
                          departure: {
                            code: 'BBI',
                            airport: 'Biju Patnaik Arpt',
                            city: 'Bhubaneswar',
                            country: 'India',
                          },
                          departureTime: 1666683000000,
                          departureDate: '2022-10-25T13:00',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666691100000,
                          arrrivalDate: '2022-10-25T15:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '545',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 8,
                      details: {
                        arrival: 1666623000000,
                        departure: 1666575000000,
                        numberOfStops: 1,
                        duration: 800,
                      },
                      key: 'UK|851|1666575000000|1666623000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_56BOMBLRUK851BLRDELUK820_18185014713430@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 14629.47,
                            publishedFare: 14882,
                            comission: 252.53,
                            bookingClass: 'P',
                            baseFare: 12690,
                            taxes: {
                              total: 2192,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 1554,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '32',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '851',
                          durationInMin: 110,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666575000000,
                          departureDate: '2022-10-24T07:00',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666581600000,
                          arrrivalDate: '2022-10-24T08:50',
                        },
                        {
                          id: '33',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '820',
                          durationInMin: 155,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666613700000,
                          departureDate: '2022-10-24T17:45',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666623000000,
                          arrrivalDate: '2022-10-24T20:20',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '851',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666671300000,
                        departure: 1666614000000,
                        numberOfStops: 1,
                        duration: 955,
                      },
                      key: 'UK|823|1666614000000|1666671300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_75BOMMAAUK823MAADELUK832_18185014737811@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '35 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 31956.04,
                            publishedFare: 32522,
                            comission: 565.96,
                            bookingClass: 'Z',
                            baseFare: 28440,
                            taxes: {
                              total: 4082,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 3444,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '918',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '823',
                          durationInMin: 120,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666614000000,
                          departureDate: '2022-10-24T17:50',
                          arrival: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          arrivalTime: 1666621200000,
                          arrrivalDate: '2022-10-24T19:50',
                        },
                        {
                          id: '919',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '832',
                          durationInMin: 160,
                          departure: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          departureTime: 1666661700000,
                          departureDate: '2022-10-25T07:05',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666671300000,
                          arrrivalDate: '2022-10-25T09:45',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '823',
                        equipment: '73H',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666616100000,
                        departure: 1666594500000,
                        numberOfStops: 1,
                        duration: 360,
                      },
                      key: 'SG|367|1666594500000|1666616100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '4-2158784108_5BOMDBRSG367DBRDELSG752_18183742631142@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 16293.24,
                            publishedFare: 16637,
                            comission: 343.76,
                            bookingClass: 'DD',
                            baseFare: 15355,
                            taxes: {
                              total: 1282,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 508,
                                'Management Fees': 0,
                                'Gst Charges': 774,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_5BOMDBRSG367DBRDELSG752_18183742630842@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 16650,
                            publishedFare: 17646,
                            comission: 996,
                            bookingClass: 'CP',
                            baseFare: 15764,
                            taxes: {
                              total: 1882,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1060,
                                'Management Fees': 0,
                                'Gst Charges': 822,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_5BOMDBRSG367DBRDELSG752_18183742631292@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 15251.94,
                            publishedFare: 15573,
                            comission: 321.06,
                            bookingClass: 'RS',
                            baseFare: 14341,
                            taxes: {
                              total: 1232,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 508,
                                'Management Fees': 0,
                                'Gst Charges': 724,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '131',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 0,
                          flightNumber: '367',
                          durationInMin: 160,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666594500000,
                          departureDate: '2022-10-24T12:25',
                          arrival: {
                            code: 'DBR',
                            airport: 'Darbhanga Airport',
                            city: 'Darbhanga',
                            country: 'India',
                          },
                          arrivalTime: 1666604100000,
                          arrrivalDate: '2022-10-24T15:05',
                        },
                        {
                          id: '132',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 1,
                          flightNumber: '752',
                          durationInMin: 120,
                          departure: {
                            code: 'DBR',
                            airport: 'Darbhanga Airport',
                            city: 'Darbhanga',
                            country: 'India',
                          },
                          departureTime: 1666608900000,
                          departureDate: '2022-10-24T16:25',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666616100000,
                          arrrivalDate: '2022-10-24T18:25',
                        },
                      ],
                      operator: {
                        name: 'SpiceJet',
                        code: 'SG',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/SG.png',
                        number: '367',
                        equipment: '737',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666692300000,
                        departure: 1666594500000,
                        numberOfStops: 1,
                        duration: 1630,
                      },
                      key: 'SG|367|1666594500000|1666692300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '4-2158784108_6BOMDBRSG367DBRDELSG8486_18183742633192@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 16292.04,
                            publishedFare: 16647,
                            comission: 354.96,
                            bookingClass: 'HR',
                            baseFare: 15855,
                            taxes: {
                              total: 792,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 0,
                                'Management Fees': 0,
                                'Gst Charges': 792,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_6BOMDBRSG367DBRDELSG8486_18183742632712@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 16660.57,
                            publishedFare: 17689,
                            comission: 1028.43,
                            bookingClass: 'CP',
                            baseFare: 16277,
                            taxes: {
                              total: 1412,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 570,
                                'Management Fees': 0,
                                'Gst Charges': 842,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_6BOMDBRSG367DBRDELSG8486_18183742633022@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 15251.94,
                            publishedFare: 15573,
                            comission: 321.06,
                            bookingClass: 'RS',
                            baseFare: 14341,
                            taxes: {
                              total: 1232,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 508,
                                'Management Fees': 0,
                                'Gst Charges': 724,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '55',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 0,
                          flightNumber: '367',
                          durationInMin: 160,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666594500000,
                          departureDate: '2022-10-24T12:25',
                          arrival: {
                            code: 'DBR',
                            airport: 'Darbhanga Airport',
                            city: 'Darbhanga',
                            country: 'India',
                          },
                          arrivalTime: 1666604100000,
                          arrrivalDate: '2022-10-24T15:05',
                        },
                        {
                          id: '56',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 1,
                          flightNumber: '8486',
                          durationInMin: 100,
                          departure: {
                            code: 'DBR',
                            airport: 'Darbhanga Airport',
                            city: 'Darbhanga',
                            country: 'India',
                          },
                          departureTime: 1666686300000,
                          departureDate: '2022-10-25T13:55',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666692300000,
                          arrrivalDate: '2022-10-25T15:35',
                        },
                      ],
                      operator: {
                        name: 'SpiceJet',
                        code: 'SG',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/SG.png',
                        number: '367',
                        equipment: '737',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666615200000,
                        departure: 1666607100000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: '6E|2012|1666607100000|1666615200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_4BOMDEL6E2012_18188342233495@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 5758.83,
                            publishedFare: 5867,
                            comission: 108.17,
                            bookingClass: 'T',
                            baseFare: 5128,
                            taxes: {
                              total: 739,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 739,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_4BOMDEL6E2012_18188342233035@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 7162.18,
                            publishedFare: 7547,
                            comission: 384.82,
                            bookingClass: 'M',
                            baseFare: 6500,
                            taxes: {
                              total: 1047,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1047,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_4BOMDEL6E2012_18188342233805@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 7170.89,
                            publishedFare: 7308,
                            comission: 137.11,
                            bookingClass: 'R',
                            baseFare: 6500,
                            taxes: {
                              total: 808,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 808,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '447',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '2012',
                          durationInMin: 135,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666607100000,
                          departureDate: '2022-10-24T15:55',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666615200000,
                          arrrivalDate: '2022-10-24T18:10',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '2012',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666607700000,
                        departure: 1666584000000,
                        numberOfStops: 1,
                        duration: 395,
                      },
                      key: 'AI|639|1666584000000|1666607700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_51BOMBLRAI639BLRDELAI501_18185014707599@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 12128.89,
                            publishedFare: 12349,
                            comission: 220.11,
                            bookingClass: 'W',
                            baseFare: 11061,
                            taxes: {
                              total: 1288,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 570,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '464',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '639',
                          durationInMin: 100,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666584000000,
                          departureDate: '2022-10-24T09:30',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666590000000,
                          arrrivalDate: '2022-10-24T11:10',
                        },
                        {
                          id: '465',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '501',
                          durationInMin: 165,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666597800000,
                          departureDate: '2022-10-24T13:20',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666607700000,
                          arrrivalDate: '2022-10-24T16:05',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '639',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666604700000,
                        departure: 1666596600000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: 'AI|677|1666596600000|1666604700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_3BOMDELAI677_18185014628415@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6149.94,
                            publishedFare: 6258,
                            comission: 108.06,
                            bookingClass: 'S',
                            baseFare: 5430,
                            taxes: {
                              total: 828,
                              breakups: {
                                'Carrier Misc': 170,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '262',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '677',
                          durationInMin: 135,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666596600000,
                          departureDate: '2022-10-24T13:00',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666604700000,
                          arrrivalDate: '2022-10-24T15:15',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '677',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 6,
                      details: {
                        arrival: 1666618200000,
                        departure: 1666599000000,
                        numberOfStops: 1,
                        duration: 320,
                      },
                      key: '6E|5228|1666599000000|1666618200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_37BOMLKO6E5228LKODEL6E2027_18188342319789@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 6,
                            offeredFare: 15060.18,
                            publishedFare: 15897,
                            comission: 836.82,
                            bookingClass: 'M',
                            baseFare: 14135,
                            taxes: {
                              total: 1762,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1762,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_37BOMLKO6E5228LKODEL6E2027_18188342320239@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 6,
                            offeredFare: 13756.94,
                            publishedFare: 14028,
                            comission: 271.06,
                            bookingClass: 'R',
                            baseFare: 12850,
                            taxes: {
                              total: 1178,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1178,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '603',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '5228',
                          durationInMin: 140,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666599000000,
                          departureDate: '2022-10-24T13:40',
                          arrival: {
                            code: 'LKO',
                            airport: 'Amausi Arpt',
                            city: 'Lucknow',
                            country: 'India',
                          },
                          arrivalTime: 1666607400000,
                          arrrivalDate: '2022-10-24T16:00',
                        },
                        {
                          id: '604',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '2027',
                          durationInMin: 75,
                          departure: {
                            code: 'LKO',
                            airport: 'Amausi Arpt',
                            city: 'Lucknow',
                            country: 'India',
                          },
                          departureTime: 1666613700000,
                          departureDate: '2022-10-24T17:45',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666618200000,
                          arrrivalDate: '2022-10-24T19:00',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '5228',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666604400000,
                        departure: 1666584600000,
                        numberOfStops: 1,
                        duration: 330,
                      },
                      key: '6E|5334|1666584600000|1666604400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_39BOMNAG6E5334NAGDEL6E6026_18188342325379@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 15911.15,
                            publishedFare: 16797,
                            comission: 885.85,
                            bookingClass: 'M',
                            baseFare: 14963,
                            taxes: {
                              total: 1834,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1834,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_39BOMNAG6E5334NAGDEL6E6026_18188342325859@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 15418.88,
                            publishedFare: 15724,
                            comission: 305.12,
                            bookingClass: 'R',
                            baseFare: 14465,
                            taxes: {
                              total: 1259,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1259,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '655',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '5334',
                          durationInMin: 90,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666584600000,
                          departureDate: '2022-10-24T09:40',
                          arrival: {
                            code: 'NAG',
                            airport: 'Dr Ambedkar Intl Arpt',
                            city: 'Nagpur',
                            country: 'India',
                          },
                          arrivalTime: 1666590000000,
                          arrrivalDate: '2022-10-24T11:10',
                        },
                        {
                          id: '656',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '6026',
                          durationInMin: 110,
                          departure: {
                            code: 'NAG',
                            airport: 'Dr Ambedkar Intl Arpt',
                            city: 'Nagpur',
                            country: 'India',
                          },
                          departureTime: 1666597800000,
                          departureDate: '2022-10-24T13:20',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666604400000,
                          arrrivalDate: '2022-10-24T15:10',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '5334',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666619400000,
                        departure: 1666613700000,
                        numberOfStops: 0,
                        duration: 95,
                      },
                      key: 'G8|287|1666613700000|1666619400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_9BOMSXRG8287SXRDELG8189_18183418061292@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7115.08,
                            publishedFare: 7308,
                            comission: 192.92,
                            bookingClass: 'GS',
                            baseFare: 6463,
                            taxes: {
                              total: 845,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 845,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '436',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 1,
                          flightNumber: '189',
                          durationInMin: 95,
                          departure: {
                            code: 'SXR',
                            airport: 'Srinagar Arpt',
                            city: 'Srinagar',
                            country: 'India',
                          },
                          departureTime: 1666613700000,
                          departureDate: '2022-10-24T17:45',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666619400000,
                          arrrivalDate: '2022-10-24T19:20',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '287',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666626300000,
                        departure: 1666618200000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: 'AI|888|1666618200000|1666626300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_4BOMDELAI888_18185014629935@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6149.94,
                            publishedFare: 6258,
                            comission: 108.06,
                            bookingClass: 'S',
                            baseFare: 5430,
                            taxes: {
                              total: 828,
                              breakups: {
                                'Carrier Misc': 170,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '700',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '888',
                          durationInMin: 135,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666618200000,
                          departureDate: '2022-10-24T19:00',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666626300000,
                          arrrivalDate: '2022-10-24T21:15',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '888',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666599000000,
                        departure: 1666588800000,
                        numberOfStops: 0,
                        duration: 170,
                      },
                      key: 'G8|287|1666588800000|1666599000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_8BOMIXJG8287IXJDELG8197_18183418059342@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 7115.08,
                            publishedFare: 7308,
                            comission: 192.92,
                            bookingClass: 'GS',
                            baseFare: 6463,
                            taxes: {
                              total: 845,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 845,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '594',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 0,
                          flightNumber: '287',
                          durationInMin: 170,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666588800000,
                          departureDate: '2022-10-24T10:50',
                          arrival: {
                            code: 'IXJ',
                            airport: 'Satwari Arpt',
                            city: 'Jammu',
                            country: 'India',
                          },
                          arrivalTime: 1666599000000,
                          arrrivalDate: '2022-10-24T13:40',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '287',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 8,
                      details: {
                        arrival: 1666680000000,
                        departure: 1666615500000,
                        numberOfStops: 1,
                        duration: 1075,
                      },
                      key: 'UK|545|1666615500000|1666680000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_55BOMBBIUK545BBIDELUK786_18185014711999@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 14804.57,
                            publishedFare: 15078,
                            comission: 273.43,
                            bookingClass: 'Q',
                            baseFare: 13740,
                            taxes: {
                              total: 1338,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 700,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_55BOMBBIUK545BBIDELUK786_18185014711529@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 14354.44,
                            publishedFare: 14602,
                            comission: 247.56,
                            bookingClass: 'U',
                            baseFare: 12440,
                            taxes: {
                              total: 2162,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 1524,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '466',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '545',
                          durationInMin: 140,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666615500000,
                          departureDate: '2022-10-24T18:15',
                          arrival: {
                            code: 'BBI',
                            airport: 'Biju Patnaik Arpt',
                            city: 'Bhubaneswar',
                            country: 'India',
                          },
                          arrivalTime: 1666623900000,
                          arrrivalDate: '2022-10-24T20:35',
                        },
                        {
                          id: '467',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '786',
                          durationInMin: 150,
                          departure: {
                            code: 'BBI',
                            airport: 'Biju Patnaik Arpt',
                            city: 'Bhubaneswar',
                            country: 'India',
                          },
                          departureTime: 1666671000000,
                          departureDate: '2022-10-25T09:40',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666680000000,
                          arrrivalDate: '2022-10-25T12:10',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '545',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 7,
                      details: {
                        arrival: 1666601100000,
                        departure: 1666584600000,
                        numberOfStops: 1,
                        duration: 275,
                      },
                      key: '6E|6277|1666584600000|1666601100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_31BOMIXC6E6277IXCDEL6E2195_18188342302618@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 7,
                            offeredFare: 10927.95,
                            publishedFare: 11141,
                            comission: 213.05,
                            bookingClass: 'T',
                            baseFare: 10100,
                            taxes: {
                              total: 1041,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1041,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_31BOMIXC6E6277IXCDEL6E2195_18188342301928@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 7,
                            offeredFare: 11443.57,
                            publishedFare: 12072,
                            comission: 628.43,
                            bookingClass: 'M',
                            baseFare: 10615,
                            taxes: {
                              total: 1457,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1457,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_31BOMIXC6E6277IXCDEL6E2195_18188342302398@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 7,
                            offeredFare: 10464.44,
                            publishedFare: 10668,
                            comission: 203.56,
                            bookingClass: 'R',
                            baseFare: 9650,
                            taxes: {
                              total: 1018,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1018,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '438',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '6277',
                          durationInMin: 135,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666584600000,
                          departureDate: '2022-10-24T09:40',
                          arrival: {
                            code: 'IXC',
                            airport: 'Chandigarh Arpt',
                            city: 'Chandigarh',
                            country: 'India',
                          },
                          arrivalTime: 1666592700000,
                          arrrivalDate: '2022-10-24T11:55',
                        },
                        {
                          id: '439',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '2195',
                          durationInMin: 70,
                          departure: {
                            code: 'IXC',
                            airport: 'Chandigarh Arpt',
                            city: 'Chandigarh',
                            country: 'India',
                          },
                          departureTime: 1666596900000,
                          departureDate: '2022-10-24T13:05',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666601100000,
                          arrrivalDate: '2022-10-24T14:15',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '6277',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666605900000,
                        departure: 1666572600000,
                        numberOfStops: 1,
                        duration: 555,
                      },
                      key: 'SG|115|1666572600000|1666605900000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '4-2158784108_3BOMDBRSG115DBRDELSG8486_18183742626622@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 26773.69,
                            publishedFare: 27357,
                            comission: 583.31,
                            bookingClass: 'HR',
                            baseFare: 26055,
                            taxes: {
                              total: 1302,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 8,
                                'Management Fees': 0,
                                'Gst Charges': 1294,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_3BOMDBRSG115DBRDELSG8486_18183742626282@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 28707.99,
                            publishedFare: 30480,
                            comission: 1772.01,
                            bookingClass: 'CP',
                            baseFare: 28046,
                            taxes: {
                              total: 2434,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 982,
                                'Management Fees': 0,
                                'Gst Charges': 1452,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_3BOMDBRSG115DBRDELSG8486_18183742626462@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 25073.74,
                            publishedFare: 25620,
                            comission: 546.26,
                            bookingClass: 'SR',
                            baseFare: 24400,
                            taxes: {
                              total: 1220,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 0,
                                'Management Fees': 0,
                                'Gst Charges': 1220,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '526',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 0,
                          flightNumber: '115',
                          durationInMin: 160,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666572600000,
                          departureDate: '2022-10-24T06:20',
                          arrival: {
                            code: 'DBR',
                            airport: 'Darbhanga Airport',
                            city: 'Darbhanga',
                            country: 'India',
                          },
                          arrivalTime: 1666582200000,
                          arrrivalDate: '2022-10-24T09:00',
                        },
                        {
                          id: '527',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 1,
                          flightNumber: '8486',
                          durationInMin: 100,
                          departure: {
                            code: 'DBR',
                            airport: 'Darbhanga Airport',
                            city: 'Darbhanga',
                            country: 'India',
                          },
                          departureTime: 1666599900000,
                          departureDate: '2022-10-24T13:55',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666605900000,
                          arrrivalDate: '2022-10-24T15:35',
                        },
                      ],
                      operator: {
                        name: 'SpiceJet',
                        code: 'SG',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/SG.png',
                        number: '115',
                        equipment: '7M8',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666687800000,
                        departure: 1666629000000,
                        numberOfStops: 1,
                        duration: 980,
                      },
                      key: 'I5|1784|1666629000000|1666687800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_8BOMBLRI51784BLRDELI5741_18182007598762@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 10662.83,
                            publishedFare: 10810,
                            comission: 147.17,
                            bookingClass: 'SM',
                            baseFare: 9861,
                            taxes: {
                              total: 949,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 496,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_8BOMBLRI51784BLRDELI5741_18182007599052@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'PROMO',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 8249.62,
                            publishedFare: 8362,
                            comission: 112.38,
                            bookingClass: 'BT',
                            baseFare: 7529,
                            taxes: {
                              total: 833,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 380,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_8BOMBLRI51784BLRDELI5741_18182007599342@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PREMIUM_FLEX',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 10935.89,
                            publishedFare: 11087,
                            comission: 151.11,
                            bookingClass: 'HF',
                            baseFare: 10124,
                            taxes: {
                              total: 963,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 510,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '833',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '1784',
                          durationInMin: 110,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666629000000,
                          departureDate: '2022-10-24T22:00',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666635600000,
                          arrrivalDate: '2022-10-24T23:50',
                        },
                        {
                          id: '834',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '741',
                          durationInMin: 170,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666677600000,
                          departureDate: '2022-10-25T11:30',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666687800000,
                          arrrivalDate: '2022-10-25T14:20',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '1784',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666666200000,
                        departure: 1666621800000,
                        numberOfStops: 1,
                        duration: 740,
                      },
                      key: 'UK|949|1666621800000|1666666200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_71BOMAMDUK949AMDDELUK926_18185014733460@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '01 Bag of 15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 17667.66,
                            publishedFare: 18464,
                            comission: 796.34,
                            bookingClass: 'O',
                            baseFare: 16965,
                            taxes: {
                              total: 1499,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 861,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_71BOMAMDUK949AMDDELUK926_18185014733290@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '30 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 21900.93,
                            publishedFare: 22285,
                            comission: 384.07,
                            bookingClass: 'Z',
                            baseFare: 19300,
                            taxes: {
                              total: 2985,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 2347,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '980',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '949',
                          durationInMin: 85,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666621800000,
                          departureDate: '2022-10-24T20:00',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666626900000,
                          arrrivalDate: '2022-10-24T21:25',
                        },
                        {
                          id: '981',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '926',
                          durationInMin: 95,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666660500000,
                          departureDate: '2022-10-25T06:45',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666666200000,
                          arrrivalDate: '2022-10-25T08:20',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '949',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666622400000,
                        departure: 1666614600000,
                        numberOfStops: 0,
                        duration: 130,
                      },
                      key: 'AI|660|1666614600000|1666622400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_1BOMDELAI660_18185014625155@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6149.94,
                            publishedFare: 6258,
                            comission: 108.06,
                            bookingClass: 'S',
                            baseFare: 5430,
                            taxes: {
                              total: 828,
                              breakups: {
                                'Carrier Misc': 170,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '106',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '660',
                          durationInMin: 130,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666614600000,
                          departureDate: '2022-10-24T18:00',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666622400000,
                          arrrivalDate: '2022-10-24T20:10',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '660',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666626900000,
                        departure: 1666582500000,
                        numberOfStops: 1,
                        duration: 740,
                      },
                      key: 'UK|863|1666582500000|1666626900000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_46BOMBLRUK863BLRDELUK802_18185014700349@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 11586.2,
                            publishedFare: 12296,
                            comission: 709.8,
                            bookingClass: 'W',
                            baseFare: 11090,
                            taxes: {
                              total: 1206,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 568,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_46BOMBLRUK863BLRDELUK802_18185014700039@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 11586.2,
                            publishedFare: 12296,
                            comission: 709.8,
                            bookingClass: 'W',
                            baseFare: 11090,
                            taxes: {
                              total: 1206,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 568,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '282',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '863',
                          durationInMin: 100,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666582500000,
                          departureDate: '2022-10-24T09:05',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666588500000,
                          arrrivalDate: '2022-10-24T10:45',
                        },
                        {
                          id: '283',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '802',
                          durationInMin: 160,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666617300000,
                          departureDate: '2022-10-24T18:45',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666626900000,
                          arrrivalDate: '2022-10-24T21:25',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '863',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 6,
                      details: {
                        arrival: 1666610700000,
                        departure: 1666602600000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: 'UK|944|1666602600000|1666610700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_73BOMDELUK944_18185014735701@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 6,
                            offeredFare: 29753.26,
                            publishedFare: 30282,
                            comission: 528.74,
                            bookingClass: 'S',
                            baseFare: 26570,
                            taxes: {
                              total: 3712,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 3204,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_73BOMDELUK944_18185014735541@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 6,
                            offeredFare: 29753.26,
                            publishedFare: 30282,
                            comission: 528.74,
                            bookingClass: 'S',
                            baseFare: 26570,
                            taxes: {
                              total: 3712,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 3204,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '427',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '944',
                          durationInMin: 135,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666602600000,
                          departureDate: '2022-10-24T14:40',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666610700000,
                          arrrivalDate: '2022-10-24T16:55',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '944',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666609500000,
                        departure: 1666602300000,
                        numberOfStops: 0,
                        duration: 120,
                      },
                      key: '6E|993|1666602300000|1666609500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_11BOMDEL6E993_18188342247615@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7675.58,
                            publishedFare: 8090,
                            comission: 414.42,
                            bookingClass: 'M',
                            baseFare: 7000,
                            taxes: {
                              total: 1090,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1090,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_11BOMDEL6E993_18188342248085@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7685.34,
                            publishedFare: 7833,
                            comission: 147.66,
                            bookingClass: 'R',
                            baseFare: 7000,
                            taxes: {
                              total: 833,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 833,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '631',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '993',
                          durationInMin: 120,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666602300000,
                          departureDate: '2022-10-24T14:35',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666609500000,
                          arrrivalDate: '2022-10-24T16:35',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '993',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666668300000,
                        departure: 1666629000000,
                        numberOfStops: 1,
                        duration: 655,
                      },
                      key: 'I5|1784|1666629000000|1666668300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_5BOMBLRI51784BLRDELI51528_18182007589471@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 10122.62,
                            publishedFare: 10262,
                            comission: 139.38,
                            bookingClass: 'SM',
                            baseFare: 9339,
                            taxes: {
                              total: 923,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 470,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_5BOMBLRI51784BLRDELI51528_18182007589701@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'PROMO',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 7536.91,
                            publishedFare: 7639,
                            comission: 102.09,
                            bookingClass: 'BT',
                            baseFare: 6840,
                            taxes: {
                              total: 799,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 346,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_5BOMBLRI51784BLRDELI51528_18182007589931@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PREMIUM_FLEX',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 10223.18,
                            publishedFare: 10364,
                            comission: 140.82,
                            bookingClass: 'HF',
                            baseFare: 9435,
                            taxes: {
                              total: 929,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 476,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '540',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '1784',
                          durationInMin: 110,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666629000000,
                          departureDate: '2022-10-24T22:00',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666635600000,
                          arrrivalDate: '2022-10-24T23:50',
                        },
                        {
                          id: '541',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '1528',
                          durationInMin: 165,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666658400000,
                          departureDate: '2022-10-25T06:10',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666668300000,
                          arrrivalDate: '2022-10-25T08:55',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '1784',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 8,
                      details: {
                        arrival: 1666682400000,
                        departure: 1666618200000,
                        numberOfStops: 1,
                        duration: 1070,
                      },
                      key: 'AI|641|1666618200000|1666682400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_19BOMBLRAI641BLRDELAI505_18185014658857@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 8832.57,
                            publishedFare: 8989,
                            comission: 156.43,
                            bookingClass: 'G',
                            baseFare: 7861,
                            taxes: {
                              total: 1128,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 410,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '589',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '641',
                          durationInMin: 100,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666618200000,
                          departureDate: '2022-10-24T19:00',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666624200000,
                          arrrivalDate: '2022-10-24T20:40',
                        },
                        {
                          id: '590',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '505',
                          durationInMin: 170,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666672200000,
                          departureDate: '2022-10-25T10:00',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666682400000,
                          arrrivalDate: '2022-10-25T12:50',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '641',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666616100000,
                        departure: 1666572600000,
                        numberOfStops: 1,
                        duration: 725,
                      },
                      key: 'SG|115|1666572600000|1666616100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '4-2158784108_4BOMDBRSG115DBRDELSG752_18183742629222@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 26774.89,
                            publishedFare: 27347,
                            comission: 572.11,
                            bookingClass: 'TT',
                            baseFare: 25555,
                            taxes: {
                              total: 1792,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 508,
                                'Management Fees': 0,
                                'Gst Charges': 1284,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_4BOMDBRSG115DBRDELSG752_18183742628882@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 27367.41,
                            publishedFare: 29025,
                            comission: 1657.59,
                            bookingClass: 'CP',
                            baseFare: 26235,
                            taxes: {
                              total: 2790,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1426,
                                'Management Fees': 0,
                                'Gst Charges': 1364,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_4BOMDBRSG115DBRDELSG752_18183742629442@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 25733.59,
                            publishedFare: 26283,
                            comission: 549.41,
                            bookingClass: 'SR',
                            baseFare: 24541,
                            taxes: {
                              total: 1742,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 508,
                                'Management Fees': 0,
                                'Gst Charges': 1234,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '664',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 0,
                          flightNumber: '115',
                          durationInMin: 160,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666572600000,
                          departureDate: '2022-10-24T06:20',
                          arrival: {
                            code: 'DBR',
                            airport: 'Darbhanga Airport',
                            city: 'Darbhanga',
                            country: 'India',
                          },
                          arrivalTime: 1666582200000,
                          arrrivalDate: '2022-10-24T09:00',
                        },
                        {
                          id: '665',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 1,
                          flightNumber: '752',
                          durationInMin: 120,
                          departure: {
                            code: 'DBR',
                            airport: 'Darbhanga Airport',
                            city: 'Darbhanga',
                            country: 'India',
                          },
                          departureTime: 1666608900000,
                          departureDate: '2022-10-24T16:25',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666616100000,
                          arrrivalDate: '2022-10-24T18:25',
                        },
                      ],
                      operator: {
                        name: 'SpiceJet',
                        code: 'SG',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/SG.png',
                        number: '115',
                        equipment: '7M8',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666713300000,
                        departure: 1666612800000,
                        numberOfStops: 1,
                        duration: 1675,
                      },
                      key: 'UK|843|1666612800000|1666713300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_49BOMBLRUK843BLRDELUK802_18185014704559@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 11586.2,
                            publishedFare: 12296,
                            comission: 709.8,
                            bookingClass: 'W',
                            baseFare: 11090,
                            taxes: {
                              total: 1206,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 568,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '771',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '843',
                          durationInMin: 105,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666612800000,
                          departureDate: '2022-10-24T17:30',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666619100000,
                          arrrivalDate: '2022-10-24T19:15',
                        },
                        {
                          id: '772',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '802',
                          durationInMin: 160,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666703700000,
                          departureDate: '2022-10-25T18:45',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666713300000,
                          arrrivalDate: '2022-10-25T21:25',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '843',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666634400000,
                        departure: 1666614900000,
                        numberOfStops: 1,
                        duration: 325,
                      },
                      key: '6E|6418|1666614900000|1666634400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_34BOMPAT6E6418PATDEL6E6927_18188342310948@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 12799.42,
                            publishedFare: 13506,
                            comission: 706.58,
                            bookingClass: 'M',
                            baseFare: 11935,
                            taxes: {
                              total: 1571,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1571,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_34BOMPAT6E6418PATDEL6E6927_18188342311418@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 11699.13,
                            publishedFare: 11928,
                            comission: 228.87,
                            bookingClass: 'R',
                            baseFare: 10850,
                            taxes: {
                              total: 1078,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1078,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '676',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '6418',
                          durationInMin: 130,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666614900000,
                          departureDate: '2022-10-24T18:05',
                          arrival: {
                            code: 'PAT',
                            airport: 'Jai Prakash Narayan Arpt',
                            city: 'Patna',
                            country: 'India',
                          },
                          arrivalTime: 1666622700000,
                          arrrivalDate: '2022-10-24T20:15',
                        },
                        {
                          id: '677',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '6927',
                          durationInMin: 100,
                          departure: {
                            code: 'PAT',
                            airport: 'Jai Prakash Narayan Arpt',
                            city: 'Patna',
                            country: 'India',
                          },
                          departureTime: 1666628400000,
                          departureDate: '2022-10-24T21:50',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666634400000,
                          arrrivalDate: '2022-10-24T23:30',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '6418',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666668300000,
                        departure: 1666622400000,
                        numberOfStops: 1,
                        duration: 765,
                      },
                      key: 'AI|619|1666622400000|1666668300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_43BOMHYDAI619HYDDELAI559_18185014696999@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 11818.88,
                            publishedFare: 12033,
                            comission: 214.12,
                            bookingClass: 'G',
                            baseFare: 10760,
                            taxes: {
                              total: 1273,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 555,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '613',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '619',
                          durationInMin: 90,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666622400000,
                          departureDate: '2022-10-24T20:10',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666627800000,
                          arrrivalDate: '2022-10-24T21:40',
                        },
                        {
                          id: '614',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '559',
                          durationInMin: 135,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666660200000,
                          departureDate: '2022-10-25T06:40',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666668300000,
                          arrrivalDate: '2022-10-25T08:55',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '619',
                        equipment: '319',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666608300000,
                        departure: 1666593600000,
                        numberOfStops: 1,
                        duration: 245,
                      },
                      key: 'UK|852|1666593600000|1666608300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_89BOMDEDUK852DEDDELUK618_18185014754391@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 15699.67,
                            publishedFare: 15989,
                            comission: 289.33,
                            bookingClass: 'H',
                            baseFare: 14539,
                            taxes: {
                              total: 1450,
                              breakups: {
                                'Carrier Misc': 160,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 555,
                                'Management Fees': 0,
                                'Gst Charges': 735,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '252',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '852',
                          durationInMin: 130,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666593600000,
                          departureDate: '2022-10-24T12:10',
                          arrival: {
                            code: 'DED',
                            airport: 'Jolly Grant Airport',
                            city: 'Dehra Dun',
                            country: 'India',
                          },
                          arrivalTime: 1666601400000,
                          arrrivalDate: '2022-10-24T14:20',
                        },
                        {
                          id: '253',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '618',
                          durationInMin: 55,
                          departure: {
                            code: 'DED',
                            airport: 'Jolly Grant Airport',
                            city: 'Dehra Dun',
                            country: 'India',
                          },
                          departureTime: 1666605000000,
                          departureDate: '2022-10-24T15:20',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666608300000,
                          arrrivalDate: '2022-10-24T16:15',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '852',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666700100000,
                        departure: 1666612200000,
                        numberOfStops: 1,
                        duration: 1465,
                      },
                      key: 'UK|773|1666612200000|1666700100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_83BOMCCUUK773CCUDELUK778_18185014747961@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 20985.17,
                            publishedFare: 21378,
                            comission: 392.83,
                            bookingClass: 'H',
                            baseFare: 19740,
                            taxes: {
                              total: 1638,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 1000,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_83BOMCCUUK773CCUDELUK778_18185014747811@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '35 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 41065.27,
                            publishedFare: 41796,
                            comission: 730.73,
                            bookingClass: 'Z',
                            baseFare: 36720,
                            taxes: {
                              total: 5076,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 4438,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '302',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '773',
                          durationInMin: 155,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666612200000,
                          departureDate: '2022-10-24T17:20',
                          arrival: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          arrivalTime: 1666621500000,
                          arrrivalDate: '2022-10-24T19:55',
                        },
                        {
                          id: '303',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '778',
                          durationInMin: 145,
                          departure: {
                            code: 'CCU',
                            airport: 'Netaji Subhas Chandra Bose Intl',
                            city: 'Kolkata',
                            country: 'India',
                          },
                          departureTime: 1666691400000,
                          departureDate: '2022-10-25T15:20',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666700100000,
                          arrrivalDate: '2022-10-25T17:45',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '773',
                        equipment: '73H',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666628700000,
                        departure: 1666613400000,
                        numberOfStops: 1,
                        duration: 255,
                      },
                      key: '6E|6515|1666613400000|1666628700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_26BOMJAI6E6515JAIDEL6E7404_18188342287107@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 8994.58,
                            publishedFare: 9168,
                            comission: 173.42,
                            bookingClass: 'T',
                            baseFare: 8221,
                            taxes: {
                              total: 947,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 947,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_26BOMJAI6E6515JAIDEL6E7404_18188342286647@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 10194.5,
                            publishedFare: 10751,
                            comission: 556.5,
                            bookingClass: 'M',
                            baseFare: 9400,
                            taxes: {
                              total: 1351,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1351,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_26BOMJAI6E6515JAIDEL6E7404_18188342287347@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 9590.37,
                            publishedFare: 9776,
                            comission: 185.63,
                            bookingClass: 'R',
                            baseFare: 8800,
                            taxes: {
                              total: 976,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 976,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '310',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '6515',
                          durationInMin: 115,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666613400000,
                          departureDate: '2022-10-24T17:40',
                          arrival: {
                            code: 'JAI',
                            airport: 'Sanganeer Arpt',
                            city: 'Jaipur',
                            country: 'India',
                          },
                          arrivalTime: 1666620300000,
                          arrrivalDate: '2022-10-24T19:35',
                        },
                        {
                          id: '311',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '7404',
                          durationInMin: 65,
                          departure: {
                            code: 'JAI',
                            airport: 'Sanganeer Arpt',
                            city: 'Jaipur',
                            country: 'India',
                          },
                          departureTime: 1666624800000,
                          departureDate: '2022-10-24T20:50',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666628700000,
                          arrrivalDate: '2022-10-24T21:55',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '6515',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666609200000,
                        departure: 1666565400000,
                        numberOfStops: 1,
                        duration: 730,
                      },
                      key: 'I5|331|1666565400000|1666609200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_1BOMGOII5331GOIDELI5776_18182007578741@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7288.5,
                            publishedFare: 7387,
                            comission: 98.5,
                            bookingClass: 'SM',
                            baseFare: 6600,
                            taxes: {
                              total: 787,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 334,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_1BOMGOII5331GOIDELI5776_18182007578951@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7288.5,
                            publishedFare: 7387,
                            comission: 98.5,
                            bookingClass: 'EC',
                            baseFare: 6600,
                            taxes: {
                              total: 787,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 334,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '957',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '331',
                          durationInMin: 70,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666565400000,
                          departureDate: '2022-10-24T04:20',
                          arrival: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          arrivalTime: 1666569600000,
                          arrrivalDate: '2022-10-24T05:30',
                        },
                        {
                          id: '958',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '776',
                          durationInMin: 165,
                          departure: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          departureTime: 1666599300000,
                          departureDate: '2022-10-24T13:45',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666609200000,
                          arrrivalDate: '2022-10-24T16:30',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '331',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666614900000,
                        departure: 1666607400000,
                        numberOfStops: 0,
                        duration: 125,
                      },
                      key: 'G8|339|1666607400000|1666614900000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_2BOMDELG8339_18183418040221@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7319.11,
                            publishedFare: 7518,
                            comission: 198.89,
                            bookingClass: 'SP',
                            baseFare: 6663,
                            taxes: {
                              total: 855,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 855,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_2BOMDELG8339_18183418040001@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7725.06,
                            publishedFare: 8226,
                            comission: 500.94,
                            bookingClass: 'BC',
                            baseFare: 7091,
                            taxes: {
                              total: 1135,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1135,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_2BOMDELG8339_18183418040441@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7828.18,
                            publishedFare: 8042,
                            comission: 213.82,
                            bookingClass: 'GS',
                            baseFare: 7163,
                            taxes: {
                              total: 879,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 879,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '837',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 0,
                          flightNumber: '339',
                          durationInMin: 125,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666607400000,
                          departureDate: '2022-10-24T16:00',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666614900000,
                          arrrivalDate: '2022-10-24T18:05',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '339',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 8,
                      details: {
                        arrival: 1666668300000,
                        departure: 1666618200000,
                        numberOfStops: 1,
                        duration: 835,
                      },
                      key: 'AI|641|1666618200000|1666668300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_18BOMBLRAI641BLRDELAI804_18185014657027@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 8832.57,
                            publishedFare: 8989,
                            comission: 156.43,
                            bookingClass: 'G',
                            baseFare: 7861,
                            taxes: {
                              total: 1128,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 410,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '63',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '641',
                          durationInMin: 100,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666618200000,
                          departureDate: '2022-10-24T19:00',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666624200000,
                          arrrivalDate: '2022-10-24T20:40',
                        },
                        {
                          id: '64',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '804',
                          durationInMin: 165,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666658400000,
                          departureDate: '2022-10-25T06:10',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666668300000,
                          arrrivalDate: '2022-10-25T08:55',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '641',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666587300000,
                        departure: 1666573500000,
                        numberOfStops: 1,
                        duration: 230,
                      },
                      key: 'UK|939|1666573500000|1666587300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_69BOMAMDUK939AMDDELUK946_18185014730870@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 17276.81,
                            publishedFare: 17598,
                            comission: 321.19,
                            bookingClass: 'W',
                            baseFare: 16140,
                            taxes: {
                              total: 1458,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 820,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_69BOMAMDUK939AMDDELUK946_18185014730700@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '30 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 21900.93,
                            publishedFare: 22285,
                            comission: 384.07,
                            bookingClass: 'Z',
                            baseFare: 19300,
                            taxes: {
                              total: 2985,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 2347,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '249',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '939',
                          durationInMin: 75,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666573500000,
                          departureDate: '2022-10-24T06:35',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666578000000,
                          arrrivalDate: '2022-10-24T07:50',
                        },
                        {
                          id: '250',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '946',
                          durationInMin: 115,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666580400000,
                          departureDate: '2022-10-24T08:30',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666587300000,
                          arrrivalDate: '2022-10-24T10:25',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '939',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 8,
                      details: {
                        arrival: 1666686900000,
                        departure: 1666618200000,
                        numberOfStops: 1,
                        duration: 1145,
                      },
                      key: 'AI|641|1666618200000|1666686900000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_20BOMBLRAI641BLRDELAI507_18185014660477@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 8832.57,
                            publishedFare: 8989,
                            comission: 156.43,
                            bookingClass: 'G',
                            baseFare: 7861,
                            taxes: {
                              total: 1128,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 410,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '29',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '641',
                          durationInMin: 100,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666618200000,
                          departureDate: '2022-10-24T19:00',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666624200000,
                          arrrivalDate: '2022-10-24T20:40',
                        },
                        {
                          id: '30',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '507',
                          durationInMin: 170,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666676700000,
                          departureDate: '2022-10-25T11:15',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666686900000,
                          arrrivalDate: '2022-10-25T14:05',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '641',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 6,
                      details: {
                        arrival: 1666649700000,
                        departure: 1666625400000,
                        numberOfStops: 1,
                        duration: 405,
                      },
                      key: 'AI|683|1666625400000|1666649700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_53BOMGOIAI683GOIDELAI884_18185014709569@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 6,
                            offeredFare: 12333.93,
                            publishedFare: 12558,
                            comission: 224.07,
                            bookingClass: 'V',
                            baseFare: 11260,
                            taxes: {
                              total: 1298,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 580,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '261',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '683',
                          durationInMin: 80,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666625400000,
                          departureDate: '2022-10-24T21:00',
                          arrival: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          arrivalTime: 1666630200000,
                          arrrivalDate: '2022-10-24T22:20',
                        },
                        {
                          id: '262',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '884',
                          durationInMin: 150,
                          departure: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          departureTime: 1666640700000,
                          departureDate: '2022-10-25T01:15',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666649700000,
                          arrrivalDate: '2022-10-25T03:45',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '683',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666669800000,
                        departure: 1666623000000,
                        numberOfStops: 1,
                        duration: 780,
                      },
                      key: 'G8|364|1666623000000|1666669800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_4BOMAMDG8364AMDDELG8720_18183418044371@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 15015.86,
                            publishedFare: 15440,
                            comission: 424.14,
                            bookingClass: 'SP',
                            baseFare: 14209,
                            taxes: {
                              total: 1231,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1231,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_4BOMAMDG8364AMDDELG8720_18183418043821@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 16251.42,
                            publishedFare: 17345,
                            comission: 1093.58,
                            bookingClass: 'BC',
                            baseFare: 15480,
                            taxes: {
                              total: 1865,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1865,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_4BOMAMDG8364AMDDELG8720_18183418044591@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 15322.91,
                            publishedFare: 15756,
                            comission: 433.09,
                            bookingClass: 'GS',
                            baseFare: 14509,
                            taxes: {
                              total: 1247,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1247,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '854',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 0,
                          flightNumber: '364',
                          durationInMin: 75,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666623000000,
                          departureDate: '2022-10-24T20:20',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666627500000,
                          arrrivalDate: '2022-10-24T21:35',
                        },
                        {
                          id: '855',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 1,
                          flightNumber: '720',
                          durationInMin: 110,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666663200000,
                          departureDate: '2022-10-25T07:30',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666669800000,
                          arrrivalDate: '2022-10-25T09:20',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '364',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666610100000,
                        departure: 1666593900000,
                        numberOfStops: 1,
                        duration: 270,
                      },
                      key: 'UK|613|1666593900000|1666610100000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_59BOMUDRUK613UDRDELUK628_18185014716650@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 14111.95,
                            publishedFare: 14372,
                            comission: 260.05,
                            bookingClass: 'H',
                            baseFare: 13068,
                            taxes: {
                              total: 1304,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 666,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_59BOMUDRUK613UDRDELUK628_18185014716480@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 16533.04,
                            publishedFare: 16820,
                            comission: 286.96,
                            bookingClass: 'T',
                            baseFare: 14420,
                            taxes: {
                              total: 2400,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 1762,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '958',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '613',
                          durationInMin: 90,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666593900000,
                          departureDate: '2022-10-24T12:15',
                          arrival: {
                            code: 'UDR',
                            airport: 'Maharana Pratap Arpt',
                            city: 'Udaipur',
                            country: 'India',
                          },
                          arrivalTime: 1666599300000,
                          arrrivalDate: '2022-10-24T13:45',
                        },
                        {
                          id: '959',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '628',
                          durationInMin: 85,
                          departure: {
                            code: 'UDR',
                            airport: 'Maharana Pratap Arpt',
                            city: 'Udaipur',
                            country: 'India',
                          },
                          departureTime: 1666605000000,
                          departureDate: '2022-10-24T15:20',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666610100000,
                          arrrivalDate: '2022-10-24T16:45',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '613',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666596000000,
                        departure: 1666569300000,
                        numberOfStops: 1,
                        duration: 445,
                      },
                      key: 'AI|570|1666569300000|1666596000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_40BOMMAAAI570MAADELAI430_18185014693669@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 11633.46,
                            publishedFare: 11844,
                            comission: 210.54,
                            bookingClass: 'W',
                            baseFare: 10580,
                            taxes: {
                              total: 1264,
                              breakups: {
                                'Carrier Misc': 340,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 546,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '318',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '570',
                          durationInMin: 120,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666569300000,
                          departureDate: '2022-10-24T05:25',
                          arrival: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          arrivalTime: 1666576500000,
                          arrrivalDate: '2022-10-24T07:25',
                        },
                        {
                          id: '319',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 1,
                          flightNumber: '430',
                          durationInMin: 175,
                          departure: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          departureTime: 1666585500000,
                          departureDate: '2022-10-24T09:55',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666596000000,
                          arrrivalDate: '2022-10-24T12:50',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '570',
                        equipment: '32N',
                      },
                    },
                    {
                      remaining: 4,
                      details: {
                        arrival: 1666588200000,
                        departure: 1666580400000,
                        numberOfStops: 0,
                        duration: 130,
                      },
                      key: '6E|2137|1666580400000|1666588200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_9BOMDEL6E2137_18188342244505@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 7675.58,
                            publishedFare: 8090,
                            comission: 414.42,
                            bookingClass: 'M',
                            baseFare: 7000,
                            taxes: {
                              total: 1090,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1090,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_9BOMDEL6E2137_18188342244975@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 7685.34,
                            publishedFare: 7833,
                            comission: 147.66,
                            bookingClass: 'R',
                            baseFare: 7000,
                            taxes: {
                              total: 833,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 833,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '684',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '2137',
                          durationInMin: 130,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666580400000,
                          departureDate: '2022-10-24T08:30',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666588200000,
                          arrrivalDate: '2022-10-24T10:40',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '2137',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666584300000,
                        departure: 1666576800000,
                        numberOfStops: 0,
                        duration: 125,
                      },
                      key: 'UK|930|1666576800000|1666584300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_11BOMDELUK930_18185014643806@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 8415.37,
                            publishedFare: 8568,
                            comission: 152.63,
                            bookingClass: 'K',
                            baseFare: 7670,
                            taxes: {
                              total: 898,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 390,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_11BOMDELUK930_18185014642416@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 8415.37,
                            publishedFare: 8568,
                            comission: 152.63,
                            bookingClass: 'K',
                            baseFare: 7670,
                            taxes: {
                              total: 898,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 390,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '778',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '930',
                          durationInMin: 125,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666576800000,
                          departureDate: '2022-10-24T07:30',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666584300000,
                          arrrivalDate: '2022-10-24T09:35',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '930',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666632300000,
                        departure: 1666623900000,
                        numberOfStops: 0,
                        duration: 140,
                      },
                      key: '6E|2518|1666623900000|1666632300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_2BOMDEL6E2518_18188342229954@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6117.49,
                            publishedFare: 6233,
                            comission: 115.51,
                            bookingClass: 'T',
                            baseFare: 5476,
                            taxes: {
                              total: 757,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 757,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_2BOMDEL6E2518_18188342229414@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7162.18,
                            publishedFare: 7547,
                            comission: 384.82,
                            bookingClass: 'M',
                            baseFare: 6500,
                            taxes: {
                              total: 1047,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1047,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_2BOMDEL6E2518_18188342230174@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7170.89,
                            publishedFare: 7308,
                            comission: 137.11,
                            bookingClass: 'R',
                            baseFare: 6500,
                            taxes: {
                              total: 808,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 808,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '969',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '2518',
                          durationInMin: 140,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666623900000,
                          departureDate: '2022-10-24T20:35',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666632300000,
                          arrrivalDate: '2022-10-24T22:55',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '2518',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666648800000,
                        departure: 1666629000000,
                        numberOfStops: 1,
                        duration: 330,
                      },
                      key: 'I5|1784|1666629000000|1666648800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_4BOMBLRI51784BLRDELI5722_18182007586721@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 10122.62,
                            publishedFare: 10262,
                            comission: 139.38,
                            bookingClass: 'SM',
                            baseFare: 9339,
                            taxes: {
                              total: 923,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 470,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_4BOMBLRI51784BLRDELI5722_18182007586931@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'PROMO',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 7536.91,
                            publishedFare: 7639,
                            comission: 102.09,
                            bookingClass: 'BT',
                            baseFare: 6840,
                            taxes: {
                              total: 799,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 346,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_4BOMBLRI51784BLRDELI5722_18182007587151@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PREMIUM_FLEX',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 10223.18,
                            publishedFare: 10364,
                            comission: 140.82,
                            bookingClass: 'HF',
                            baseFare: 9435,
                            taxes: {
                              total: 929,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 476,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '763',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '1784',
                          durationInMin: 110,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666629000000,
                          departureDate: '2022-10-24T22:00',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666635600000,
                          arrrivalDate: '2022-10-24T23:50',
                        },
                        {
                          id: '764',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '722',
                          durationInMin: 170,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666638600000,
                          departureDate: '2022-10-25T00:40',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666648800000,
                          arrrivalDate: '2022-10-25T03:30',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '1784',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666616400000,
                        departure: 1666597500000,
                        numberOfStops: 1,
                        duration: 315,
                      },
                      key: '6E|5349|1666597500000|1666616400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_36BOMVNS6E5349VNSDEL6E2096_18188342316909@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 14607.22,
                            publishedFare: 15418,
                            comission: 810.78,
                            bookingClass: 'M',
                            baseFare: 13695,
                            taxes: {
                              total: 1723,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1723,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_36BOMVNS6E5349VNSDEL6E2096_18188342317379@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 13345.38,
                            publishedFare: 13608,
                            comission: 262.62,
                            bookingClass: 'R',
                            baseFare: 12450,
                            taxes: {
                              total: 1158,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1158,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '433',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '5349',
                          durationInMin: 125,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666597500000,
                          departureDate: '2022-10-24T13:15',
                          arrival: {
                            code: 'VNS',
                            airport: 'Lal Bahadur Shastri Arpt',
                            city: 'Varanasi',
                            country: 'India',
                          },
                          arrivalTime: 1666605000000,
                          arrrivalDate: '2022-10-24T15:20',
                        },
                        {
                          id: '434',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '2096',
                          durationInMin: 90,
                          departure: {
                            code: 'VNS',
                            airport: 'Lal Bahadur Shastri Arpt',
                            city: 'Varanasi',
                            country: 'India',
                          },
                          departureTime: 1666611000000,
                          departureDate: '2022-10-24T17:00',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666616400000,
                          arrrivalDate: '2022-10-24T18:30',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '5349',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666709400000,
                        departure: 1666612800000,
                        numberOfStops: 1,
                        duration: 1610,
                      },
                      key: 'UK|843|1666612800000|1666709400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_48BOMBLRUK843BLRDELUK820_18185014703609@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 11586.2,
                            publishedFare: 12296,
                            comission: 709.8,
                            bookingClass: 'W',
                            baseFare: 11090,
                            taxes: {
                              total: 1206,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 568,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '640',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '843',
                          durationInMin: 105,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666612800000,
                          departureDate: '2022-10-24T17:30',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666619100000,
                          arrrivalDate: '2022-10-24T19:15',
                        },
                        {
                          id: '641',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '820',
                          durationInMin: 155,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666700100000,
                          departureDate: '2022-10-25T17:45',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666709400000,
                          arrrivalDate: '2022-10-25T20:20',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '843',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666621800000,
                        departure: 1666574100000,
                        numberOfStops: 1,
                        duration: 795,
                      },
                      key: 'UK|821|1666574100000|1666621800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_77BOMMAAUK821MAADELUK834_18185014740231@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 16658.75,
                            publishedFare: 16968,
                            comission: 309.25,
                            bookingClass: 'K',
                            baseFare: 15540,
                            taxes: {
                              total: 1428,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 790,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_77BOMMAAUK821MAADELUK834_18185014740081@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '35 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 4,
                            offeredFare: 33496.18,
                            publishedFare: 34090,
                            comission: 593.82,
                            bookingClass: 'Z',
                            baseFare: 29840,
                            taxes: {
                              total: 4250,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 3612,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '890',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '821',
                          durationInMin: 125,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666574100000,
                          departureDate: '2022-10-24T06:45',
                          arrival: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          arrivalTime: 1666581600000,
                          arrrivalDate: '2022-10-24T08:50',
                        },
                        {
                          id: '891',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '834',
                          durationInMin: 165,
                          departure: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          departureTime: 1666611900000,
                          departureDate: '2022-10-24T17:15',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666621800000,
                          arrrivalDate: '2022-10-24T20:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '821',
                        equipment: '73H',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666642200000,
                        departure: 1666634400000,
                        numberOfStops: 0,
                        duration: 130,
                      },
                      key: '6E|248|1666634400000|1666642200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_1BOMDEL6E248_18188342227954@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 5758.83,
                            publishedFare: 5867,
                            comission: 108.17,
                            bookingClass: 'T',
                            baseFare: 5128,
                            taxes: {
                              total: 739,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 739,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_1BOMDEL6E248_18188342227394@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7162.18,
                            publishedFare: 7547,
                            comission: 384.82,
                            bookingClass: 'M',
                            baseFare: 6500,
                            taxes: {
                              total: 1047,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1047,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_1BOMDEL6E248_18188342228214@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7170.89,
                            publishedFare: 7308,
                            comission: 137.11,
                            bookingClass: 'R',
                            baseFare: 6500,
                            taxes: {
                              total: 808,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 808,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '684',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '248',
                          durationInMin: 130,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666634400000,
                          departureDate: '2022-10-24T23:30',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666642200000,
                          arrrivalDate: '2022-10-25T01:40',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '248',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666638900000,
                        departure: 1666621500000,
                        numberOfStops: 1,
                        duration: 290,
                      },
                      key: '6E|192|1666621500000|1666638900000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_13BOMHYD6E192HYDDEL6E2324_18188342254236@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 11134.71,
                            publishedFare: 11352,
                            comission: 217.29,
                            bookingClass: 'T',
                            baseFare: 10301,
                            taxes: {
                              total: 1051,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1051,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_13BOMHYD6E192HYDDEL6E2324_18188342253536@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7825.96,
                            publishedFare: 8246,
                            comission: 420.04,
                            bookingClass: 'M',
                            baseFare: 7095,
                            taxes: {
                              total: 1151,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1151,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_13BOMHYD6E192HYDDEL6E2324_18188342254036@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7171.94,
                            publishedFare: 7308,
                            comission: 136.06,
                            bookingClass: 'R',
                            baseFare: 6450,
                            taxes: {
                              total: 858,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 858,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '766',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '192',
                          durationInMin: 85,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666621500000,
                          departureDate: '2022-10-24T19:55',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666626600000,
                          arrrivalDate: '2022-10-24T21:20',
                        },
                        {
                          id: '767',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '2324',
                          durationInMin: 130,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666631100000,
                          departureDate: '2022-10-24T22:35',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666638900000,
                          arrrivalDate: '2022-10-25T00:45',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '192',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666560000000,
                        departure: 1666551900000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: 'SG|712|1666551900000|1666560000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '4-2158784108_0BOMDELSG712_18183742620552@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 7264.92,
                            publishedFare: 7412,
                            comission: 147.08,
                            bookingClass: 'DD',
                            baseFare: 6570,
                            taxes: {
                              total: 842,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 508,
                                'Management Fees': 0,
                                'Gst Charges': 334,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_0BOMDELSG712_18183742621252@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 7602.55,
                            publishedFare: 7757,
                            comission: 154.45,
                            bookingClass: 'SA',
                            baseFare: 6899,
                            taxes: {
                              total: 858,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 508,
                                'Management Fees': 0,
                                'Gst Charges': 350,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_0BOMDELSG712_18183742619812@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 6869.7,
                            publishedFare: 7262,
                            comission: 392.3,
                            bookingClass: 'CP',
                            baseFare: 6209,
                            taxes: {
                              total: 1053,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 725,
                                'Management Fees': 0,
                                'Gst Charges': 328,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_0BOMDELSG712_18183742621482@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 7320.55,
                            publishedFare: 7518,
                            comission: 197.45,
                            bookingClass: 'HR',
                            baseFare: 6575,
                            taxes: {
                              total: 943,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 609,
                                'Management Fees': 0,
                                'Gst Charges': 334,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '425',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 0,
                          flightNumber: '712',
                          durationInMin: 135,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666551900000,
                          departureDate: '2022-10-24T00:35',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666560000000,
                          arrrivalDate: '2022-10-24T02:50',
                        },
                      ],
                      operator: {
                        name: 'SpiceJet',
                        code: 'SG',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/SG.png',
                        number: '712',
                        equipment: '737',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666620000000,
                        departure: 1666599300000,
                        numberOfStops: 1,
                        duration: 345,
                      },
                      key: '6E|5261|1666599300000|1666620000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_32BOMNAG6E5261NAGDEL6E2067_18188342305398@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 12067.58,
                            publishedFare: 12732,
                            comission: 664.42,
                            bookingClass: 'M',
                            baseFare: 11223,
                            taxes: {
                              total: 1509,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1509,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_32BOMNAG6E5261NAGDEL6E2067_18188342305868@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 11635.43,
                            publishedFare: 11863,
                            comission: 227.57,
                            bookingClass: 'R',
                            baseFare: 10788,
                            taxes: {
                              total: 1075,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1075,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '47',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '5261',
                          durationInMin: 75,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666599300000,
                          departureDate: '2022-10-24T13:45',
                          arrival: {
                            code: 'NAG',
                            airport: 'Dr Ambedkar Intl Arpt',
                            city: 'Nagpur',
                            country: 'India',
                          },
                          arrivalTime: 1666603800000,
                          arrrivalDate: '2022-10-24T15:00',
                        },
                        {
                          id: '48',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '2067',
                          durationInMin: 110,
                          departure: {
                            code: 'NAG',
                            airport: 'Dr Ambedkar Intl Arpt',
                            city: 'Nagpur',
                            country: 'India',
                          },
                          departureTime: 1666613400000,
                          departureDate: '2022-10-24T17:40',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666620000000,
                          arrrivalDate: '2022-10-24T19:30',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '5261',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666692300000,
                        departure: 1666626600000,
                        numberOfStops: 1,
                        duration: 1095,
                      },
                      key: 'UK|869|1666626600000|1666692300000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_29BOMHYDUK869HYDDELUK880_18185014677448@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 11816.3,
                            publishedFare: 12032,
                            comission: 215.7,
                            bookingClass: 'Q',
                            baseFare: 10839,
                            taxes: {
                              total: 1193,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 555,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_29BOMHYDUK869HYDDELUK880_18185014676508@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 10168.14,
                            publishedFare: 10352,
                            comission: 183.86,
                            bookingClass: 'Q',
                            baseFare: 9239,
                            taxes: {
                              total: 1113,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 475,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '202',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '869',
                          durationInMin: 80,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666626600000,
                          departureDate: '2022-10-24T21:20',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666631400000,
                          arrrivalDate: '2022-10-24T22:40',
                        },
                        {
                          id: '203',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '880',
                          durationInMin: 130,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666684500000,
                          departureDate: '2022-10-25T13:25',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666692300000,
                          arrrivalDate: '2022-10-25T15:35',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '869',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666626000000,
                        departure: 1666596900000,
                        numberOfStops: 1,
                        duration: 485,
                      },
                      key: 'I5|942|1666596900000|1666626000000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_15BOMBLRI5942BLRDELI51730_18182007618314@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 15368.98,
                            publishedFare: 15584,
                            comission: 215.02,
                            bookingClass: 'SM',
                            baseFare: 14407,
                            taxes: {
                              total: 1177,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 724,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_15BOMBLRI5942BLRDELI51730_18182007618894@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PREMIUM_FLEX',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 15652.88,
                            publishedFare: 15872,
                            comission: 219.12,
                            bookingClass: 'HF',
                            baseFare: 14681,
                            taxes: {
                              total: 1191,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 738,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '581',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '942',
                          durationInMin: 100,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666596900000,
                          departureDate: '2022-10-24T13:05',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666602900000,
                          arrrivalDate: '2022-10-24T14:45',
                        },
                        {
                          id: '582',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '1730',
                          durationInMin: 175,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666615500000,
                          departureDate: '2022-10-24T18:15',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666626000000,
                          arrrivalDate: '2022-10-24T21:10',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '942',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 7,
                      details: {
                        arrival: 1666586700000,
                        departure: 1666578600000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: 'AI|806|1666578600000|1666586700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_2BOMDELAI806_18185014626805@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 7,
                            offeredFare: 6149.94,
                            publishedFare: 6258,
                            comission: 108.06,
                            bookingClass: 'S',
                            baseFare: 5430,
                            taxes: {
                              total: 828,
                              breakups: {
                                'Carrier Misc': 170,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 280,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '670',
                          logo: 'https://static.eka.io/airline/AI.png',
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '806',
                          durationInMin: 135,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666578600000,
                          departureDate: '2022-10-24T08:00',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666586700000,
                          arrrivalDate: '2022-10-24T10:15',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/AI.png',
                        number: '806',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666600800000,
                        departure: 1666593300000,
                        numberOfStops: 0,
                        duration: 125,
                      },
                      key: '6E|168|1666593300000|1666600800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_15BOMDEL6E168_18188342259516@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 7984.82,
                            publishedFare: 8417,
                            comission: 432.18,
                            bookingClass: 'M',
                            baseFare: 7300,
                            taxes: {
                              total: 1117,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1117,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_15BOMDEL6E168_18188342259986@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 7994.01,
                            publishedFare: 8148,
                            comission: 153.99,
                            bookingClass: 'R',
                            baseFare: 7300,
                            taxes: {
                              total: 848,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 848,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '822',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '168',
                          durationInMin: 125,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666593300000,
                          departureDate: '2022-10-24T12:05',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666600800000,
                          arrrivalDate: '2022-10-24T14:10',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '168',
                        equipment: '321',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666632900000,
                        departure: 1666611600000,
                        numberOfStops: 1,
                        duration: 355,
                      },
                      key: '6E|6183|1666611600000|1666632900000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_35BOMRPR6E6183RPRDEL6E2521_18188342313839@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 13704.32,
                            publishedFare: 14463,
                            comission: 758.68,
                            bookingClass: 'M',
                            baseFare: 12815,
                            taxes: {
                              total: 1648,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1648,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_35BOMRPR6E6183RPRDEL6E2521_18188342314309@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 12522.25,
                            publishedFare: 12768,
                            comission: 245.75,
                            bookingClass: 'R',
                            baseFare: 11650,
                            taxes: {
                              total: 1118,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1118,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '728',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '6183',
                          durationInMin: 120,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666611600000,
                          departureDate: '2022-10-24T17:10',
                          arrival: {
                            code: 'RPR',
                            airport: 'Raipur Arpt',
                            city: 'Raipur',
                            country: 'India',
                          },
                          arrivalTime: 1666618800000,
                          arrrivalDate: '2022-10-24T19:10',
                        },
                        {
                          id: '729',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '2521',
                          durationInMin: 110,
                          departure: {
                            code: 'RPR',
                            airport: 'Raipur Arpt',
                            city: 'Raipur',
                            country: 'India',
                          },
                          departureTime: 1666626300000,
                          departureDate: '2022-10-24T21:15',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666632900000,
                          arrrivalDate: '2022-10-24T23:05',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '6183',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666601400000,
                        departure: 1666594500000,
                        numberOfStops: 0,
                        duration: 115,
                      },
                      key: 'UK|952|1666594500000|1666601400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_26BOMDELUK952_18185014671167@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 9960.52,
                            publishedFare: 10143,
                            comission: 182.48,
                            bookingClass: 'L',
                            baseFare: 9170,
                            taxes: {
                              total: 973,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 465,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_26BOMDELUK952_18185014670707@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 9960.52,
                            publishedFare: 10143,
                            comission: 182.48,
                            bookingClass: 'L',
                            baseFare: 9170,
                            taxes: {
                              total: 973,
                              breakups: {
                                'Carrier Misc': 130,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 465,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '635',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '952',
                          durationInMin: 115,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666594500000,
                          departureDate: '2022-10-24T12:25',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666601400000,
                          arrrivalDate: '2022-10-24T14:20',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '952',
                        equipment: '73H',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666707900000,
                        departure: 1666626600000,
                        numberOfStops: 1,
                        duration: 1355,
                      },
                      key: 'UK|869|1666626600000|1666707900000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_30BOMHYDUK869HYDDELUK890_18185014680318@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 11816.3,
                            publishedFare: 12032,
                            comission: 215.7,
                            bookingClass: 'Q',
                            baseFare: 10839,
                            taxes: {
                              total: 1193,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 555,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_30BOMHYDUK869HYDDELUK890_18185014679378@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 10168.14,
                            publishedFare: 10352,
                            comission: 183.86,
                            bookingClass: 'Q',
                            baseFare: 9239,
                            taxes: {
                              total: 1113,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 475,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '737',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '869',
                          durationInMin: 80,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666626600000,
                          departureDate: '2022-10-24T21:20',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666631400000,
                          arrrivalDate: '2022-10-24T22:40',
                        },
                        {
                          id: '738',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '890',
                          durationInMin: 135,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666699800000,
                          departureDate: '2022-10-25T17:40',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666707900000,
                          arrrivalDate: '2022-10-25T19:55',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '869',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 2,
                      details: {
                        arrival: 1666601400000,
                        departure: 1666578300000,
                        numberOfStops: 1,
                        duration: 385,
                      },
                      key: 'I5|631|1666578300000|1666601400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '22-16-2158784108_6BOMIXRI5631IXRDELI5549_18182007592302@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 10467.67,
                            publishedFare: 10612,
                            comission: 144.33,
                            bookingClass: 'SM',
                            baseFare: 9671,
                            taxes: {
                              total: 941,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 488,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '22-16-2158784108_6BOMIXRI5631IXRDELI5549_18182007592532@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 2,
                            offeredFare: 10467.67,
                            publishedFare: 10612,
                            comission: 144.33,
                            bookingClass: 'EC',
                            baseFare: 9671,
                            taxes: {
                              total: 941,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 453,
                                'Management Fees': 0,
                                'Gst Charges': 488,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '820',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 0,
                          flightNumber: '631',
                          durationInMin: 160,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666578300000,
                          departureDate: '2022-10-24T07:55',
                          arrival: {
                            code: 'IXR',
                            airport: 'Birsa Munda Arpt',
                            city: 'Ranchi',
                            country: 'India',
                          },
                          arrivalTime: 1666587900000,
                          arrrivalDate: '2022-10-24T10:35',
                        },
                        {
                          id: '821',
                          logo: 'https://static.eka.io/airline/I5.png',
                          flightCode: 'I5',
                          airline: 'AirAsia India',
                          rank: 1,
                          flightNumber: '549',
                          durationInMin: 120,
                          departure: {
                            code: 'IXR',
                            airport: 'Birsa Munda Arpt',
                            city: 'Ranchi',
                            country: 'India',
                          },
                          departureTime: 1666594200000,
                          departureDate: '2022-10-24T12:20',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666601400000,
                          arrrivalDate: '2022-10-24T14:20',
                        },
                      ],
                      operator: {
                        name: 'AirAsia India',
                        code: 'I5',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/I5.png',
                        number: '631',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666621200000,
                        departure: 1666571400000,
                        numberOfStops: 1,
                        duration: 830,
                      },
                      key: 'UK|653|1666571400000|1666621200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_63BOMIXCUK653IXCDELUK638_18185014723000@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 17585.84,
                            publishedFare: 17913,
                            comission: 327.16,
                            bookingClass: 'A',
                            baseFare: 16440,
                            taxes: {
                              total: 1473,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 835,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_63BOMIXCUK653IXCDELUK638_18185014722840@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '20 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 18039.78,
                            publishedFare: 18354,
                            comission: 314.22,
                            bookingClass: 'T',
                            baseFare: 15790,
                            taxes: {
                              total: 2564,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 1926,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '156',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '653',
                          durationInMin: 135,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666571400000,
                          departureDate: '2022-10-24T06:00',
                          arrival: {
                            code: 'IXC',
                            airport: 'Chandigarh Arpt',
                            city: 'Chandigarh',
                            country: 'India',
                          },
                          arrivalTime: 1666579500000,
                          arrrivalDate: '2022-10-24T08:15',
                        },
                        {
                          id: '157',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '638',
                          durationInMin: 60,
                          departure: {
                            code: 'IXC',
                            airport: 'Chandigarh Arpt',
                            city: 'Chandigarh',
                            country: 'India',
                          },
                          departureTime: 1666617600000,
                          departureDate: '2022-10-24T18:50',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666621200000,
                          arrrivalDate: '2022-10-24T19:50',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '653',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666669800000,
                        departure: 1666630800000,
                        numberOfStops: 1,
                        duration: 650,
                      },
                      key: 'G8|363|1666630800000|1666669800000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_5BOMAMDG8363AMDDELG8720_18183418048221@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 15015.86,
                            publishedFare: 15440,
                            comission: 424.14,
                            bookingClass: 'SP',
                            baseFare: 14209,
                            taxes: {
                              total: 1231,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1231,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_5BOMAMDG8363AMDDELG8720_18183418047671@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 16251.42,
                            publishedFare: 17345,
                            comission: 1093.58,
                            bookingClass: 'BC',
                            baseFare: 15480,
                            taxes: {
                              total: 1865,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1865,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_5BOMAMDG8363AMDDELG8720_18183418048461@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 15322.91,
                            publishedFare: 15756,
                            comission: 433.09,
                            bookingClass: 'GS',
                            baseFare: 14509,
                            taxes: {
                              total: 1247,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1247,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '750',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 0,
                          flightNumber: '363',
                          durationInMin: 80,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666630800000,
                          departureDate: '2022-10-24T22:30',
                          arrival: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          arrivalTime: 1666635600000,
                          arrrivalDate: '2022-10-24T23:50',
                        },
                        {
                          id: '751',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 1,
                          flightNumber: '720',
                          durationInMin: 110,
                          departure: {
                            code: 'AMD',
                            airport: 'Sardar Vallabh Bhai Patel Intl Arpt',
                            city: 'Ahmedabad',
                            country: 'India',
                          },
                          departureTime: 1666663200000,
                          departureDate: '2022-10-25T07:30',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666669800000,
                          arrrivalDate: '2022-10-25T09:20',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '363',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 5,
                      details: {
                        arrival: 1666673700000,
                        departure: 1666601100000,
                        numberOfStops: 1,
                        duration: 1210,
                      },
                      key: 'SG|2901|1666601100000|1666673700000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '4-2158784108_8BOMRAJSG2901RAJDELSG3703_18183742641593@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 16972.41,
                            publishedFare: 17331,
                            comission: 358.59,
                            bookingClass: 'DD',
                            baseFare: 16017,
                            taxes: {
                              total: 1314,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 508,
                                'Management Fees': 0,
                                'Gst Charges': 806,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_8BOMRAJSG2901RAJDELSG3703_18183742642053@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'SEMI_LOADED',
                          message:
                            'One step below Fully Loaded Fares, Refundable - Upto 75%, Free - Seat & Meals',
                          typeKey: 'SME',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 19172.51,
                            publishedFare: 19579,
                            comission: 406.49,
                            bookingClass: 'SA',
                            baseFare: 18157,
                            taxes: {
                              total: 1422,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 508,
                                'Management Fees': 0,
                                'Gst Charges': 914,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_8BOMRAJSG2901RAJDELSG3703_18183742641283@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 17475.07,
                            publishedFare: 18522,
                            comission: 1046.93,
                            bookingClass: 'CP',
                            baseFare: 16570,
                            taxes: {
                              total: 1952,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1088,
                                'Management Fees': 0,
                                'Gst Charges': 864,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '4-2158784108_8BOMRAJSG2901RAJDELSG3703_18183742642213@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 5,
                            offeredFare: 17202.26,
                            publishedFare: 17650,
                            comission: 447.74,
                            bookingClass: 'HR',
                            baseFare: 16155,
                            taxes: {
                              total: 1495,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 681,
                                'Management Fees': 0,
                                'Gst Charges': 814,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '563',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 0,
                          flightNumber: '2901',
                          durationInMin: 95,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666601100000,
                          departureDate: '2022-10-24T14:15',
                          arrival: {
                            code: 'RAJ',
                            airport: 'Rajkot Civil Arpt',
                            city: 'Rajkot',
                            country: 'India',
                          },
                          arrivalTime: 1666606800000,
                          arrrivalDate: '2022-10-24T15:50',
                        },
                        {
                          id: '564',
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 1,
                          flightNumber: '3703',
                          durationInMin: 140,
                          departure: {
                            code: 'RAJ',
                            airport: 'Rajkot Civil Arpt',
                            city: 'Rajkot',
                            country: 'India',
                          },
                          departureTime: 1666665300000,
                          departureDate: '2022-10-25T08:05',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666673700000,
                          arrrivalDate: '2022-10-25T10:25',
                        },
                      ],
                      operator: {
                        name: 'SpiceJet',
                        code: 'SG',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/SG.png',
                        number: '2901',
                        equipment: 'DH8',
                      },
                    },
                    {
                      remaining: 9,
                      details: {
                        arrival: 1666610400000,
                        departure: 1666590900000,
                        numberOfStops: 1,
                        duration: 325,
                      },
                      key: '6E|179|1666590900000|1666610400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '5-2158784108_19BOMGOI6E179GOIDEL6E2332_18188342267336@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG',
                          message:
                            'Exclusive Unbeatable Prices, Refundable - Upto 20%, Paid -  Seats &  Meals',
                          typeKey: 'TACTICAL',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 11236.62,
                            publishedFare: 11456,
                            comission: 219.38,
                            bookingClass: 'T',
                            baseFare: 10400,
                            taxes: {
                              total: 1056,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1056,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_19BOMGOI6E179GOIDEL6E2332_18188342266736@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORP_CONNECT',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 8277.91,
                            publishedFare: 8724,
                            comission: 446.09,
                            bookingClass: 'M',
                            baseFare: 7535,
                            taxes: {
                              total: 1189,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1189,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '5-2158784108_19BOMGOI6E179GOIDEL6E2332_18188342267146@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg (01 Piece only)',
                              cabin: '01 Small Handbag under the seat',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 7583.51,
                            publishedFare: 7728,
                            comission: 144.49,
                            bookingClass: 'R',
                            baseFare: 6850,
                            taxes: {
                              total: 878,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 878,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '616',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 0,
                          flightNumber: '179',
                          durationInMin: 75,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666590900000,
                          departureDate: '2022-10-24T11:25',
                          arrival: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          arrivalTime: 1666595400000,
                          arrrivalDate: '2022-10-24T12:40',
                        },
                        {
                          id: '617',
                          logo: 'https://static.eka.io/airline/6E.png',
                          flightCode: '6E',
                          airline: 'IndiGo',
                          rank: 1,
                          flightNumber: '2332',
                          durationInMin: 165,
                          departure: {
                            code: 'GOI',
                            airport: 'Dabolim Arpt',
                            city: 'Goa In',
                            country: 'India',
                          },
                          departureTime: 1666600500000,
                          departureDate: '2022-10-24T14:05',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666610400000,
                          arrrivalDate: '2022-10-24T16:50',
                        },
                      ],
                      operator: {
                        name: 'IndiGo',
                        code: '6E',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/6E.png',
                        number: '179',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 1,
                      details: {
                        arrival: 1666666500000,
                        departure: 1666631400000,
                        numberOfStops: 1,
                        duration: 585,
                      },
                      key: 'G8|325|1666631400000|1666666500000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '1-2158784108_6BOMBLRG8325BLRDELG8116_18183418053552@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'OMG2',
                          message:
                            'One step below OMG Fares, Refundable - Upto 20%, Paid -  Seats &  Meals.',
                          typeKey: 'COUPON',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 8598.65,
                            publishedFare: 8835,
                            comission: 236.35,
                            bookingClass: 'SP',
                            baseFare: 7918,
                            taxes: {
                              total: 917,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 917,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_6BOMBLRG8325BLRDELG8116_18183418053112@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 9227.71,
                            publishedFare: 9833,
                            comission: 605.29,
                            bookingClass: 'BC',
                            baseFare: 8568,
                            taxes: {
                              total: 1265,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 1265,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '1-2158784108_6BOMBLRG8325BLRDELG8116_18183418053792@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kg',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 1,
                            offeredFare: 9210.73,
                            publishedFare: 9465,
                            comission: 254.27,
                            bookingClass: 'GS',
                            baseFare: 8518,
                            taxes: {
                              total: 947,
                              breakups: {
                                'Carrier Misc': 0,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 947,
                                'Management Fees': 0,
                                'Gst Charges': 0,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '978',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 0,
                          flightNumber: '325',
                          durationInMin: 110,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666631400000,
                          departureDate: '2022-10-24T22:40',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666638000000,
                          arrrivalDate: '2022-10-25T00:30',
                        },
                        {
                          id: '979',
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 1,
                          flightNumber: '116',
                          durationInMin: 175,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666656000000,
                          departureDate: '2022-10-25T05:30',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666666500000,
                          arrrivalDate: '2022-10-25T08:25',
                        },
                      ],
                      operator: {
                        name: 'Go First',
                        code: 'G8',
                        lcc: true,
                        logo: 'https://static.eka.io/airline/G8.png',
                        number: '325',
                        equipment: '32A',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666681200000,
                        departure: 1666626600000,
                        numberOfStops: 1,
                        duration: 910,
                      },
                      key: 'UK|869|1666626600000|1666681200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_28BOMHYDUK869HYDDELUK830_18185014674568@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 11816.3,
                            publishedFare: 12032,
                            comission: 215.7,
                            bookingClass: 'Q',
                            baseFare: 10839,
                            taxes: {
                              total: 1193,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 555,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_28BOMHYDUK869HYDDELUK830_18185014673638@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 10168.14,
                            publishedFare: 10352,
                            comission: 183.86,
                            bookingClass: 'Q',
                            baseFare: 9239,
                            taxes: {
                              total: 1113,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 475,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '640',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '869',
                          durationInMin: 80,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666626600000,
                          departureDate: '2022-10-24T21:20',
                          arrival: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          arrivalTime: 1666631400000,
                          arrrivalDate: '2022-10-24T22:40',
                        },
                        {
                          id: '641',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '830',
                          durationInMin: 140,
                          departure: {
                            code: 'HYD',
                            airport: 'Shamshabad Rajiv Gandhi Intl Arpt',
                            city: 'Hyderabad',
                            country: 'India',
                          },
                          departureTime: 1666672800000,
                          departureDate: '2022-10-25T10:10',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666681200000,
                          arrrivalDate: '2022-10-25T12:30',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '869',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 8,
                      details: {
                        arrival: 1666626900000,
                        departure: 1666575000000,
                        numberOfStops: 1,
                        duration: 865,
                      },
                      key: 'UK|851|1666575000000|1666626900000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_47BOMBLRUK851BLRDELUK802_18185014702079@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 11586.2,
                            publishedFare: 12296,
                            comission: 709.8,
                            bookingClass: 'W',
                            baseFare: 11090,
                            taxes: {
                              total: 1206,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 568,
                              },
                            },
                          },
                        },
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_47BOMBLRUK851BLRDELUK802_18185014701619@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'RETAIL',
                          message:
                            'What everyone else buys , Refundable - Upto 50%, Paid -  Seats &  Meals',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 8,
                            offeredFare: 11586.2,
                            publishedFare: 12296,
                            comission: 709.8,
                            bookingClass: 'W',
                            baseFare: 11090,
                            taxes: {
                              total: 1206,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 568,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '47',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '851',
                          durationInMin: 110,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666575000000,
                          departureDate: '2022-10-24T07:00',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          arrivalTime: 1666581600000,
                          arrrivalDate: '2022-10-24T08:50',
                        },
                        {
                          id: '48',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '802',
                          durationInMin: 160,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                          },
                          departureTime: 1666617300000,
                          departureDate: '2022-10-24T18:45',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666626900000,
                          arrrivalDate: '2022-10-24T21:25',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '851',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666697400000,
                        departure: 1666597800000,
                        numberOfStops: 1,
                        duration: 1660,
                      },
                      key: 'UK|621|1666597800000|1666697400000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_93BOMVNSUK621VNSDELUK674_18185014758432@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 24487.51,
                            publishedFare: 24948,
                            comission: 460.49,
                            bookingClass: 'M',
                            baseFare: 23140,
                            taxes: {
                              total: 1808,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 1170,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '227',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '621',
                          durationInMin: 135,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666597800000,
                          departureDate: '2022-10-24T13:20',
                          arrival: {
                            code: 'VNS',
                            airport: 'Lal Bahadur Shastri Arpt',
                            city: 'Varanasi',
                            country: 'India',
                          },
                          arrivalTime: 1666605900000,
                          arrrivalDate: '2022-10-24T15:35',
                        },
                        {
                          id: '228',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '674',
                          durationInMin: 100,
                          departure: {
                            code: 'VNS',
                            airport: 'Lal Bahadur Shastri Arpt',
                            city: 'Varanasi',
                            country: 'India',
                          },
                          departureTime: 1666691400000,
                          departureDate: '2022-10-25T15:20',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666697400000,
                          arrrivalDate: '2022-10-25T17:00',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '621',
                        equipment: '320',
                      },
                    },
                    {
                      remaining: 3,
                      details: {
                        arrival: 1666684200000,
                        departure: 1666625400000,
                        numberOfStops: 1,
                        duration: 980,
                      },
                      key: 'UK|827|1666625400000|1666684200000@634e7b4f46e0fb0007c3a0c2',
                      fares: [
                        {
                          override: false,
                          key: '21-10-15-2-2158784108_86BOMMAAUK827MAADELUK836_18185014751031@63039e1f46e0fb0007c7dbe9',
                          appId: '63039e1f46e0fb0007c7dbe9',
                          type: 'LOADED',
                          message:
                            'All In prices, Refundable - Upto 100%, Free - Seat & Meals',
                          typeKey: 'CORPORATE',
                          adult: {
                            baggage: {
                              checkIn: '15 Kilograms',
                              cabin: '7 Kg (01 small handbag under the seat)',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: true,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 3,
                            offeredFare: 13672.44,
                            publishedFare: 13924,
                            comission: 251.56,
                            bookingClass: 'Q',
                            baseFare: 12641,
                            taxes: {
                              total: 1283,
                              breakups: {
                                'Carrier Misc': 260,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 0,
                                'Other Charges': 378,
                                'Management Fees': 0,
                                'Gst Charges': 645,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          id: '658',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 0,
                          flightNumber: '827',
                          durationInMin: 125,
                          departure: {
                            code: 'BOM',
                            airport:
                              'Chhatrapati Shivaji Maharaj International Airport',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          departureTime: 1666625400000,
                          departureDate: '2022-10-24T21:00',
                          arrival: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          arrivalTime: 1666632900000,
                          arrrivalDate: '2022-10-24T23:05',
                        },
                        {
                          id: '659',
                          logo: 'https://static.eka.io/airline/UK.png',
                          flightCode: 'UK',
                          airline: 'Vistara',
                          rank: 1,
                          flightNumber: '836',
                          durationInMin: 165,
                          departure: {
                            code: 'MAA',
                            airport: 'Chennai Arpt',
                            city: 'Chennai',
                            country: 'India',
                          },
                          departureTime: 1666674300000,
                          departureDate: '2022-10-25T10:35',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          arrivalTime: 1666684200000,
                          arrrivalDate: '2022-10-25T13:20',
                        },
                      ],
                      operator: {
                        name: 'Vistara',
                        code: 'UK',
                        lcc: false,
                        logo: 'https://static.eka.io/airline/UK.png',
                        number: '827',
                        equipment: '73H',
                      },
                    },
                  ],
                },
                null,
                2
              )}
            </code>
          </CodeGroup>
        </Col>
      </Row>
    </>
  );
}
