import { Col, Properties, Property, Row } from '../../Common/Property';
import { CodeGroup } from '../../Common/Code';
import Eyebrow from '../../Common/Eyebrow';

const ROUTE = '/hotel/booking';

export default function Docs() {
  return (
    <>
      <Eyebrow label={ROUTE} tag='GET' />
      <h1>Order Details</h1>
      <p>
       This api is used to fetch current booking details
      </p>
      <h2>Request Model</h2>
      <p className='lead'>
        Details of all the parameters that can be passed in the request.
      </p>
      <Row>
        <Col>
          <Properties>
            <Property name='bookingId' type='number'>
              Value can be extracted from Refund Details api response.
            </Property>
          </Properties>
        </Col>
        <Col>
          <CodeGroup tag='GET' label={ROUTE} title='Request Payload'>
            <code title='URL'>
                /hotel/booking?bookingId=223072400001
            </code>
          </CodeGroup>
          <CodeGroup title='Response Pay Load'>
            <code title='json'>{JSON.stringify({status:'SUCCESS',transId:"AX0001",amount:1202,ticket:{"name":"Bloom Hub - ORR Marathahalli","address":"Survey No 1725  1805 Outer Ring Road Marathahalli Bangalore 560037","city":"Bangalore","date":{"in":"06-08-2023","out":"07-08-2023"},"night":1,"criteria":{"appConfig":{},"city":"Bangalore","country":"INDIA","rooms":[],"dates":{"in":"06-08-2023","out":"07-08-2023"},"international":false},"travellers":[{"nationality":"IN","mealMaps":{},"baggageMaps":{},"seatMaps":{},"type":"ADULT","sequences":[],"primary":false,"id":"64be7f3184718c00070c6cc9","addOnCost":0,"refundAmount":0,"refundId":"-","meals":{},"seats":{},"pnrs":{},"tickets":{},"empty":true,"name":"Chandan Maiti","fName":"Chandan","lName":"Maiti","communicationEmail":"chandan.maiti@flipkart.com","email":"chandan.maiti@flipkart.com","mobile":"9477305035","gender":"MALE","dob":0,"age":25}],"roomName":"Deluxe","roomDescription":"Modified American Plan","gstSlab":12,"pricePerNight":5697,"cancellationPolicy":"24 hrs prior to check-in date","amount":6380.64,"bookingId":"2446471","pnr":"2446471"}}, null, 2)}</code>
          </CodeGroup>
        </Col>
      </Row>
    </>
  );
}
