export default {
	title: 'Expenses',
	href: '/docs/apis/expenses',
	sections:[
		{
			title:"All expenses",
			link:"/docs/apis/expenses"
		},
		{
			title:"All Bookings",
			link:"/docs/apis/expenses/bookings"
		},
	]
}
