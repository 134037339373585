import {Note} from "./Common/Note";
import {CodeGroup} from "./Common/Code";
import {Link} from "react-router-dom";
import {Heading} from "./Common/Heading";
import React from "react";

export default function Quickstart(){
	return(
		<>
			<h1 id="apiKey">
				Quickstart
			</h1>
			<p className="lead">
				This guide will get you all set up and ready to use the Dice API. We'll cover how to get started using one of our API clients and how to make your first API request. We'll also look at where to go next to find all the information you need to take full advantage of our powerful REST API.
			</p>
			<div >
				<Note>
					Before you can make requests to the Dice API, you will need to grab your
					API key from your dashboard. You find it under <span style={{color:"var(--tw-prose-links)",fontWeight:600}}>Control Room  » Dice Studio » API</span>.
				</Note>
			</div>
			<Heading level={2} id="choose-clientt">
				Choose Your Client
			</Heading>
			<p className="lead">
				Before making your first API request, you need to pick which API client you will use. In addition to good ol' cURL HTTP requests, Dice offers code for JavaScript, Java, and PHP. In the following example, you can see how to do api request from each client.
			</p>
			<CodeGroup tag="GET" label="/api/ai/search">
				<code title="Bash">
					# cURL is most likely already installed on your machine <br/>
					curl --location 'https://provider.dice.tech/api/ai/search?q=Flights from delhi to mumbai next week' \<br/>
					--header 'Origin:  {`{{origin}}`}' \<br/>
					--header 'X-Api-Key: {`{{apiKey}}`}' \<br/>
					--header 'Authorization: Bearer {`{{token}}`} \<br/>
				</code>
				<code title="Js">
					var myHeaders = new Headers();<br/>
					myHeaders.append("Origin", `{`{origin}`}`);<br/>
					myHeaders.append("X-Api-Key", `{`{apiKey}`}`);<br/>
					myHeaders.append("Authorization", `Bearer {`{token}`}`);<br/>
					var raw = "";<br/>
					var requestOptions = {`{
						\tmethod: 'GET',
						\theaders: myHeaders,
						\tbody: raw,
						\tredirect: 'follow'
					}`};<br/>
					fetch("https://provider.dice.tech/api/ai/search?q=Flights from delhi to mumbai next week", requestOptions)
					.then(response => response.text())<br/>
					.then(result => console.log(result))<br/>
					.catch(error => console.log('error', error));<br/>
				</code>
				<code title="Java">
					Unirest.setTimeouts(0, 0);<br/>
					HttpResponse response = Unirest.get("https://provider.dice.tech/api/ai/search?q=Flights%20from%20delhi%20to%20mumbai%20next%20week")<br/>
					&nbsp;&nbsp;&nbsp;	.header("Origin", "{`{origin}`}")<br/>
					&nbsp;&nbsp;&nbsp;	.header("X-Api-Key", "{`{apiKey}`}")<br/>
					&nbsp;&nbsp;&nbsp;	.header("Authorization", "Bearer {`{token}`}")<br/>
					&nbsp;&nbsp;&nbsp;	.body("")<br/>
					&nbsp;&nbsp;&nbsp;	.asString();<br/>
				</code>
				<code title="Php">
					$client = new Client();<br/>
					$headers = [<br/>
					&nbsp;&nbsp;&nbsp;'Origin' => '{`{origin}`}',<br/>
					&nbsp;&nbsp;&nbsp;'X-Api-Key' => '{`{apiKey}`}',<br/>
					&nbsp;&nbsp;&nbsp;'Authorization' => 'Bearer {`{token}`}'<br/>
					];<br/>
					$body = '';<br/>
					$request = new Request('GET', 'https://provider.dice.tech/api/ai/search?q=Flights from delhi to mumbai next week', $headers, $body);<br/>
					$res = $client->sendAsync($request)->wait();<br/>
					echo $res->getBody();<br/>
				</code>
			</CodeGroup>
			<Heading level={2} id="first-api-request">
				Making your first API request
			</Heading>
			<p className="lead">
				After picking your preferred client, you are ready to make your first call to the Protocol API. Below, you can see how to send a GET request to the Conversations endpoint to get a list of all your conversations. In the cURL example, results are limited to ten conversations, the default page length for each client.
			</p>
			<CodeGroup tag="GET" label="/api/ai/search">
				<code title="Bash">
					# cURL is most likely already installed on your machine <br/>
					curl --location 'https://provider.dice.tech/api/ai/search?q=Flights from delhi to mumbai next week' \<br/>
					--header 'Origin:  {`{{origin}}`}' \<br/>
					--header 'X-Api-Key: {`{{apiKey}}`}' \<br/>
					--header 'Authorization: Bearer {`{{token}}`} \<br/>
				</code>
				<code title="Js">
					var myHeaders = new Headers();<br/>
					myHeaders.append("Origin", `{`{origin}`}`);<br/>
					myHeaders.append("X-Api-Key", `{`{apiKey}`}`);<br/>
					myHeaders.append("Authorization", `Bearer {`{token}`}`);<br/>
					var raw = "";<br/>
					var requestOptions = {`{
						\tmethod: 'GET',
						\theaders: myHeaders,
						\tbody: raw,
						\tredirect: 'follow'
					}`};<br/>
					fetch("https://provider.dice.tech/api/ai/search?q=Flights from delhi to mumbai next week", requestOptions)
					.then(response => response.text())<br/>
					.then(result => console.log(result))<br/>
					.catch(error => console.log('error', error));<br/>
				</code>
				<code title="Java">
					Unirest.setTimeouts(0, 0);<br/>
					HttpResponse response = Unirest.get("https://provider.dice.tech/api/ai/search?q=Flights%20from%20delhi%20to%20mumbai%20next%20week")<br/>
					&nbsp;&nbsp;&nbsp;	.header("Origin", "{`{origin}`}")<br/>
					&nbsp;&nbsp;&nbsp;	.header("X-Api-Key", "{`{apiKey}`}")<br/>
					&nbsp;&nbsp;&nbsp;	.header("Authorization", "Bearer {`{token}`}")<br/>
					&nbsp;&nbsp;&nbsp;	.body("")<br/>
					&nbsp;&nbsp;&nbsp;	.asString();<br/>
				</code>
				<code title="Php">
					$client = new Client();<br/>
					$headers = [<br/>
					&nbsp;&nbsp;&nbsp;'Origin' => '{`{origin}`}',<br/>
					&nbsp;&nbsp;&nbsp;'X-Api-Key' => '{`{apiKey}`}',<br/>
					&nbsp;&nbsp;&nbsp;'Authorization' => 'Bearer {`{token}`}'<br/>
					];<br/>
					$body = '';<br/>
					$request = new Request('GET', 'https://provider.dice.tech/api/ai/search?q=Flights from delhi to mumbai next week', $headers, $body);<br/>
					$res = $client->sendAsync($request)->wait();<br/>
					echo $res->getBody();<br/>
				</code>
			</CodeGroup>
			<h2>
				What's next?
			</h2>
			<p className="lead">
				Great, you're now set up with an API client and have made your first request to the API. Here are a few links that might be handy as you venture further into the Dice API:
			</p>
			<ul>
				<li>
					<Link to="#apiKey" onClick={()=>{
						document.getElementById("apiKey").scrollIntoView()
					}}>
						Grab your API key from the Protocol dashboard
					</Link>
				</li>
				<li>
					<Link to="/docs/errors">
						Learn about the different error messages in Protocol
					</Link>
				</li>
			</ul>
		</>
	)
}
