import React from 'react'
import {Note} from "./Common/Note";
import {Heading} from "./Common/Heading";
import {CodeGroup} from "./Common/Code";

export default function Authentication() {
	return (
		<div>
			<h1>
				Authentication
			</h1>
			<p className="lead">
				You'll need to authenticate your requests to access any of the endpoints in the Dice API. In this guide, we'll look at how authentication works. Dice offers industry best securest way to authenticate your API. OAuth2 with a token and Api Key.
			</p>
			<Note>
				Please don't commit your Dice credentials to GitHub!
			</Note>
			<Heading level={2} id="oauth">
				OAuth2 with bearer token
			</Heading>
			<p className="lead">
				The recommended way to authenticate with the Dice API is by using OAuth2. When establishing a connection using OAuth2, you will need your access token — you will find it in the   <span style={{color:"var(--tw-prose-links)",fontWeight:600}}>Control Room  » Dice Studio » API</span> under API settings. Here's how to add the token to the request header using cURL:
			</p>
			<CodeGroup title="Example request with bearer token">
				<code title="Example request with bearer token">
					curl https://provider.dice.tech \ <br/>
					-H "Authorization: Bearer {`{token}`}" \ <br/>
					-H "Origin: {`{origin}`}" \ <br/>
					-H "X-Api-Key: {`{apiKey}`}" <br/>
				</code>
			</CodeGroup>
			<p className="lead">
				Always keep your token safe and reset it if you suspect it has been compromised.
			</p>
		</div>
	)
}
