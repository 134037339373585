export default {
  title: 'Domestic Round Trip',
  href: '/docs/flights/round',
  sections: [
    {
      title: 'Round Search',
      link: '/docs/flights/round/search',
      tag: 'POST',
    },
    {
      title: 'Review Flight',
      link: '/docs/flights/round/review-flight',
      tag: 'POST',
    },
    {
      title: 'Fare Rules',
      link: '/docs/flights/round/fare-rules',
      tag: 'POST',
    },
    {
      title: 'Seat Map',
      link: '/docs/flights/round/seat-map',
      tag: 'POST',
    },
    {
      title: 'Hold Flight',
      link: '/docs/flights/round/hold-flight',
      tag: 'POST',
    },
    {
      title: 'Booking Session',
      link: '/docs/flights/round/booking-session',
      tag: 'GET',
    },
    {
      title: 'Sync Book',
      link: '/docs/flights/round/book-flight',
      tag: 'POST',
    },
    {
      title: 'Async Book',
      link: '/docs/flights/round/async-book',
      tag: 'POST',
    },
    {
      title: 'RetrieveBooking',
      link: '/docs/flights/round/retrieve-booking',
      tag: 'GET',
    },
  ],
};
