import { Col, Properties, Property, Row } from '../../../Common/Property';
import { CodeGroup } from '../../../Common/Code';
import Eyebrow from '../../../Common/Eyebrow';

const ROUTE = '/flight/sandbox/domestic/round/book';

export default function Docs() {
  return (
    <>
      <Eyebrow label={ROUTE} tag='POST' />
      <h1>Sync Book</h1>
      <p>
        As the name suggests, employees are a core part of Dice — the very
        reason Dice exists is so you can provide secure spends management to
        your employees. On this page, we'll dive into how to create employees
        programmatically.
      </p>
      <h2>Request Model</h2>
      <p className='lead'>
        Details of all the parameters that can be passed in the request.
      </p>
      <Row>
        <Col>
          <Properties>
            <Property name='holdId' type='string'>
              Value can be extracted from Sync Book api response.
            </Property>
            <Property name='amount' type='double'>
              Value can be extracted from Sync Book api response.
            </Property>
            <Property name='referenceId' type='string'>
              Value can be extracted from Sync Book api response.
            </Property>
            <Property name='accountId' type='string'>
              Deposit Account Id Value can be extracted from Sync Book api
              response.
            </Property>
          </Properties>
        </Col>
        <Col>
          <CodeGroup tag='POST' label={ROUTE} title='Request Payload'>
            <code title='cURL'>
              curl --request POST \ <br />
              --url {ROUTE} \ <br />
              --header 'Origin: Dice' \ <br />
              --header 'accept: application/json' \ <br />
              --header 'content-type: application/json' \ <br />
              --data '
              {JSON.stringify({
                referenceId: '(Optional)',
              })}
              '
            </code>
          </CodeGroup>

          <CodeGroup title='Response Pay Load'>
            <code title='json'>
              {JSON.stringify(
                {
                  bookingId: '632d9d674cedfd00082f5232',
                  status: 'SUCCESS',
                  ticket: {
                    amount: 8456,
                    flowId: 'TJ102243567803',
                    bookingId: 'BK-PERSONAL-000000028',
                    travellers: [
                      {
                        type: 'ADULT',
                        primary: false,
                        id: '632d9d6e46e0fb0008bd187f',
                        addOnCost: 0,
                        meals: {},
                        seats: {},
                        pnrs: {
                          'DEL-PNQ': 'K543WI',
                        },
                        tickets: {},
                        empty: true,
                        name: 'Rameshwar Das Jain',
                        fName: 'Rameshwar',
                        lName: 'Das Jain',
                        gender: 'MALE',
                      },
                      {
                        type: 'ADULT',
                        primary: false,
                        id: '632d9d6e46e0fb0008bd1880',
                        addOnCost: 0,
                        meals: {},
                        seats: {},
                        pnrs: {
                          'DEL-PNQ': 'K543WI',
                        },
                        tickets: {},
                        empty: true,
                        name: 'Rajesh Jain',
                        fName: 'Rajesh',
                        lName: 'Jain',
                        gender: 'MALE',
                      },
                    ],
                    segments: [
                      {
                        id: '175',
                        meals: [
                          {
                            code: 'CRPX',
                            name: 'Free Corporate Meal',
                            amount: 0,
                          },
                        ],
                        logo: 'https://static.eka.io/airline/G8.png',
                        flightCode: 'G8',
                        airline: 'Go First',
                        rank: 0,
                        flightNumber: '1171',
                        durationInMin: 120,
                        departure: {
                          code: 'DEL',
                          airport: 'Delhi Indira Gandhi Intl',
                          city: 'Delhi',
                          country: 'India',
                        },
                        departureTime: 1664374200000,
                        departureDate: '2022-09-28T19:40',
                        arrival: {
                          code: 'PNQ',
                          airport: 'Lohegaon Arpt',
                          city: 'Pune',
                          country: 'India',
                        },
                        arrivalTime: 1664381400000,
                        arrrivalDate: '2022-09-28T21:40',
                      },
                    ],
                  },
                },
                null,
                2
              )}
            </code>
          </CodeGroup>
        </Col>
      </Row>
    </>
  );
}
