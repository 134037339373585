export default {
	title: 'Settlements', href: '/docs/apis/settlements/voucher',
	sections:[
		// {
		// 	title:"Voucher",
		// 	link:"/docs/apis/settlements/voucher"
		// },
		{
			title:"Vouchers",
			link:"/docs/apis/settlements/vouchers"
		}
	]
}
