import { Col, Properties, Property, Row } from '../../../Common/Property';
import { CodeGroup } from '../../../Common/Code';
import Eyebrow from '../../../Common/Eyebrow';
import { Note } from '../../../Common/Note';

export default function Docs() {
  return (
    <>
      <Eyebrow label='/flight/sandbox/domestic/search' tag='POST' />
      <h1>Search Flights</h1>
      <p>
        As the name suggests, employees are a core part of Dice — the very
        reason Dice exists is so you can provide secure spends management to
        your employees. On this page, we'll dive into how to create employees
        programmatically.
      </p>
      <h2>Request Model</h2>
      <p className='lead'>
        Details of all the parameters that can be passed in the request.
      </p>
      <Row>
        <Col>
          <Properties>
            <Property name='origin' type='string'>
              Value can be extracted from Search api response.
            </Property>
            <Property name='destination' type='string'>
              Value can be extracted from Search api response.
            </Property>
            <Property name='date' type='string'>
              Value can be extracted from Search api response.
            </Property>
            <Property name='counts' type='object'>
              Value can be extracted from Search api response.
            </Property>
            <Note>Search results are valid for only 15 mins</Note>
          </Properties>
        </Col>
        <Col>
          <CodeGroup
            tag='POST'
            label='/flight/sandbox/domestic/search'
            title='Request Payload'
          >
            <code title='cURL'>
              curl --request POST \ <br />
              --url /flight/sandbox/domestic/search \ <br />
              --header 'Origin: Dice' \ <br />
              --header 'accept: application/json' \ <br />
              --header 'content-type: application/json' \ <br />
              --data ' <br />
              {JSON.stringify({
                origin: 'DEL',
                destination: 'BOM',
                date: 'dd-MM-yyyy',
              })}
              '
            </code>
          </CodeGroup>

          <CodeGroup title='Response Pay Load'>
            <code title='json'>
              {JSON.stringify(
                {
                  id: '631787344cedfd0007bbdc10',
                  success: true,
                  count: {
                    adult: 1,
                    child: 0,
                    infant: 0,
                  },
                  origin: {
                    code: 'DEL',
                    id: 1,
                    airport: 'Indira Gandhi International Airport',
                    city: 'Delhi',
                    country: 'INDIA',
                  },
                  destination: {
                    code: 'BOM',
                    id: 2,
                    airport: 'Chhatrapati Shivaji International Airport',
                    city: 'Mumbai',
                    country: 'INDIA',
                  },
                  results: [
                    {
                      remaining: 9,
                      details: {
                        arrival: 1665553500000,
                        departure: 1665545400000,
                        numberOfStops: 0,
                        duration: 135,
                      },
                      key: 'AI|678|1665545400000|1665553500000',
                      fares: [
                        {
                          override: false,
                          appId: '63039e1f46e0fb0007c7dbe9',
                          key: '15-2-10-8966528749_104DELBOMAI678_279017526829771',
                          type: 'REGULAR',
                          typeKey: 'PUBLISHED',
                          adult: {
                            baggage: {
                              checkIn: '25 Kg (01 Piece only)',
                              cabin: '7 Kg',
                            },
                            cancellationCharges: {
                              type: 'REFUNDABLE',
                            },
                            ancillery: {
                              meal: false,
                              seat: false,
                              dateChange: false,
                            },
                            remainingSeats: 9,
                            offeredFare: 6225.3,
                            publishedFare: 6514,
                            comission: 288.7,
                            bookingClass: 'U',
                            baseFare: 5130,
                            taxes: {
                              total: 1384,
                              breakups: {
                                'Carrier Misc': 170,
                                'Fuel Surcharge': 0,
                                'Management Fee Tax': 9,
                                'Other Charges': 890,
                                'Management Fees': 50,
                                'Gst Charges': 265,
                              },
                            },
                          },
                        },
                      ],
                      segments: [
                        {
                          flightCode: 'AI',
                          airline: 'Air India',
                          rank: 0,
                          flightNumber: '678',
                          durationInMin: 135,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                          },
                          departureTime: 1665545400000,
                          departureDate: '2022-10-12T09:00',
                          arrival: {
                            code: 'BOM',
                            airport: 'Chhatrapati Shivaji',
                            city: 'Mumbai',
                            country: 'India',
                          },
                          arrivalTime: 1665553500000,
                          arrrivalDate: '2022-10-12T11:15',
                        },
                      ],
                      operator: {
                        name: 'Air India',
                        code: 'AI',
                        lcc: false,
                        number: '678',
                        equipment: '32N',
                      },
                    },
                  ],
                },
                null,
                2
              )}
            </code>
          </CodeGroup>
        </Col>
      </Row>
    </>
  );
}
