import { Col, Properties, Property, Row } from '../../../Common/Property';
import { CodeGroup } from '../../../Common/Code';
import Eyebrow from '../../../Common/Eyebrow';
import { Note } from '../../../Common/Note';

const ROUTE = '/flight/sandbox/domestic/round/review';

export default function Docs() {
  return (
    <>
      <Eyebrow label={ROUTE} tag='POST' />
      <h1>Review Flight</h1>
      <p>
        As the name suggests, employees are a core part of Dice — the very
        reason Dice exists is so you can provide secure spends management to
        your employees. On this page, we'll dive into how to create employees
        programmatically.
      </p>
      <h2>Request Model</h2>
      <p className='lead'>
        Details of all the parameters that can be passed in the request.
      </p>
      <Row>
        <Col>
          <Properties>
            <Property name='onwardsFareId' type='string'>
              Value can be extracted from Review Flight api response.
            </Property>
            <Property name='inventoryId' type='string'>
              Value can be extracted from Review Flight api response.
            </Property>
            <Property name='returnFareId' type='string'>
              Value can be extracted from Review Flight api response.
            </Property>
            <Note>
              <code>onwardsFareId</code>, <code>inventoryId</code>, &{' '}
              <code>returnFareId</code> are valid for only 15 mins
            </Note>
          </Properties>
        </Col>
        <Col>
          <CodeGroup tag='POST' label={ROUTE} title='Request Payload'>
            <code title='cURL'>
              curl --request POST \ <br />
              --url {ROUTE} \ <br />
              --header 'Origin: Dice' \ <br />
              --header 'accept: application/json' \ <br />
              --header 'content-type: application/json' <br />
            </code>
          </CodeGroup>

          <CodeGroup title='Response Pay Load'>
            <code title='json'>
              {JSON.stringify(
                {
                  flowId: 'TJ104354037694@TJ_FLIGHT',
                  search: {
                    origin: {
                      code: 'DEL',
                      id: 1,
                      airport: 'Indira Gandhi International Airport',
                      city: 'Delhi',
                      country: 'INDIA',
                      terminal: '-',
                    },
                    destination: {
                      code: 'BLR',
                      id: 9,
                      airport: 'Kempegowda International Airport, Bengaluru',
                      city: 'Bangalore',
                      country: 'INDIA',
                      terminal: '-',
                    },
                    count: {
                      adult: 1,
                      child: 0,
                      infant: 0,
                    },
                    date: '16-01-2023',
                    returnDate: '19-01-2023',
                    cabinClass: 'ECONOMY',
                  },
                  success: true,
                  review: {
                    flowId: 'TJ104354037694',
                    code: 200,
                    alerts: [],
                    fare: {
                      offeredFare: 13341.89,
                      publishedFare: 14083,
                      baseFare: 11657,
                      taxes: {
                        total: 2426,
                        breakups: {
                          'Carrier Misc': 0,
                          'Fuel Surcharge': 0,
                          'Management Fee Tax': 0,
                          'Other Charges': 1804,
                          'Management Fees': 0,
                          'Gst Charges': 622,
                        },
                      },
                    },
                    ONWARDS: {
                      segments: [
                        {
                          id: '647',
                          meals: [],
                          logo: 'https://static.eka.io/airline/SG.png',
                          flightCode: 'SG',
                          airline: 'SpiceJet',
                          rank: 0,
                          flightNumber: '143',
                          durationInMin: 155,
                          departure: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                            terminal: 'Terminal 1D',
                          },
                          departureTime: 1673881200000,
                          departureDate: '2023-01-16T20:30',
                          arrival: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                            terminal: 'Terminal 1',
                          },
                          arrivalTime: 1673890500000,
                          arrrivalDate: '2023-01-16T23:05',
                        },
                      ],
                      baggages: [],
                      fares: {
                        fareTypeKey: 'CORPORATE',
                        fareType: 'LOADED',
                        adult: {
                          baggage: {
                            checkIn: '15 Kg',
                            cabin: '7 Kg',
                          },
                          cancellationCharges: {
                            type: 'REFUNDABLE',
                          },
                          ancillery: {
                            meal: false,
                            seat: false,
                            dateChange: false,
                          },
                          remainingSeats: 1,
                          offeredFare: 7076.72,
                          publishedFare: 7459,
                          comission: 382.28,
                          bookingClass: 'CP',
                          fareBasis: 'CCRP',
                          cabinClass: 'ECONOMY',
                          baseFare: 6337,
                          taxes: {
                            total: 1122,
                            breakups: {
                              'Carrier Misc': 0,
                              'Fuel Surcharge': 0,
                              'Management Fee Tax': 0,
                              'Other Charges': 786,
                              'Management Fees': 0,
                              'Gst Charges': 336,
                            },
                          },
                        },
                      },
                    },
                    RETURN: {
                      segments: [
                        {
                          id: '3',
                          meals: [
                            {
                              code: 'CRPX',
                              name: 'Free Corporate Meal',
                            },
                          ],
                          logo: 'https://static.eka.io/airline/G8.png',
                          flightCode: 'G8',
                          airline: 'Go First',
                          rank: 0,
                          flightNumber: '116',
                          durationInMin: 135,
                          departure: {
                            code: 'BLR',
                            airport: 'Bengaluru Intl Arpt',
                            city: 'Bengaluru',
                            country: 'India',
                            terminal: 'Terminal 1',
                          },
                          departureTime: 1674091800000,
                          departureDate: '2023-01-19T07:00',
                          arrival: {
                            code: 'DEL',
                            airport: 'Delhi Indira Gandhi Intl',
                            city: 'Delhi',
                            country: 'India',
                            terminal: 'Terminal 2',
                          },
                          arrivalTime: 1674099900000,
                          arrrivalDate: '2023-01-19T09:15',
                        },
                      ],
                      baggages: [],
                      fares: {
                        fareTypeKey: 'CORPORATE',
                        fareType: 'LOADED',
                        adult: {
                          baggage: {
                            checkIn: '15 Kg',
                            cabin: '7 Kg',
                          },
                          cancellationCharges: {
                            type: 'REFUNDABLE',
                          },
                          ancillery: {
                            meal: false,
                            seat: false,
                            dateChange: false,
                          },
                          remainingSeats: 2,
                          offeredFare: 6265.17,
                          publishedFare: 6624,
                          comission: 358.83,
                          bookingClass: 'BC',
                          fareBasis: 'KO9CBINE',
                          cabinClass: 'ECONOMY',
                          baseFare: 5320,
                          taxes: {
                            total: 1304,
                            breakups: {
                              'Carrier Misc': 0,
                              'Fuel Surcharge': 0,
                              'Management Fee Tax': 0,
                              'Other Charges': 1018,
                              'Management Fees': 0,
                              'Gst Charges': 286,
                            },
                          },
                        },
                      },
                    },
                    condition: {
                      passportMandatory: false,
                      dateOfBirth: false,
                      infantDob: true,
                      gstRequired: false,
                      gstApplicable: true,
                    },
                  },
                },
                null,
                2
              )}
            </code>
          </CodeGroup>
        </Col>
      </Row>
    </>
  );
}
