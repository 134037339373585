import React from 'react'
import {Link, Outlet} from "react-router-dom";
import { motion,  } from 'framer-motion'
import Logo from "./logo.png";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

export default function Layout() {

    return (
        <div className="lg:ml-72 xl:ml-80">
            <motion.header
                layoutScroll
                className="contents lg:pointer-events-none lg:fixed lg:inset-0 lg:z-40 lg:flex"
            >
                <div className="contents lg:pointer-events-auto lg:block lg:w-72 lg:overflow-y-auto lg:border-r lg:border-zinc-900/10 lg:px-6 lg:pb-8 lg:pt-4 lg:dark:border-white/10 xl:w-80">
                    <div className="hidden lg:flex">
                        <Link to="/" aria-label="Home">
                            <img alt="logo" src={Logo} style={{height: 30}}/>
                        </Link>
                    </div>
                    <Header/>
                    <Sidebar className="hidden lg:mt-10 lg:block"/>
                </div>
            </motion.header>
            <div className="relative px-4 pt-14 sm:px-6 lg:px-8">
                <main className="py-16">
                    <article className="prose dark:prose-invert">
                        <Outlet/>
                    </article>
                </main>
            </div>
            <Footer/>
        </div>
    )
}
