import React from 'react';
import {Heading} from '../../Common/Heading';
import Eyebrow from '../../Common/Eyebrow';
import {Col, Properties, Property, Row} from '../../Common/Property';
import {CodeGroup} from '../../Common/Code';

export default function Vouchers() {
	return (
		<>
			<h1>Voucher Settlements</h1>
			<p className='lead mb-10'>
				As the name suggests, this page is related to manage voucher submitted
				by employees. You can approve/decline those vouchers.
			</p>
			<Heading level={2}>Fetch Vouchers Settlements</Heading>
			<div className='lead mb-8'>
				This api is for fetching all vouchers between a certain timeframe.
			</div>
			<Eyebrow
				label='https://provider.dice.tech/api/v1/settlements/vouchers'
				tag='POST'
			/>
			<div className='mb-6'/>
			<Row>
				<Col>
					<Properties>
						<Property name='startDate' type='timestamp'>
							Start of timeframe to fetch data.
						</Property>
						<Property name='endDate' type='timestamp'>
							End of timeframe to fetch data.
						</Property>
						<Property name='filter.employeeCode' type='string'>
							This field is optional.
						</Property>
					</Properties>
				</Col>
				<Col>
					<CodeGroup
						tag='POST'
						label='/api/v1/settlements/vouchers'
						title='Request Payload'
					>
						<code title='json'>
							{JSON.stringify(
								{
									startDate: 1682983046000,
									endDate: 1685661446000,
									filter: {
										employeeCode: "90434"
									}
								},
								null,
								2
							)}
						</code>
					</CodeGroup>
					<CodeGroup title='Response Payload'>
						<code title='json'>
							{JSON.stringify(
								{
									success: true,
									ledgers: [
										{
											voucher: {
												voucherId: 'V-CLTP-000000019',
												createdAt: 1683019767863,
												currency: 'INR',
												amount: 1234.0,
												claimed: 1234.0,
												status: 'REIMBURSED',
												transactions: [
													{
														transId: 'O-CLTP-000000171',
														service: 'Food',
														status: {
															id: 3,
															value: 'Approved',
														},
														type: 'SELFPAID',
														createdAt: 1683019715736,
														updatedAt: 1683831306480,
														amount: {
															currency: 'INR',
															amount: 1234.0,
														},
														reimbursable: {
															currency: 'INR',
															amount: 1234.0,
														},
														filled: {
															currency: 'INR',
															amount: 1234.0,
														},
														invoiceId: '',
														fields: {},
														documents: [],
													},
												],
											},
											ledgerId: 'LG003292305120001',
											currency: 'INR',
											amount: 1234.0,
											settled: false,
											createdAt: 1683831306438,
											transferred: true,
											employee: {
												id: 240375,
												ownerType: 'EMPLOYEE',
												ownerId: '90434',
												ownerName: 'Aasang Dixit',
												fields: {
													manager: '90434',
													alternate: '167462',
													team: 'CT LEADS',
													department: 'CT LEADS',
													doj: '1681009914000',
												},
											},
										},
									],
								},
								null,
								2
							)}
						</code>
					</CodeGroup>
				</Col>
			</Row>
			<Heading level={2}>Voucher Employees</Heading>
            <p className='lead mb-10'>
                As the name suggests, this page is related to fetch all employees for whom
                voucher is pending for settlements
            </p>
            <Eyebrow
                label='https://provider.dice.tech/api/v1/settlements/employees'
                tag='POST'
            />
            <Row>
                <Col>
                    <Properties>
                        <Property name='startDate' type='timestamp'>
                            Start of timeframe to fetch data.
                        </Property>
                        <Property name='endDate' type='timestamp'>
                            End of timeframe to fetch data.
                        </Property>
                    </Properties>
                </Col>
                <Col>
                    <CodeGroup
                        tag='POST'
                        label='/api/v1/settlements/vouchers'
                        title='Request Payload'
                    >
                        <code title='json'>
                            {JSON.stringify(
                                {
                                    startDate: 1682983046000,
                                    endDate: 1685661446000
                                },
                                null,
                                2
                            )}
                        </code>
                    </CodeGroup>
                    <CodeGroup title='Response Payload'>
                        <code title='json'>
                            {JSON.stringify(
                                {
                                    success: true,
                                    employees: [
                                        {
                                            id: 240375,
                                            ownerType: 'EMPLOYEE',
                                            ownerId: '90434',
                                            ownerName: 'Aasang Dixit',
                                            fields: {
                                                manager: '90434',
                                                alternate: '167462',
                                                team: 'CT LEADS',
                                                department: 'CT LEADS',
                                                doj: '1681009914000',
                                            }
                                        },
                                    ],
                                },
                                null,
                                2
                            )}
                        </code>
                    </CodeGroup>
                </Col>
            </Row>
			<Heading level={2}>Settle Vouchers</Heading>
			<div className='lead mb-8'>
				This api is for fetching all vouchers between a certain timeframe.
			</div>
			<Eyebrow
				label='https://provider.dice.tech/api/v1/settlements/vouchers/actions'
				tag='POST'
			/>
			<div className='mb-6'/>
			<Row>
				<Col>
					<Properties>
						<Property name='items' type='object'>
							All items you want to settle items here.
						</Property>
					</Properties>
				</Col>
				<Col>
					<CodeGroup
						tag='POST'
						label='/api/v1/settlements/vouchers/action'
						title='Request Payload'
					>
						<code title='json'>
							{JSON.stringify(
								{
									items: [
										{
											ledgerId: 'LG000001',
											type: 'APPROVE',
											attrs: {
												utr: '0000001',
											},
										},
										{
											ledgerId: 'LG000002',
											type: 'RECALL',
											remarks: '',
										},
									],
								},
								null,
								2
							)}
						</code>
					</CodeGroup>
					<CodeGroup title='Response Payload'>
						<code title='json'>
							{JSON.stringify(
								{
									success: true,
									errors: {
										LG000002: 'You need to pass remarks to recall voucher',
									},
								},
								null,
								2
							)}
						</code>
					</CodeGroup>
				</Col>
			</Row>
		</>
	);
}
