import { Col, Properties, Property, Row } from '../../Common/Property';
import { CodeGroup } from '../../Common/Code';
import Eyebrow from '../../Common/Eyebrow';

const ROUTE = '/hotel/details';

export default function Docs() {
  return (
    <>
      <Eyebrow label={ROUTE} tag='POST' />
      <h1>Hotel Details</h1>
      <p>
        This endpoint will provide static dump of a particular hotel.
      </p>
      <h2>Request Model</h2>
      <p className='lead'>
        Details of all the parameters that can be passed in the request.
      </p>
      <Row>
        <Col>
          <Properties>
            <Property name='hotelId' type='string'>
              Value can be extracted from Hotel Search api response.<br/>
                root.hotels[:].id
            </Property>
              <Property name='sessionId' type='string'>
                  Value can be extracted from Hotel Search api response. <br/>
                  root.sessionId
              </Property>
              <Property name='appId' type='string'>
                  Value can be extracted from Hotel Search api response. <br/>
                  root.hotels[:].providerKey
              </Property>
          </Properties>
        </Col>
        <Col>
          <CodeGroup tag='POST' label={ROUTE} title='Request Payload'>
            <code title='json'>
                {
                    JSON.stringify({ sessionId: '63d1a5d5c9e77c000797e6ed',appId:"Provider key",
                        hotelId: 'ChIJdbadeOi7wjsRsVmEMhrj-9Q'},null,2)
                }
            </code>
          </CodeGroup>

          <CodeGroup title='Response Pay Load'>
            <code title='json'>
              {JSON.stringify(
                {
                  sessionId: '63d1a5d5c9e77c000797e6ed',
                  hotelId: 'ChIJdbadeOi7wjsRsVmEMhrj-9Q',
                  success: true,
                  details: {
                    id: 'ChIJdbadeOi7wjsRsVmEMhrj-9Q',
                    name: 'Hyatt Place Pune Hinjawadi',
                    description:
                      'With grand exterior and plush interiors, Hyatt Place Pune is an ideal option for business travellers. The hotel is in proximity to some major companies and comes with ultra modern fixtures. In tune with state-of-the-art facilities, Hyatt Place is a perfect place to relax, dine and explore.\r\n\r\n<b>Location</b>\r\nLocated in the heart of Rajiv Gandhi Infotech Park, this business hotel at Hinjewadi in Pune is close to major IT companies like Infosys, Cognizant, Persistent, Wipro, Cisco, Siemens, IBM, Credit Suisse, TCS and more. Right off the Mumbai-Pune expressway the hotel is 45 minutes from the airport.\r\n\r\nPune International Airport: 24 km approx.\r\nPune Junction: 19 km approx.\r\n\r\n<b>Features</b>\r\nGallery Cafe offers a selection of Indian and International cuisine for lunch and dinner. With 24/7 Gallery Menu guest is likely to enjoy a fresh-made meal any time day or night. 24/7 Gallery Market - open 24 hours a day, 7 days a week, offers a selection of perfectly packaged grab n go items from snacks to sandwiches, while the Bakery Case offers an array of tempting pastries and sweets. Coffee to Cocktails Bar offers specialty coffee and tea selections, and a variety of premium beers, wines and cocktails (alcohol will be served from April 2015 onwards).\r\n\r\n<b>Rooms</b>\r\nThe hotel offers 117 spacious guestrooms with a mixture of contemporary and stylish furnishings including a plush Hyatt Grand Bed, state-of-the-art media, work centre, cozy corner sofa sleeper and luxury bath amenities. This hotel is one of the few hotels in Pune which offers free breakfast served daily with stay, free Wi-Fi throughout the hotel and an option to print remotely from the comfort of guestroom.',
                    rating: 4,
                    userRating: 4.2,
                    address: {
                      lat: 18.5860926,
                      lng: 73.7361416,
                      country: 'India',
                      city: 'Pune',
                      address: 'Rajiv Gandhi Infotech Park, Phase 1, Hinjawadi',
                    },
                    amenities: [],
                    map: {
                      parking: true,
                      pool: true,
                      ac: true,
                      wifi: false,
                      tv: true,
                    },
                    type: 'HOTEL',
                    images: [
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/c6ace22c7b0111e6bcdd36cfdd80c293.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/b3ae5a6272a311e788a00a4cef95d023.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/f1e38f4672a311e7b7fa025f77df004f.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/a76ac00672a311e783e6025f77df004f.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/da5d260c72a311e799540a4cef95d023.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/8c30b45872a311e783e6025f77df004f.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/c77e08ee72a311e783e6025f77df004f.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/03ab60e672a411e78bc5025f77df004f.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/d8c51b1a72a311e788a00a4cef95d023.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/eaf0b0b072a311e7938c025f77df004f.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/2eb9bd3c72a411e788a00a4cef95d023.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/001b54ae72a411e7938c025f77df004f.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/b6a2e92c72a311e7b21e0a4cef95d023.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/e0fa7df272a311e7b345025f77df004f.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/1046133c72a411e78bc5025f77df004f.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/c260465672a311e7b21e0a4cef95d023.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/2175ab7272a411e7b345025f77df004f.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/c75dc68872a311e7b7fa025f77df004f.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/e606cda072a311e78bc5025f77df004f.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/f3e0e25872a311e7b7fa025f77df004f.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/bdcf1190cd4f11e4a65b36cfdd80c293.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/f715ca56931711e99b5a0242ac110005.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/171d2b7872a411e7b7fa025f77df004f.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/676d5f4072a311e799540a4cef95d023.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/e3f2b3be0d0011e9bddb0242ac110005.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/aab0732872a311e799540a4cef95d023.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/00a4689872a411e7b345025f77df004f.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/2baa186272a411e7938c025f77df004f.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/0ffb18fc0d0111e9a6f80242ac110003.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/1b16cce872a411e788a00a4cef95d023.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/bfe9be407ad811e9a9ca0242ac110003.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/a2fc2588865211e78d5d02393f683f32.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/c3b108d872a311e799540a4cef95d023.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/1d35484e0d0111e9b3ac0242ac110002.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/86dcd68072a311e783e6025f77df004f.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/13be127672a411e7938c025f77df004f.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/9a479d2272a311e7b345025f77df004f.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/bfe90c0c7ad811e9b3630242ac110002.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/09ae4a5872a411e788a00a4cef95d023.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/581cf1b272a411e7938c025f77df004f.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/cbf97bf672a311e7b345025f77df004f.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/c5c12fe072a311e7b21e0a4cef95d023.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/aa44586472a311e7b21e0a4cef95d023.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/0aa4542a72a411e78bc5025f77df004f.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/b89121f0cd4f11e48e4536cfdd80c293.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/1b86dc18931811e997b60242ac110003.jpg',
                      'https://fastui.cltpstatic.com/image/upload/hotels/places/hotels/7366/736600/images/d3d0ef9e72a311e78bc5025f77df004f.jpg',
                    ],
                    code: 200,
                    latency: 1674683868305,
                    errors: [],
                  },
                  errors: [],
                },
                null,
                2
              )}
            </code>
          </CodeGroup>
        </Col>
      </Row>
    </>
  );
}
