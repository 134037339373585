import React from 'react'
import {Heading} from "../Common/Heading";
import {CodeGroup} from "../Common/Code";

export default function Trips() {
	return (
		<>
           <h1>Trips Events</h1>
            <p className="lead">
                In this guide, we will look at how to you can consume events related to trips. With webhooks, your app can know when trips are updated/created in Dice,
                such as someone approving a trip.
            </p>
            <Heading level={2}>
                On Trip Created
            </Heading>
            <code>
                trips.create
            </code>
            <CodeGroup title="Sample Payload">
                <code>
                    {JSON.stringify(
                        {
                            id:"647521b23eb05207b07791e5",
                            type:"trips.created",
                            createdOn:12124121221,
                            data:{
                                "owner.type": "EMPLOYEE",
                                "owner.ownerId": "167462",
                                "trip.id": "TI003292305300001",
                                "trip.reason": "NONE",
                                "trip.created": "30-05-2023",
                                "trip.title": "nknknk",
                                "trip.start": "31-05-2023",
                                "trip.budget": "10.0",
                                "trip.end": "31-05-2023"
                            }
                        }
                        ,null,2
                    )}
                </code>
            </CodeGroup>
            <Heading level={2}>
                On Trip Approved
            </Heading>
            <code>
                trips.approved
            </code>
            <CodeGroup title="Sample Payload">
                <code>
                    {JSON.stringify(
                        {
                            id:"647521b23eb05207b07791e5",
                            type:"trips.approved",
                            createdOn:12124121221,
                            data:{
                                "owner.type": "EMPLOYEE",
                                "owner.ownerId": "167462",
                                "trip.id": "TI003292305300001",
                                "trip.reason": "NONE",
                                "trip.created": "30-05-2023",
                                "trip.title": "nknknk",
                                "trip.start": "31-05-2023",
                                "trip.budget": "10.0",
                                "trip.end": "31-05-2023",
                                "approvers":[
                                    {
                                        "name":"Lakshay Jain",
                                        "code":"E0001",
                                        "approvedOn":12124121221
                                    }
                                ]
                            }
                        }
                        ,null,2
                    )}
                </code>
            </CodeGroup>
		</>
	)
}
