export default {
	title: 'Employees',
	href: '/docs/apis/employees',
	sections:[
		{
			title:"Create Employees",
			link:"/docs/apis/employees",
			tag:"POST"
		},
		{
			title:"Create Employee Tags",
			link:"/docs/apis/employees/tags",
			tag:"POST"
		}
	]
}
