import {Col, Properties, Property, Row} from '../../Common/Property';
import {CodeGroup} from '../../Common/Code';
import Eyebrow from '../../Common/Eyebrow';
const ROUTE = '/hotel/hold';

export default function Docs() {
	return (
		<>
			<Eyebrow label={ROUTE} tag='POST'/>
			<h1>Hotel Hotel Rooms</h1>
			<p>
				This api is used to hold a particular hotel room.
			</p>
			<h2>Request Model</h2>
			<p className='lead'>
				Details of all the parameters that can be passed in the request.
			</p>
			<Row>
				<Col>
					<Properties>
						<Property name='hotelId' type='string'>
							Hotel Id you want to hold.
						</Property>
						<Property name='roomId' type='string'>
							Room Id you want to book..
						</Property>
						<Property name='sessionId' type='string'>
							Session Id generated for this request
						</Property>
						<Property name='details' type='object'>
							This contain traveller information for which rooms want to book.
						</Property>
						<Property name='gst' type='object'>
							This node contains gst details if applicable.
						</Property>
					</Properties>
				</Col>
				<Col>
					<CodeGroup tag='POST' label={ROUTE} title='Request Payload'>
						<code title='json'>
							{
								JSON.stringify({
									sessionId: "",
									hotelId: "",
									roomId: "",
									gst: {
										name: "ABC",
										number: "Gstin Number",
										email: "",
										address: ""
									},
									details: {
										travellers: [
											{
												"email": "ABC.ABC@ABC.com",
												"fName": "ABC",
												"gender": "MALE",
												"lName": "ABC",
												"mobile": "ABC"
											}
										]
									}
								}, null, 2)
							}
						</code>
					</CodeGroup>

					<CodeGroup title='Response Pay Load'>
						<code title='json'>
							{JSON.stringify(
								{
									success: true,
									id: 'AXAXAXAX',
									amount: 12002,
									serviceFees: 100
								},
								null,
								2
							)}
						</code>
					</CodeGroup>
				</Col>
			</Row>
		</>
	);
}
