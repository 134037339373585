import { Col, Properties, Property, Row } from '../../../Common/Property';
import { CodeGroup } from '../../../Common/Code';
import Eyebrow from '../../../Common/Eyebrow';
import { Note } from '../../../Common/Note';

const ROUTE = '/flight/sandbox/domestic/round/map';

export default function Docs() {
  return (
    <>
      <Eyebrow label={ROUTE} tag='POST' />
      <h1>Seat Map</h1>
      <p>
        As the name suggests, employees are a core part of Dice — the very
        reason Dice exists is so you can provide secure spends management to
        your employees. On this page, we'll dive into how to create employees
        programmatically.
      </p>
      <h2>Request Model</h2>
      <p className='lead'>
        Details of all the parameters that can be passed in the request.
      </p>
      <Row>
        <Col>
          <Properties>
            <Property name='flowId' type='string'>
              Value can be extracted from Seat Map api response.
            </Property>
            <Property name='inventoryId' type='string'>
              Value can be extracted from Seat Map api response.
            </Property>
            <Note>
              <code>flowId</code> & <code>inventoryId</code> are valid for only
              15 mins
            </Note>
          </Properties>
        </Col>
        <Col>
          <CodeGroup tag='POST' label={ROUTE} title='Request Payload'>
            <code title='cURL'>
              curl --request POST \ <br />
              --url {ROUTE} \ <br />
              --header 'Origin: Dice' \ <br />
              --header 'accept: application/json' \ <br />
              --header 'content-type: application/json' <br />
            </code>
          </CodeGroup>

          <CodeGroup title='Response Pay Load'>
            <code title='json'>
              {JSON.stringify(
                {
                  success: true,
                  app: 'Trip Jack Flights',
                  map: [
                    {
                      id: '957',
                      rows: 33,
                      firstRow: 1,
                      firstCol: 1,
                      cols: 7,
                      seats: [
                        {
                          row: 1,
                          column: 1,
                          empty: false,
                          code: '1A',
                          amount: 1199,
                          booked: false,
                          aisle: false,
                          legroom: true,
                        },
                        {
                          row: 1,
                          column: 2,
                          empty: false,
                          code: '1B',
                          amount: 1199,
                          booked: false,
                          aisle: false,
                          legroom: true,
                        },
                        {
                          row: 1,
                          column: 3,
                          empty: false,
                          code: '1C',
                          amount: 1199,
                          booked: false,
                          aisle: true,
                          legroom: true,
                        },
                        {
                          row: 2,
                          column: 1,
                          empty: false,
                          code: '2A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 2,
                          column: 2,
                          empty: false,
                          code: '2B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 2,
                          column: 3,
                          empty: false,
                          code: '2C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 2,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 2,
                          column: 5,
                          empty: false,
                          code: '2D',
                          amount: 1099,
                          booked: false,
                          aisle: true,
                          legroom: true,
                        },
                        {
                          row: 2,
                          column: 6,
                          empty: false,
                          code: '2E',
                          amount: 1099,
                          booked: false,
                          aisle: false,
                          legroom: true,
                        },
                        {
                          row: 2,
                          column: 7,
                          empty: false,
                          code: '2F',
                          amount: 1099,
                          booked: false,
                          aisle: false,
                          legroom: true,
                        },
                        {
                          row: 3,
                          column: 1,
                          empty: false,
                          code: '3A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 3,
                          column: 2,
                          empty: false,
                          code: '3B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 3,
                          column: 3,
                          empty: false,
                          code: '3C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 3,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 3,
                          column: 5,
                          empty: false,
                          code: '3D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 3,
                          column: 6,
                          empty: false,
                          code: '3E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 3,
                          column: 7,
                          empty: false,
                          code: '3F',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 4,
                          column: 1,
                          empty: false,
                          code: '4A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 4,
                          column: 2,
                          empty: false,
                          code: '4B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 4,
                          column: 3,
                          empty: false,
                          code: '4C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 4,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 4,
                          column: 5,
                          empty: false,
                          code: '4D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 4,
                          column: 6,
                          empty: false,
                          code: '4E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 4,
                          column: 7,
                          empty: false,
                          code: '4F',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 5,
                          column: 1,
                          empty: false,
                          code: '5A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 5,
                          column: 2,
                          empty: false,
                          code: '5B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 5,
                          column: 3,
                          empty: false,
                          code: '5C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 5,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 5,
                          column: 5,
                          empty: false,
                          code: '5D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 5,
                          column: 6,
                          empty: false,
                          code: '5E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 5,
                          column: 7,
                          empty: false,
                          code: '5F',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 6,
                          column: 1,
                          empty: false,
                          code: '6A',
                          amount: 0,
                          booked: true,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 6,
                          column: 2,
                          empty: false,
                          code: '6B',
                          amount: 0,
                          booked: true,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 6,
                          column: 3,
                          empty: false,
                          code: '6C',
                          amount: 0,
                          booked: true,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 6,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 6,
                          column: 5,
                          empty: false,
                          code: '6D',
                          amount: 0,
                          booked: true,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 6,
                          column: 6,
                          empty: false,
                          code: '6E',
                          amount: 0,
                          booked: true,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 6,
                          column: 7,
                          empty: false,
                          code: '6F',
                          amount: 0,
                          booked: true,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 7,
                          column: 1,
                          empty: false,
                          code: '7A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 7,
                          column: 2,
                          empty: false,
                          code: '7B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 7,
                          column: 3,
                          empty: false,
                          code: '7C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 7,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 7,
                          column: 5,
                          empty: false,
                          code: '7D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 7,
                          column: 6,
                          empty: false,
                          code: '7E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 7,
                          column: 7,
                          empty: false,
                          code: '7F',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 8,
                          column: 1,
                          empty: false,
                          code: '8A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 8,
                          column: 2,
                          empty: false,
                          code: '8B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 8,
                          column: 3,
                          empty: false,
                          code: '8C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 8,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 8,
                          column: 5,
                          empty: false,
                          code: '8D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 8,
                          column: 6,
                          empty: false,
                          code: '8E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 8,
                          column: 7,
                          empty: false,
                          code: '8F',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 9,
                          column: 1,
                          empty: false,
                          code: '9A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 9,
                          column: 2,
                          empty: false,
                          code: '9B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 9,
                          column: 3,
                          empty: false,
                          code: '9C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 9,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 9,
                          column: 5,
                          empty: false,
                          code: '9D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 9,
                          column: 6,
                          empty: false,
                          code: '9E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 9,
                          column: 7,
                          empty: false,
                          code: '9F',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 10,
                          column: 1,
                          empty: false,
                          code: '10A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 10,
                          column: 2,
                          empty: false,
                          code: '10B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 10,
                          column: 3,
                          empty: false,
                          code: '10C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 10,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 10,
                          column: 5,
                          empty: false,
                          code: '10D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 10,
                          column: 6,
                          empty: false,
                          code: '10E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 10,
                          column: 7,
                          empty: false,
                          code: '10F',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 11,
                          column: 1,
                          empty: false,
                          code: '11A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 11,
                          column: 2,
                          empty: false,
                          code: '11B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 11,
                          column: 3,
                          empty: false,
                          code: '11C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 11,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 11,
                          column: 5,
                          empty: false,
                          code: '11D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 11,
                          column: 6,
                          empty: false,
                          code: '11E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 11,
                          column: 7,
                          empty: false,
                          code: '11F',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 12,
                          column: 1,
                          empty: false,
                          code: '12A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 12,
                          column: 2,
                          empty: false,
                          code: '12B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 12,
                          column: 3,
                          empty: false,
                          code: '12C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 12,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 12,
                          column: 5,
                          empty: false,
                          code: '12D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 12,
                          column: 6,
                          empty: false,
                          code: '12E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 12,
                          column: 7,
                          empty: false,
                          code: '12F',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 14,
                          column: 1,
                          empty: false,
                          code: '14A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 14,
                          column: 2,
                          empty: false,
                          code: '14B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 14,
                          column: 3,
                          empty: false,
                          code: '14C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 14,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 14,
                          column: 5,
                          empty: false,
                          code: '14D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 14,
                          column: 6,
                          empty: false,
                          code: '14E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 14,
                          column: 7,
                          empty: false,
                          code: '14F',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 15,
                          column: 1,
                          empty: false,
                          code: '15A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 15,
                          column: 2,
                          empty: false,
                          code: '15B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 15,
                          column: 3,
                          empty: false,
                          code: '15C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 15,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 15,
                          column: 5,
                          empty: false,
                          code: '15D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 15,
                          column: 6,
                          empty: false,
                          code: '15E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 15,
                          column: 7,
                          empty: false,
                          code: '15F',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 16,
                          column: 1,
                          empty: false,
                          code: '16A',
                          amount: 1099,
                          booked: false,
                          aisle: false,
                          legroom: true,
                        },
                        {
                          row: 16,
                          column: 2,
                          empty: false,
                          code: '16B',
                          amount: 1099,
                          booked: false,
                          aisle: false,
                          legroom: true,
                        },
                        {
                          row: 16,
                          column: 3,
                          empty: false,
                          code: '16C',
                          amount: 1099,
                          booked: false,
                          aisle: true,
                          legroom: true,
                        },
                        {
                          row: 16,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 16,
                          column: 5,
                          empty: false,
                          code: '16D',
                          amount: 1099,
                          booked: false,
                          aisle: true,
                          legroom: true,
                        },
                        {
                          row: 16,
                          column: 6,
                          empty: false,
                          code: '16E',
                          amount: 1099,
                          booked: false,
                          aisle: false,
                          legroom: true,
                        },
                        {
                          row: 16,
                          column: 7,
                          empty: false,
                          code: '16F',
                          amount: 1099,
                          booked: false,
                          aisle: false,
                          legroom: true,
                        },
                        {
                          row: 17,
                          column: 1,
                          empty: false,
                          code: '17A',
                          amount: 1099,
                          booked: false,
                          aisle: false,
                          legroom: true,
                        },
                        {
                          row: 17,
                          column: 2,
                          empty: false,
                          code: '17B',
                          amount: 1099,
                          booked: false,
                          aisle: false,
                          legroom: true,
                        },
                        {
                          row: 17,
                          column: 3,
                          empty: false,
                          code: '17C',
                          amount: 1099,
                          booked: false,
                          aisle: true,
                          legroom: true,
                        },
                        {
                          row: 17,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 17,
                          column: 5,
                          empty: false,
                          code: '17D',
                          amount: 1099,
                          booked: false,
                          aisle: true,
                          legroom: true,
                        },
                        {
                          row: 17,
                          column: 6,
                          empty: false,
                          code: '17E',
                          amount: 1099,
                          booked: false,
                          aisle: false,
                          legroom: true,
                        },
                        {
                          row: 17,
                          column: 7,
                          empty: false,
                          code: '17F',
                          amount: 1099,
                          booked: false,
                          aisle: false,
                          legroom: true,
                        },
                        {
                          row: 18,
                          column: 1,
                          empty: false,
                          code: '18A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 18,
                          column: 2,
                          empty: false,
                          code: '18B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 18,
                          column: 3,
                          empty: false,
                          code: '18C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 18,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 18,
                          column: 5,
                          empty: false,
                          code: '18D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 18,
                          column: 6,
                          empty: false,
                          code: '18E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 18,
                          column: 7,
                          empty: false,
                          code: '18F',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 19,
                          column: 1,
                          empty: false,
                          code: '19A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 19,
                          column: 2,
                          empty: false,
                          code: '19B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 19,
                          column: 3,
                          empty: false,
                          code: '19C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 19,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 19,
                          column: 5,
                          empty: false,
                          code: '19D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 19,
                          column: 6,
                          empty: false,
                          code: '19E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 19,
                          column: 7,
                          empty: false,
                          code: '19F',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 20,
                          column: 1,
                          empty: false,
                          code: '20A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 20,
                          column: 2,
                          empty: false,
                          code: '20B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 20,
                          column: 3,
                          empty: false,
                          code: '20C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 20,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 20,
                          column: 5,
                          empty: false,
                          code: '20D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 20,
                          column: 6,
                          empty: false,
                          code: '20E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 20,
                          column: 7,
                          empty: false,
                          code: '20F',
                          amount: 0,
                          booked: true,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 21,
                          column: 1,
                          empty: false,
                          code: '21A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 21,
                          column: 2,
                          empty: false,
                          code: '21B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 21,
                          column: 3,
                          empty: false,
                          code: '21C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 21,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 21,
                          column: 5,
                          empty: false,
                          code: '21D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 21,
                          column: 6,
                          empty: false,
                          code: '21E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 21,
                          column: 7,
                          empty: false,
                          code: '21F',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 22,
                          column: 1,
                          empty: false,
                          code: '22A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 22,
                          column: 2,
                          empty: false,
                          code: '22B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 22,
                          column: 3,
                          empty: false,
                          code: '22C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 22,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 22,
                          column: 5,
                          empty: false,
                          code: '22D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 22,
                          column: 6,
                          empty: false,
                          code: '22E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 22,
                          column: 7,
                          empty: false,
                          code: '22F',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 23,
                          column: 1,
                          empty: false,
                          code: '23A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 23,
                          column: 2,
                          empty: false,
                          code: '23B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 23,
                          column: 3,
                          empty: false,
                          code: '23C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 23,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 23,
                          column: 5,
                          empty: false,
                          code: '23D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 23,
                          column: 6,
                          empty: false,
                          code: '23E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 23,
                          column: 7,
                          empty: false,
                          code: '23F',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 24,
                          column: 1,
                          empty: false,
                          code: '24A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 24,
                          column: 2,
                          empty: false,
                          code: '24B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 24,
                          column: 3,
                          empty: false,
                          code: '24C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 24,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 24,
                          column: 5,
                          empty: false,
                          code: '24D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 24,
                          column: 6,
                          empty: false,
                          code: '24E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 24,
                          column: 7,
                          empty: false,
                          code: '24F',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 25,
                          column: 1,
                          empty: false,
                          code: '25A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 25,
                          column: 2,
                          empty: false,
                          code: '25B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 25,
                          column: 3,
                          empty: false,
                          code: '25C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 25,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 25,
                          column: 5,
                          empty: false,
                          code: '25D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 25,
                          column: 6,
                          empty: false,
                          code: '25E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 25,
                          column: 7,
                          empty: false,
                          code: '25F',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 26,
                          column: 1,
                          empty: false,
                          code: '26A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 26,
                          column: 2,
                          empty: false,
                          code: '26B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 26,
                          column: 3,
                          empty: false,
                          code: '26C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 26,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 26,
                          column: 5,
                          empty: false,
                          code: '26D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 26,
                          column: 6,
                          empty: false,
                          code: '26E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 26,
                          column: 7,
                          empty: false,
                          code: '26F',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 27,
                          column: 1,
                          empty: false,
                          code: '27A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 27,
                          column: 2,
                          empty: false,
                          code: '27B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 27,
                          column: 3,
                          empty: false,
                          code: '27C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 27,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 27,
                          column: 5,
                          empty: false,
                          code: '27D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 27,
                          column: 6,
                          empty: false,
                          code: '27E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 27,
                          column: 7,
                          empty: false,
                          code: '27F',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 28,
                          column: 1,
                          empty: false,
                          code: '28A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 28,
                          column: 2,
                          empty: false,
                          code: '28B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 28,
                          column: 3,
                          empty: false,
                          code: '28C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 28,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 28,
                          column: 5,
                          empty: false,
                          code: '28D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 28,
                          column: 6,
                          empty: false,
                          code: '28E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 28,
                          column: 7,
                          empty: false,
                          code: '28F',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 29,
                          column: 1,
                          empty: false,
                          code: '29A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 29,
                          column: 2,
                          empty: false,
                          code: '29B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 29,
                          column: 3,
                          empty: false,
                          code: '29C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 29,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 29,
                          column: 5,
                          empty: false,
                          code: '29D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 29,
                          column: 6,
                          empty: false,
                          code: '29E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 29,
                          column: 7,
                          empty: false,
                          code: '29F',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 30,
                          column: 1,
                          empty: false,
                          code: '30A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 30,
                          column: 2,
                          empty: false,
                          code: '30B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 30,
                          column: 3,
                          empty: false,
                          code: '30C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 30,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 30,
                          column: 5,
                          empty: false,
                          code: '30D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 30,
                          column: 6,
                          empty: false,
                          code: '30E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 30,
                          column: 7,
                          empty: false,
                          code: '30F',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 31,
                          column: 1,
                          empty: false,
                          code: '31A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 31,
                          column: 2,
                          empty: false,
                          code: '31B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 31,
                          column: 3,
                          empty: false,
                          code: '31C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 31,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 31,
                          column: 5,
                          empty: false,
                          code: '31D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 31,
                          column: 6,
                          empty: false,
                          code: '31E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 31,
                          column: 7,
                          empty: false,
                          code: '31F',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 32,
                          column: 1,
                          empty: false,
                          code: '32A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 32,
                          column: 2,
                          empty: false,
                          code: '32B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 32,
                          column: 3,
                          empty: false,
                          code: '32C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 32,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 32,
                          column: 5,
                          empty: false,
                          code: '32D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 32,
                          column: 6,
                          empty: false,
                          code: '32E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 32,
                          column: 7,
                          empty: false,
                          code: '32F',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 33,
                          column: 1,
                          empty: false,
                          code: '33A',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 33,
                          column: 2,
                          empty: false,
                          code: '33B',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 33,
                          column: 3,
                          empty: false,
                          code: '33C',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 33,
                          column: 4,
                          empty: true,
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 33,
                          column: 5,
                          empty: false,
                          code: '33D',
                          amount: 0,
                          booked: false,
                          aisle: true,
                          legroom: false,
                        },
                        {
                          row: 33,
                          column: 6,
                          empty: false,
                          code: '33E',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                        {
                          row: 33,
                          column: 7,
                          empty: false,
                          code: '33F',
                          amount: 0,
                          booked: false,
                          aisle: false,
                          legroom: false,
                        },
                      ],
                    },
                  ],
                },
                null,
                2
              )}
            </code>
          </CodeGroup>
        </Col>
      </Row>
    </>
  );
}
