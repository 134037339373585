import {Note} from "../../Common/Note";
import {Col, Properties, Property, Row} from "../../Common/Property";
import {CodeGroup} from "../../Common/Code";
import Eyebrow from "../../Common/Eyebrow";


export default function Docs(){
	return (
		<>
			<Eyebrow label="//provider.dice.tech/api/v1/employees/upload" tag="POST" />
			<h1>
				Create Employees
			</h1>
			<p>
				As the name suggests, employees are a core part of Dice — the very reason Dice exists is so you can provide secure spends management to your employees. On this page, we'll dive into how to create employees programmatically.
			</p>
			<Note>
				By default this api supports updation of employees as well. If you send an employee data which already exists then it will compare
				data with existing record and do an update operation.
			</Note>
			<h2>
				The Employee Model
			</h2>
			<p className="lead">
				The employee model contains all the information about your employees, such as their name, avatar, and phone number.
			</p>
			<Row>
				<Col>
					<Properties>
						<Property name="code" type="string">
							Unique identifier for the employee.
						</Property>
						<Property name="firstName" type="string">
							First name of the employee
						</Property>
						<Property name="lastName" type="string">
							Last name of the employee. If your user don't have last name then pass first name as last name.
						</Property>
						<Property name="email" type="string">
							Email address of the employee. Its a unique identifier too. Pass official email address.
						</Property>
						<Property name="mobile" type="string">
							Mobile number of the employee. Its a unique identifier too.
						</Property>
						<Property name="office" type="string">
							Office in which this employee exists
						</Property>
						<Property name="department" type="string">
							Department in which this employee exists
						</Property>
						<Property name="gender" type="enum(MALE|FEMALE)">
							Gender of the employee
						</Property>
						<Property name="dob" type="number(optional)">
							Timestamp in millis for this particular employee
						</Property>
						<Property name="costCenter" type="string(optional)">
							Cost Center of this particular employee
						</Property>
					</Properties>
				</Col>
				<Col>
					<CodeGroup tag="POST" label="/api/v1/employees/upload" title="Request Payload">
						<code title="json">
							{
								JSON.stringify({
									items : [
										{
											code:"E0001",
											firstName : "Lakshay",
											lastName: "Jain",
											email: "xxxxx@dice.tech",
											mobile : "X9X9X9X9X9",
											office : "Pune",
											department : "Founders",
											gender : "MALE",
											costCenter :"0001",
											dob : 1210212121
										}
									]
								},null,2)
							}
						</code>
					</CodeGroup>

					<CodeGroup  title="Response Pay Load">
						<code title="json">
							{
								JSON.stringify({
									success : true,
									errors : {
										"E0001" : "Last name is missing"
									}
								},null,2)
							}
						</code>
					</CodeGroup>
				</Col>
			</Row>
		</>
	)
}
