import React from 'react'
import Landing from './Landing'
import Features from "./Features";

export default function Home() {
	return (
		<>
			<Landing/>
			<Features/>
		</>
	)
}
