import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Search from './Search';
import FareRules from './FareRules';
import SeatMap from './SeatMap';
import ReviewFlight from './ReviewFlight';
import HoldFlight from './HoldFlight';
import BookingSession from './BookingSession';
import SyncBook from './SyncBook';
import AsyncBook from './AsyncBook';
import RetrieveBooking from './RetrieveBooking';

export default function index() {
  return (
    <Routes>
      <Route element={<Search />} path='/search' />
      <Route element={<FareRules />} path='/fare-rules' />
      <Route element={<SeatMap />} path='/seat-map' />
      <Route element={<ReviewFlight />} path='/review' />
      <Route element={<HoldFlight />} path='/hold' />
      <Route element={<BookingSession />} path='/session' />
      <Route element={<SyncBook />} path='/book-flight' />
      <Route element={<AsyncBook />} path='/async-book-apis' />
      <Route element={<RetrieveBooking />} path='/retrieve-booking' />
    </Routes>
  );
}
