import React,{useState,useEffect,useRef} from 'react'
import SearchDialog from "./SearchDialog";

function SearchIcon(props) {
	return (
		<svg viewBox="0 0 20 20" fill="none" aria-hidden="true" {...props}>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M12.01 12a4.25 4.25 0 1 0-6.02-6 4.25 4.25 0 0 0 6.02 6Zm0 0 3.24 3.25"
			/>
		</svg>
	)
}

function useSearchProps() {
	let buttonRef = useRef()
	let [open, setOpen] = useState(false)

	return {
		buttonProps: {
			ref: buttonRef,
			onClick() {
				setOpen(true)
			},
		},
		dialogProps: {
			open,
			setOpen(open) {
				let { width, height } = buttonRef.current.getBoundingClientRect()
				if (!open || (width !== 0 && height !== 0)) {
					setOpen(open)
				}
			},
		},
	}
}

export default function Search() {
	let [modifierKey, setModifierKey] = useState()
	let { buttonProps, dialogProps } = useSearchProps()

	useEffect(() => {
		setModifierKey(
			/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform) ? '⌘' : 'Ctrl '
		)
	}, [])

	return (
		<div className="hidden lg:block lg:max-w-md lg:flex-auto">
			<button
				type="button"
				className="hidden h-8 w-full items-center gap-2 rounded-full bg-white pl-2 pr-3 text-sm text-zinc-500 ring-1 ring-zinc-900/10 transition hover:ring-zinc-900/20 dark:bg-white/5 dark:text-zinc-400 dark:ring-inset dark:ring-white/10 dark:hover:ring-white/20 lg:flex focus:[&:not(:focus-visible)]:outline-none"
				{...buttonProps}
			>
				<SearchIcon className="h-5 w-5 stroke-current" />
				Find something...
				<kbd className="ml-auto text-2xs text-zinc-400 dark:text-zinc-500">
					<kbd className="font-sans">{modifierKey}</kbd>
					<kbd className="font-sans">K</kbd>
				</kbd>
			</button>

			<SearchDialog className="hidden lg:block" {...dialogProps} />
		</div>
	)
}
