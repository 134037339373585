import React from 'react'
import {Route, Routes} from "react-router-dom";
import Expenses from "./Expenses";
import Bookings from "./Bookings";

export default function index() {
	return (
		<Routes>
			<Route element={<Expenses/>} path=""/>
			<Route element={<Bookings/>} path="bookings"/>
		</Routes>
	)
}
