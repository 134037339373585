import Apis from './Apis/navigation';
import Webhook from './Webhooks/navigation';
import FlightAPIs from './FlightAPIs/navigation';
import HotelAPIs from './HotelAPIs/navigation';

export default [
  {
    title: 'Guides',
    links: [
      {
        title: 'Introduction',
        href: '/docs/home',
      },
      { title: 'Quickstart', href: '/docs/quickstart' },
      { title: 'FunX', href: '/docs/funx' },
      { title: 'Authentication', href: '/docs/authentication' },
      { title: 'Pagination', href: '/docs/pagination' },
      { title: 'Errors', href: '/docs/errors' },
      { title: 'Webhooks', href: '/docs/webhooks' },
    ],
  },
  Webhook,
  Apis,
  FlightAPIs,
  HotelAPIs,
];
