import { Col, Properties, Property, Row } from '../../../Common/Property';
import { CodeGroup } from '../../../Common/Code';
import Eyebrow from '../../../Common/Eyebrow';

export default function Docs() {
  return (
    <>
      <Eyebrow label='/flight/sandbox/domestic/hold' tag='POST' />
      <h1>Hold Flight</h1>
      <p>
        As the name suggests, employees are a core part of Dice — the very
        reason Dice exists is so you can provide secure spends management to
        your employees. On this page, we'll dive into how to create employees
        programmatically.
      </p>
      <h2>Request Model</h2>
      <p className='lead'>
        Details of all the parameters that can be passed in the request.
      </p>
      <Row>
        <Col>
          <Properties>
            <Property name='inventoryId' type='string'>
              Value can be extracted from Seat Map api response.
            </Property>
            <Property name='flowId' type='string'>
              Value can be extracted from Seat Map api response.
            </Property>
          </Properties>
        </Col>
        <Col>
          <CodeGroup
            tag='POST'
            label='/flight/sandbox/domestic/hold'
            title='Request Payload'
          >
            <code title='cURL'>
              curl --request POST \ <br />
              /flight/sandbox/domestic/hold \ <br />
              --header 'Origin: Dice' \ <br />
              --header 'accept: application/json' \ <br />
              --header 'content-type: application/json' <br />
            </code>
          </CodeGroup>

          <CodeGroup title='Response Pay Load'>
            <code title='json'>
              {JSON.stringify(
                {
                  holdId: 'sandbox::flight::hold:631f5d31c9e77c00075ba0e8',
                  amount: 4485.08,
                  travellerCount: 1,
                },
                null,
                2
              )}
            </code>
          </CodeGroup>
        </Col>
      </Row>
    </>
  );
}
