import { Col, Properties, Property, Row } from '../../../Common/Property';
import { CodeGroup } from '../../../Common/Code';
import Eyebrow from '../../../Common/Eyebrow';

export default function Docs() {
  return (
    <>
      <Eyebrow label='/flight/sandbox/domestic/order' tag='POST' />
      <h1>Async Book</h1>
      <p>
        As the name suggests, employees are a core part of Dice — the very
        reason Dice exists is so you can provide secure spends management to
        your employees. On this page, we'll dive into how to create employees
        programmatically.
      </p>
      <h2>Request Model</h2>
      <p className='lead'>
        Details of all the parameters that can be passed in the request.
      </p>
      <Row>
        <Col>
          <Properties>
            <Property name='holdId' type='string'>
              Value can be extracted from Async Book api response.
            </Property>
            <Property name='referenceId' type='string'>
              Value can be extracted from Async Book api response.
            </Property>
            <Property name='accountId' type='string'>
              Value can be extracted from Async Book api response.
            </Property>
          </Properties>
        </Col>
        <Col>
          <CodeGroup
            tag='POST'
            label='/flight/sandbox/domestic/order'
            title='Request Payload'
          >
            <code title='cURL'>
              curl --request POST \ <br />
              --url https://saasapi.cleartrip.com/flight/sandbox/domestic/order
              \ <br />
              --header 'Origin: Dice' \ <br />
              --header 'accept: application/json' \ <br />
              --header 'content-type: application/json' \ <br />
              --data ' <br />
              {JSON.stringify({
                referenceId: 'Optional',
                accountId: 'Optional',
              })}
              '
            </code>
          </CodeGroup>

          <CodeGroup title='Response Pay Load'>
            <code title='json'>
              {JSON.stringify(
                {
                  bookingId: '632d9d674cedfd00082f5232',
                  status: 'ONGOING',
                },
                null,
                2
              )}
            </code>
          </CodeGroup>
        </Col>
      </Row>
    </>
  );
}
