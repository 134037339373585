import React from 'react'
import {Route, Routes} from "react-router-dom";
import Vouchers from "./Vouchers";

export default function index() {
	return (
		<Routes>
			<Route element={<Vouchers/>} path="vouchers"/>
		</Routes>
	)
}
